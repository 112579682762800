<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Dashboard") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.Hardware") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer">
      {{ t("Pos.Add") }}
    </a-button>
  </div>

  <div>
    <a-table
      :columns="columns"
      :data-source="state.data"
      :pagination="false"
      class="table-container"
    >
      <template #emptyText>
        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title>{{ t("Pos.View") }}</template>
              <a-button
                class="view-btn"
                @click="() => showDisplayDrawer(record)"
              >
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Edit") }}</template>
              <a-button type="primary" @click="() => showDrawer(record)">
                <template #icon>
                  <EditOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Delete") }}</template>
              <a-button type="primary" danger @click="showDeletePopup(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Login") }}</template>
              <a-button
                class="login-btn"
                @click="showModal(record)"
                :disabled="record.type === 'printer'"
              >
                <template #icon>
                  <LoginOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.LogOut") }}</template>
              <a-button
                danger
                @click="logout(record.id)"
                :disabled="record.type === 'printer'"
              >
                <template #icon>
                  <LogoutOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Refresh") }}</template>
              <a-button
                class="variant-btn"
                @click="refresh(record.id)"
                :disabled="record.type === 'printer'"
              >
                <template #icon>
                  <RedoOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template
          v-if="column.key === 'start_date' || column.key === 'end_date'"
        >
          {{ formatDisplayDate(record[column.key]) }}
        </template>

        <template v-if="column.key === 'is_active'">
          <template v-if="record.is_active === 1 || record.is_active === true">
            <check-circle-two-tone two-tone-color="#52c41a" />
          </template>
          <template v-if="record.is_active === 0 || record.is_active === false">
            <close-circle-two-tone two-tone-color="#ff4d4f" />
          </template>
        </template>

        <template v-if="column.key == 'type'">
          <template v-if="column.key === 'type'">
            {{ t(`Pos.${record.type}`) }}
          </template>
        </template>
      </template>

      <!--search -->
      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <!--search -->

      <template v-if="column.key === 'is_active'">
        <template v-if="record[column.dataIndex] === 'true'">
          <check-circle-two-tone two-tone-color="#52c41a" />
        </template>
        <template v-else>
          <CheckCircleOutlined />
        </template>
      </template>
    </a-table>

    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        v-model:pageSize="perPage"
        :total="state.totalPages"
        @change="handlePageChange"
        :default-page-size="perPage"
        :showSizeChanger="false"
      />

      <a-select
        class="select-input"
        v-model:value="selectPagination"
        @change="handleSelectPaginationChange"
        v-if="state.totalPages > 10"
      >
        <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
      </a-select>
    </div>

    <!-- display drawer -->
    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      @after-open-change="afterOpenChange"
    >
      <template #title>
        <p :class="{ 'is-rtl': textDirection === 'rtl' }">
          {{ t("Pos.DeviceInfo") }}
        </p>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="24">
            <p>
              <span class="info-title">{{ t("Pos.Name") }} </span
              >{{ form.name }}
            </p>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.Active") }} </span>
              <template v-if="form.is_active === 1 || form.is_active === true">
                <check-circle-two-tone two-tone-color="#52c41a" />
              </template>
              <template v-if="form.is_active === 0 || form.is_active === false">
                <close-circle-two-tone two-tone-color="#ff4d4f" />
              </template>
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.Type") }} </span
              >{{ t(`Pos.${form.type}`) }}
            </p>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.CreatedAt") }} </span
              >{{ formatDisplayDate(form.created_at) }}
            </p>
          </a-col>

          <a-col :span="12">
            <p v-if="form.pinged_at !== null">
              <span class="info-title">{{ t("Pos.PingedAt") }} :</span
              >{{ formatDisplayDate(form.pinged_at) }}
            </p>
            <template v-if="form.pinged_at === null || form.pinged_at === ''">
              <p>
                <span class="info-title">{{ t("Pos.PingedAt") }} </span
                ><a-tag>{{ t("Pos.Emptyfield") }}</a-tag>
              </p>
            </template>
          </a-col>
        </a-row>

        <template v-if="form.config !== ''">
          <a-row
            v-if="
              configItemsUpdate === 'kitchenConf' ||
              configItemsUpdate === 'menuConf' ||
              configItemsUpdate === 'orderConf'
            "
          >
            <a-col :span="24">
              <p class="config-title">{{ t("Pos.Store") }}</p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate !== 'printerConf'">
            <a-col :span="8">
              <p>
                <span class="info-title">{{ t("Pos.PrimaryColor") }} </span>
                <input type="color" v-model="form.primaryColor" disabled />
              </p>
            </a-col>

            <a-col :span="8">
              <p>
                <span class="info-title">{{ t("Pos.SecondaryColor") }} </span>
                <input type="color" v-model="form.primaryLight" disabled />
              </p>
            </a-col>
            <a-col :span="8">
              <p>
                <span class="info-title">{{ t("Pos.otherColor") }} </span>
                <input type="color" v-model="form.secondaryColor" disabled />
              </p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.DefaultLanguage") }} </span>
                <span v-if="form.defaultLanguage === 'en'">{{
                  t("Pos.English")
                }}</span>
                <span v-if="form.defaultLanguage === 'fr'">{{
                  t("Pos.French")
                }}</span>
                <span v-if="form.defaultLanguage === 'ar'">{{
                  t("Pos.Arabic")
                }}</span>
              </p>
            </a-col>

            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.StoreName") }} </span
                >{{ form.store_name }}
              </p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.DefaultRoute") }} </span>
                {{ form.defaultRoute }}
                <a-tag v-if="form.defaultRoute == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>

            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.SafeSleepTime") }} </span>
                {{ form.hibernate }}
                <a-tag v-if="form.hibernate == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate !== 'printerConf'">
            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.IpAddress") }} </span>
                {{ form.ip_address }}
                <a-tag v-if="form.ip_address == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>

            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.MacAddress") }} </span>
                {{ form.mac_address }}
                <a-tag v-if="form.mac_address == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>
          </a-row>

          <a-row v-if="configItemsUpdate === 'posConf'">
            <a-col :span="12">
              <p>
                <span class="info-title"
                  >{{ t("Pos.DefaultTableFilter") }} :
                </span>
                {{
                  getTableLocationName(form.config.posConfig.defaultTableFilter)
                }}
                <a-tag
                  v-if="form.config.posConfig.defaultTableFilter == null"
                  >{{ t("Pos.Emptyfield") }}</a-tag
                >
              </p>
            </a-col>

            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.Warehouse") }} : </span>
                {{ getWarehouseName(form.config.store.warehouse_id) }}
                <a-tag v-if="form.config.warehouse_id == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>
          </a-row>

          <a-row v-if="configItemsUpdate === 'posConf'">
            <a-col :span="24">
              <p class="config-title">{{ t("Pos.Pos") }}</p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
            <a-col :span="8">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.config.posConfig.show_table === 1 ||
                    form.config.posConfig.show_table === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.config.posConfig.show_table === 0 ||
                    form.config.posConfig.show_table === false
                  "
                />
                <span class="info-title title-check">{{
                  t("Pos.ShowTable")
                }}</span>
              </p>
            </a-col>

            <a-col :span="8">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.config.posConfig.show_cashier === 1 ||
                    form.config.posConfig.show_cashier === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.config.posConfig.show_cashier === 0 ||
                    form.config.posConfig.show_cashier === false
                  "
                />
                <span class="info-title title-check">{{
                  t("Pos.ShowCashier")
                }}</span>
              </p>
            </a-col>

            <a-col :span="8">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.config.posConfig.showKeyboard === 1 ||
                    form.config.posConfig.showKeyboard === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.config.posConfig.showKeyboard === 0 ||
                    form.config.posConfig.showKeyboard === false
                  "
                />
                <span class="info-title title-check">{{
                  t("Pos.ShowKeyboard")
                }}</span>
              </p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
            <a-col :span="12">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.config.posConfig.open_drawer_after_printing === 1 ||
                    form.config.posConfig.open_drawer_after_printing === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.config.posConfig.open_drawer_after_printing === 0 ||
                    form.config.posConfig.open_drawer_after_printing === false
                  "
                />

                <span class="info-title title-check">{{
                  t("Pos.openDrawerAfterPrint")
                }}</span>
              </p>
            </a-col>

            <a-col :span="12" v-if="configItemsUpdate === 'posConf'">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.config.posConfig.allow_print_before_payement === 1 ||
                    form.config.posConfig.allow_print_before_payement === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.config.posConfig.allow_print_before_payement === 0 ||
                    form.config.posConfig.allow_print_before_payement === false
                  "
                />

                <span class="info-title title-check"
                  >{{ t("Pos.allowPrintBefore") }}
                </span>
              </p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
            <a-col :span="12">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.config.posConfig.show_products === 1 ||
                    form.config.posConfig.show_products === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.config.posConfig.show_products === 0 ||
                    form.config.posConfig.show_products === false
                  "
                />

                <span class="info-title title-check">{{
                  t("Pos.showProducts")
                }}</span>
              </p>
            </a-col>

            <a-col :span="12">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.config.posConfig.show_refundables === 1 ||
                    form.config.posConfig.show_refundables === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.config.posConfig.show_refundables === 0 ||
                    form.config.posConfig.show_refundables === false
                  "
                />
                <span class="info-title title-check">{{
                  t("Pos.showRefundables")
                }}</span>
              </p>
            </a-col>
          </a-row>

          <a-row v-if="configItemsUpdate === 'posConf'">
            <a-col :span="12">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.config.posConfig.allow_print_after_payement === 1 ||
                    form.config.posConfig.allow_print_after_payement === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.config.posConfig.allow_print_after_payement === 0 ||
                    form.config.posConfig.allow_print_after_payement === false
                  "
                />

                <span class="info-title title-check"
                  >{{ t("Pos.allowPrintAfter") }}
                </span>
              </p>
            </a-col>

            <a-col :span="12" v-if="configItemsUpdate === 'posConf'">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="form.generate_sale === 1 || form.generate_sale === true"
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.generate_sale === 0 || form.generate_sale === false
                  "
                />

                <span class="info-title title-check"
                  >{{ t("Pos.generateSale") }}
                </span>
              </p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
            <a-col :span="24">
              <p>
                <check-circle-two-tone
                  two-tone-color="#52c41a"
                  v-if="
                    form.has_second_display === 1 ||
                    form.has_second_display === true
                  "
                />
                <close-circle-two-tone
                  two-tone-color="#ff4d4f"
                  v-if="
                    form.has_second_display === 0 ||
                    form.has_second_display === false
                  "
                />

                <span class="info-title title-check">{{
                  t("Pos.hasSecondScreen")
                }}</span>
              </p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="form.has_second_display">
           
            <a-col :span="12" class="img-container" v-if="form.secondBgImg64!=='' ">
                <span class="info-title">{{t('Pos.secondScreenBgImage')}}</span>
                <a-image
                    :width="100"
                    :height="100"
                    :src="form.secondBgImg64"
                />  
            </a-col>
            <a-col :span="12" class="img-container" v-if="form.secondSideImg64!=''">
                <span class="info-title">{{t('Pos.secondScreenSide')}}</span>
                <a-image
                    :width="100"
                    :height="100"
                    :src="form.secondSideImg64"
                />  
            </a-col>
          </a-row>

          <a-row v-if="configItemsUpdate === 'posConf'">
            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.DisplayText") }} : </span>
                {{ form.config.posConfig.showTextLcd }}
                <a-tag v-if="form.config.posConfig.showTextLcd == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>

            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.footerTxt") }} : </span>
                {{ form.config.posConfig.footer_text }}
                <a-tag v-if="form.config.posConfig.footer_text == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>
          </a-row>

          <a-row
            v-if="
              configItemsUpdate === 'posConf' ||
              configItemsUpdate === 'orderConf'
            "
          >
            <a-col :span="12">
              <p>
                <a-tooltip>
                  <template #title>{{ t("Pos.decimalPercisionTxt") }}</template>
                  <QuestionCircleOutlined class="tooltip-icon" />
                </a-tooltip>
                <span class="info-title"
                  >{{ t("Pos.decimalPercision") }} :
                </span>
                {{ form.decimal_precision }}
                <a-tag v-if="form.decimal_precision == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>
          </a-row>

          <a-row v-if="configItemsUpdate === 'posConf'">
            <a-col :span="12">
              <p>
                <a-tooltip>
                  <template #title>{{
                    t("Pos.RedirectAfterCartTxT")
                  }}</template>
                  <QuestionCircleOutlined class="tooltip-icon" />
                </a-tooltip>
                <span class="info-title"
                  >{{ t("Pos.RedirectAfterCart") }} :
                </span>
                {{ form.redirectAfterCart }}
                <a-tag v-if="form.redirectAfterCart == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>
          </a-row>

          <a-row v-if="configItemsUpdate === 'printerConf'">
            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.IpAddress") }} </span>
                {{ form.config.ip_address }}
                <a-tag v-if="form.config.ip_address == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>

            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.MacAddress") }} </span>
                {{ form.config.mac_address }}
                <a-tag v-if="form.config.mac_address == null">{{
                  t("Pos.Emptyfield")
                }}</a-tag>
              </p>
            </a-col>
          </a-row>

          <a-row :gutter="16" v-if="configItemsUpdate === 'printerConf'">
            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.DefaultLanguage") }} </span>
                <span v-if="form.config.defaultLanguage === 'en'">{{
                  t("Pos.English")
                }}</span>
                <span v-if="form.config.defaultLanguage === 'fr'">{{
                  t("Pos.French")
                }}</span>
                <span v-if="form.config.defaultLanguage === 'ar'">{{
                  t("Pos.Arabic")
                }}</span>
              </p>
            </a-col>

            <a-col :span="12">
              <p>
                <span class="info-title">{{ t("Pos.ProductionPoint") }} </span>
                {{ getProductionPointName(form.production_point_id) }}
              </p>
            </a-col>
          </a-row>
        </template>

        <!-- v2 -->

        <!-- <a-row v-if="configItemsUpdate==='posConf' && state.printersArray.length!==0">
                        <span class="config-title print-title">{{ t('Pos.printers') }}</span>
                    </a-row>

                    <template v-for="item in state.printersArray" :key="item">
                        <a-descriptions bordered size="small" class="ingredient-des">
                            <a-descriptions-item :label="t('Pos.Name')" :span="3">{{ item.printerName }}</a-descriptions-item>
                            <a-descriptions-item :label="t('Pos.IpAddress')" >{{ item.ipAddress }}</a-descriptions-item>
                            <a-descriptions-item :label="t('Pos.ProductionPoint')" >{{ getProductionPointName(item.productionPointId) }}</a-descriptions-item>
                        </a-descriptions>
                    </template> -->
      </a-form>
    </a-drawer>
    <!-- display drawer -->

    <!-- update drawer -->
    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      @close="cancelUpdate"
    >
      <template #title>
        <p :class="{ 'is-rtl': textDirection === 'rtl' }">
          {{ t("Pos.UpdateDevice") }}
        </p>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="name">
              <template #label>
                <span class="label-form">{{ t("Pos.Name") }}</span>
              </template>
              <a-input
                v-model:value="form.name"
                :placeholder="t('Pos.Name')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="created_at">
              <template #label>
                <span class="label-form">{{ t("Pos.CreatedAt") }}</span>
              </template>
              <a-date-picker
                show-time
                :placeholder="t('Pos.CreatedAt')"
                class="date-picker"
                disabled
                :value="formatDate(form.created_at)"
              >
              </a-date-picker>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="pinged_at">
              <template #label>
                <span class="label-form">{{ t("Pos.PingedAt") }}</span>
              </template>

              <a-date-picker
                show-time
                :placeholder="t('Pos.PingedAt')"
                class="date-picker"
                disabled
                :value="formatDate(form.updated_at)"
              >
              </a-date-picker>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="is_active">
              <template #label>
                <span class="label-form">{{ t("Pos.Active") }}</span>
              </template>
              <a-switch v-model:checked="form.is_active" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="type">
              <template #label>
                <span class="label-form">{{ t("Pos.Type") }}</span>
              </template>
              <a-select v-model:value="form.type">
                <a-select-option value="pos">{{
                  t("Pos.pos")
                }}</a-select-option>
                <a-select-option value="kitchen_display">{{
                  t("Pos.kitchen_display")
                }}</a-select-option>
                <a-select-option value="menu_display">{{
                  t("Pos.menudisplay")
                }}</a-select-option>
                <a-select-option value="order_display">{{
                  t("Pos.orderdisplay")
                }}</a-select-option>
                <a-select-option value="printer">{{
                  t("Pos.printer")
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItemsUpdate === 'posConf'">
          <a-col :span="24">
            <p class="config-title">{{ t("Pos.Store") }}</p>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItemsUpdate !== 'printerConf'">
          <a-col :span="8">
            <a-form-item name="color_primary">
              <template #label>
                <span class="label-form">{{ t("Pos.PrimaryColor") }}</span>
              </template>

              <input type="color" v-model="form.primaryColor" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="primary_light">
              <template #label>
                <span class="label-form">{{ t("Pos.SecondaryColor") }}</span>
              </template>
              <input type="color" v-model="form.primaryLight" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="color_secondary">
              <template #label>
                <span class="label-form">{{ t("Pos.otherColor") }}</span>
              </template>
              <input type="color" v-model="form.secondaryColor" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row
          v-if="
            configItemsUpdate === 'posConf' || configItemsUpdate === 'orderConf'
          "
        >
          <a-col :span="24">
            <a-form-item name="store_name">
              <template #label>
                <span class="label-form">{{ t("Pos.StoreName") }}</span>
              </template>
              <a-input
                v-model:value="form.store_name"
                :placeholder="t('Pos.StoreName')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItemsUpdate !== 'printerConf'">
          <a-col :span="12">
            <a-form-item name="defaultLanguage">
              <template #label>
                <span class="label-form">{{ t("Pos.DefaultLanguage") }}</span>
              </template>
              <a-select v-model:value="form.defaultLanguage" show-search>
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>
                <a-select-option value="en" :label="t('Pos.English')">{{
                  t("Pos.English")
                }}</a-select-option>
                <a-select-option value="fr" :label="t('Pos.French')">{{
                  t("Pos.French")
                }}</a-select-option>
                <a-select-option value="ar" :label="t('Pos.Arabic')">{{
                  t("Pos.Arabic")
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="mac_address">
              <template #label>
                <span class="label-form">{{ t("Pos.MacAddress") }}</span>
              </template>
              <a-input
                v-model:value="form.mac_address"
                :placeholder="t('Pos.MacAddress')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItemsUpdate !== 'printerConf'">
          <a-col :span="12">
            <a-form-item name="hibernate">
              <template #label>
                <span class="label-form">{{ t("Pos.SafeSleepTime") }}</span>
              </template>
              <a-input
                v-model:value="form.hibernate"
                :placeholder="t('Pos.SafeSleepTime')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="ip_address">
              <template #label>
                <span class="label-form">{{ t("Pos.IpAddress") }}</span>
              </template>
              <a-input
                v-model:value="form.ip_address"
                :placeholder="t('Pos.IpAddress')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12" v-if="configItemsUpdate === 'posConf'">
            <a-form-item name="defaultRoute">
              <template #label>
                <span class="label-form">{{ t("Pos.DefaultRoute") }}</span>
              </template>
              <a-input
                v-model:value="form.defaultRoute"
                :placeholder="t('Pos.DefaultRoute')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12" v-if="configItemsUpdate === 'posConf'">
            <a-form-item name="defaultTableFilter">
              <template #label>
                <span class="label-form">{{
                  t("Pos.DefaultTableFilter")
                }}</span>
              </template>
              <a-select
                show-search
                allowClear
                v-model:value="form.tableLocation_name"
                :option-filter-prop="'label'"
              >
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>
                <a-select-option
                  v-for="location in state.tableLocations"
                  :key="location.id"
                  :value="location.id"
                  :selected="location.id === form.defaultTableFilter"
                  :label="location.name"
                  >{{ location.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItemsUpdate === 'posConf'">
          <a-col :span="24">
            <a-form-item name="warehouse_id">
              <template #label>
                <span class="label-form">{{ t("Pos.Warehouse") }}</span>
              </template>

              <a-select
                show-search
                allowClear
                v-model:value="form.warehouse_id"
                :option-filter-prop="'label'"
              >
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>
                <a-select-option
                  v-for="warehouse in state.warehouses"
                  :key="warehouse.id"
                  :value="warehouse.id"
                  :label="warehouse.name"
                  :selected="warehouse.id === form.warehouse_id"
                  >{{ warehouse.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItemsUpdate === 'posConf'">
          <a-col :span="24">
            <p class="config-title">{{ t("Pos.Pos") }}</p>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
          <a-col :span="8">
            <a-form-item name="show_table">
              <a-checkbox v-model:checked="form.show_table">{{
                t("Pos.ShowTable")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="show_cashier">
              <a-checkbox v-model:checked="form.show_cashier">{{
                t("Pos.ShowCashier")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="showKeyboard">
              <a-checkbox v-model:checked="form.showKeyboard">{{
                t("Pos.ShowKeyboard")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
          <a-col :span="8">
            <a-form-item name="open_drawer_after_print">
              <a-checkbox v-model:checked="form.open_drawer_after_print">{{
                t("Pos.openDrawerAfterPrint")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="allow_print_before">
              <a-checkbox v-model:checked="form.allow_print_before">{{
                t("Pos.allowPrintBefore")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="allow_print_after">
              <a-checkbox v-model:checked="form.allow_print_after">{{
                t("Pos.allowPrintAfter")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
          <a-col :span="8">
            <a-form-item name="show_products">
              <a-checkbox v-model:checked="form.show_products">{{
                t("Pos.showProducts")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="show_refundables">
              <a-checkbox v-model:checked="form.show_refundables">{{
                t("Pos.showRefundables")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="generate_sale">
              <a-checkbox v-model:checked="form.generate_sale">{{
                t("Pos.generateSale")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItemsUpdate === 'posConf'">
          <a-col :span="24">
            <a-form-item name="has_second_display">
              <a-checkbox v-model:checked="form.has_second_display">{{
                t("Pos.hasSecondScreen")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="form.has_second_display">
          <a-col :span="12">
            <a-form-item name="secondBgImg">
              <template #label>
                <span class="label-form">{{
                  t("Pos.secondScreenBgImage")
                }}</span>
              </template>

              <a-upload
                name="file"
                v-model:fileList="form.secondBgImg"
                :multiple="false"
                :max-count="1"
                :before-upload="beforeUpload"
              >
                <a-button>
                  <UploadOutlined />
                  {{ t("Pos.clickToUpload") }}
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="secondSideImg">
              <template #label>
                <span class="label-form">{{ t("Pos.secondScreenSide") }}</span>
              </template>

              <a-upload
                name="file"
                v-model:fileList="form.secondSideImg"
                :multiple="false"
                :max-count="1"
                :before-upload="beforeUpload"
              >
                <a-button>
                  <UploadOutlined />
                  {{ t("Pos.clickToUpload") }}
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItemsUpdate === 'posConf'">
          <a-col :span="12">
            <a-form-item name="showTextLcd">
              <template #label>
                <span class="label-form">{{ t("Pos.DisplayText") }}</span>
              </template>
              <a-input
                v-model:value="form.showTextLcd"
                :placeholder="t('Pos.DisplayText')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="showTextLcd">
              <template #label>
                <span class="label-form">{{ t("Pos.footerTxt") }}</span>
              </template>
              <a-input
                v-model:value="form.footer_text"
                :placeholder="t('Pos.footerTxt')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row
          v-if="
            configItemsUpdate === 'posConf' || configItemsUpdate === 'orderConf'
          "
        >
          <a-col :span="24">
            <a-form-item name="decimal_precision">
              <template #label>
                <a-tooltip>
                  <template #title>{{ t("Pos.decimalPercisionTxt") }}</template>
                  <QuestionCircleOutlined class="tooltip-icon" />
                </a-tooltip>
                <span class="label-form">{{ t("Pos.decimalPercision") }}</span>
              </template>
              <a-input
                v-model:value="form.decimal_precision"
                :placeholder="t('Pos.decimalPercision')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItemsUpdate === 'posConf'">
          <a-col :span="24">
            <a-form-item name="redirect_after_cart">
              <template #label>
                <a-tooltip>
                  <template #title>{{
                    t("Pos.RedirectAfterCartTxT")
                  }}</template>
                  <QuestionCircleOutlined class="tooltip-icon" />
                </a-tooltip>
                <span class="label-form">{{ t("Pos.RedirectAfterCart") }}</span>
              </template>
              <a-input
                v-model:value="form.redirectAfterCart"
                :placeholder="t('Pos.RedirectAfterCart')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItemsUpdate === 'printerConf'" :gutter="16">
          <a-col :span="12">
            <a-form-item name="mac_address">
              <template #label>
                <span class="label-form">{{ t("Pos.MacAddress") }}</span>
              </template>
              <a-input
                v-model:value="form.mac_address"
                :placeholder="t('Pos.MacAddress')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="ip_address">
              <template #label>
                <span class="label-form">{{ t("Pos.IpAddress") }}</span>
              </template>
              <a-input
                v-model:value="form.ip_address"
                :placeholder="t('Pos.IpAddress')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItemsUpdate === 'printerConf'">
          <a-col :span="12">
            <a-form-item name="production_point_id">
              <template #label>
                <span class="label-form">{{ t("Pos.ProductionPoint") }}</span>
              </template>

              <a-select
                v-model:value="form.production_point_id"
                show-search
                allowClear
                :option-filter-prop="'label'"
                @select="selectedProductionUpdate"
              >
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>
                <a-select-option
                  v-for="item in state.productionPoints"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                  :selected="item.id === form.production_point_id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- update printers V3 -->

        <!-- update printers v2 -->
        <!-- <a-row v-if="configItemsUpdate==='posConf' ">
                        <span class="label-form print-title">{{ t('Pos.printers') }}</span>
                    </a-row>
                    
                    <template v-if="configItemsUpdate==='posConf' ">
                        <a-row v-for="item in state.printersArray" :key="item" :gutter="16">
                            
                            <a-col :span="7">
                                <a-form-item has-feedback  :name="'name' + item.id" :validate-status="getValidatingStatusQT(item.id,'name')" :help="getValidatingHelpQT(item.id,'name')">
                                    <a-input v-model:value="item.printerName" :id="'name' + item.id" :placeholder="t('Pos.printer')" class="input-form"/>
                                </a-form-item>
                            </a-col>

                            <a-col :span="7">
                                <a-form-item has-feedback  :name="'ip' + item.id" :validate-status="getValidatingStatusQT(item.id,'ip')" :help="getValidatingHelpQT(item.id,'ip')">
                                    <a-input v-model:value="item.ipAddress" :id="'ip' + item.id" :placeholder="t('Pos.IpAddress')" class="input-form"/>
                                </a-form-item>
                            </a-col>  

                            <a-col :span="8">
                                <a-form-item
                                    :validate-status="getValidatingStatusQT(item.id,'pp')" :help="getValidatingHelpQT(item.id,'pp')"
                                >
                                    <a-select :id="'pp' + item.id" :placeholder="t('Pos.ProductionPoint')" v-model:value="item.productionPointId" @change="handleUnitChange($event,item.id)">
                                        <template #notFoundContent>
                                            <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                        </template>
                                        <a-select-option v-for="item in state.productionPoints" :label="item.name" :key="item.id" :value="item.id" >{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col> 

                            <a-col :span="2"  class="minus-conatiner">
                                <MinusCircleOutlined class="cancel-pref-icon" @click="removePrinterUpdate(item.id)" />
                            </a-col>
                            

                        </a-row>
                    </template> -->

        <!-- <template v-if="configItemsUpdate==='posConf' ">

                        <a-form
                            ref="formRefUpdatePrint"
                            name="dynamic_form_nest_item"
                            :model="dynamicForm"                        
                        >

                        <a-row
                            v-for="(print, index) in dynamicForm.printers"
                            :key="print.id"
                            :gutter="16"
                           
                        >
                            <a-col :span="7">
                            <a-form-item
                                :name="['printers', index, 'printerName']"
                                :rules="{
                                    required: true,
                                    message: t('Pos.RequiredField'),
                                }"
                            >
                                <a-input v-model:value="print.printerName" :placeholder="t('Pos.Name')" />
                            </a-form-item>
                            </a-col>


                            <a-col :span="7">    
                            <a-form-item
                                :name="['printers', index, 'ipAddress']"
                                :rules="{
                                    required: true,
                                    message: t('Pos.RequiredField'),
                                }"
                            >
                                <a-input v-model:value="print.ipAddress" :placeholder="t('Pos.IpAddress')" />
                            </a-form-item>
                            </a-col>
                            
                            <a-col :span="8">
                            <a-form-item
                                :name="['printers', index, 'production_point_id']"
                                :rules="{
                                    required: true,
                                    message: t('Pos.RequiredField'),
                                }"
                            >
                                <a-select v-model:value="print.production_point_id" :placeholder="t('Pos.ProductionPoint')" >
                                    <a-select-option v-for="point in state.productionPoints" :key="point.id" :value="point.id">
                                        {{ point.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            </a-col>

                           
                            <a-col :span="2" class="minus-conatiner">
                            <MinusCircleOutlined class="cancel-pref-icon" @click="removePrintUpdate(print)" />
                            </a-col>
                        </a-row>


                        <a-form-item>
                            <a-button type="dashed" block @click="addPrintsUpdate">
                            <PlusOutlined />
                            {{t('Pos.AddPrinter')}}
                            </a-button>
                        </a-form-item>

                       

                       

                        </a-form>

                    </template> -->

        <!-- update printers v1 -->

        <!-- <a-row :gutter="16" v-if="configItemsUpdate==='posConf'">

                        <a-col :span="24">
                            <a-form-item  name="ingredients_id">
                                <template #label>
                                    <span class="label-form">{{t('Pos.printers')}}</span>
                                </template>
                                    
                                <a-select
                                    v-model:value="form.printers_id"
                                    mode="multiple"
                                    style="width: 100%"
                                    :options="optionsUpdate"
                                    @change="handleUpdatePinter"
                                    show-search
                                ></a-select>

                            </a-form-item>
                        </a-col>
                    </a-row>
                    <template v-if="configItemsUpdate==='posConf'">
                        <a-row v-for="item in state.printersArray" :key="item" :gutter="16" class="ingredients-container">

                            <a-col :span="4">
                                <span class="label-form">{{item.printerName}}</span>
                            </a-col>
                            
                            <a-col :span="20">
                                <a-form-item
                                    :validate-status="getValidatingStatusQT(item.printerId,'pp')" :help="getValidatingHelpQT(item.printerId,'pp')"
                                >
                                    <a-select :id="'pp' + item.printerId" :placeholder="t('Pos.ProductionPoint')" v-model:value="item.productionPointId" @change="handleUnitChange($event,item.printerId)">
                                        <template #notFoundContent>
                                            <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                        </template>
                                        <a-select-option v-for="item in state.productionPoints" :label="item.name" :key="item.id" :value="item.id" >{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </template> -->
      </a-form>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="update(form.id)">{{
            t("Pos.Update")
          }}</a-button>
          <a-button type="primary" danger @click="cancelUpdate">{{
            t("Pos.Cancel")
          }}</a-button>
        </a-space>
      </template>
    </a-drawer>
    <!-- update drawer -->

    <!-- add drawer -->
    <a-drawer
      v-model:open="openAdd"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      @close="resetFormAdd"
    >
      <template #title>
        <p :class="{ 'is-rtl': textDirection === 'rtl' }">
          {{ t("Pos.AddDevice") }}
        </p>
      </template>

      <a-form
        ref="addFormRef"
        :model="addForm"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="name">
              <template #label>
                <span class="label-form">{{ t("Pos.Name") }}</span>
              </template>
              <a-input
                v-model:value="addForm.name"
                :placeholder="t('Pos.Name')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="is_active">
              <template #label>
                <span class="label-form">{{ t("Pos.Active") }}</span>
              </template>
              <a-switch v-model:checked="addForm.is_active" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="type">
              <template #label>
                <span class="label-form">{{ t("Pos.Type") }}</span>
              </template>
              <a-select v-model:value="addForm.type">
                <a-select-option value="pos">{{
                  t("Pos.pos")
                }}</a-select-option>
                <a-select-option value="kitchen_display">{{
                  t("Pos.kitchen_display")
                }}</a-select-option>
                <a-select-option value="menu_display">{{
                  t("Pos.menudisplay")
                }}</a-select-option>
                <a-select-option value="order_display">{{
                  t("Pos.orderdisplay")
                }}</a-select-option>
                <a-select-option value="printer">{{
                  t("Pos.printer")
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItems === 'posConf'">
          <a-col :span="24">
            <p class="config-title">{{ t("Pos.Store") }}</p>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItems !== 'printerConf'">
          <a-col :span="8">
            <a-form-item name="primaryColor">
              <template #label>
                <span class="label-form">{{ t("Pos.PrimaryColor") }}</span>
              </template>
              <input type="color" v-model="addForm.primaryColor" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="primaryLight">
              <template #label>
                <span class="label-form">{{ t("Pos.SecondaryColor") }}</span>
              </template>
              <input type="color" v-model="addForm.primaryLight" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="secondaryColor">
              <template #label>
                <span class="label-form">{{ t("Pos.otherColor") }}</span>
              </template>
              <input type="color" v-model="addForm.secondaryColor" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItems === 'posConf' || configItems === 'orderConf'">
          <a-col :span="24">
            <a-form-item name="store_name">
              <template #label>
                <span class="label-form">{{ t("Pos.StoreName") }}</span>
              </template>
              <a-input
                v-model:value="addForm.store_name"
                :placeholder="t('Pos.StoreName')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="defaultLanguage">
              <template #label>
                <span class="label-form">{{ t("Pos.DefaultLanguage") }}</span>
              </template>
              <a-select
                v-model:value="addForm.defaultLanguage"
                show-search
                :option-filter-prop="'label'"
              >
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>

                <a-select-option value="en" :label="t('Pos.English')">{{
                  t("Pos.English")
                }}</a-select-option>
                <a-select-option value="fr" :label="t('Pos.French')">{{
                  t("Pos.French")
                }}</a-select-option>
                <a-select-option value="ar" :label="t('Pos.Arabic')">{{
                  t("Pos.Arabic")
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="mac_address">
              <template #label>
                <span class="label-form">{{ t("Pos.MacAddress") }}</span>
              </template>
              <a-input
                v-model:value="addForm.mac_address"
                :placeholder="t('Pos.MacAddress')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItems !== 'printerConf'">
          <a-col :span="12">
            <a-form-item name="hibernate">
              <template #label>
                <span class="label-form">{{ t("Pos.SafeSleepTime") }}</span>
              </template>
              <a-input
                v-model:value="addForm.hibernate"
                :placeholder="t('Pos.SafeSleepTime')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="ip_address">
              <template #label>
                <span class="label-form">{{ t("Pos.IpAddress") }}</span>
              </template>
              <a-input
                v-model:value="addForm.ip_address"
                :placeholder="t('Pos.IpAddress')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12" v-if="configItems === 'posConf'">
            <a-form-item name="defaultRoute">
              <template #label>
                <span class="label-form">{{ t("Pos.DefaultRoute") }}</span>
              </template>
              <a-input
                v-model:value="addForm.defaultRoute"
                :placeholder="t('Pos.DefaultRoute')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12" v-if="configItems === 'posConf'">
            <a-form-item name="defaultTableFilter">
              <template #label>
                <span class="label-form">{{
                  t("Pos.DefaultTableFilter")
                }}</span>
              </template>
              <a-select
                show-search
                allowClear
                v-model:value="addForm.defaultTableFilter"
                :option-filter-prop="'label'"
              >
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>
                <a-select-option
                  v-for="location in state.tableLocations"
                  :key="location.id"
                  :value="location.id"
                  :selected="location.id === addForm.defaultTableFilter"
                  :label="location.name"
                  >{{ location.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItems === 'posConf'">
          <a-col :span="24">
            <a-form-item name="warehouse_id">
              <template #label>
                <span class="label-form">{{ t("Pos.Warehouse") }}</span>
              </template>
              <a-select
                show-search
                allowClear
                v-model:value="addForm.warehouse_id"
                :option-filter-prop="'label'"
              >
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>
                <a-select-option
                  v-for="warehouse in state.warehouses"
                  :key="warehouse.id"
                  :value="warehouse.id"
                  :label="warehouse.name"
                  :selected="warehouse.id === addForm.warehouse_id"
                  >{{ warehouse.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :span="12" v-if="configItems === 'posConf'">
          <a-col :span="24">
            <p class="config-title">{{ t("Pos.Pos") }}</p>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItems === 'posConf'">
          <a-col :span="8">
            <a-form-item name="show_table">
              <a-checkbox v-model:checked="addForm.show_table">{{
                t("Pos.ShowTable")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="show_table">
              <a-checkbox v-model:checked="addForm.show_cashier">{{
                t("Pos.ShowCashier")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="show_table">
              <a-checkbox v-model:checked="addForm.showKeyboard">{{
                t("Pos.ShowKeyboard")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItems === 'posConf'">
          <a-col :span="8">
            <a-form-item name="open_drawer">
              <a-checkbox v-model:checked="addForm.open_drawer_after_print">{{
                t("Pos.openDrawerAfterPrint")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="allow_print_before">
              <a-checkbox v-model:checked="addForm.allow_print_before">{{
                t("Pos.allowPrintBefore")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="allow_print_after">
              <a-checkbox v-model:checked="addForm.allow_print_after">{{
                t("Pos.allowPrintAfter")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItems === 'posConf'">
          <a-col :span="8">
            <a-form-item name="show_products">
              <a-checkbox v-model:checked="addForm.show_products">{{
                t("Pos.showProducts")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="show_refundables">
              <a-checkbox v-model:checked="addForm.show_refundables">{{
                t("Pos.showRefundables")
              }}</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item name="generate_sale">
              <a-checkbox v-model:checked="addForm.generate_sale">{{
                t("Pos.generateSale")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItems === 'posConf'">
          <a-col :span="24">
            <a-form-item name="has_second_display">
              <a-checkbox v-model:checked="addForm.has_second_display">{{
                t("Pos.hasSecondScreen")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="addForm.has_second_display">
          <a-col :span="12">
            <a-form-item name="secondBgImg">
              <template #label>
                <span class="label-form">{{
                  t("Pos.secondScreenBgImage")
                }}</span>
              </template>

              <a-upload
                name="file"
                v-model:fileList="addForm.secondBgImg"
                :multiple="false"
                :max-count="1"
                :before-upload="beforeUpload"
              >
                <a-button>
                  <UploadOutlined />
                  {{ t("Pos.clickToUpload") }}
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="secondSideImg">
              <template #label>
                <span class="label-form">{{ t("Pos.secondScreenSide") }}</span>
              </template>

              <a-upload
                name="file"
                v-model:fileList="addForm.secondSideImg"
                :multiple="false"
                :max-count="1"
                :before-upload="beforeUpload"
              >
                <a-button>
                  <UploadOutlined />
                  {{ t("Pos.clickToUpload") }}
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="configItems === 'posConf'">
          <a-col :span="12">
            <a-form-item name="showTextLcd">
              <template #label>
                <span class="label-form">{{ t("Pos.DisplayText") }}</span>
              </template>
              <a-input
                v-model:value="addForm.showTextLcd"
                :placeholder="t('Pos.DisplayText')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="footer_text">
              <template #label>
                <span class="label-form">
                  {{ t("Pos.footerTxt") }}
                </span>
              </template>
              <a-input
                v-model:value="addForm.footer_text"
                :placeholder="t('Pos.footerTxt')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItems === 'posConf' || configItems === 'orderConf'">
          <a-col :span="24">
            <a-form-item name="decimal_precision">
              <template #label>
                <a-tooltip>
                  <template #title>{{ t("Pos.decimalPercisionTxt") }}</template>
                  <QuestionCircleOutlined class="tooltip-icon" />
                </a-tooltip>
                <span class="label-form">{{ t("Pos.decimalPercision") }}</span>
              </template>
              <a-input
                v-model:value="addForm.decimal_precision"
                :placeholder="t('Pos.decimalPercision')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="configItems === 'posConf'">
          <a-col :span="24">
            <a-form-item name="redirect_after_cart">
              <template #label>
                <a-tooltip>
                  <template #title>{{
                    t("Pos.RedirectAfterCartTxT")
                  }}</template>
                  <QuestionCircleOutlined class="tooltip-icon" />
                </a-tooltip>
                <span class="label-form">{{ t("Pos.RedirectAfterCart") }}</span>
              </template>
              <a-input
                v-model:value="addForm.redirect_after_cart"
                :placeholder="t('Pos.RedirectAfterCart')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="configItems === 'printerConf'">
          <a-col :span="12">
            <a-form-item name="ip_address">
              <template #label>
                <span class="label-form">{{ t("Pos.IpAddress") }}</span>
              </template>
              <a-input
                v-model:value="addForm.ip_address"
                :placeholder="t('Pos.IpAddress')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="production_point_id">
              <template #label>
                <span class="label-form">{{ t("Pos.ProductionPoint") }}</span>
              </template>
              <a-select
                show-search
                allowClear
                v-model:value="addForm.production_point_id"
                :option-filter-prop="'label'"
                @select="selectedProduction"
              >
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>
                <a-select-option
                  v-for="item in state.productionPoints"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                  :selected="item.id === addForm.production_point_id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <!--printers v1 -->

        <!-- <a-row v-if="configItems==='posConf'">
                    <a-col :span="24">
                        <a-form-item  name="printers">
                            <template #label>
                                <span class="label-form">{{t('Pos.printers')}}</span>
                            </template>
                           
                            <a-select
                                v-model:value="addForm.items"
                                mode="multiple"
                                style="width: 100%"
                                :placeholder="t('Pos.printers')"
                                :options="options"
                                @change="handleMultiChange"
                                show-search
                                :option-filter-prop="'label'"
                            >
                                <template #notFoundContent>
                                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                </template>
                            </a-select>

                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row v-for="item in state.prArray" :key="item" :span="12" :gutter="16">

                    <a-col :span="4">
                        <span class="label-form">{{item.item_name}}</span>
                    </a-col>


                    <a-col :span="20">
                        <a-form-item has-feedback  :name="'pp' + item.item_id" :validate-status="getValidatingStatusQT(item.item_id,'pp')" :help="getValidatingHelpQT(item.item_id,'pp')">

                            <a-select  :id="'pp'+ item.item_id" show-search :option-filter-prop="'label'"  @change="handleUnitChange($event,item.item_id)">

                                <template #notFoundContent>
                                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                </template>
                                <a-select-option v-for="item in state.productionPoints" :label="item.name" :key="item.id" :value="item.id" >{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row> -->

        <!-- printers  add v2 -->
        <!--
               <template v-if="configItems==='posConf'">

                    <a-form
                        ref="formRefPrint"
                        name="dynamic_form_nest_item"
                        :model="dynamicValidateForm"
                        @finish="add"
                        
                    >
                        <a-row
                            v-for="(print, index) in dynamicValidateForm.prints"
                            :key="print.id"
                            :gutter="16"
                        >
                            <a-col :span="7">
                            <a-form-item
                                :name="['prints', index, 'printerName']"
                                :rules="{
                                    required: true,
                                    message: t('Pos.RequiredField'),
                                }"
                                
                            >
                                <a-input v-model:value="print.printerName" :placeholder="t('Pos.Name')" class="dynamic-input" />
                            </a-form-item>
                            </a-col>

                            <a-col :span="7">
                            <a-form-item
                                :name="['prints', index, 'ipAddress']"
                                :rules="{
                                    required: true,
                                    message: t('Pos.RequiredField'),
                                }"
                            >
                                <a-input v-model:value="print.ipAddress" :placeholder="t('Pos.IpAddress')" />
                            </a-form-item>
                            </a-col>


                            <a-col :span="8">
                            <a-form-item
                                :name="['prints', index, 'production_point_id']"
                                :rules="{
                                    required: true,
                                    message: t('Pos.RequiredField'),
                                }"
                            >
                                <a-select v-model:value="print.production_point_id" :placeholder="t('Pos.ProductionPoint')" style="width: 100%">
                                    <a-select-option v-for="point in state.productionPoints" :key="point.id" :value="point.id">
                                        {{ point.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            </a-col>

                           
                            <a-col :span="2" class="minus-conatiner">
                                <MinusCircleOutlined class="cancel-pref-icon" @click="removePrint(print)" />
                            </a-col>
                        
                        </a-row>


                        <a-form-item>
                            <a-button type="dashed" block @click="addPrints">
                            <PlusOutlined />
                            {{t('Pos.AddPrinter')}}
                            </a-button>
                        </a-form-item>

                       

                        <a-form-item v-if="dynamicValidateForm.prints.length!==0">
                            <a-button type="primary" html-type="submit">{{t('Pos.Add')}}</a-button>
                        </a-form-item>

                    </a-form>

                </template>
                -->

        <!-- <a-row>
                    <a-col :span="24">
                        <a-form-item name="config">

                            <template #label>
                                <span class="label-form">{{ t('Pos.Config') }}</span>
                            </template>
                            <a-textarea v-model:value="addForm.config" :rows="4" :placeholder="t('Pos.Config')" />
                        </a-form-item>
                    </a-col>
                </a-row> -->

        <!--
                <a-row >
                    <a-col :span="24">
                        <a-form-item name="has_config">
                            <template #label>
                                <span class="label-form">Configuration</span>
                            </template>
                            <a-switch v-model:checked="addForm.has_config" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row v-if="addForm.has_config">
                    <a-col :span="12">
                        <a-form-item>
                            <template #label>
                                <span class="label-form">Primary color</span>
                            </template>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item>
                            <template #label>
                                <span class="label-form">Secondry color</span>
                            </template>
                        </a-form-item>
                    </a-col>
                </a-row>
                
                <a-row v-if="addForm.has_config">
                    <a-col :span="12">
                        <a-form-item>
                            <template #label>
                                <span class="label-form">Third color</span>
                            </template>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item>
                            <template #label>
                                <span class="label-form">Logo</span>
                            </template>
                        </a-form-item>
                    </a-col>
                </a-row>-->
      </a-form>

      <template #footer>
        <a-space>
          <a-button type="primary" @click="add">{{ t("Pos.Add") }}</a-button>
          <a-button type="primary" danger @click="resetFormAdd">{{
            t("Pos.Cancel")
          }}</a-button>
        </a-space>
      </template>
    </a-drawer>
    <!-- add drawer -->

    <!--modal login -->
    <a-modal
      v-model:open="openModal"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="resetFormLogin"
    >
      <template #title>
        <p :class="{ 'is-rtl': textDirection === 'rtl' }">
          {{ t("Pos.Login") }}
        </p>
      </template>

      <template #footer>
        <a-button key="submit" type="primary" @click="handleOk">{{
          t("Pos.Login")
        }}</a-button>
        <a-button key="back" @click="handleCancel">{{
          t("Pos.Cancel")
        }}</a-button>
      </template>

      <a-form
        ref="logInFormRef"
        :model="loginForm"
        :rules="logInRules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-form-item has-feedback name="code">
          <template #label>
            <span class="label-form">{{ t("Pos.DeviceAuthCode") }}</span>
          </template>
          <a-input v-model:value="loginForm.code" autocomplete="off" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  watch,
  computed,
} from "vue";
import type { FormInstance, Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
  CheckCircleOutlined,
  LoginOutlined,
  LogoutOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  RedoOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import {
  deviceService,
  productionPointService,
  tableLocationService,
  warehouseService,
} from "@/_services";
import dayjs, { Dayjs } from "dayjs";
import { useRouter } from "vue-router";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { useI18n } from "vue-i18n";
import { Empty } from "ant-design-vue";

export default defineComponent({
  name: "indexDevice",
  components: {
    EyeOutlined,
    DeleteOutlined,
    SearchOutlined,
    EditOutlined,
    CheckCircleOutlined,
    LoginOutlined,
    LogoutOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    RedoOutlined,
    QuestionCircleOutlined,
    LbrxLoaderSpinner,
    UploadOutlined,
  },
  setup() {
    const modalWidth = ref(720);
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const { t } = useI18n();
    const loading = ref(false);

    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link);
    };

    const state = reactive({
      searchText: "",
      searchedColumn: "",
      data: [] as any[],
      categories: [] as any[],
      products: [] as any[],
      totalPages: 0,
      tableLocations: [] as any[],
      warehouses: [] as any[],
      beginSearch: false,
      deviceName: "",
      printers: [] as any[],
      prArray: [] as any[],
      productionPoints: [] as any[],
      printersArray: [] as any[],
    });

    const formatType = (type: string): string => {
      const formattedType = type.replace("_", " ").toLowerCase();
      return formattedType.charAt(0).toUpperCase() + formattedType.slice(1);
    };

    const columns = [
      {
        title: t("Pos.Name"),
        key: "name",
        dataIndex: "name",
        customFilterDropdown: true,
      },
      {
        title: t("Pos.Type"),
        key: "type",
        dataIndex: "type",
      },
      {
        title: t("Pos.Active"),
        key: "is_active",
        dataIndex: "is_active",
        //customRender: (isActive: number) => isActive === 0 ? '<check-circle-two-tone two-tone-color="#52c41a" />' : '<CheckCircleOutlined />'
      },
      {
        title: "Actions",
        key: "actions",
      },
    ];

    //pagination
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const selectPagination = ref("10");

    const handleSelectPaginationChange = (selectedItem: any) => {
      perPage.value = Number.parseInt(selectedItem);
      currentPage.value = 1;

      if (state.beginSearch === true) {
        loading.value = true;
        if (state.deviceName !== "") {
          loading.value = true;
          deviceService
            .filter("name", state.deviceName, currentPage.value, perPage.value)
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.total;
              loading.value = false;
            })
            .catch((err: any) => {
              console.log(err);
              loading.value = false;
            });
        }
      } else {
        loading.value = true;
        deviceService
          .getAllWithPagination(currentPage.value, perPage.value)
          .then((response) => {
            state.data = response.data;
            state.totalPages = response.total;
          })
          .catch((error) => {
            console.log("error api : get device", error);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;

      if (state.beginSearch === true) {
        state.data = [];
        loading.value = true;
        deviceService
          .filter("name", state.deviceName, currentPage.value, perPage.value)
          .then((res) => {
            state.data = res.data;
            state.totalPages = res.total;
            loading.value = false;
          })
          .catch((err: any) => {
            console.log(err);
            loading.value = false;
          });
      } else {
        loading.value = true;
        deviceService
          .getAllWithPagination(currentPage.value, perPage.value)
          .then((response) => {
            state.data = response.data;
            state.totalPages = response.total;
          })
          .catch((error) => {
            console.log("error api : get device", error);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    //form
    const formRef = ref();
    const addFormRef = ref();
    const logInFormRef = ref();

    interface PrinterInfo {
      printerId: any;
      printerName: any;
      ipAddress: any;
      productionPointId: any;
    }

    const form = reactive({
      id: "",
      name: "",
      type: "",
      is_active: false,
      config: "",
      has_config: false,
      created_at: "",
      updated_at: "",
      pinged_at: "",
      defaultTableFilter: "",
      defaultRoute: "",
      primaryColor: "",
      primaryLight: "",
      secondaryColor: "",
      defaultLanguage: "en",
      hibernate: "",
      ip_address: "",
      mac_address: "",
      show_table: true,
      show_cashier: true,
      showKeyboard: false,
      footer_text: "",
      showTextLcd: "",
      open_drawer_after_print: false,
      warehouse_id: "",
      warehouse: "",
      tableLocation_name: "",
      allow_print_before: false,
      allow_print_after: false,
      store_name: "",
      show_products: false,
      show_refundables: false,
      decimal_precision: "",
      items: [] as any,
      printers_id: [] as string[],
      printers_info: [] as PrinterInfo[],
      production_point_id: "",
      production_point_name: "",
      generate_sale: true,
      redirectAfterCart: "",
      has_second_display: false,
      secondBgImg64:"",
      secondBgImg: [] as any,
      secondSideImg64:"",
      secondSideImg: [] as any,
    });

    const loginForm = reactive({
      code: "",
    });
    const storeItem = localStorage.getItem("store")
      ? JSON.parse(localStorage.getItem("store")!)
      : "";

    const addForm = reactive({
      id: "",
      name: "",
      type: "pos",
      is_active: true,
      config: "",
      has_config: false,
      created_at: "",
      updated_at: "",
      pinged_at: "",

      defaultTableFilter: "",
      defaultRoute: "",
      primaryColor: "",
      primaryLight: "",
      secondaryColor: "",
      defaultLanguage: "en",
      hibernate: "",
      ip_address: "",
      mac_address: "",
      show_table: true,
      show_cashier: true,
      showKeyboard: false,
      footer_text: "",
      showTextLcd: "",
      open_drawer_after_print: false,
      warehouse_id: "",
      allow_print_before: true,
      allow_print_after: false,
      store_name: storeItem.name,
      show_products: false,
      show_refundables: false,
      decimal_precision: "3",
      printer_id: "",
      items: [] as any,
      production_point_id: "",
      production_point_name: "",
      generate_sale: true,
      redirect_after_cart: "",
      has_second_display: false,
      secondBgImg: [] as any,
      secondSideImg: [] as any,
    });

    const rules: Record<string, Rule[]> = {
      name: [{ required: true, message: t("Pos.RequiredField") }],
      type: [{ required: true, message: t("Pos.RequiredField") }],
      configuration: [{ required: true, message: t("Pos.RequiredField") }],
      warehouse_id: [{ required: true, message: t("Pos.RequiredField") }],
      hibernate: [
        {
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t("Pos.numericField"));
          },
        },
      ],
      decimal_precision: [
        { required: true, message: t("Pos.RequiredField") },
        {
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t("Pos.numericField"));
          },
        },
      ],
      production_point_id: [
        { required: true, message: t("Pos.RequiredField") },
      ],
    };

    const logInRules: Record<string, Rule[]> = {
      code: [
        { required: true, message: t("Pos.RequiredField") },
        {
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t("Pos.numericField"));
          },
        },
      ],
    };

    //bgPhotos
    const beforeUpload = () => {
      return false;
    };

    const convertToBase64 = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const base64ToFile = (base64: string, filename: string) => {
        const arr = base64.split(',') as any;
        const mime = arr[0].match(/:(.*?);/)[1]; // Extract MIME type
        const bstr = atob(arr[1]); // Decode Base64 string
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        //base64 to byte array
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        //file object
        return new File([u8arr], filename, { type: mime });
    };


    //drawer
    const open = ref<boolean>(false);
    const openDisplay = ref<boolean>(false);
    const openAdd = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
      updateModalWidth();
      getTableLocations();
      getWarehouses();
      //getPrinters();
      getProductionPoints();
      openAdd.value = true;
    };

    const afterOpenChange = (bool: boolean) => {

      if(bool == false){
        formRef.value.resetFields();
        form.secondBgImg=[];
        form.secondSideImg=[];
        form.secondBgImg64="";
        form.secondSideImg64="";
      }
    };

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      getDeviceById(record.id);
      getTableLocations();
      getWarehouses();
      getProductionPoints();
      openDisplay.value = true;
    };

    const showDrawer = (record: any) => {
      updateModalWidth();
      getDeviceById(record.id);
      getTableLocations();
      getWarehouses();
      //getPrintersForUpdate();
      getProductionPoints();
      open.value = true;
    };

    const cancelUpdate = () => {
      open.value = false;

      validationHelpQT.value = {};
      validationStatusQT.value = {};
      dynamicForm.printers = [];
    };

    const afterOpenAddChange = (bool: boolean) => {
      console.log("open", bool);

      if(bool == false){
        resetFormAdd();
      }
    };

    const formatDate = (dateString: string) => {
      return dayjs(dateString);
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    //notification
    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    const showErrorNotification = (message: string) => {
      notification.error({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#FFBFC98A",
          color: "white",
        },
      });
    };

    const getAll = () => {
      loading.value = true;
      deviceService
        .getAllWithPagination(currentPage.value, perPage.value)
        .then((response) => {
          state.data = response.data;
          state.totalPages = response.total;

          console.log("devices", state.data);
        })
        .catch((error) => {
          console.log("error get devices", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const configItemsUpdate = ref();
    const getDeviceById = (id: string) => {
      loading.value = true;
      deviceService
        .show(id)
        .then((res) => {
          form.name = res.data.name;
          form.type = res.data.type;
          form.is_active = res.data.is_active === 1;
          form.pinged_at = res.data.pinged_at;
          form.created_at = res.data.created_at;
          form.updated_at = res.data.updated_at;
          form.id = res.data.id;
          form.config = JSON.parse(res.data.config);
          const config = JSON.parse(res.data.config);

          //v2
          // if(printerConfig){

          //     const printersArray: any[] = [];
          //     const printersIds: any[] = [];
          //     let idCounter = 0;
          //     config.printerConfig.forEach((printer: any, index:any) => {
          //         printersArray.push({
          //             id: idCounter++,
          //             printerName: printer.printerName,
          //             ipAddress: printer.ipAddress,
          //             productionPointId: printer.production_point_id
          //         });
          //         //selectedOptions[idCounter] = printer.production_point_id;
          //         selectedOptions[index] = printer.production_point_id;
          //         console.log('selected options',selectedOptions.length);
          //         printersIds.push(printer.printerId);
          //     });

          //     // Set state and form properties
          //     state.printersArray = printersArray;
          //     form.printers_id = printersIds;
          //     form.printers_info=printersArray;

          //     console.log('state.prArray',state.printersArray);

          // }

          if (form.type === "pos") {
            configItemsUpdate.value = "posConf";
            form.defaultLanguage = config.store.defaultLanguage
              ? config.store.defaultLanguage
              : "";
            form.ip_address = config.posConfig.ip_address
              ? config.posConfig.ip_address
              : "";
            form.mac_address = config.posConfig.mac_address
              ? config.posConfig.mac_address
              : "";
            form.primaryColor = config.store.primaryColor
              ? config.store.primaryColor
              : "";
            form.secondaryColor = config.store.secondaryColor
              ? config.store.secondaryColor
              : "";
            form.primaryLight = config.store.primaryLight
              ? config.store.primaryLight
              : "";
            form.defaultRoute = config.store.defaultRoute
              ? config.store.defaultRoute
              : "";
            form.hibernate = config.posConfig.hibernate
              ? config.posConfig.hibernate
              : "";
            form.defaultTableFilter = config.posConfig.defaultTableFilter
              ? config.posConfig.defaultTableFilter
              : "";
            form.tableLocation_name = config.posConfig.defaultTableFilter
              ? getTableLocationName(config.posConfig.defaultTableFilter)
              : "";
            form.warehouse_id = config.warehouse_id ? config.warehouse_id : "";
            form.warehouse = config.store.warehouse_id
              ? getWarehouseName(config.store.warehouse_id)
              : "";
            form.show_table = config.posConfig.show_table === 1;
            form.show_cashier = config.posConfig.show_cashier === 1;
            form.showKeyboard = config.posConfig.showKeyboard === 1;
            form.open_drawer_after_print =
              config.posConfig.open_drawer_after_printing === 1;
            form.showTextLcd = config.posConfig.showTextLcd
              ? config.posConfig.showTextLcd
              : "";
            form.footer_text = config.posConfig.footer_text
              ? config.posConfig.footer_text
              : "";
            form.allow_print_before =
              config.posConfig.allow_print_before_payement === 1;
            form.allow_print_after =
              config.posConfig.allow_print_after_payement === 1;
            form.store_name = config.store.name ? config.store.name : "";
            form.show_products = config.posConfig.show_products === 1;
            form.show_refundables = config.posConfig.show_refundables === 1;
            form.decimal_precision = config.posConfig.decimalPrecision;
            form.generate_sale = config.posConfig.generate_sale === 1;
            form.redirectAfterCart = config.posConfig.redirect_after_cart;
            form.has_second_display = config.posConfig.has_second_display
              ? config.posConfig.has_second_display === 1
              : false;

            if (config.posConfig.has_second_display) {
                console.log('in here get by id and has second screen')
                if (config.posConfig.second_screen_bg) {
                const base64BgImage = config.posConfig.second_screen_bg;
                form.secondBgImg64 = base64BgImage;
                const bgFile = base64ToFile(base64BgImage, 'second_screen_bg.png');
                form.secondBgImg = [{ originFileObj: bgFile, name: 'second_screen_bg.png' }];
                }else{
                    form.secondBgImg64="";
                }

                if (config.posConfig.second_screen_side) {
                    const base64SideImage = config.posConfig.second_screen_side;
                    form.secondSideImg64 = base64SideImage;
                    const sideFile = base64ToFile(base64SideImage, 'second_screen_side.png');
                    form.secondSideImg = [{ originFileObj: sideFile, name: 'second_screen_side.png' }];
                }else{
                    form.secondSideImg64="";
                }
            }
          } else if (form.type === "kitchen_display") {
            configItemsUpdate.value = "kitchenConf";
            form.defaultLanguage = config.defaultLanguage
              ? config.defaultLanguage
              : "";
            form.ip_address = config.ip_address ? config.ip_address : "";
            form.mac_address = config.mac_address ? config.mac_address : "";
            form.hibernate = config.hibernate ? config.hibernate : "";
            form.primaryColor = config.primaryColor ? config.primaryColor : "";
            form.secondaryColor = config.secondaryColor
              ? config.secondaryColor
              : "";
            form.primaryLight = config.primaryLight ? config.primaryLight : "";
          } else if (form.type === "menu_display") {
            configItemsUpdate.value = "menuConf";
            form.defaultLanguage = config.defaultLanguage
              ? config.defaultLanguage
              : "";
            form.ip_address = config.ip_address ? config.ip_address : "";
            form.mac_address = config.mac_address ? config.mac_address : "";
            form.hibernate = config.hibernate ? config.hibernate : "";
            form.primaryColor = config.primaryColor ? config.primaryColor : "";
            form.secondaryColor = config.secondaryColor
              ? config.secondaryColor
              : "";
            form.primaryLight = config.primaryLight ? config.primaryLight : "";
          } else if (form.type === "order_display") {
            configItemsUpdate.value = "orderConf";
            form.defaultLanguage = config.defaultLanguage
              ? config.defaultLanguage
              : "";
            form.ip_address = config.ip_address ? config.ip_address : "";
            form.mac_address = config.mac_address ? config.mac_address : "";
            form.hibernate = config.hibernate ? config.hibernate : "";
            form.primaryColor = config.primaryColor ? config.primaryColor : "";
            form.secondaryColor = config.secondaryColor
              ? config.secondaryColor
              : "";
            form.primaryLight = config.primaryLight ? config.primaryLight : "";
          } else if (form.type === "printer") {
            configItemsUpdate.value = "printerConf";
            form.defaultLanguage = config.defaultLanguage
              ? config.defaultLanguage
              : "";
            form.production_point_id = config.production_point_id
              ? config.production_point_id
              : "";
            form.ip_address = config.ip_address ? config.ip_address : "";
            form.mac_address = config.mac_address ? config.mac_address : "";
          }
          loading.value = false;
        })
        .catch((error) => {
          console.error("Error fetching device by id:", error);
        });
    };

    const deleteDevice = (id: string) => {
      loading.value = true;
      deviceService
        .delete(id)
        .then((res) => {
          //state.data = state.data.filter(e => e.id !== id);

          showSuccessNotification(t("Pos.DeleteDeviceSuccess"));
          getAll();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log("finish");
        });
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t("Pos.TitleDeleteDevice"),
        text: t("Pos.TextDeleteDevice"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonColor: "#d33",
        cancelButtonText: t("Pos.Cancel"),
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteDevice(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      });
    };

    const resetFormAdd = () => {
      addFormRef.value.resetFields();
      options.value = [];
      state.prArray = [];
      validationHelpQT.value = {};
      validationStatusQT.value = {};
      addForm.items = [];

      dynamicValidateForm.prints = [];
    };

    const resetFormLogin = () => {
      logInFormRef.value.resetFields();
      addForm.primaryColor = "#0000";
    };

    //modal :
    const openModal = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    let deviceId: any = null;
    const showModal = (record: any) => {
      openModal.value = true;
      deviceId = record.id;
    };

    const handleOks = () => {
      confirmLoading.value = true;
      setTimeout(() => {
        openModal.value = false;
        confirmLoading.value = false;
      }, 2000);
    };

    const handleCancel = () => {
      openModal.value = false;

      resetFormLogin();
    };

    const handleOk = async () => {
      logInFormRef.value
        .validate()
        .then(() => {
          try {
            confirmLoading.value = true;
            let payload = {
              code: loginForm.code,
            };

            //await deviceService.connectDevice(deviceId, payload);
            deviceService
              .connectDevice(deviceId, payload)
              .then((res) => {
                showSuccessNotification(t("Pos.ConnectDeviceSuccess"));
              })
              .catch((error) => {
                if (error === 400) {
                  showErrorNotification("Invalid code");
                }
              })
              .finally(() => {
                confirmLoading.value = false;
                openModal.value = false;

                resetFormLogin();
              });
          } catch (error) {
            console.error("Error connecting device:", error);
          }
        })
        .catch((error: any) => {
          console.error("Validation error:", error);
        });
    };

    const getTableLocations = () => {
      console.log("begin table locations");
      return tableLocationService
        .getAll()
        .then((rep) => {
          console.log("rep.data : ", rep.data);

          state.tableLocations = rep.data;
        })
        .catch((error) => {
          console.log("error filter tabe locations", error);
        });
    };

    const getWarehouses = () => {
      console.log("begin table locations");
      return warehouseService
        .getAll()
        .then((rep) => {
          console.log("warehouses : ", rep.data);

          state.warehouses = rep.data;
        })
        .catch((error) => {
          console.log("error filter warehouses", error);
        });
    };

    const transformUpperCase = (str: string) => {
      if (!str || str.length === 0) {
        return str;
      }

      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const getWarehouseName = (id: any) => {
      const warehouse = state.warehouses.find((item) => item.id === id);
      return warehouse ? warehouse.name : null;
    };

    const getTableLocationName = (id: any) => {
      const tableLocation = state.tableLocations.find((item) => item.id === id);
      return tableLocation ? tableLocation.name : null;
    };

    //config watchers :
    const configItems = ref("posConf");
    watch(
      () => addForm.type,
      (newValue) => {
        if (newValue === "pos") {
          configItems.value = "posConf";
        } else if (newValue === "kitchen_display") {
          configItems.value = "kitchenConf";
        } else if (newValue === "menu_display") {
          configItems.value = "menuConf";
        } else if (newValue === "order_display") {
          configItems.value = "orderConf";
        } else if (newValue === "printer") {
          configItems.value = "printerConf";
        }
      }
    );

    watch(
      () => form.type,
      (newValue) => {
        if (newValue === "pos") {
          configItemsUpdate.value = "posConf";
        } else if (newValue === "kitchen_display") {
          configItemsUpdate.value = "kitchenConf";
        } else if (newValue === "menu_display") {
          configItemsUpdate.value = "menuConf";
        } else if (newValue === "order_display") {
          configItemsUpdate.value = "orderConf";
        } else if (newValue === "printer") {
          configItemsUpdate.value = "printerConf";
        }
      }
    );

    const logout = (id: any) => {
      Swal.fire({
        title: t("Pos.TitleLogout"),
        text: t("Pos.TextLogout"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Logout"),
        cancelButtonColor: "#d33",
        cancelButtonText: t("Pos.Cancel"),
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deviceService
            .logoutDevice(id)
            .then((res) => {
              console.log("response logout", res);
              showSuccessNotification(t("Pos.DeviceLogoutSuccess"));
            })
            .catch((error) => {
              console.log("error", error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      });
    };

    const refresh = (id: any) => {
      Swal.fire({
        title: t("Pos.TitleRefresh"),
        text: t("Pos.TextRefresh"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Refresh"),
        cancelButtonColor: "#d33",
        cancelButtonText: t("Pos.Cancel"),
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          loading.value = true;
          deviceService
            .refreshDevice(id)
            .then((res) => {
              showSuccessNotification(t("Pos.RefreshDeviceSuccess"));
              loading.value = false;
            })
            .catch((error) => {
              loading.value = false;
              console.log("error", error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      });
    };

    //search

    const filter = (key: string, value: string) => {
      currentPage.value = 1;
      loading.value = true;
      deviceService
        .filter(key, value, currentPage.value, perPage.value)
        .then((res) => {
          state.data = res.data;
          state.totalPages = res.total;
          state.beginSearch = true;
          loading.value = false;
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();

      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;

      state.deviceName = state.searchText;

      console.log("searched data", state.searchText);
      console.log("seareched column", state.searchedColumn);

      if (dataIndex === "name" && state.searchText) {
        filter(dataIndex, state.searchText);
      }
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      state.searchText = "";
      state.beginSearch = false;

      (state.deviceName = ""), (currentPage.value = 1);
      perPage.value = 10;
      selectPagination.value = "10";

      getAll();
    };

    //mutiselect
    const options = ref();
    let items = ref<any[]>([]);

    const handleMultiChange = (value: any[]) => {
      items.value = value;
      addForm.items = value;
      console.log("items", items.value);
      console.log("values", value);
      //console.log('state values', state.allValues);

      //state.allValues = value.slice();

      state.prArray = state.prArray.filter((item) =>
        value.includes(item.item_id)
      );

      if (value) {
        value.forEach((itemId) => {
          const selectedItem = options.value.find(
            (option: any) => option.value === itemId
          );
          if (
            selectedItem &&
            !state.prArray.some((item) => item.item_id === itemId)
          ) {
            state.prArray.push({
              item_id: itemId,
              item_name: selectedItem.label,
              ipAddres: selectedItem.ip,
            });
          }
        });
      }

      console.log("pr array", state.prArray);
    };

    const getPrinters = () => {
      deviceService
        .getAll()
        .then((res) => {
          options.value = res.data.map((item: any) => {
            const config = JSON.parse(item.config);
            return {
              value: item.id,
              label: item.name,
              ip: config.posConfig.ip_address,
            };
          });
        })
        .catch((error) => {
          console.log("error getting device type printer", error);
        });
    };

    const getProductionPoints = () => {
      productionPointService
        .getAll()
        .then((res) => {
          state.productionPoints = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    //validation
    interface ValidationStatus {
      [key: string]: string;
    }

    interface ValidationHelp {
      [key: string]: string;
    }

    const validationStatusQT = ref<ValidationStatus>({});

    const validationHelpQT = ref<ValidationHelp>({});

    const getValidatingStatusQT = (id: any, fieldType: string) => {
      return validationStatusQT.value[`${fieldType}${id}`] || "";
    };

    const getValidatingHelpQT = (id: any, fieldType: string) => {
      return validationHelpQT.value[`${fieldType}${id}`] || "";
    };

    interface SelectedOptions {
      [key: string]: any;
    }

    const selectedOptions = reactive<SelectedOptions>({});

    const handleUnitChange = (value: any, id: string) => {
      console.log("Selected value:", value, "id : ", id);
      selectedOptions[id] = value;

      validationStatusQT.value[`pp${id}`] = "success";
      validationHelpQT.value[`pp${id}`] = "";
    };

    // multi select update
    let optionsUpdate = ref();

    const getPrintersForUpdate = () => {
      deviceService.getAll().then((res) => {
        optionsUpdate.value = res.data.map((item: any) => {
          const config = JSON.parse(item.config);
          return {
            value: item.id,
            label: item.name,
            ip: config.posConfig.ip_address,
          };
        });
      });
    };

    const handleUpdatePinter = (value: any[]) => {
      console.log("selected ingr update", value);

      state.printersArray = state.printersArray.filter((item) =>
        value.includes(item.item_id)
      );
      if (value) {
        value.forEach((itemId) => {
          const selectedItem = form.printers_info.find(
            (option: any) => option.printerId === itemId
          );
          if (
            selectedItem &&
            !state.printersArray.some((itm: any) => itm.printerId === itemId)
          ) {
            // add dinto ingrArray with additional info
            state.printersArray.push({
              printerId: selectedItem.printerId,
              printerName: selectedItem.printerName,
              ipAddress: selectedItem.ipAddress,
              productionPointId: selectedItem.productionPointId,
            });
          } else if (
            !selectedItem &&
            !state.printersArray.some((itm: any) => itm.printerId === itemId)
          ) {
            //add into ingrArray with id and name
            const newItem = optionsUpdate.value.find(
              (opt: any) => opt.value === itemId
            );
            state.printersArray.push({
              printerId: itemId,
              printerName: newItem.label,
            });
          }
        });
      }
    };

    //printers add  V2
    interface Printer {
      printerName: string;
      ipAddress: string;
      production_point_id: string;
    }
    const formRefPrint = ref<FormInstance>();
    const dynamicValidateForm = reactive<{ prints: Printer[] }>({
      prints: [],
    });

    const removePrint = (item: Printer) => {
      console.log("item", item);
      const index = dynamicValidateForm.prints.indexOf(item);
      console.log("remove", item);

      if (index !== -1) {
        dynamicValidateForm.prints.splice(index, 1);
      }
    };

    const addPrints = () => {
      dynamicValidateForm.prints.push({
        printerName: "",
        ipAddress: "",
        production_point_id: "",
      });
    };

    const onFinish = (values: any) => {
      console.log("Received values of form:", values);
      console.log("dynamicValidateForm.prefs:", dynamicValidateForm.prints);
    };

    const add = () => {
      addFormRef.value
        .validate()
        .then(async () => {
          let payload: any = {};

          console.log("configItems", configItems.value);
          if (configItems.value === "posConf") {
            const storeItem = localStorage.getItem("store")
              ? JSON.parse(localStorage.getItem("store")!)
              : "";
            let store = {
              logo: storeItem.logo,
              name: addForm.store_name,
              phone: storeItem.phone,
              address: storeItem.address,
              defaultRoute: addForm.defaultRoute ? addForm.defaultRoute : null,
              primaryColor: addForm.primaryColor
                ? addForm.primaryColor
                : "#000",
              primaryLight: addForm.primaryLight
                ? addForm.primaryLight
                : "#000",
              secondaryColor: addForm.secondaryColor
                ? addForm.secondaryColor
                : "#000",
              defaultLanguage: addForm.defaultLanguage
                ? addForm.defaultLanguage
                : null,
            };

            let posConfig = {
              hibernate: addForm.hibernate ? addForm.hibernate : null,
              ip_address: addForm.ip_address ? addForm.ip_address : null,
              show_table: addForm.show_table ? 1 : 0,
              footer_text: addForm.footer_text ? addForm.footer_text : null,
              mac_address: addForm.mac_address ? addForm.mac_address : null,
              showTextLcd: addForm.showTextLcd,
              showKeyboard: addForm.showKeyboard ? 1 : 0,
              show_cashier: addForm.show_cashier ? 1 : 0,
              defaultTableFilter: addForm.defaultTableFilter,
              open_drawer_after_printing: addForm.open_drawer_after_print
                ? 1
                : 0,
              allow_print_before_payement: addForm.allow_print_before ? 1 : 0,
              allow_print_after_payement: addForm.allow_print_after ? 1 : 0,
              show_refundables: addForm.show_refundables ? 1 : 0,
              show_products: addForm.show_products ? 1 : 0,
              decimalPrecision: addForm.decimal_precision,
              generate_sale: addForm.generate_sale ? 1 : 0,
              has_second_display: addForm.has_second_display ? 1 : 0,
            } as any;

            if (addForm.has_second_display) {
              if (addForm.secondBgImg.length > 0) {
                const bgFile = addForm.secondBgImg[0]?.originFileObj;
                if (bgFile) {
                  posConfig["second_screen_bg"] = await convertToBase64(bgFile);
                }
              }

              if (addForm.secondSideImg.length > 0) {
                const sideFile = addForm.secondSideImg[0]?.originFileObj;
                if (sideFile) {
                  posConfig["second_screen_side"] = await convertToBase64(
                    sideFile
                  );
                }
              }
            }

            let config = {
              warehouse_id: addForm.warehouse_id,
              store: store,
              posConfig: posConfig,
            };

            console.log("config", config);
            const isActive = addForm.is_active ? 1 : 0;
            payload = {
              name: addForm.name,
              type: addForm.type,
              config: JSON.stringify(config),
              is_active: isActive,
            };
          } else if (configItems.value === "printerConf") {
            let printerConfig = {
              mac_address: addForm.mac_address ? addForm.mac_address : null,
              ip_address: addForm.ip_address ? addForm.ip_address : null,
              defaultLanguage: addForm.defaultLanguage
                ? addForm.defaultLanguage
                : null,
              production_point_id: addForm.production_point_id,
              production_point_name: addForm.production_point_name,
            };
            const isActive = addForm.is_active ? 1 : 0;
            payload = {
              name: addForm.name,
              type: addForm.type,
              config: JSON.stringify(printerConfig),
              is_active: isActive,
            };
          } else if (
            configItems.value === "kitchenConf" ||
            configItems.value === "menuConf" ||
            configItems.value === "printerConf" ||
            configItems.value === "orderConf"
          ) {
            let config = {
              mac_address: addForm.mac_address ? addForm.mac_address : null,
              ip_address: addForm.ip_address ? addForm.ip_address : null,
              defaultLanguage: addForm.defaultLanguage
                ? addForm.defaultLanguage
                : null,
              hibernate: addForm.hibernate ? addForm.hibernate : null,
              primaryColor: addForm.primaryColor
                ? addForm.primaryColor
                : "#000",
              primaryLight: addForm.primaryLight
                ? addForm.primaryLight
                : "#000",
              secondaryColor: addForm.secondaryColor
                ? addForm.secondaryColor
                : "#000",
              storeName: addForm.store_name ? addForm.store_name : "",
              decimalPrecision: addForm.decimal_precision
                ? addForm.decimal_precision
                : "",
            };

            const isActive = addForm.is_active ? 1 : 0;
            payload = {
              name: addForm.name,
              type: addForm.type,
              config: JSON.stringify(config),
              is_active: isActive,
            };
          }

          console.log("payload", payload);

          loading.value = true;

          deviceService
            .create(payload)
            .then((rep) => {
              //state.data.push(rep.data);
              showSuccessNotification(t("Pos.AddDeviceSuccess"));
              getAll();
              resetFormAdd();
            })
            .catch((error) => {
              console.error("Error adding discount:", error);
            })
            .finally(() => {
              loading.value = false;
              openAdd.value = false;
            });
        })
        .catch((error: any) => {
          console.error("Validation error:", error);
        });

      /*

            Promise.all([
                addFormRef.value.validate(),
                formRefPrint?.value?.validateFields(),
            ]).then(() => {
                console.log('Both forms are valid');
                console.log('dynamicValidateForm.prefs:', dynamicValidateForm.prints);

                    const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
                        
                    let store={
                        logo:storeItem.logo,
                        name:addForm.store_name,
                        phone:storeItem.phone,
                        address:storeItem.address,
                        defaultRoute:addForm.defaultRoute ? addForm.defaultRoute : null ,
                        primaryColor:addForm.primaryColor ? addForm.primaryColor : "#000",
                        primaryLight:addForm.primaryLight ? addForm.primaryLight : "#000",
                        secondaryColor:addForm.secondaryColor ? addForm.secondaryColor : "#000",
                        defaultLanguage:addForm.defaultLanguage ? addForm.defaultLanguage :null ,
                        
                    }

                    let posConfig={
                        hibernate:addForm.hibernate ? addForm.hibernate : null,
                        ip_address:addForm.ip_address ? addForm.ip_address :null,
                        show_table:addForm.show_table,
                        footer_text:addForm.footer_text ? addForm.footer_text :null,
                        mac_address:addForm.mac_address ? addForm.mac_address :null,
                        showTextLcd:addForm.showTextLcd,
                        showKeyboard:addForm.showKeyboard?1:0,
                        show_cashier:addForm.show_cashier?1:0,
                        defaultTableFilter:addForm.defaultTableFilter,
                        open_drawer_after_printing:addForm.open_drawer_after_print?1:0,
                        allow_print_before_payement:addForm.allow_print_before?1:0,
                        allow_print_after_payement:addForm.allow_print_after?1:0,
                        show_refundables:addForm.show_refundables?1:0,
                        show_products:addForm.show_products?1:0,
                        decimalPrecision:addForm.decimal_precision,

                    }

                    let config={
                        warehouse_id:addForm.warehouse_id,
                        store:store,
                        posConfig:posConfig,
                        printerConfig:dynamicValidateForm.prints,
                    }

                    console.log('config', config);
                    const isActive = addForm.is_active ? 1 : 0;
                    let payload = {
                        name:addForm.name,
                        type:addForm.type,
                        config: JSON.stringify(config),
                        is_active:isActive  
                    
                    };

                    console.log('payload', payload);

                    
                    loading.value = true;

                    deviceService.create(payload).then((rep)=>{
                        
                        state.data.push(rep.data);
                        resetFormAdd();
                        showSuccessNotification(t('Pos.AddDeviceSuccess'));
                    }).catch((error) => {
                        console.error('Error adding discount:', error); 
                    }).finally(()=>{
                        loading.value = false;
                        openAdd.value=false;
                    });

            }).catch((errors) => {
                console.error('Validation error:', errors);
            });
            */

      /*
            if(state.prArray.length!==0){
                let hasErrors = false;

                let printers : any[]=[];
                state.prArray.forEach((item)=>{
                    if (!selectedOptions[item.item_id] || selectedOptions[item.item_id] === "") {
                        validationStatusQT.value[`pp${item.item_id}`] = 'error';
                        validationHelpQT.value[`pp${item.item_id}`] = t('Pos.RequiredField');
                        hasErrors=true;
                    } else {
                        validationStatusQT.value[`pp${item.item_id}`] = 'success';
                        validationHelpQT.value[`pp${item.item_id}`] = '';
                        

                       let printer={
                            printerId:item.item_id,
                            name:item.item_name,
                            ipAddress:item.ipAddres,
                            production_point_id:selectedOptions[item.item_id],
                        }
                        console.log('printer', printer);
                        printers.push(printer);
                        console.log('printers', printers);
                    }

                    
                });
                if (!hasErrors) {

                    addFormRef.value
                    .validate()
                    .then(()=>{
                        const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
                        
                        let store={
                            logo:storeItem.logo,
                            name:addForm.store_name,
                            phone:storeItem.phone,
                            address:storeItem.address,
                            defaultRoute:addForm.defaultRoute ? addForm.defaultRoute : null ,
                            primaryColor:addForm.primaryColor ? addForm.primaryColor : "#000",
                            primaryLight:addForm.primaryLight ? addForm.primaryLight : "#000",
                            secondaryColor:addForm.secondaryColor ? addForm.secondaryColor : "#000",
                            defaultLanguage:addForm.defaultLanguage ? addForm.defaultLanguage :null ,
                            
                        }

                        let posConfig={
                            hibernate:addForm.hibernate ? addForm.hibernate : null,
                            ip_address:addForm.ip_address ? addForm.ip_address :null,
                            show_table:addForm.show_table,
                            footer_text:addForm.footer_text ? addForm.footer_text :null,
                            mac_address:addForm.mac_address ? addForm.mac_address :null,
                            showTextLcd:addForm.showTextLcd,
                            showKeyboard:addForm.showKeyboard?1:0,
                            show_cashier:addForm.show_cashier?1:0,
                            defaultTableFilter:addForm.defaultTableFilter,
                            open_drawer_after_printing:addForm.open_drawer_after_print?1:0,
                            allow_print_before_payement:addForm.allow_print_before?1:0,
                            allow_print_after_payement:addForm.allow_print_after?1:0,
                            show_refundables:addForm.show_refundables?1:0,
                            show_products:addForm.show_products?1:0,
                            decimalPrecision:addForm.decimal_precision,

                        }

                        let config={
                            warehouse_id:addForm.warehouse_id,
                            store:store,
                            posConfig:posConfig,
                            printerConfig:printers,
                        }
                        const isActive = addForm.is_active ? 1 : 0;
                        let payload = {
                            name:addForm.name,
                            type:addForm.type,
                            config: JSON.stringify(config),
                            is_active:isActive  
                        
                        };

                    
                        loading.value = true;

                        deviceService.create(payload).then((rep)=>{
                            
                            state.data.push(rep.data);
                            resetFormAdd();
                            showSuccessNotification(t('Pos.AddDeviceSuccess'));
                        }).catch((error) => {
                            console.error('Error adding discount:', error); 
                        }).finally(()=>{
                            loading.value = false;
                            openAdd.value=false;
                        });
                        

                    }).catch((error:any) => {
                        console.error('Validation error:', error);
                    });

                }else{
                    console.log('error validation dynamic fields');
                }
               
            }else{

                console.log('pr Array empty');

                addFormRef.value
                .validate()
                .then(()=>{
                    const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
                
                    let store={
                        logo:storeItem.logo,
                        name:addForm.store_name,
                        phone:storeItem.phone,
                        address:storeItem.address,
                        defaultRoute:addForm.defaultRoute ? addForm.defaultRoute : null ,
                        primaryColor:addForm.primaryColor ? addForm.primaryColor : "#000",
                        primaryLight:addForm.primaryLight ? addForm.primaryLight : "#000",
                        secondaryColor:addForm.secondaryColor ? addForm.secondaryColor : "#000",
                        defaultLanguage:addForm.defaultLanguage ? addForm.defaultLanguage :null ,
                        
                    }

                    let posConfig={
                        hibernate:addForm.hibernate ? addForm.hibernate : null,
                        ip_address:addForm.ip_address ? addForm.ip_address :null,
                        show_table:addForm.show_table,
                        footer_text:addForm.footer_text ? addForm.footer_text :null,
                        mac_address:addForm.mac_address ? addForm.mac_address :null,
                        showTextLcd:addForm.showTextLcd,
                        showKeyboard:addForm.showKeyboard?1:0,
                        show_cashier:addForm.show_cashier?1:0,
                        defaultTableFilter:addForm.defaultTableFilter,
                        open_drawer_after_printing:addForm.open_drawer_after_print?1:0,
                        allow_print_before_payement:addForm.allow_print_before?1:0,
                        allow_print_after_payement:addForm.allow_print_after?1:0,
                        show_refundables:addForm.show_refundables?1:0,
                        show_products:addForm.show_products?1:0,
                        decimalPrecision:addForm.decimal_precision,

                    }

                    let config={
                        warehouse_id:addForm.warehouse_id,
                        store:store,
                        posConfig:posConfig
                    }
                    const isActive = addForm.is_active ? 1 : 0;
                    let payload = {
                        name:addForm.name,
                        type:addForm.type,
                        config: JSON.stringify(config),
                        is_active:isActive  
                       
                    };

                    console.log('posConfig', posConfig);

                    
                   
                    console.log('payload', payload);
                   
                    loading.value = true;

                    deviceService.create(payload).then((rep)=>{
                        console.log('Device added successfully:', rep);
                        
                        state.data.push(rep.data);
                        resetFormAdd();
                        showSuccessNotification(t('Pos.AddDeviceSuccess'));
                    }).catch((error) => {
                        console.error('Error adding discount:', error); 
                    }).finally(()=>{
                        loading.value = false;
                        openAdd.value=false;
                    });
                    

                }).catch((error:any) => {
                    console.error('Validation error:', error);
                });

            }
            */
    };

    // dynamic form update v2
    const formRefUpdatePrint = ref<FormInstance>();

    const dynamicForm = reactive<{ printers: Printer[] }>({
      printers: [],
    });

    const addPrintsUpdate = () => {
      dynamicForm.printers.push({
        printerName: "",
        ipAddress: "",
        production_point_id: "",
      });
    };

    const removePrintUpdate = (item: Printer) => {
      console.log("item", item);
      const index = dynamicForm.printers.indexOf(item);
      console.log("remove", item);

      if (index !== -1) {
        dynamicForm.printers.splice(index, 1);
      }
    };

    const removePrinterUpdate = (id: any) => {
      console.log("removed item,", id);
      const index = state.printersArray.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.printersArray.splice(index, 1);
      }
    };

    const update = (id: string) => {
      formRef.value
        .validate()
        .then(async () => {
          let payload: any = {};

          if (configItemsUpdate.value === "posConf") {
            const storeItem = localStorage.getItem("store")
              ? JSON.parse(localStorage.getItem("store")!)
              : "";

            let store = {
              logo: storeItem.logo,
              name: form.store_name,
              phone: storeItem.phone,
              address: storeItem.address,
              defaultRoute: form.defaultRoute ? form.defaultRoute : null,
              primaryColor: form.primaryColor ? form.primaryColor : null,
              primaryLight: form.primaryLight ? form.primaryLight : null,
              secondaryColor: form.secondaryColor ? form.secondaryColor : null,
              defaultLanguage: form.defaultLanguage
                ? form.defaultLanguage
                : null,
            };

            let posConfig = {
              hibernate: form.hibernate ? form.hibernate : null,
              ip_address: form.ip_address ? form.ip_address : null,
              show_table: form.show_table ? 1 : 0,
              footer_text: form.footer_text ? form.footer_text : null,
              mac_address: form.mac_address ? form.mac_address : null,
              showTextLcd: form.showTextLcd,
              showKeyboard: form.showKeyboard ? 1 : 0,
              show_cashier: form.show_cashier ? 1 : 0,
              defaultTableFilter: form.defaultTableFilter,
              open_drawer_after_printing: form.open_drawer_after_print ? 1 : 0,
              allow_print_before_payement: form.allow_print_before ? 1 : 0,
              allow_print_after_payement: form.allow_print_after ? 1 : 0,
              show_refundables: form.show_refundables ? 1 : 0,
              show_products: form.show_products ? 1 : 0,
              decimalPrecision: form.decimal_precision,
              generate_sale: form.generate_sale ? 1 : 0,
              redirect_after_cart: form.redirectAfterCart,
              has_second_display: form.has_second_display ? 1 : 0,
            } as any;

            if (form.has_second_display) {
              if (form.secondBgImg.length > 0) {
                const bgFile = form.secondBgImg[0]?.originFileObj;
                if (bgFile) {
                  posConfig["second_screen_bg"] = await convertToBase64(bgFile);
                }
              }

              if (form.secondSideImg.length > 0) {
                const sideFile = form.secondSideImg[0]?.originFileObj;
                if (sideFile) {
                  posConfig["second_screen_side"] = await convertToBase64(
                    sideFile
                  );
                }
              }
            }

            let config = {
              warehouse_id: form.warehouse_id,
              store: store,
              posConfig: posConfig,
            };

            // console.log('update config', config)

            payload = {
              name: form.name,
              type: form.type,
              is_active: form.is_active ? 1 : 0,
              config: JSON.stringify(config),
            };
          } else if (configItemsUpdate.value === "printerConf") {
            let printerConfig = {
              mac_address: form.mac_address ? form.mac_address : null,
              ip_address: form.ip_address ? form.ip_address : null,
              defaultLanguage: form.defaultLanguage
                ? form.defaultLanguage
                : null,
              production_point_id: form.production_point_id,
              production_point_name: form.production_point_name,
            };
            payload = {
              name: form.name,
              type: form.type,
              config: JSON.stringify(printerConfig),
              is_active: form.is_active ? 1 : 0,
            };
          }
        //   console.log("payload", payload);
          
          loading.value = true;
          deviceService
            .update(id, payload)
            .then((response) => {
              showSuccessNotification(t("Pos.UpdateDeviceSuccess"));

              state.data = state.data.map((el) =>
                el.id !== id ? el : response.data
              );
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
              open.value = false;
            });
            
        })
        .catch((error: any) => {
          console.error("Validation error:", error);
        });

      //v2
      /*
            Promise.all([
                formRef.value.validate(),
                formRefUpdatePrint?.value?.validateFields(),
            ]).then(() => {
                console.log('Both forms are valid');
                console.log('dynamicForm.printers:', dynamicForm.printers);

                let printers : any[]=[];
                let hasErrors=false;
                state.printersArray.forEach((item)=>{
                    const nameInput = document.getElementById(`name${item.id}`) as HTMLInputElement;
                    const ipInput = document.getElementById(`ip${item.id}`) as HTMLInputElement;

                    if(nameInput.value ===""){
                        validationStatusQT.value[`name${item.id}`] = 'error';
                        validationHelpQT.value[`name${item.id}`] = t('Pos.RequiredField');
                        hasErrors = true;
                    }else{
                        validationStatusQT.value[`name${item.id}`] = '';
                        validationHelpQT.value[`name${item.id}`] = '';
                    }

                    if(ipInput.value ===""){
                        validationStatusQT.value[`ip${item.id}`] = 'error';
                        validationHelpQT.value[`ip${item.id}`] = t('Pos.RequiredField');
                        hasErrors = true;
                    }else{
                        validationStatusQT.value[`ip${item.id}`] = '';
                        validationHelpQT.value[`ip${item.id}`] = '';
                    }

                    if (!selectedOptions[item.id] || selectedOptions[item.id] === "") {
                        validationStatusQT.value[`pp${item.id}`] = 'error';
                        validationHelpQT.value[`pp${item.id}`] = t('Pos.RequiredField');
                        hasErrors = true;
                    } else {
                        validationStatusQT.value[`pp${item.id}`] = 'success';
                        validationHelpQT.value[`pp${item.id}`] = '';
                    }

                    if (!hasErrors) { 
                        let printer={
                            printerName:nameInput.value,
                            ipAddress:ipInput.value,
                            production_point_id:selectedOptions[item.id]
                        }

                        printers.push(printer);
                    }
                });

                dynamicForm.printers.forEach((item)=>{
                    let printer={
                        printerName:item.printerName,
                        ipAddress:item.ipAddress,
                        production_point_id:item.production_point_id
                    }

                    printers.push(printer);
                });
                console.log('final printers config', printers);



                if(!hasErrors){

                

                    const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
                        
                    let store={
                        logo:storeItem.logo,
                        name:form.store_name,
                        phone:storeItem.phone,
                        address:storeItem.address,
                        defaultRoute:form.defaultRoute ? form.defaultRoute : null ,
                        primaryColor:form.primaryColor ? form.primaryColor : null,
                        primaryLight:form.primaryLight ? form.primaryLight : null,
                        secondaryColor:form.secondaryColor ? form.secondaryColor : null,
                        defaultLanguage:form.defaultLanguage ? form.defaultLanguage :null ,
                    
                    }

                    let posConfig={
                        hibernate:form.hibernate ? form.hibernate : null,
                        ip_address:form.ip_address ? form.ip_address :null,
                        show_table:form.show_table,
                        footer_text:form.footer_text ? form.footer_text :null,
                        mac_address:form.mac_address ? form.mac_address :null,
                        showTextLcd:form.showTextLcd,
                        showKeyboard:form.showKeyboard,
                        show_cashier:form.show_cashier,
                        defaultTableFilter:form.defaultTableFilter,
                        open_drawer_after_printing:form.open_drawer_after_print,
                        allow_print_before_payement:form.allow_print_before?1:0,
                        allow_print_after_payement:form.allow_print_after?1:0,
                        show_refundables:form.show_refundables,
                        show_products:form.show_products,
                        decimalPrecision:form.decimal_precision,

                    }

                    let config={
                        warehouse_id:form.warehouse_id,
                        store:store,
                        posConfig:posConfig,
                        printerConfig:printers
                    }

                    
                    let payload = {
                        name:form.name,
                        type:form.type,
                        is_active: form.is_active ? 1 : 0,
                        config: JSON.stringify(config),
                    }
                    console.log('payload', payload);

                    loading.value = true;
                    deviceService.update(id, payload).then((response)=>{
                        
                        showSuccessNotification(t('Pos.UpdateDeviceSuccess'));

                        state.data = state.data.map(el => el.id !== id ? el : response.data);
                    }).catch((err) => {
                    console.log(err)
                    }).finally(() => {
                        loading.value = false;
                        open.value=false;

                        dynamicForm.printers=[];

                        validationHelpQT.value={};
                        validationStatusQT.value={};



                    }); 

                }else{
                    console.log('error valdation dybamic fields');
                }

            }).catch((errors) => {
                console.error('Validation error:', errors);
            });
            */

      //V1

      // let hasErrors = false;
      // if(state.printersArray.length!==0){
      //     state.printersArray.forEach((item)=>{

      //         console.log('selectedOptions', selectedOptions);
      //         if (!selectedOptions[item.printerId] || selectedOptions[item.printerId] === "") {
      //             validationStatusQT.value[`pp${item.printerId}`] = 'error';
      //             validationHelpQT.value[`pp${item.printerId}`] = t('Pos.RequiredField');
      //             hasErrors=true;
      //         } else {
      //             validationStatusQT.value[`pp${item.printerId}`] = 'success';
      //             validationHelpQT.value[`pp${item.printerId}`] = '';
      //         }
      //     });

      // }else{

      //     formRef.value
      //     .validate()
      //     .then(()=>{

      //         const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';

      //         let store={
      //             logo:storeItem.logo,
      //             name:form.store_name,
      //             phone:storeItem.phone,
      //             address:storeItem.address,
      //             defaultRoute:form.defaultRoute ? form.defaultRoute : null ,
      //             primaryColor:form.primaryColor ? form.primaryColor : null,
      //             primaryLight:form.primaryLight ? form.primaryLight : null,
      //             secondaryColor:form.secondaryColor ? form.secondaryColor : null,
      //             defaultLanguage:form.defaultLanguage ? form.defaultLanguage :null ,

      //         }

      //         let posConfig={
      //             hibernate:form.hibernate ? form.hibernate : null,
      //             ip_address:form.ip_address ? form.ip_address :null,
      //             show_table:form.show_table,
      //             footer_text:form.footer_text ? form.footer_text :null,
      //             mac_address:form.mac_address ? form.mac_address :null,
      //             showTextLcd:form.showTextLcd,
      //             showKeyboard:form.showKeyboard,
      //             show_cashier:form.show_cashier,
      //             defaultTableFilter:form.defaultTableFilter,
      //             open_drawer_after_printing:form.open_drawer_after_print,
      //             allow_print_before_payement:form.allow_print_before?1:0,
      //             allow_print_after_payement:form.allow_print_after?1:0,
      //             show_refundables:form.show_refundables,
      //             show_products:form.show_products,
      //             decimalPrecision:form.decimal_precision,

      //         }

      //         let config={
      //             warehouse_id:form.warehouse_id,
      //             store:store,
      //             posConfig:posConfig
      //         }

      //         let payload = {
      //             name:form.name,
      //             type:form.type,
      //             is_active: form.is_active ? 1 : 0,
      //             config: JSON.stringify(config),
      //         }
      //         console.log('payload', payload);

      //         loading.value = true;
      //         deviceService.update(id, payload).then((response)=>{

      //             showSuccessNotification(t('Pos.UpdateDeviceSuccess'));

      //             state.data = state.data.map(el => el.id !== id ? el : response.data);
      //         }).catch((err) => {
      //         console.log(err)
      //         }).finally(() => {
      //             loading.value = false;
      //             open.value=false;
      //         });
      //     }).catch((error:any) => {
      //         console.error('Validation error:', error);
      //     });

      // }
    };

    const getProductionPointName = (id: any) => {
      console.log("get pp ", id);
      const productionPoint = state.productionPoints.find(
        (point) => point.id === id
      );
      return productionPoint ? productionPoint.name : "";
    };

    const selectedProduction = (value: any, option: any) => {
      console.log("selected producytop", value, " option :", option);
      addForm.production_point_name = option.label;
    };

    const selectedProductionUpdate = (value: any, option: any) => {
      console.log("selected producytop", value, " option :", option);
      form.production_point_name = option.label;
    };

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };

    onMounted(() => {
      getAll();
    });

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    return {
      beforeUpload,
      updateModalWidth,
      modalWidth,
      locale,
      textDirection,
      handleSelectPaginationChange,
      selectedProductionUpdate,
      selectedProduction,
      getProductionPointName,
      removePrintUpdate,
      removePrinterUpdate,
      formRefUpdatePrint,
      addPrintsUpdate,
      dynamicForm,
      formRefPrint,
      dynamicValidateForm,
      removePrint,
      addPrints,
      onFinish,
      t,
      loading,
      navigatePage,
      state,
      columns,
      rules,
      formRef,
      addFormRef,
      form,
      addForm,
      open,
      openDisplay,
      openAdd,
      showAddDrawer,
      afterOpenChange,
      showDisplayDrawer,
      showDrawer,
      afterOpenAddChange,
      showSuccessNotification,
      formatDate,
      showDeletePopup,
      add,
      resetFormAdd,
      update,
      openModal,
      confirmLoading,
      showModal,
      handleOk,
      logInFormRef,
      loginForm,
      logInRules,
      resetFormLogin,
      formatDisplayDate,
      cancelUpdate,
      transformUpperCase,
      getWarehouseName,
      getTableLocationName,
      configItems,
      configItemsUpdate,
      logout,
      refresh,
      handleCancel,
      perPage,
      currentPage,
      handlePageChange,
      handleReset,
      handleSearch,
      simpleImage,
      options,
      handleMultiChange,
      getValidatingStatusQT,
      getValidatingHelpQT,
      handleUnitChange,
      handleUpdatePinter,
      optionsUpdate,
      selectPagination,
    };
  },
});
</script>

<style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}
.range-picker {
  width: 100%;
}

.switch-container {
  display: flex;
  align-items: center;
}
.switch-container p {
  margin-right: 15px;
}

.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}
.login-btn {
  color: #28c76f;
  border-color: #28c76f;
  background: #fafafa;
}

.login-btn :hover {
  color: #28c76f !important;
  border-color: #28c76f !important;
  background: #fafafa;
}

.breadcrumb-icon {
  cursor: pointer;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.info-title {
  font-size: 15px !important;
  font-weight: bold;
  margin-right: 4px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.config-title {
  font-weight: 600;
  font-size: 18px;
}

.variant-btn {
  color: #3b8dffdf;
  border-color: #3b8dffdf;
  background: #fafafa;
}

.variant-btn:hover {
  color: #3b8dffdf;
  border-color: #3b8dffdf;
  background: #fafafa;
}
.tooltip-icon {
  margin-right: 5px;
}

.title-check {
  margin-left: 4px;
}

.print-title {
  margin-bottom: 10px;
}

.ingredient-des {
  margin-bottom: 15px;
}

.print-space {
  width: 100%;
}

.dynamic-input {
  width: 100%;
}

.cancel-pref-icon {
  cursor: pointer;
  color: #ea5455;
  font-size: 20px;
}

.minus-conatiner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

/* CSS for RTL */
.is-rtl {
  direction: rtl;
}

.paginationArrows {
  direction: ltr;
  margin-bottom: 16px;
}

.table-container {
  overflow-x: auto;
}

.img-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
  }
}
</style>
