<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.StockOverview") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-space>
      <a-button class="add-btn" @click="exportReport()">
        {{ t("Pos.Export") }}
      </a-button>
    </a-space>
  </div>

  <div class="btn-container"></div>
  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title>{{ t("Pos.View") }}</template>
              <a-button type="primary" @click="showDrawer(record)">
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template
          v-if="column.key === 'created_at' || column.key === 'updated_at'"
        >
          {{ formatDisplayDate(record[column.key]) }}
        </template>

        <template v-if="column.key.startsWith('warehouse_id')">
          {{ quantityByWarehouseId(record, column.key) }}
        </template>
        <template v-else>
          <!-- Handle other column types (e.g., 'name' and 'actions') -->
          {{ record[column.dataIndex] }}
        </template>

        <template v-if="column.key === 'status'">
          <template v-if="record[column.dataIndex] === 'paid'">
            <check-circle-two-tone two-tone-color="#52c41a" />
          </template>
          <template v-else>
            <check-circle-two-tone two-tone-color="#ff4d4f" />
          </template>
        </template>

        <template v-if="column.key === 'checkbox'">
          <a-checkbox
            v-model="record.checked"
            @change="handleCheckboxChange(record)"
          />
        </template>
      </template>

      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <!--
    <div class="pagination">
                <a-pagination v-model:current="currentPage"  @change="handlePageChange"  :default-page-size="perPage"/>
            </div>
           -->
    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="t('Pos.saleDetails')"
      placement="right"
      :width="720"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
    >
      <a-form ref="formRef" :model="form" layout="vertical">
        <template #header>
          <div class="custom-header">{{ t("Pos.GeneralInformations") }}</div>
        </template>

        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title" v-if="form.name != ''" placeholder="name"
                >{{ t("Pos.Name") }} :</span
              >
              {{ form.name }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span
                class="info-title"
                v-if="form.email != ''"
                placeholder="Email"
                >{{ t("Pos.Email") }} :</span
              >
              {{ form.email }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="Username"
                >{{ t("Pos.Username") }}
              </span>
              {{ form.username }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="Price"
                >{{ t("Pos.Price") }} :</span
              >
              {{ form.price }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="Status">
                {{ t("Pos.Status") }} :
              </span>
              <check-circle-two-tone
                two-tone-color="#52c41a"
                v-if="form.is_paid === 1"
              />
              <close-circle-two-tone
                two-tone-color="#ff4d4f"
                v-if="form.is_paid === 0"
              />
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="discountType">{{
                t("Pos.discountType")
              }}</span>
              {{ form.discount_type }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="discount">{{
                t("Pos.discount")
              }}</span>
              {{ form.discount }}
            </p>
          </a-col>

          <a-col :span="24">
            <p>
              <span class="info-title" placeholder="ProductName">{{
                t("Pos.ProductName")
              }}</span>
              {{ form.productname }}
            </p>
          </a-col>

          <a-col :span="24">
            <p>
              <span class="info-title" placeholder="ProductDescription">{{
                t("Pos.ProductDescription")
              }}</span>
              {{ form.productdescription }}
            </p>
          </a-col>
        </a-row>
      </a-form>
    </a-drawer>

    <!-- modal transfer -->
    <a-modal v-model:open="openTransferModal" @ok="handleTransferOk">
      <template #title>
        <p>{{ t("Pos.ExportStockOverView") }}</p>
      </template>

      <a-form
        ref="formSalesRef"
        :model="exportSalesForm"
        :rules="rules"
        layout="vertical"
      >
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="order">
              <template #label>
                <span class="label-form">{{ t("Pos.Type") }}</span>
              </template>
              <a-select
                v-model:value="exportSalesForm.type"
                placeholder="Order"
                class="input-form"
              >
                <a-select-option value="pdf">pdf</a-select-option>
                <a-select-option value="excel">excel</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
    <script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRaw,
  watch,
  Ref,
} from "vue";

import type { Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";

import {
  EyeOutlined,
  SearchOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { notification } from "ant-design-vue";
import { storeService, customerService } from "@/_services";
import {
  paymentMethodsService,
  salesService,
  invoiceService,
  warehouseService,
} from "@/_services";
import dayjs from "dayjs";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { computed } from "vue";

export default defineComponent({
  name: "indexStore",
  components: {
    EyeOutlined,
    LbrxLoaderSpinner,
    SearchOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
  },
  setup() {
    const { locale } = useI18n();
    const rules: Record<string, Rule[]> = {
      customer_id: [
        {
          required: true,
          message: "Please select start date",
          trigger: "change",
        },
      ],
      invoice_date: [
        {
          required: true,
          message: "Please select start date",
          trigger: "change",
        },
      ],

      //endDate: [{ required: true, message: 'Please select end date', trigger: 'change' }],
      //orderBy: [{ required: true, message: 'Please select order by', trigger: 'change' }],
      //order: [{ required: true, message: 'Please select order', trigger: 'change' }]
    };

    const isSelectOpen = ref(false);

    let debounceTimer: ReturnType<typeof setTimeout> | null = null;
    let startedTyping = ref(false);
    const handleInput = async (event: InputEvent) => {
      startedTyping.value = true;
      //console.log('started typing', startedTyping);
      isSelectOpen.value = true;
      const input = (event.target as HTMLInputElement).value;
      //console.log('input',input);
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      debounceTimer = setTimeout(() => {
        getCustomers(input);

        //console.log('call api');
        //handleFilterOption(input);
      }, 5000); //ms
    };
    const customers = ref([]);
    const selectKey = ref(0);

    const getCustomers = (value: string) => {
      //console.log('begin customers');
      return customerService
        .filter("name", value)
        .then((rep) => {
          //console.log('rep.data : ', rep.data);

          //state.customers=rep.data;
          customers.value = rep.data;
          selectKey.value += 1;
          isSelectOpen.value = true;
          //console.log('reactive display data', customers.value);
        })
        .catch((error) => {
          //console.log("error filter customers", error);
        });
    };

    const handleChange = (value: string) => {
      //console.log(`selected ${value}`);
      isSelectOpen.value = false;
    };

    const openTransferModal = ref<boolean>(false);
    const searchInput = ref<HTMLInputElement | null>(null);
    const loading = ref(false);
    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };

    const { t } = useI18n();
    interface CustomColumn {
      title: string;
      key: string;
      dataIndex: string;
    }
    //const columns = ref([]);

    const columns: Ref<CustomColumn[]> = ref([]);

    const generateColumns = (warehouseIds: any, warehouseIdToNameMap: any) => {
      const newColumns = [
        {
          title: t("Pos.Products"),
          key: "name",
          dataIndex: "name",
          customFilterDropdown: true,
          onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible && !searchInput.value) {
              setTimeout(() => {
                const input = document.querySelector(
                  ".ant-table-filter-dropdown input"
                );

                if (input instanceof HTMLInputElement) {
                  searchInput.value = input;
                  searchInput.value.focus();
                }
              }, 100);
            }
          },
        },
      ];

      warehouseIds.forEach((warehouseId: any) => {
        const warehouseName = warehouseIdToNameMap.get(warehouseId);
        newColumns.push({
          title: warehouseName,
          key: `warehouse${warehouseId}`,
          dataIndex: `warehouse${warehouseId}`,
          customFilterDropdown: false,
          onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible && !searchInput.value) {
              setTimeout(() => {
                const input = document.querySelector(
                  ".ant-table-filter-dropdown input"
                );

                if (input instanceof HTMLInputElement) {
                  searchInput.value = input;
                  searchInput.value.focus();
                }
              }, 100);
            }
          },
        });
      });

      newColumns.push({
        title: t("Pos.totalQuantity"),
        key: "totalQuantity",
        dataIndex: "totalQuantity",
        customFilterDropdown: false,
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector(
                ".ant-table-filter-dropdown input"
              );

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      });

      return newColumns;
    };

    const quantityByWarehouseId = (record: any, key: any) => {
      // Log the record and key for debugging
      //console.log('Record:', record, 'Key:', key);

      const warehouseKey = key.replace("warehouse_id", "warehouse"); // Convert key from 'warehouse_id1' to 'warehouse1'
      const warehouseData = record[warehouseKey];

      // Log the warehouse data for debugging
      //console.log('Warehouse Data:', warehouseData);

      if (warehouseData && warehouseData.quantity != null) {
        return warehouseData.quantity;
      } else {
        return "0";
      }
    };

    //form
    const formRef = ref();
    const visible = ref<boolean>(false);
    const addFormRef = ref();

    const exportSalesForm = reactive({
      data: [] as any[],
      startDate: "",
      invoice_date: "",
      endDate: "",
      type: "pdf",
      orderBy: "name",
    });

    const reactiveData = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      customers: [] as any[],
      searchText: "",
    });
    const reactiveData2 = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      searchText: "",
    });

    const form = reactive({
      id: "",
      countSelected: false,
      name: "",
      description: "",
      address: "",
      phone: "",
      email: "",
      username: "",
      password: "",
      payment_method_id: "",
      config: "",
      status: "",
      accountNumber: "",
      active: "",

      customer: "",
      discount_type: "",
      discount: "",
      price: "",
      product1: "",
      product2: "",
      saleDetails: [],
      loyaltyPoints: 0,
      productname: "",
      productdescription: "",
      user_id: "Anonymes",
      customer_id: "",
      sale_id: "",
      invoice_number: "",
      subtotal: "",
      vat: "",
      notes: " ",
      invoice_date: "",
      selectedRowIds: "",
      countselectedRowIds: 0,
      is_paid: 0,
    });

    const Days = (date: string) => {
      form.invoice_date = new Date().toISOString().substr(0, 10);
    };

    const resetForm = () => {
      form.id = "";
      form.name = "";
      form.description = "";
      form.address = "";
      form.phone = "";
      form.email = "";
      form.username = "";
      form.payment_method_id = "";
      form.status = "";
      form.password = "";
      form.active = "";
      form.price = "";
      form.discount_type = "";
      form.discount = "";
      form.product1 = "";
      form.productdescription = "";
      form.loyaltyPoints = 0;
      form.productname = "";
    };

    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

    const formatDate = (dateString: string) => {
      return dayjs(dateString);
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    const handleTransferOk = () => {
      createBlop();
    };

    const createBlop = () => {
      loading.value = true;
      warehouseService
        .exportStockOverView(exportSalesForm.type)
        .then((blob) => {
          // Log the form values here
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Stock-Overview-Report";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          openTransferModal.value = false;
          showSuccessNotification(t("Pos.exportFile"));
        })
        .catch((error) => {
          // Handle errors
          console.error("Error exporting file:", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const formatcustomer = (dateString: any) => {
      if (dateString && dateString.customer) {
        if (dateString.customer.first_name) {
          return (
            dateString.customer.first_name + " " + dateString.customer.last_name
          );
        }
      }
      return t("Pos.Anonyme");
    };

    const productName = (dateString: any) => {
      if (dateString && dateString.stockable) {
        return dateString.stockable.name;
      } else {
        return t("----------");
      }
    };

    /*    const formatcurrency_code = (dateString: any) => {
    
                if (dateString && dateString.payments) {
                    if (dateString.payments[0].currency) {
                        return dateString.total+" "+dateString.payments[0].currency.code;
                    }
                }
                return 'N/A'; 
            };*/

    const formatTotal = (dateString: any) => {
      if (dateString && dateString.currency) {
        if (dateString.currency.name) {
          return dateString.total + " " + dateString.currency.name;
        }
      }
      return "";
    };

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();
      getStockForFilter(selectedKeys[0]);
      reactiveData.searchedColumn = dataIndex;
    };

    const getStockForFilter = (searchInput: any) => {
      console.log("input text", searchInput);
      getAll(searchInput);
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      reactiveData.searchText = "";
      getAll("");
    };

    //drawer

    const open = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
      open.value = state;
      reactiveData.action = "add";
      resetForm();
    };

    const closeOpens = () => {
      open.value = false;
    };

    const exportReport = () => {
      openTransferModal.value = true;
    };

    const afterOpenChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    const showDrawer = (record: any) => {
      /* resetForm(); 
                open.value = true;
                getSalesByid(record.id);
                reactiveData.action = 'update';*/
    };

    const hideForm = (bool: boolean) => {
      open.value = false;
    };

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    /*   const getStore = () => {
                storeService.getAll()
                    .then((res) => {
                        reactiveData.data = res.data;
                    })
                    .catch((error: any) => {
                        console.error('Error fetching Stores:', error);
                    });
            }*/

    const formatToStringDate = (date: any) => {
      if (!date || !date.$d) {
        return "";
      }

      const year = date.$y;
      const month = date.$M + 1;
      const day = date.$D < 10 ? "0" + date.$D : date.$D;

      const stringDate = `${year}-${month < 10 ? "0" : ""}${month}-${day}`;
      return stringDate;
    };

    const handleCheckboxChange = (record: any) => {
      // Toggle the checked state of the record
      record.checked = !record.checked;

      // If the record is checked, add its ID to selectedRowIds
      if (record.checked) {
        if (form.selectedRowIds) {
          form.selectedRowIds += "," + record.id;
        } else {
          form.selectedRowIds = record.id.toString();
        }
        form.countselectedRowIds += 1;
      } else {
        // If the record is unchecked, remove its ID from selectedRowIds
        const ids = form.selectedRowIds.split(",");
        const index = ids.indexOf(record.id.toString());
        if (index !== -1) {
          ids.splice(index, 1);
          form.selectedRowIds = ids.join(",");
        }
        form.countselectedRowIds -= 1;
      }

      //console.log("Selected Row IDs:", form.selectedRowIds);
    };

    const handlePageChange = (newPage: any) => {
      /*currentPage.value = newPage;
        loading.value = true;

        console.log('per page', perPage.value);
        console.log('page', currentPage.value)

        salesService.getAll(currentPage.value, perPage.value).then((response)=>{
        
            console.log('data', response.data)
            reactiveData.data=response.data;
        }).catch((error)=>{
            console.log("error api : get products", error);
        })
        .finally(()=>{
            loading.value = false;
        });*/
    };

    const deletePaymentMethod = (id: string) => {
      paymentMethodsService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification("Payment method deleted successfully");
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          //console.log('finish')
        });
    };

    const getPaymentMethodById = (id: string) => {
      paymentMethodsService
        .show(id)
        .then((res) => {
          form.payment_method_id = res.data.payment_method_id;
          form.status = res.data.status;
          form.config = res.data.config;
        })
        .catch((error) => {
          //console.error('Error fetching categories:', error);
        });
    };

    const getSalesByid = (id: string) => {
      loading.value = true;
      salesService
        .show(id)
        .then((res) => {
          form.is_paid = res.data.is_paid;
          form.price = res.data.total;
          form.status = res.data.status;
          form.discount_type = res.data.discount_type;
          form.discount = res.data.discount_value;
          form.productname = res.data.sale_details[0].product.name;
          form.productdescription =
            res.data.sale_details[0].product.description;
          form.loyaltyPoints = res.data.sale_details[0].product.loyalty_points;

          if (res.data.customer && res.data.customer.email) {
            form.email = res.data.customer.email;
          } else {
            form.email = "";
          }

          if (
            res.data.customer &&
            res.data.customer.first_name &&
            res.data.customer.last_name
          ) {
            form.name =
              res.data.customer.first_name + " " + res.data.customer.last_name;
          } else {
            form.name = "";
          }

          if (
            res.data.user &&
            res.data.user.first_name &&
            res.data.user.last_name
          ) {
            form.username =
              res.data.user.first_name + " " + res.data.user.last_name;
          } else {
            form.username = "";
          }
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getSalesForInvoice = (id: string) => {
      loading.value = true;
      salesService
        .show(id)
        .then((res) => {
          form.sale_id = res.data.id;
          form.customer_id = res.data.customer_id;
          form.invoice_number = res.data.id;
          form.user_id = res.data.user_id;
        })
        .catch((error) => {
          // console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    // form.email = res.data.customer.email;

    const addStore = () => {
      formRef.value.validate().then(() => {
        let payload = {
          name: form.name,
          description: form.description,
          address: form.address,
          phone: form.phone,
          email: form.email,
          username: form.username,
          password: form.password,
        };
        storeService
          .create(payload)
          .then((res) => {
            reactiveData.data.push(res.store);
          })
          .catch((err) => {
            //console.log(err)
          })
          .finally(() => {
            open.value = false;
          });
      });
    };

    const addPaymentMethod = () => {
      formRef.value.validate().then(() => {
        let payload = {
          payment_method_id: form.active,
          status: form.status,
          config: "null",
        };
        paymentMethodsService
          .create(payload)
          .then((res) => {
            reactiveData.data.push(res.store);
          })
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            open.value = false;
          });
      });
    };

    const updatePaymentMethod = (id: string) => {
      let payload = {
        payment_method_id: 4,
        status: form.status,
        config: form.config,
      };
      paymentMethodsService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          open.value = false;
        });
    };

    const updateStore = (id: string) => {
      let payload = {
        name: form.name,
        description: form.description,
        address: form.address,
        phone: form.phone,
        email: form.email,
        username: form.username,
        password: form.password,
      };
      storeService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          open.value = false;
        });
    };

    const deleteStore = (id: string) => {
      storeService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification("Store deleted successfully");
        })
        .catch((err) => {
          //  console.log(err)
        })
        .finally(() => {
          //  console.log('finish')
        });
    };

    const close = () => {
      //openAdd.value = false
    };
    const onSubmitInvoice = (record: any) => {
      reactiveData.action = "add";
      getSalesForInvoice(record.id);
      form.notes = "";
    };

    const createInvoice = () => {
      const userString = localStorage.getItem("user");
      if (userString === null) {
        return; // Exit early if user data is not available
      }

      const user = JSON.parse(userString);
      const userId = user.id;

      const payload = {
        customer_id: form.customer_id,
        user_id: userId,
        sales: form.sale_id,
        invoice_date: formatToStringDate(exportSalesForm.invoice_date),
        notes: form.notes,
      };

      loading.value = true;

      invoiceService
        .add(payload)
        .then((res) => {
          //reactiveData.data.push(res.data);
          showSuccessNotification("Invoice sent successfully");
          return invoiceService.generate(res.data.id);
        })
        .then((blobData) => {
          const blobUrl = URL.createObjectURL(blobData);
          window.open(blobUrl, "_blank");
          loading.value = false;
        })
        .catch((err) => {
          //  console.error('Error adding invoice:', err);
        })
        .finally(() => {
          showAddDrawer(false);
          closeOpens();
          loading.value = false;
        });
    };

    const formattedStartDate = () => {
      if (!exportSalesForm.startDate) {
        return null;
      }
      const date = new Date(exportSalesForm.startDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    interface WarehouseData {
      id: number;
      quantity: number;
      warehouseName: string;
    }

    interface TransformedData {
      [productName: string]: {
        [warehouseKey: string]: WarehouseData;
      };
    }

    const getAll = (searchInput: string) => {
      loading.value = true;

      warehouseService
        .getStockItemsWithoutFilter(searchInput)
        .then((response) => {
          const warehouseIdToNameMap: Map<number, string> = new Map();

          const transformedData: Record<string, any> = {};
          const warehouseIds = new Set<number>();

          response.data.forEach((item: any) => {
            //console.log('Raw response data:', response.data);

            if (!warehouseIdToNameMap.has(item.warehouse_id)) {
              warehouseIdToNameMap.set(item.warehouse_id, item.warehouse.name);
            }

            const productName = item.stockable.name;
            const warehouseKey = `warehouse${item.warehouse_id}`;
            const warehouseName = warehouseIdToNameMap.get(item.warehouse_id);

            if (!transformedData[productName]) {
              transformedData[productName] = {};
            }

            transformedData[productName][warehouseKey] = item.quantity;

            warehouseIds.add(item.warehouse_id);
          });

          Object.keys(transformedData).forEach((productName) => {
            warehouseIds.forEach((warehouseId) => {
              const warehouseKey = `warehouse${warehouseId}`;

              Object.keys(transformedData).forEach((productName) => {
                warehouseIds.forEach((warehouseId) => {
                  const warehouseKey = `warehouse${warehouseId}`;

                  if (
                    !Object.prototype.hasOwnProperty.call(
                      transformedData[productName],
                      warehouseKey
                    )
                  ) {
                    transformedData[productName][warehouseKey] = 0;
                  }
                });
              });
            });
          });

          const transformedDataArray = Object.keys(transformedData).map(
            (productName) => {
              const productData = transformedData[productName];
              let totalQuantity = 0;

              Object.values(productData).forEach((quantity) => {
                if (typeof quantity === "number") {
                  totalQuantity += quantity;
                } else {
                  console.error(
                    "Expected quantity to be a number, but got:",
                    quantity
                  );
                }
              });

              return {
                name: productName,
                totalQuantity,
                ...productData,
              };
            }
          );

          //console.log('Transformed data:', transformedDataArray);

          columns.value = generateColumns(
            Array.from(warehouseIds),
            warehouseIdToNameMap
          );

          reactiveData.data = transformedDataArray;
        })
        .catch((error) => {
          console.error("Error fetching stock items:", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: "Delete Payment Method",
        text: "Are you sure you want to delte this payment method",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: "Delete",
        cancelButtonColor: "#d33",
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deletePaymentMethod(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //  console.log("cancel delete ");
        }
      });
    };

    onMounted(() => {
      getAll("");
      //   console.log("mrigla")
    });

    return {
      locale,
      textDirection,
      quantityByWarehouseId,
      handleChange,
      customers,
      selectKey,
      isSelectOpen,
      startedTyping,
      handleInput,
      exportSalesForm,
      formatToStringDate,
      handleTransferOk,
      reactiveData2,
      close,
      createInvoice,
      hideForm,
      //formatcurrency_code,
      formatTotal,
      handleReset,
      handleSearch,
      loading,
      rules,
      handlePageChange,
      currentPage,
      perPage,
      formatcustomer,
      navigatePage,
      t,
      columns,
      formRef,
      form,
      Days,
      formatDisplayDate,
      reactiveData,
      showAddDrawer,
      showDrawer,
      open,
      formatDate,
      updateStore,
      addStore,
      showDeletePopup,
      visible,
      addPaymentMethod,
      updatePaymentMethod,
      handleCheckboxChange,
      isFieldDisabled: true,
      onSubmitInvoice,
      afterOpenChange,
      closeOpens,
      exportReport,
      openTransferModal,
      formattedStartDate,
    };
  },
});
</script>
    
    <style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.report-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}
/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-btn {
  color: #bfffca8a;
}
.breadcrumb-icon {
  cursor: pointer;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.table-container {
  overflow-x: auto;
}

.range-picker {
  width: 100%;
}

.is-rtl {
  direction: rtl;
}
</style>
    