import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle, renderList as _renderList, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/nophoto.png'


const _withScopeId = n => (_pushScopeId("data-v-0a56c7ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = {
  key: 0,
  class: "btn-actions"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"padding":"8px"} }
const _hoisted_5 = { class: "pagination" }
const _hoisted_6 = { class: "steps-content" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = { class: "label-form" }
const _hoisted_17 = { class: "label-form" }
const _hoisted_18 = { class: "label-form" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "label-form" }
const _hoisted_21 = { class: "label-form" }
const _hoisted_22 = { class: "label-form" }
const _hoisted_23 = { class: "label-form" }
const _hoisted_24 = { class: "ant-upload-drag-icon" }
const _hoisted_25 = { class: "ant-upload-text" }
const _hoisted_26 = { class: "ant-upload-hint" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "photo-title" }
const _hoisted_29 = { class: "switch-container" }
const _hoisted_30 = { class: "switch-container" }
const _hoisted_31 = { class: "switch-container" }
const _hoisted_32 = { class: "label-form" }
const _hoisted_33 = { class: "label-form" }
const _hoisted_34 = { class: "check-btn" }
const _hoisted_35 = { class: "label-form" }
const _hoisted_36 = { class: "steps-action" }
const _hoisted_37 = { class: "custom-header" }
const _hoisted_38 = {
  key: 1,
  width: 100,
  src: _imports_0
}
const _hoisted_39 = { class: "info-title" }
const _hoisted_40 = { class: "info-title" }
const _hoisted_41 = { class: "info-title" }
const _hoisted_42 = { class: "info-title" }
const _hoisted_43 = { class: "info-title" }
const _hoisted_44 = { class: "info-title" }
const _hoisted_45 = { class: "info-title" }
const _hoisted_46 = { class: "info-title" }
const _hoisted_47 = { key: 0 }
const _hoisted_48 = { key: 1 }
const _hoisted_49 = { key: 2 }
const _hoisted_50 = { key: 3 }
const _hoisted_51 = { class: "info-title" }
const _hoisted_52 = { class: "info-title" }
const _hoisted_53 = { class: "info-title" }
const _hoisted_54 = { class: "info-title" }
const _hoisted_55 = { class: "info-title" }
const _hoisted_56 = { key: 0 }
const _hoisted_57 = { key: 1 }
const _hoisted_58 = { class: "info-title" }
const _hoisted_59 = { key: 0 }
const _hoisted_60 = { key: 1 }
const _hoisted_61 = { class: "custom-header" }
const _hoisted_62 = { key: 0 }
const _hoisted_63 = { key: 1 }
const _hoisted_64 = { class: "custom-header" }
const _hoisted_65 = { class: "custom-header" }
const _hoisted_66 = { class: "label-form" }
const _hoisted_67 = { class: "label-form" }
const _hoisted_68 = { class: "label-form" }
const _hoisted_69 = { class: "label-form" }
const _hoisted_70 = { class: "label-form" }
const _hoisted_71 = { class: "label-form" }
const _hoisted_72 = { class: "steps-content" }
const _hoisted_73 = { key: 0 }
const _hoisted_74 = { class: "label-form" }
const _hoisted_75 = { class: "label-form" }
const _hoisted_76 = { class: "label-form" }
const _hoisted_77 = { class: "label-form" }
const _hoisted_78 = { class: "label-form" }
const _hoisted_79 = { class: "label-form" }
const _hoisted_80 = { class: "label-form" }
const _hoisted_81 = { class: "label-form" }
const _hoisted_82 = { class: "label-form" }
const _hoisted_83 = { class: "label-form" }
const _hoisted_84 = { class: "label-form" }
const _hoisted_85 = { key: 1 }
const _hoisted_86 = { class: "label-form" }
const _hoisted_87 = { class: "label-form" }
const _hoisted_88 = { class: "label-form" }
const _hoisted_89 = { class: "label-form" }
const _hoisted_90 = { class: "ant-upload-drag-icon" }
const _hoisted_91 = { class: "ant-upload-text" }
const _hoisted_92 = { class: "ant-upload-hint" }
const _hoisted_93 = { class: "label-form" }
const _hoisted_94 = { class: "label-form" }
const _hoisted_95 = { class: "label-form" }
const _hoisted_96 = { class: "label-form" }
const _hoisted_97 = { class: "label-form" }
const _hoisted_98 = { class: "label-form" }
const _hoisted_99 = { class: "label-form" }
const _hoisted_100 = { class: "switch-container" }
const _hoisted_101 = { class: "switch-container" }
const _hoisted_102 = { class: "switch-container" }
const _hoisted_103 = { key: 1 }
const _hoisted_104 = { class: "label-form" }
const _hoisted_105 = { class: "check-btn" }
const _hoisted_106 = { class: "label-form" }
const _hoisted_107 = { class: "steps-action" }
const _hoisted_108 = { class: "steps-content" }
const _hoisted_109 = { key: 0 }
const _hoisted_110 = { class: "label-form" }
const _hoisted_111 = { class: "label-form" }
const _hoisted_112 = { class: "label-form" }
const _hoisted_113 = { class: "label-form" }
const _hoisted_114 = { class: "label-form" }
const _hoisted_115 = { class: "label-form" }
const _hoisted_116 = { class: "label-form" }
const _hoisted_117 = { class: "label-form" }
const _hoisted_118 = { class: "label-form" }
const _hoisted_119 = { key: 1 }
const _hoisted_120 = { class: "label-form" }
const _hoisted_121 = { class: "label-form" }
const _hoisted_122 = { class: "ant-upload-drag-icon" }
const _hoisted_123 = { class: "ant-upload-text" }
const _hoisted_124 = { class: "ant-upload-hint" }
const _hoisted_125 = { class: "label-form" }
const _hoisted_126 = { class: "label-form" }
const _hoisted_127 = { class: "check-btn" }
const _hoisted_128 = { class: "label-form" }
const _hoisted_129 = { class: "steps-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_InboxOutlined = _resolveComponent("InboxOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_HeartOutlined = _resolveComponent("HeartOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_upload_dragger = _resolveComponent("a-upload-dragger")!
  const _component_PaperClipOutlined = _resolveComponent("PaperClipOutlined")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_CaretRightOutlined = _resolveComponent("CaretRightOutlined")!
  const _component_check_circle_two_tone = _resolveComponent("check-circle-two-tone")!
  const _component_close_circle_two_tone = _resolveComponent("close-circle-two-tone")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_MinusCircleOutlined = _resolveComponent("MinusCircleOutlined")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
    }, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Product')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_row, { gutter: 16 }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            xs: { span: 24},
            sm: { span: 12},
            md: {span: 12},
            lg: { span: 12 },
            xl: { span: 12 },
            xxl: { span: 12 },
            class: "action-btn-container"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                class: "add-btn",
                onClick: _ctx.showAddDrawer
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_InboxOutlined)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')) + " ", 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.state.data,
        pagination: false
      }, {
        emptyText: _withCtx(() => [
          _createVNode(_component_a_empty, {
            description: _ctx.t('Pos.NoData'),
            image: _ctx.simpleImage
          }, null, 8, ["description", "image"])
        ]),
        bodyCell: _withCtx(({ column, record }) => [
          (column.key === 'actions')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_a_space, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          class: "view-btn",
                          onClick: () => _ctx.showDisplayDrawer(record)
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_EyeOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: () => _ctx.showDrawer(record)
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_EditOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          danger: "",
                          onClick: ($event: any) => (_ctx.handleDelete(record))
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_DeleteOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.AddVariant')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          class: "variant-btn",
                          onClick: ($event: any) => (_ctx.addVariant(record))
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_PlusOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Preferences')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          class: "pref-btn",
                          onClick: ($event: any) => (_ctx.showPrefModal(record))
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_HeartOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]))
            : _createCommentVNode("", true),
          (column.key === 'img')
            ? (_openBlock(), _createBlock(_component_a_row, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { flex: "50px" }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "img-table",
                        src: record.photo
                      }, null, 8, _hoisted_3)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key === 'unit_price')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(_ctx.formatPrice(record.unit_price)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key === 'purchase_price')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(_toDisplayString(_ctx.formatPrice(record.purchase_price)), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `${column.title}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
          pageSize: _ctx.perPage,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
          total: _ctx.state.totalPages,
          "default-page-size": _ctx.perPage,
          onChange: _ctx.handlePageChange,
          showSizeChanger: false,
          direction: "ltr"
        }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
        (_ctx.state.totalPages>10)
          ? (_openBlock(), _createBlock(_component_a_select, {
              key: 0,
              class: "select-input",
              value: _ctx.selectPagination,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
              onChange: _ctx.handleSelectPaginationChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "10" }, {
                  default: _withCtx(() => [
                    _createTextVNode("10 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "20" }, {
                  default: _withCtx(() => [
                    _createTextVNode("20 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "50" }, {
                  default: _withCtx(() => [
                    _createTextVNode("50 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "100" }, {
                  default: _withCtx(() => [
                    _createTextVNode("100 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        width: _ctx.modalWidth,
        placement: _ctx.locale === 'ar' ? 'left' : 'right'
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.UpdateProduct')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_steps, {
            current: _ctx.currentUpdate,
            items: _ctx.itemsUpdate
          }, null, 8, ["current", "items"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_a_form, {
              ref: "formRef",
              model: _ctx.form,
              rules: _ctx.rules,
              layout: "vertical",
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                (_ctx.stepsUpdate[_ctx.currentUpdate].content==='first-content')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "name" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Name')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.form.name,
                                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.name) = $event)),
                                    placeholder: _ctx.t('Pos.Name'),
                                    class: "input-form"
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "category_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.Category')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.category_id,
                                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.category_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.categories, (category) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          key: category.id,
                                          value: category.id,
                                          selected: category.id === _ctx.form.category_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(category.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "manufacturer_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.Manufacturer')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.manufacturer_id,
                                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.manufacturer_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.manufacturers, (item) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          key: item.id,
                                          value: item.id,
                                          label: item.name,
                                          selected: item.id === _ctx.form.manufacturer_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "label", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "supplier_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.Supplier')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.supplier_id,
                                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.supplier_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.suppliers, (supplier) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          key: supplier.id,
                                          value: supplier.id,
                                          label: supplier.company_name,
                                          selected: supplier.id === _ctx.form.supplier_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(supplier.company_name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "label", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "vat_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.Vat')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.vat_id,
                                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.vat_id) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.vats, (vat) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          key: vat.id,
                                          value: vat.id,
                                          selected: vat.id === _ctx.form.vat_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(vat.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "price" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.Price')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.form.price,
                                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.price) = $event)),
                                    placeholder: _ctx.t('Pos.Price')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "purchase_price" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Pos.PurchasePrice')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.form.purchase_price,
                                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.purchase_price) = $event)),
                                    placeholder: _ctx.t('Pos.PurchasePrice')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "unit" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Pos.Unit')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.unit,
                                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.unit) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select_option, { value: "mg" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_select_option, { value: "pcs" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_select_option, { value: "ml" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_select_option, { value: "mm" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "display_rank" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Pos.DisplayRank')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.form.display_rank,
                                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.display_rank) = $event)),
                                    placeholder: _ctx.t('Pos.DisplayRank')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "code_ean" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Pos.Code')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.form.code_ean,
                                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.code_ean) = $event)),
                                    placeholder: _ctx.t('Pos.Code')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "barcode" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.BarCode')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.form.barcode,
                                    "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.barcode) = $event)),
                                    placeholder: _ctx.t('Pos.BarCode')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.stepsUpdate[_ctx.currentUpdate].content==='second-content')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "production_point_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Pos.ProductionPoint')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.production_point_name,
                                    "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.production_point_name) = $event)),
                                    "show-search": "",
                                    onChange: _ctx.handleProductionPoint,
                                    "option-filter-prop": 'label',
                                    allowClear: ""
                                  }, {
                                    notFoundContent: _withCtx(() => [
                                      _createVNode(_component_a_empty, {
                                        description: _ctx.t('Pos.NoData'),
                                        image: _ctx.simpleImage
                                      }, null, 8, ["description", "image"])
                                    ]),
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.productionPoints, (item) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          key: item.id,
                                          value: item.id,
                                          selected: item.id === _ctx.form.production_point_id ,
                                          label: item.name
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "selected", "label"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value", "onChange"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "fixed_tax" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Pos.FixedTax')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.form.fixed_tax,
                                    "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.fixed_tax) = $event)),
                                    placeholder: _ctx.t('Pos.FixedTax'),
                                    class: "input-form"
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "description" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('Pos.Description')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_textarea, {
                                    value: _ctx.form.description,
                                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.description) = $event)),
                                    rows: 4,
                                    placeholder: _ctx.t('Pos.Description')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, null, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Pos.Photo')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_upload_dragger, {
                                    fileList: _ctx.fileList,
                                    "onUpdate:fileList": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.fileList) = $event)),
                                    name: "file",
                                    multiple: false,
                                    "max-count": 1,
                                    onChange: _ctx.handleUpdateChangeFile,
                                    onDrop: _ctx.handleDrop,
                                    "before-upload": _ctx.beforeUpload
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_24, [
                                        _createVNode(_component_InboxOutlined)
                                      ]),
                                      _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.t('Pos.dropTitle1')), 1),
                                      _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.t('Pos.dropTitle2')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["fileList", "onChange", "onDrop", "before-upload"]),
                                  (_ctx.form.photo!==null && !_ctx.state.fileChanged)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                        _createElementVNode("p", {
                                          class: "photo-info",
                                          onClick: _cache[19] || (_cache[19] = () => _ctx.setVisible(true))
                                        }, [
                                          _createVNode(_component_PaperClipOutlined),
                                          _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.extractFilename(_ctx.form.photo)), 1),
                                          _createTextVNode(),
                                          _createVNode(_component_DeleteOutlined, { onClick: _ctx.deletePhoto }, null, 8, ["onClick"])
                                        ]),
                                        _createVNode(_component_a_image, {
                                          width: 200,
                                          style: { display: 'none' },
                                          preview: {
                          visible: _ctx.visible,
                          onVisibleChange: _ctx.setVisible,
                        },
                                          src: _ctx.form.photo
                                        }, null, 8, ["preview", "src"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.refundable')), 1),
                                _createVNode(_component_a_switch, {
                                  checked: _ctx.form.refundable,
                                  "onUpdate:checked": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.refundable) = $event))
                                }, null, 8, ["checked"])
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.stockable')), 1),
                                _createVNode(_component_a_switch, {
                                  checked: _ctx.form.stockable,
                                  "onUpdate:checked": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.form.stockable) = $event))
                                }, null, 8, ["checked"])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_31, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.HasIngredients')), 1),
                                _createVNode(_component_a_switch, {
                                  checked: _ctx.form.has_ingredients,
                                  "onUpdate:checked": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.has_ingredients) = $event))
                                }, null, 8, ["checked"])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.form.has_ingredients===true)
                        ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "ingredients_id" }, {
                                    label: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t('Pos.Ingredients')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select, {
                                        value: _ctx.form.ingredients_id,
                                        "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.form.ingredients_id) = $event)),
                                        mode: "multiple",
                                        style: {"width":"100%"},
                                        options: _ctx.optionsUpdate,
                                        onChange: _ctx.handleUpdateIngredient,
                                        "show-search": "",
                                        "option-filter-prop": 'label'
                                      }, {
                                        notFoundContent: _withCtx(() => [
                                          _createVNode(_component_a_empty, {
                                            description: _ctx.t('Pos.NoData'),
                                            image: _ctx.simpleImage
                                          }, null, 8, ["description", "image"])
                                        ]),
                                        _: 1
                                      }, 8, ["value", "options", "onChange"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.form.has_ingredients===true)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.state.ingrArray, (item) => {
                            return (_openBlock(), _createBlock(_component_a_row, {
                              key: item,
                              gutter: 16,
                              class: "ingredients-container"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, {
                                  xs: { span: 24},
                                  sm: { span: 24},
                                  md: {span: 24},
                                  lg: { span: 24 },
                                  xl: { span: 24 },
                                  xxl: { span: 24 },
                                  class: "ingr-name-container"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_33, _toDisplayString(item.ingredient_name), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_col, {
                                  xs: { span: 24},
                                  sm: { span: 24},
                                  md: {span: 6},
                                  lg: { span: 6 },
                                  xl: { span: 6 },
                                  xxl: { span: 6 },
                                  class: "ingr-content-container"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, {
                                      "has-feedback": "",
                                      name: 'qtUpdate_' + item.ingredient_id,
                                      "validate-status": _ctx.getValidatingStatus(item.ingredient_id,'qtUpdate'),
                                      help: _ctx.getValidatingHelp(item.ingredient_id,'qtUpdate')
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input, {
                                          id: 'qtUpdate_' + item.ingredient_id,
                                          placeholder: _ctx.t('Pos.Quantity'),
                                          class: "input-form",
                                          onInput: ($event: any) => (_ctx.clearError(item.ingredient_id, 'qtUpdate')),
                                          value: item.quantity_used,
                                          "onUpdate:value": ($event: any) => ((item.quantity_used) = $event)
                                        }, null, 8, ["id", "placeholder", "onInput", "value", "onUpdate:value"])
                                      ]),
                                      _: 2
                                    }, 1032, ["name", "validate-status", "help"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_col, {
                                  xs: { span: 24},
                                  sm: { span: 24},
                                  md: {span: 6},
                                  lg: { span: 6 },
                                  xl: { span: 6 },
                                  xxl: { span: 6 },
                                  class: "ingr-content-container"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_34, [
                                      _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.t('Pos.IncludedbyDefault')), 1),
                                      _createVNode(_component_a_switch, {
                                        id: 'defaultUpdate_' + item.ingredient_id,
                                        checked: item.included_by_default,
                                        "onUpdate:checked": ($event: any) => ((item.included_by_default) = $event),
                                        class: "swicth-btn"
                                      }, null, 8, ["id", "checked", "onUpdate:checked"])
                                    ])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_col, {
                                  xs: { span: 24},
                                  sm: { span: 24},
                                  md: {span: 6},
                                  lg: { span: 6 },
                                  xl: { span: 6 },
                                  xxl: { span: 6 },
                                  class: "ingr-content-container"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, {
                                      "has-feedback": "",
                                      "validate-status": _ctx.getValidatingStatus(item.ingredient_id,'costUpdate'),
                                      help: _ctx.getValidatingHelp(item.ingredient_id,'costUpdate')
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input, {
                                          value: item.additional_cost,
                                          "onUpdate:value": ($event: any) => ((item.additional_cost) = $event),
                                          id: 'costUpdate_' + item.ingredient_id,
                                          placeholder: _ctx.t('Pos.AdditionalCost'),
                                          class: "input-form",
                                          onInput: ($event: any) => (_ctx.clearError(item.ingredient_id, 'costUpdate'))
                                        }, null, 8, ["value", "onUpdate:value", "id", "placeholder", "onInput"])
                                      ]),
                                      _: 2
                                    }, 1032, ["validate-status", "help"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_col, {
                                  xs: { span: 24},
                                  sm: { span: 24},
                                  md: {span: 6},
                                  lg: { span: 6 },
                                  xl: { span: 6 },
                                  xxl: { span: 6 },
                                  class: "ingr-content-container"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, {
                                      "validate-status": _ctx.getValidatingStatus(item.ingredient_id,'unit'),
                                      help: _ctx.getValidatingHelp(item.ingredient_id,'unit')
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select, {
                                          id: 'unit_' + item.ingredient_id,
                                          placeholder: _ctx.t('Pos.Unit'),
                                          value: item.unit,
                                          "onUpdate:value": ($event: any) => ((item.unit) = $event),
                                          onChange: ($event: any) => (_ctx.handleUnitChange($event,item.ingredient_id))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_a_select_option, { value: "mg" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_a_select_option, { value: "pcs" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_a_select_option, { value: "ml" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_a_select_option, { value: "mm" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["id", "placeholder", "value", "onUpdate:value", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1032, ["validate-status", "help"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["model", "rules", "class"])
          ]),
          _createElementVNode("div", _hoisted_36, [
            (_ctx.currentUpdate < _ctx.stepsUpdate.length - 1)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  onClick: _ctx.nextUpdate,
                  class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Next')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "class"]))
              : _createCommentVNode("", true),
            (_ctx.currentUpdate == _ctx.stepsUpdate.length - 1)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  type: "primary",
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.onSubmit(_ctx.form.id))),
                  class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                  ]),
                  _: 1
                }, 8, ["class"]))
              : _createCommentVNode("", true),
            (_ctx.currentUpdate > 0)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 2,
                  style: {"margin-left":"8px"},
                  onClick: _ctx.prevUpdate,
                  class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Previous')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "class"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["open", "width", "placement"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        width: _ctx.modalWidth,
        placement: _ctx.locale === 'ar' ? 'left' : 'right'
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.ProductInfo')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_collapse, {
                activeKey: _ctx.activeKey,
                "onUpdate:activeKey": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.activeKey) = $event)),
                bordered: false,
                class: "collapse"
              }, {
                expandIcon: _withCtx(({ isActive }) => [
                  _createVNode(_component_CaretRightOutlined, {
                    rotate: isActive ? 90 : 0,
                    class: "caret-icon"
                  }, null, 8, ["rotate"])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_collapse_panel, {
                    key: "1",
                    style: _normalizeStyle(_ctx.customStyle)
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.t('Pos.GeneralInfo')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, {
                        class: "photo-container",
                        gutter: 16
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { flex: "150px" }, {
                            default: _withCtx(() => [
                              (_ctx.form.photo!==null)
                                ? (_openBlock(), _createBlock(_component_a_image, {
                                    key: 0,
                                    width: 100,
                                    height: 100,
                                    src: _ctx.form.photo
                                  }, null, 8, ["src"]))
                                : _createCommentVNode("", true),
                              (_ctx.form.photo===null)
                                ? (_openBlock(), _createElementBlock("img", _hoisted_38))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { flex: "auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_row, { class: "info info-title" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.form.name), 1)
                                ]),
                                _: 1
                              }),
                              (_ctx.form.description==='null')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
                                : (_openBlock(), _createBlock(_component_a_row, {
                                    key: 1,
                                    class: "info-txt"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.form.description), 1)
                                    ]),
                                    _: 1
                                  }))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t('Pos.Category')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.form.category_name), 1)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.t('Pos.Manufacturer')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.transformUpperCase(_ctx.form.manufacturer_name)), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.t('Pos.Supplier')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.transformUpperCase(_ctx.form.supplier_name)), 1)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.t('Pos.Vat')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.getVATValue(_ctx.form.vat_id)) + " %", 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.t('Pos.Price')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.form.price), 1)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.t('Pos.PurchasePrice')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.form.purchase_price), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.t('Pos.DisplayRank')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.form.display_rank), 1)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.t('Pos.Unit')) + " :", 1),
                                (_ctx.form.unit==='mg')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(_ctx.t('Pos.Milligrams')), 1))
                                  : _createCommentVNode("", true),
                                (_ctx.form.unit==='pcs')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_48, _toDisplayString(_ctx.t('Pos.Pieces')), 1))
                                  : _createCommentVNode("", true),
                                (_ctx.form.unit==='ml')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_49, _toDisplayString(_ctx.t('Pos.Milliliter')), 1))
                                  : _createCommentVNode("", true),
                                (_ctx.form.unit==='mm')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_50, _toDisplayString(_ctx.t('Pos.Millimeter')), 1))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.t('Pos.Code')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.form.code_ean), 1)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.t('Pos.BarCode')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.form.barcode), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.t('Pos.ProductionPoint')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.form.production_point_name), 1)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.t('Pos.FixedTax')) + " :", 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.form.fixed_tax), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.t('Pos.refundable')) + " :", 1),
                                (_ctx.form.refundable===1 || _ctx.form.refundable===true)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_56, [
                                      _createVNode(_component_check_circle_two_tone, { "two-tone-color": "#52c41a" })
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.form.refundable===0 || _ctx.form.refundable===false)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_57, [
                                      _createVNode(_component_close_circle_two_tone, { "two-tone-color": "#ff4d4f" })
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.t('Pos.stockable')) + " :", 1),
                                (_ctx.form.stockable===1 || _ctx.form.stockable===true)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_59, [
                                      _createVNode(_component_check_circle_two_tone, { "two-tone-color": "#52c41a" })
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.form.stockable===0 || _ctx.form.stockable===false)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_60, [
                                      _createVNode(_component_close_circle_two_tone, { "two-tone-color": "#ff4d4f" })
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["style"]),
                  (_ctx.form.has_ingr===1)
                    ? (_openBlock(), _createBlock(_component_a_collapse_panel, {
                        key: "2",
                        style: _normalizeStyle(_ctx.customStyle)
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.t('Pos.Ingredients')), 1)
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.ingredients, (ingredients, category) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: category }, [
                              _createElementVNode("h3", null, _toDisplayString(category), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ingredients, (ingredient, index) => {
                                return (_openBlock(), _createBlock(_component_a_descriptions, {
                                  key: index,
                                  bordered: "",
                                  size: "small",
                                  class: "ingredient-des"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_descriptions_item, {
                                      label: _ctx.t('Pos.Name'),
                                      span: 3
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(ingredient.ingredient.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["label"]),
                                    _createVNode(_component_a_descriptions_item, {
                                      label: _ctx.t('Pos.Cost'),
                                      span: 2
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(ingredient.additional_cost), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["label"]),
                                    _createVNode(_component_a_descriptions_item, {
                                      label: _ctx.t('Pos.Unit')
                                    }, {
                                      default: _withCtx(() => [
                                        (ingredient.unit === 'mg')
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                            ], 64))
                                          : _createCommentVNode("", true),
                                        (ingredient.unit === 'pcs')
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                            ], 64))
                                          : _createCommentVNode("", true),
                                        (ingredient.unit === 'ml')
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                              _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                            ], 64))
                                          : _createCommentVNode("", true),
                                        (ingredient.unit === 'mm')
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                              _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                            ], 64))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1032, ["label"]),
                                    _createVNode(_component_a_descriptions_item, {
                                      label: _ctx.t('Pos.QuantityUsed'),
                                      span: 2
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(ingredient.quantity_used), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["label"]),
                                    _createVNode(_component_a_descriptions_item, {
                                      label: _ctx.t('Pos.IncludedbyDefault')
                                    }, {
                                      default: _withCtx(() => [
                                        (ingredient.included_by_default===1 || ingredient.included_by_default===true)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_62, [
                                              _createVNode(_component_check_circle_two_tone, { "two-tone-color": "#52c41a" })
                                            ]))
                                          : _createCommentVNode("", true),
                                        (ingredient.included_by_default===0 || ingredient.included_by_default===false)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_63, [
                                              _createVNode(_component_close_circle_two_tone, { "two-tone-color": "#ff4d4f" })
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1032, ["label"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["style"]))
                    : _createCommentVNode("", true),
                  (_ctx.form.customData!==null)
                    ? (_openBlock(), _createBlock(_component_a_collapse_panel, {
                        key: "3",
                        style: _normalizeStyle(_ctx.customStyle)
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.t('Pos.AdditionalInfo')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_descriptions, {
                            bordered: "",
                            size: "small",
                            class: "ingredient-des"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.customFields, (item) => {
                                return (_openBlock(), _createBlock(_component_a_descriptions_item, {
                                  label: `t('Pos.Name')${item.name}`,
                                  span: 3,
                                  key: item.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["style"]))
                    : _createCommentVNode("", true),
                  (_ctx.varinats_array.length!==0)
                    ? (_openBlock(), _createBlock(_component_a_collapse_panel, {
                        key: "4",
                        style: _normalizeStyle(_ctx.customStyle)
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.t('Pos.Variants')), 1)
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.varinats_array, (item) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
                              _createElementVNode("h3", null, _toDisplayString(item.attributeName), 1),
                              _createVNode(_component_a_descriptions, {
                                bordered: "",
                                size: "small",
                                class: "ingredient-des"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_descriptions_item, {
                                    label: _ctx.t('Pos.Name'),
                                    span: 3
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["label"]),
                                  _createVNode(_component_a_descriptions_item, {
                                    label: _ctx.t('Pos.Quantity')
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.qte), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["label"])
                                ]),
                                _: 2
                              }, 1024)
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["style"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["activeKey"])
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "width", "placement"]),
      _createVNode(_component_a_modal, {
        open: _ctx.openVariant,
        "onUpdate:open": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.openVariant) = $event)),
        onCancel: _ctx.resetVarinatForm
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                key: "submit",
                type: "primary",
                onClick: _ctx.handleVariantOk,
                class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.AddVariant')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "class"]),
              _createVNode(_component_a_button, {
                key: "back",
                onClick: _ctx.handleCancelVariant,
                class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "class"])
            ]),
            _: 1
          })
        ]),
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AddVariant')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRefVarinat",
            model: _ctx.formVarinat,
            rules: _ctx.variantRules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_66, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.formVarinat.name,
                            "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.formVarinat.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "quantity" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.t('Pos.Quantity')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.formVarinat.quantity,
                            "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.formVarinat.quantity) = $event)),
                            placeholder: _ctx.t('Pos.Quantity'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "code" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_68, _toDisplayString(_ctx.t('Pos.Code')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.formVarinat.code,
                            "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.formVarinat.code) = $event)),
                            placeholder: _ctx.t('Pos.Code'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "code_ean" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_69, _toDisplayString(_ctx.t('Pos.CodeEan')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.formVarinat.code_ean,
                            "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.formVarinat.code_ean) = $event)),
                            placeholder: _ctx.t('Pos.CodeEan'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "value" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_70, _toDisplayString(_ctx.t('Pos.Options')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.value,
                            "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.value) = $event)),
                            mode: "multiple",
                            style: {"width":"100%"},
                            onChange: _ctx.handleChange,
                            "show-search": "",
                            "option-filter-prop": 'label'
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.attributes, (attribute) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: attribute.id,
                                  value: attribute.id,
                                  label: attribute.name
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(attribute.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "onChange"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedItems, (item) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  key: item,
                  span: 24
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_a_form_item, null, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_71, _toDisplayString(_ctx.t('Pos.ValuesOf')) + " " + _toDisplayString(item.attributeName), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            mode: "multiple",
                            onChange: _ctx.handleChangeSelectChange,
                            "show-search": ""
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.values, (value) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: value.id,
                                  value: value.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(value.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "onCancel"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.addProd,
        "onUpdate:open": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.addProd) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.resetAdd
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AddProduct')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_steps, {
            current: _ctx.current,
            items: _ctx.items
          }, null, 8, ["current", "items"]),
          _createElementVNode("div", _hoisted_72, [
            _createVNode(_component_a_form, {
              ref: "addFormRef",
              model: _ctx.addForm,
              rules: _ctx.rules,
              layout: "vertical",
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                (_ctx.steps[_ctx.current].content==='first-content')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "name" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_74, _toDisplayString(_ctx.t('Pos.Name')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.addForm.name,
                                    "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.addForm.name) = $event)),
                                    placeholder: _ctx.t('Pos.Name'),
                                    class: "input-form"
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "category_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_75, _toDisplayString(_ctx.t('Pos.Category')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.addForm.category_id,
                                    "onUpdate:value": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.addForm.category_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.categories, (category) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          label: category.name,
                                          key: category.id,
                                          value: category.id,
                                          selected: category.id === _ctx.form.category_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(category.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["label", "value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "manufacturer_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_76, _toDisplayString(_ctx.t('Pos.Manufacturer')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.addForm.manufacturer_id,
                                    "onUpdate:value": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.addForm.manufacturer_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.manufacturers, (item) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          label: item.name,
                                          key: item.id,
                                          value: item.id,
                                          selected: item.id === _ctx.form.manufacturer_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["label", "value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "supplier_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_77, _toDisplayString(_ctx.t('Pos.Supplier')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.addForm.supplier_id,
                                    "onUpdate:value": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.addForm.supplier_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.suppliers, (supplier) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          label: supplier.company_name,
                                          key: supplier.id,
                                          value: supplier.id,
                                          selected: supplier.id === _ctx.form.supplier_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(supplier.company_name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["label", "value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "vat_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_78, _toDisplayString(_ctx.t('Pos.Vat')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.addForm.vat_id,
                                    "onUpdate:value": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.addForm.vat_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.vats, (vat) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          label: vat.name,
                                          key: vat.id,
                                          value: vat.id,
                                          selected: vat.id === _ctx.addForm.vat_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(vat.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["label", "value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "price" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_79, _toDisplayString(_ctx.t('Pos.Price')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.addForm.price,
                                    "onUpdate:value": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.addForm.price) = $event)),
                                    placeholder: _ctx.t('Pos.Price')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "purchase_price" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_80, _toDisplayString(_ctx.t('Pos.PurchasePrice')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.addForm.purchase_price,
                                    "onUpdate:value": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.addForm.purchase_price) = $event)),
                                    placeholder: _ctx.t('Pos.PurchasePrice')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "unit" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_81, _toDisplayString(_ctx.t('Pos.Unit')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.addForm.unit,
                                    "onUpdate:value": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.addForm.unit) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select_option, {
                                        value: "mg",
                                        label: _ctx.t('Pos.Milligrams')
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["label"]),
                                      _createVNode(_component_a_select_option, {
                                        value: "pcs",
                                        label: _ctx.t('Pos.Pieces')
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["label"]),
                                      _createVNode(_component_a_select_option, {
                                        value: "ml",
                                        label: _ctx.t('Pos.Milliliter')
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["label"]),
                                      _createVNode(_component_a_select_option, {
                                        value: "mm",
                                        label: _ctx.t('Pos.Millimeter')
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["label"])
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "display_rank" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_82, _toDisplayString(_ctx.t('Pos.DisplayRank')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.addForm.display_rank,
                                    "onUpdate:value": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.addForm.display_rank) = $event)),
                                    placeholder: _ctx.t('Pos.DisplayRank')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "code_ean" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.t('Pos.Code')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.addForm.code_ean,
                                    "onUpdate:value": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.addForm.code_ean) = $event)),
                                    placeholder: _ctx.t('Pos.Code')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "barcode" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_84, _toDisplayString(_ctx.t('Pos.BarCode')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.addForm.barcode,
                                    "onUpdate:value": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.addForm.barcode) = $event)),
                                    placeholder: _ctx.t('Pos.BarCode')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.steps[_ctx.current].content==='second-content')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_85, [
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "production_point_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_86, _toDisplayString(_ctx.t('Pos.ProductionPoint')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.addForm.production_point_id,
                                    "onUpdate:value": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.addForm.production_point_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label',
                                    allowClear: ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.productionPoints, (item) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          key: item.id,
                                          value: item.id,
                                          label: item.name,
                                          selected: item.id === _ctx.addForm.production_point_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "label", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "fixed_tax" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_87, _toDisplayString(_ctx.t('Pos.FixedTax')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.addForm.fixed_tax,
                                    "onUpdate:value": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.addForm.fixed_tax) = $event)),
                                    placeholder: _ctx.t('Pos.FixedTax'),
                                    class: "input-form"
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "description" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_88, _toDisplayString(_ctx.t('Pos.Description')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_textarea, {
                                    value: _ctx.addForm.description,
                                    "onUpdate:value": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.addForm.description) = $event)),
                                    rows: 4,
                                    placeholder: _ctx.t('Pos.Description')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, null, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_89, _toDisplayString(_ctx.t('Pos.Photo')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_upload_dragger, {
                                    fileList: _ctx.fileList,
                                    "onUpdate:fileList": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.fileList) = $event)),
                                    name: "file",
                                    multiple: false,
                                    "max-count": 1,
                                    onChange: _ctx.handleChangeFile,
                                    onDrop: _ctx.handleDrop,
                                    "before-upload": _ctx.beforeUpload
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_90, [
                                        _createVNode(_component_InboxOutlined)
                                      ]),
                                      _createElementVNode("p", _hoisted_91, _toDisplayString(_ctx.t('Pos.dropTitle1')), 1),
                                      _createElementVNode("p", _hoisted_92, _toDisplayString(_ctx.t('Pos.dropTitle2')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["fileList", "onChange", "onDrop", "before-upload"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.state.customFields !== null)
                        ? (_openBlock(), _createBlock(_component_a_row, {
                            key: 0,
                            gutter: 16
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.customFields, (item) => {
                                return (_openBlock(), _createBlock(_component_a_col, {
                                  key: item,
                                  span: 12
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        (item.type==='text')
                                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                                              key: 0,
                                              name: 'customData_' + item.id,
                                              rules: [item.is_mandatory === 1 ? { required: true, message:  _ctx.t('Pos.RequiredField') } : {}],
                                              class: "dynamic-input"
                                            }, {
                                              label: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_93, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_input, {
                                                  id: 'txt_' + item.id
                                                }, null, 8, ["id"])
                                              ]),
                                              _: 2
                                            }, 1032, ["name", "rules"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        (item.type==='textarea')
                                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                                              key: 0,
                                              name: "customData",
                                              rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}],
                                              class: "dynamic-input"
                                            }, {
                                              label: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_94, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_textarea, {
                                                  id: 'area_' + item.id,
                                                  rows: 4
                                                }, null, 8, ["id"])
                                              ]),
                                              _: 2
                                            }, 1032, ["rules"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        (item.type==='number')
                                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                                              key: 0,
                                              name: "customData",
                                              rules: [item.is_mandatory === 1 ? { required: true, message:  _ctx.t('Pos.RequiredField') } : {}],
                                              class: "dynamic-input"
                                            }, {
                                              label: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_95, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_input_number, {
                                                  id: 'number_' + item.id
                                                }, null, 8, ["id"])
                                              ]),
                                              _: 2
                                            }, 1032, ["rules"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        (item.type==='date')
                                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                                              key: 0,
                                              name: "customData",
                                              rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}],
                                              class: "dynamic-input"
                                            }, {
                                              label: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_96, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_date_picker, {
                                                  "show-time": "",
                                                  id: 'date_' + item.id
                                                }, null, 8, ["id"])
                                              ]),
                                              _: 2
                                            }, 1032, ["rules"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        (item.type==='select')
                                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                                              key: 0,
                                              name: "customData",
                                              rules: [item.is_mandatory === 1 ? { required: true, message:  _ctx.t('Pos.RequiredField') } : {}],
                                              class: "dynamic-input"
                                            }, {
                                              label: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_select, {
                                                  id: 'select_' + item.id,
                                                  onChange: _ctx.handleSelectChange,
                                                  class: "dynamic-input"
                                                }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                        key: option,
                                                        value: option
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(option), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["id", "onChange"])
                                              ]),
                                              _: 2
                                            }, 1032, ["rules"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        (item.type==='checkbox')
                                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                                              key: 0,
                                              name: "customData",
                                              rules: [item.is_mandatory === 1 ? { required: true, message:  _ctx.t('Pos.RequiredField') } : {}]
                                            }, {
                                              label: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_98, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_checkbox_group, {
                                                  id: 'check_' + item.id
                                                }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                                      return (_openBlock(), _createBlock(_component_a_checkbox, {
                                                        key: option,
                                                        value: option,
                                                        style: {"line-height":"32px"}
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(option), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["id"])
                                              ]),
                                              _: 2
                                            }, 1032, ["rules"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        (item.type==='radio')
                                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                                              key: 0,
                                              name: "customData",
                                              rules: [item.is_mandatory === 1 ? { required: true, message:  _ctx.t('Pos.RequiredField') } : {}]
                                            }, {
                                              label: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_99, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_radio_group, {
                                                  id: 'radio_' + item.id
                                                }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                                      return (_openBlock(), _createBlock(_component_a_radio, {
                                                        key: option,
                                                        value: option,
                                                        style: {"line-height":"32px"}
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(option), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["id"])
                                              ]),
                                              _: 2
                                            }, 1032, ["rules"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            xs: { span: 12},
                            sm: { span: 12},
                            md: {span: 12},
                            lg: { span: 12},
                            xl: { span: 12 },
                            xxl: { span: 12 }
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_100, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.refundable')) + " :", 1),
                                _createVNode(_component_a_switch, {
                                  checked: _ctx.addForm.refundable,
                                  "onUpdate:checked": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.addForm.refundable) = $event))
                                }, null, 8, ["checked"])
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, {
                            xs: { span: 12},
                            sm: { span: 12},
                            md: {span: 12},
                            lg: { span: 12},
                            xl: { span: 12 },
                            xxl: { span: 12 }
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_101, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.stockable')) + " :", 1),
                                _createVNode(_component_a_switch, {
                                  checked: _ctx.addForm.stockable,
                                  "onUpdate:checked": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.addForm.stockable) = $event))
                                }, null, 8, ["checked"])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            xs: { span: 24},
                            sm: { span: 24},
                            md: {span: 12},
                            lg: { span: 12},
                            xl: { span: 12 },
                            xxl: { span: 12 }
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_102, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.HasIngredients')) + " :", 1),
                                _createVNode(_component_a_switch, {
                                  checked: _ctx.addForm.has_ingredients,
                                  "onUpdate:checked": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.addForm.has_ingredients) = $event))
                                }, null, 8, ["checked"])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.addForm.has_ingredients)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_103, [
                            _createVNode(_component_a_select, {
                              mode: "multiple",
                              value: _ctx.selectedIngredients,
                              "onUpdate:value": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.selectedIngredients) = $event)),
                              style: {"width":"100%"},
                              placeholder: _ctx.t('Pos.Ingredients'),
                              onChange: _ctx.handleIngredientChange,
                              "show-search": "",
                              "option-filter-prop": 'label'
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.ingredients, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.value,
                                    value: item.value,
                                    label: item.label
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value", "label"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder", "onChange"])
                          ]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.ingrAddArray, (item) => {
                        return (_openBlock(), _createBlock(_component_a_row, {
                          key: item,
                          gutter: 16,
                          class: "ingredients-container"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              xs: { span: 24},
                              sm: { span: 24},
                              md: {span: 24},
                              lg: { span: 24 },
                              xl: { span: 24 },
                              xxl: { span: 24 },
                              class: "ingr-name-container"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_104, _toDisplayString(item.item_name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, {
                              xs: { span: 24},
                              sm: { span: 24},
                              md: {span: 6},
                              lg: { span: 6 },
                              xl: { span: 6 },
                              xxl: { span: 6 },
                              class: "ingr-content-container"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  "has-feedback": "",
                                  name: 'qt_' + item.item_id,
                                  "validate-status": _ctx.getValidatingStatus(item.item_id,'qt'),
                                  help: _ctx.getValidatingHelp(item.item_id,'qt')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      id: 'qt_' + item.item_id,
                                      placeholder: _ctx.t('Pos.Quantity'),
                                      class: "input-form",
                                      onInput: ($event: any) => (_ctx.clearError(item.item_id, 'qt'))
                                    }, null, 8, ["id", "placeholder", "onInput"])
                                  ]),
                                  _: 2
                                }, 1032, ["name", "validate-status", "help"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, {
                              xs: { span: 24},
                              sm: { span: 24},
                              md: {span: 6},
                              lg: { span: 6 },
                              xl: { span: 6 },
                              xxl: { span: 6 },
                              class: "ingr-content-container"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_105, [
                                  _createElementVNode("span", _hoisted_106, _toDisplayString(_ctx.t('Pos.IncludedbyDefault')), 1),
                                  _createVNode(_component_a_switch, {
                                    id: 'default_' + item.item_id,
                                    checked: item.default,
                                    "onUpdate:checked": ($event: any) => ((item.default) = $event),
                                    class: "swicth-btn"
                                  }, null, 8, ["id", "checked", "onUpdate:checked"])
                                ])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, {
                              xs: { span: 24},
                              sm: { span: 24},
                              md: {span: 6},
                              lg: { span: 6 },
                              xl: { span: 6 },
                              xxl: { span: 6 },
                              class: "ingr-content-container"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  "has-feedback": "",
                                  "validate-status": _ctx.getValidatingStatus(item.item_id,'cost'),
                                  help: _ctx.getValidatingHelp(item.item_id,'cost')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      id: 'cost_' + item.item_id,
                                      placeholder: _ctx.t('Pos.AdditionalCost'),
                                      class: "input-form",
                                      onInput: ($event: any) => (_ctx.clearError(item.item_id, 'cost'))
                                    }, null, 8, ["id", "placeholder", "onInput"])
                                  ]),
                                  _: 2
                                }, 1032, ["validate-status", "help"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, {
                              xs: { span: 24},
                              sm: { span: 24},
                              md: {span: 6},
                              lg: { span: 6 },
                              xl: { span: 6 },
                              xxl: { span: 6 },
                              class: "ingr-content-container"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  "validate-status": _ctx.getValidatingStatus(item.item_id,'unit'),
                                  help: _ctx.getValidatingHelp(item.item_id,'unit')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select, {
                                      id: 'unit_' + item.item_id,
                                      placeholder: _ctx.t('Pos.Unit'),
                                      onChange: ($event: any) => (_ctx.handleUnitChange($event,item.item_id))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select_option, { value: "mg" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_select_option, { value: "pcs" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_select_option, { value: "ml" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_select_option, { value: "mm" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1032, ["id", "placeholder", "onChange"])
                                  ]),
                                  _: 2
                                }, 1032, ["validate-status", "help"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["model", "rules", "class"])
          ]),
          _createElementVNode("div", _hoisted_107, [
            (_ctx.current < _ctx.steps.length - 1)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  onClick: _ctx.next
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Next')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.current == _ctx.steps.length - 1)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  type: "primary",
                  onClick: _cache[53] || (_cache[53] = ($event: any) => (_ctx.addProduct(_ctx.form.id)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.current > 0)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 2,
                  style: {"margin-left":"8px"},
                  onClick: _ctx.prev
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Previous')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"]),
      _createVNode(_component_a_modal, {
        open: _ctx.openPref,
        "onUpdate:open": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.openPref) = $event)),
        onCancel: _ctx.resetPref
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.Preferences')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.resetPref,
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "class"])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.preferences, (item) => {
            return (_openBlock(), _createBlock(_component_a_space, {
              key: item,
              style: {"display":"flex","margin-bottom":"8px"},
              align: "baseline",
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  "has-feedback": "",
                  name: 'name' + item.id,
                  "validate-status": _ctx.getValidatingStatus(item.id,'name'),
                  help: _ctx.getValidatingHelp(item.id,'name')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: item.preference,
                      "onUpdate:value": ($event: any) => ((item.preference) = $event),
                      id: 'name' + item.id,
                      placeholder: _ctx.t('Pos.Preference'),
                      class: "input-form"
                    }, null, 8, ["value", "onUpdate:value", "id", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["name", "validate-status", "help"]),
                _createVNode(_component_a_form_item, {
                  "has-feedback": "",
                  name: 'desc' + item.id,
                  "validate-status": _ctx.getValidatingStatus(item.id,'desc'),
                  help: _ctx.getValidatingHelp(item.id,'desc')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: item.description,
                      "onUpdate:value": ($event: any) => ((item.description) = $event),
                      id: 'desc' + item.id,
                      placeholder: _ctx.t('Pos.Description'),
                      class: "input-form"
                    }, null, 8, ["value", "onUpdate:value", "id", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["name", "validate-status", "help"]),
                _createVNode(_component_MinusCircleOutlined, {
                  class: "cancel-pref-icon",
                  onClick: ($event: any) => (_ctx.showDeletePref(item.id))
                }, null, 8, ["onClick"]),
                _createVNode(_component_EditOutlined, {
                  class: "edit-pref-icon",
                  onClick: ($event: any) => (_ctx.updatePreference(item))
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["class"]))
          }), 128)),
          _createVNode(_component_a_form, {
            ref: "formRefPref",
            name: "dynamic_form_nest_item",
            model: _ctx.dynamicValidPrefForm,
            onFinish: _ctx.onFinish
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicValidPrefForm.prefs, (pref, index) => {
                return (_openBlock(), _createBlock(_component_a_space, {
                  key: pref.id,
                  style: {"display":"flex","margin-bottom":"8px"},
                  align: "baseline"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      name: ['prefs', index, 'preference'],
                      rules: {
                required: true,
                message: _ctx.t('Pos.RequiredField'),
              }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: pref.preference,
                          "onUpdate:value": ($event: any) => ((pref.preference) = $event),
                          placeholder: _ctx.t('Pos.Preference')
                        }, null, 8, ["value", "onUpdate:value", "placeholder"])
                      ]),
                      _: 2
                    }, 1032, ["name", "rules"]),
                    _createVNode(_component_a_form_item, {
                      name: ['prefs', index, 'description'],
                      rules: {
                required: true,
                message: _ctx.t('Pos.RequiredField'),
              }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: pref.description,
                          "onUpdate:value": ($event: any) => ((pref.description) = $event),
                          placeholder: _ctx.t('Pos.Description')
                        }, null, 8, ["value", "onUpdate:value", "placeholder"])
                      ]),
                      _: 2
                    }, 1032, ["name", "rules"]),
                    _createVNode(_component_MinusCircleOutlined, {
                      class: "cancel-pref-icon",
                      onClick: ($event: any) => (_ctx.removePref(pref))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "dashed",
                    block: "",
                    onClick: _ctx.addPrefs
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PlusOutlined),
                      _createTextVNode(" " + _toDisplayString(_ctx.t('Pos.AddPreference')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              (_ctx.dynamicValidPrefForm.prefs.length!==0)
                ? (_openBlock(), _createBlock(_component_a_form_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        "html-type": "submit"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "onFinish"])
        ]),
        _: 1
      }, 8, ["open", "onCancel"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openPack,
        "onUpdate:open": _cache[68] || (_cache[68] = ($event: any) => ((_ctx.openPack) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.resetAddPack
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AddPack')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_steps, {
            current: _ctx.curentPack,
            items: _ctx.itemsPack
          }, null, 8, ["current", "items"]),
          _createElementVNode("div", _hoisted_108, [
            _createVNode(_component_a_form, {
              ref: "packFromRef",
              model: _ctx.packForm,
              rules: _ctx.packRules,
              layout: "vertical",
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                (_ctx.stepsPack[_ctx.curentPack].content==='first-content-pack')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_109, [
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "name" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_110, _toDisplayString(_ctx.t('Pos.Name')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.packForm.name,
                                    "onUpdate:value": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.packForm.name) = $event)),
                                    placeholder: _ctx.t('Pos.Name'),
                                    class: "input-form"
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "category_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_111, _toDisplayString(_ctx.t('Pos.Category')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.packForm.category_id,
                                    "onUpdate:value": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.packForm.category_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.categories, (category) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          label: category.name,
                                          key: category.id,
                                          value: category.id,
                                          selected: category.id === _ctx.packForm.category_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(category.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["label", "value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "vat_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_112, _toDisplayString(_ctx.t('Pos.Vat')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.packForm.vat_id,
                                    "onUpdate:value": _cache[58] || (_cache[58] = ($event: any) => ((_ctx.packForm.vat_id) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.vats, (vat) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          label: vat.name,
                                          key: vat.id,
                                          value: vat.id,
                                          selected: vat.id === _ctx.packForm.vat_id 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(vat.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["label", "value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "price" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_113, _toDisplayString(_ctx.t('Pos.Price')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.packForm.price,
                                    "onUpdate:value": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.packForm.price) = $event)),
                                    placeholder: _ctx.t('Pos.Price')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "purchase_price" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_114, _toDisplayString(_ctx.t('Pos.PurchasePrice')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.packForm.purchase_price,
                                    "onUpdate:value": _cache[60] || (_cache[60] = ($event: any) => ((_ctx.packForm.purchase_price) = $event)),
                                    placeholder: _ctx.t('Pos.PurchasePrice')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "unit" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_115, _toDisplayString(_ctx.t('Pos.Unit')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.packForm.unit,
                                    "onUpdate:value": _cache[61] || (_cache[61] = ($event: any) => ((_ctx.packForm.unit) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select_option, {
                                        value: "mg",
                                        label: _ctx.t('Pos.Milligrams')
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["label"]),
                                      _createVNode(_component_a_select_option, {
                                        value: "pcs",
                                        label: _ctx.t('Pos.Pieces')
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["label"]),
                                      _createVNode(_component_a_select_option, {
                                        value: "ml",
                                        label: _ctx.t('Pos.Milliliter')
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["label"]),
                                      _createVNode(_component_a_select_option, {
                                        value: "mm",
                                        label: _ctx.t('Pos.Millimeter')
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["label"])
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "display_rank" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_116, _toDisplayString(_ctx.t('Pos.DisplayRank')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.packForm.display_rank,
                                    "onUpdate:value": _cache[62] || (_cache[62] = ($event: any) => ((_ctx.packForm.display_rank) = $event)),
                                    placeholder: _ctx.t('Pos.DisplayRank')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "code_ean" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_117, _toDisplayString(_ctx.t('Pos.Code')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.packForm.code_ean,
                                    "onUpdate:value": _cache[63] || (_cache[63] = ($event: any) => ((_ctx.packForm.code_ean) = $event)),
                                    placeholder: _ctx.t('Pos.Code')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "barcode" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_118, _toDisplayString(_ctx.t('Pos.BarCode')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.packForm.barcode,
                                    "onUpdate:value": _cache[64] || (_cache[64] = ($event: any) => ((_ctx.packForm.barcode) = $event)),
                                    placeholder: _ctx.t('Pos.BarCode')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.stepsPack[_ctx.curentPack].content==='second-content-pack')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_119, [
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "description" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_120, _toDisplayString(_ctx.t('Pos.Description')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_textarea, {
                                    value: _ctx.packForm.description,
                                    "onUpdate:value": _cache[65] || (_cache[65] = ($event: any) => ((_ctx.packForm.description) = $event)),
                                    rows: 4,
                                    placeholder: _ctx.t('Pos.Description')
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, null, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_121, _toDisplayString(_ctx.t('Pos.Photo')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_upload_dragger, {
                                    fileList: _ctx.fileListPack,
                                    "onUpdate:fileList": _cache[66] || (_cache[66] = ($event: any) => ((_ctx.fileListPack) = $event)),
                                    name: "file",
                                    multiple: false,
                                    "max-count": 1,
                                    onChange: _ctx.handleChangeFilePack,
                                    onDrop: _ctx.handleDropPack,
                                    "before-upload": _ctx.beforeUploadPack
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_122, [
                                        _createVNode(_component_InboxOutlined)
                                      ]),
                                      _createElementVNode("p", _hoisted_123, _toDisplayString(_ctx.t('Pos.dropTitle1')), 1),
                                      _createElementVNode("p", _hoisted_124, _toDisplayString(_ctx.t('Pos.dropTitle2')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["fileList", "onChange", "onDrop", "before-upload"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "products" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_125, _toDisplayString(_ctx.t('Pos.Products')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.state.value,
                                    "onUpdate:value": _cache[67] || (_cache[67] = ($event: any) => ((_ctx.state.value) = $event)),
                                    mode: "multiple",
                                    "label-in-value": "",
                                    placeholder: _ctx.t('Pos.Products'),
                                    style: {"width":"100%"},
                                    "filter-option": false,
                                    "not-found-content": _ctx.state.fetching ? undefined : null,
                                    options: _ctx.state.products,
                                    onSearch: _ctx.fetchProduct,
                                    onChange: _ctx.handleProductsSelect
                                  }, _createSlots({ _: 2 }, [
                                    (_ctx.state.fetching)
                                      ? {
                                          name: "notFoundContent",
                                          fn: _withCtx(() => [
                                            _createVNode(_component_a_spin, { size: "small" })
                                          ]),
                                          key: "0"
                                        }
                                      : undefined
                                  ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch", "onChange"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.productsPack, (item) => {
                        return (_openBlock(), _createBlock(_component_a_row, {
                          gutter: 16,
                          key: item
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              xs: { span: 24},
                              sm: { span: 24},
                              md: {span: 24},
                              lg: { span: 24 },
                              xl: { span: 24 },
                              xxl: { span: 24 },
                              class: "ingr-name-container"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_126, _toDisplayString(item.label), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, {
                              xs: { span: 24},
                              sm: { span: 24},
                              md: {span: 6},
                              lg: { span: 6 },
                              xl: { span: 6 },
                              xxl: { span: 6 },
                              class: "ingr-content-container"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  "has-feedback": "",
                                  name: 'qt_' + item.value,
                                  "validate-status": _ctx.getValidatingStatus(item.value,'qt'),
                                  help: _ctx.getValidatingHelp(item.value,'qt')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      id: 'qt_' + item.value,
                                      placeholder: _ctx.t('Pos.Quantity'),
                                      value: item.quantity,
                                      "onUpdate:value": ($event: any) => ((item.quantity) = $event),
                                      class: "input-form"
                                    }, null, 8, ["id", "placeholder", "value", "onUpdate:value"])
                                  ]),
                                  _: 2
                                }, 1032, ["name", "validate-status", "help"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, {
                              xs: { span: 24},
                              sm: { span: 24},
                              md: {span: 8},
                              lg: { span: 8 },
                              xl: { span: 8 },
                              xxl: { span: 8 },
                              class: "ingr-content-container"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_127, [
                                  _createElementVNode("span", _hoisted_128, _toDisplayString(_ctx.t('Pos.multipleChoice')), 1),
                                  _createVNode(_component_a_switch, {
                                    id: 'choice_' + item.value,
                                    checked: item.choice,
                                    "onUpdate:checked": ($event: any) => ((item.choice) = $event),
                                    class: "swicth-btn"
                                  }, null, 8, ["id", "checked", "onUpdate:checked"])
                                ])
                              ]),
                              _: 2
                            }, 1024),
                            (item.choice === true)
                              ? (_openBlock(), _createBlock(_component_a_col, {
                                  key: 0,
                                  xs: { span: 24},
                                  sm: { span: 24},
                                  md: {span: 10},
                                  lg: { span: 10 },
                                  xl: { span: 10 },
                                  xxl: { span: 10 },
                                  class: "ingr-content-container"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select, {
                                      id: 'choices_' + item.value,
                                      value: item.selectedChoices,
                                      "onUpdate:value": ($event: any) => ((item.selectedChoices) = $event),
                                      mode: "multiple",
                                      "label-in-value": "",
                                      placeholder: _ctx.t('Pos.selectChoice'),
                                      style: {"width":"100%"},
                                      "filter-option": false,
                                      "not-found-content": item.fetchingChoices ? undefined : null,
                                      options: item.choices,
                                      onSearch: (value) => _ctx.fetchChoices(item, value),
                                      onChange: (value) =>_ctx.handleChoiceSelect(item, value)
                                    }, _createSlots({ _: 2 }, [
                                      (item.fetchingChoices)
                                        ? {
                                            name: "notFoundContent",
                                            fn: _withCtx(() => [
                                              _createVNode(_component_a_spin, { size: "small" })
                                            ]),
                                            key: "0"
                                          }
                                        : undefined
                                    ]), 1032, ["id", "value", "onUpdate:value", "placeholder", "not-found-content", "options", "onSearch", "onChange"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["model", "rules", "class"])
          ]),
          _createElementVNode("div", _hoisted_129, [
            (_ctx.curentPack < _ctx.stepsPack.length - 1)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  onClick: _ctx.nextPack
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Next')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.curentPack == _ctx.stepsPack.length - 1)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  type: "primary",
                  onClick: _ctx.addPack
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.curentPack > 0)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 2,
                  style: {"margin-left":"8px"},
                  onClick: _ctx.prevPack
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Previous')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"])
    ])
  ], 64))
}