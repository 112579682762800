<template>
    <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                {{t('Pos.Dashboard')}}
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{t('Pos.Menu')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

   
  
   

    

  
    <!-- <div class="tools-container">
        
        <button class="tool-btn" @click="addText">
            <span class="Txt-btn">
                T
                <span class="bigLetter">T</span>
            </span>
        </button>



        <button class="tool-btn">
            <FileImageOutlined class="img-icon"/>
            <input ref="imageUploader" type="file" style="display: none;" @change="handleImageUpload">
        </button>

        <div class="fs-btn-container">
            <button class="font-size-btn" @click="decreaseFontSize">
                <MinusOutlined class="fs-icon"/>
            </button>

            <input  v-model="fontSizeInput" class="font-size-input" />

            <button class="font-size-btn" @click="increaseFontSize">
                <PlusOutlined class="fs-icon"/>
            </button>
        </div>

        <button class="fw-btn" @click="toggleBold">
            <span class="Txt-btn bigLetter">B</span>
        </button>

        <button class="fw-btn"  @click="toggleItalic">
            <span class="Txt-btn bigLetter"><i>I</i></span>
        </button>

        <button class="fw-btn">
            <span class="Txt-btn bigLetter underline">U</span>
        </button>


        <div class="edit-color-btn" @click="toggleColorPicker">
            <input type="color" v-model="state.color" @change="updateColor" class="color-picker" />
            <EditOutlined class="edit-icon" :style="{ color: iconColor }" />
        </div>


        <a-popover v-model:open="visible"  trigger="click"  placement="bottom">

            <template #content>
                <a-input v-model:value="url" :placeholder="t('Pos.urlPlaceholder')" :maxlength="60" />
            </template>
            
            
            <button class="tool-btn"><LinkOutlined  class="img-icon" /></button>
            
        </a-popover>

        <a-popover v-model:open="qrSettings"  trigger="click">

            <template #content>

                <div>
                    
                </div>
            </template>
            
            
            <button class="tool-btn"><QrcodeOutlined   class="img-icon" /></button>
            
        </a-popover>
           
       

        <input type="file" ref="imageUploader" @change="uploadImage" style="display: none;">

        <div class="delete-container">
            <button @click="deleteElement"   class="tool-btn">
               <ScissorOutlined class="delete-elet-icon"/>
            </button>
        </div>
    </div> -->


    <div class="preview-container" ref="previewContainer" :style="`--bg-image: url('${backgroundImageUrl}')`">
        <div
            v-for="(element, index) in elements"
            :key="index"
            class="draggable"
            :style="getElementStyle(element)"
            @mousedown="startDrag($event, element)"
            @click="selectElement(element)"
            
        >
           
            <span v-if="element.type === 'text'" contenteditable="true" class="elet" @click="selectElement(element)">
                {{ element.content }}
            </span>
        </div>

        <div class="qr-code-container" ref="qrCodeContainer" :style="qrCodeStyle">

            

            <a-space direction="vertical" align="center">
                <a-qrcode :value="url" :size="150"  @mousedown="startDragQr" :color="state.qrColor"  :bg-color="state.qrBg"   />
            </a-space>

        </div>
    </div>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { ref, defineComponent, reactive, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { notification } from "ant-design-vue";
import {
    FileImageOutlined,
    PlusOutlined,
    MinusOutlined,
    EditOutlined,
    LinkOutlined,
    QrcodeOutlined,
    ScissorOutlined

} from '@ant-design/icons-vue';

import { theme } from 'ant-design-vue';

export default defineComponent({
    name: "registerIndex",
    components: {
        // FileImageOutlined,
        // PlusOutlined,
        // MinusOutlined,
        // EditOutlined,
        // CloseCircleOutlined,
        // LinkOutlined,
        // QrcodeOutlined,
        // ScissorOutlined

       
    },
    setup() {
        const { t } = useI18n();
        const loading = ref(false);

        const state=reactive({
            color:"#000000",
            qrColor:"#000000",
            storeLogo:'',
            qrBg:"#ffffff9e"
        });

        const elements = reactive<any>([]);
        const imageUploader = ref<any>(null);
        //const previewContainer = ref<any>(null);
        const previewContainer = ref<HTMLElement | null>(null);
        const qrCodeContainer = ref<HTMLElement | null>(null);
        let draggingElement = null;
        let selectedElement: any = null;
        const fontSizeInput = ref(14);

        const addText = () => {
            elements.push({
                type: 'text',
                content: t('Pos.NewTxt'),
                top: 0,
                left: 0,
                fontSize: 14,
                fontWeight: 'normal',
                fontStyle: 'normal',
                textDecoration: 'none',
                color:'#000',
              

            });
        };

        const addImage = () => {
            imageUploader.value.click();
        };

        const uploadImage = (event: { target: { files: any[]; }; }) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    elements.push({
                        type: 'image',
                        content: e.target?.result,
                        top: 0,
                        left: 0
                    });
                };
                reader.readAsDataURL(file);
            }
        };

        const startDrag = (event: { clientX: number; clientY: number; }, element: { left: number; top: number; }) => {
            if (!previewContainer.value) {
                console.log('preview container', previewContainer.value);
                return;
            }

            draggingElement = element;
            const rect = previewContainer.value.getBoundingClientRect();
            const shiftX = event.clientX - rect.left - element.left;
            const shiftY = event.clientY - rect.top - element.top;

            const moveAt = (pageX: number, pageY: number) => {
                element.left = pageX - rect.left - shiftX;
                element.top = pageY - rect.top - shiftY;
            };

            const onMouseMove = (moveEvent: { pageX: number; pageY: number; }) => {
                moveAt(moveEvent.pageX, moveEvent.pageY);
            };

            document.addEventListener('mousemove', onMouseMove);

            document.onmouseup = () => {
                document.removeEventListener('mousemove', onMouseMove);
                document.onmouseup = null;
            };
        };

        //style txt
        const selectElement = (element: any) => {
            console.log('element selected', element);
            selectedElement = element;
        };

        const decreaseFontSize = () => {

            console.log('decrease');
            if (selectedElement) {
                selectedElement.fontSize = Math.max(8, selectedElement.fontSize - 2);
                fontSizeInput.value = selectedElement.fontSize;
            }
        };


        const increaseFontSize = () => {
            if (selectedElement) {
                selectedElement.fontSize = Math.min(72, selectedElement.fontSize + 2);
                fontSizeInput.value = selectedElement.fontSize;
            }
        };

        const toggleBold = () => {
            if (selectedElement) {
                selectedElement.fontWeight = selectedElement.fontWeight === 'bold' ? 'normal' : 'bold';
            }
        };

        const toggleItalic = () => {
            if (selectedElement) {
                selectedElement.fontStyle = selectedElement.fontStyle === 'italic' ? 'normal' : 'italic';
            }
        };

        const toggleUnderline = () => {
            if (selectedElement) {
                selectedElement.textDecoration = selectedElement.textDecoration === "underline" ? "none" : "underline";
            }
        };

        const deleteElement = () => {
            console.log('selected element for delete', selectedElement);
            if (selectedElement) {
                const index = elements.findIndex((el: any) => el === selectedElement);
                if (index !== -1) {
                    elements.splice(index, 1);
                    selectedElement = null; 
                }
            }
        };


        const getElementStyle = (element: any) => {
            return {
                top: element.top + 'px',
                left: element.left + 'px',
                position: 'absolute',
                cursor: 'move',
                fontSize: element.fontSize + 'px',
                fontWeight: element.fontWeight,
                fontStyle: element.fontStyle,
                textDecoration: element.textDecoration,
                color: element.color,
               
            };
        };

        



       

        const iconColor = ref("");

        const updateColor = () => {
            if (selectedElement && selectedElement.type === 'text') {
                console.log('update txt color');
                selectedElement.color = state.color;
                iconColor.value = state.color;
            }else{
                state.qrColor=state.color;

                console.log('state.qrColor', state.qrColor)
            }
        };

        const toggleColorPicker = () => {
            const colorPicker = document.querySelector(".color-picker");
            if (colorPicker) {
                //colorPicker.click();
                console.log('icon color', state.color);

            }
        };

        //qrCode :

        const { useToken } = theme;
        const { token } = useToken();

        const url = ref('');
        const qrCodePosition = reactive({ left: 50, top: 50 });


        
        const qrCodeStyle = computed(() => ({
            position: 'absolute',
            left: `${qrCodePosition.left}px`,
            top: `${qrCodePosition.top}px`,
            cursor: 'move',
        }));

        const startDragQr = (event: MouseEvent) => {
            if (!qrCodeContainer.value || !previewContainer.value) return;

            const previewRect = previewContainer.value.getBoundingClientRect();

            const offsetX = event.clientX - qrCodePosition.left;
            const offsetY = event.clientY - qrCodePosition.top;

            const onMouseMove = (moveEvent: MouseEvent) => {
                const newX = moveEvent.clientX - offsetX - previewRect.left;
                const newY = moveEvent.clientY - offsetY - previewRect.top;

                qrCodePosition.left = Math.max(0, Math.min(newX, previewRect.width - 150)); 
                qrCodePosition.top = Math.max(0, Math.min(newY, previewRect.height - 150));
            };

            document.addEventListener('mousemove', onMouseMove);

            document.onmouseup = () => {
                document.removeEventListener('mousemove', onMouseMove);
                document.onmouseup = null;
            };
        };

        const visible = ref<boolean>(false);
        const qrSettings = ref<boolean>(false);

        const hide = () => {
            visible.value = false;
        };

        const getStoreLogo=()=>{
            const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
 
            state.storeLogo=storeItem.logo;
            
        }

        //bg img
        const imgSetting = ref<boolean>(false);

        const backgroundImageUrl = ref('/src/assets/img/bg.jpg');



        const handleImageUpload = (event: Event) => {
            const input = event.target as HTMLInputElement;
            const file = input.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    backgroundImageUrl.value = e.target?.result as string;
                };
                reader.readAsDataURL(file);
            }
        };

        onMounted(()=>{
            getStoreLogo();
        });

       





      

      


        return{
            t,
            loading,
            state,  
            addText,
            addImage,
            elements,
            uploadImage,
            startDrag,
            selectElement,
            selectedElement,
            increaseFontSize,
            decreaseFontSize,
            toggleBold,
            toggleItalic,
            toggleUnderline,
            getElementStyle,
            draggingElement,
            previewContainer,
            qrCodeContainer,
            fontSizeInput,
            updateColor,
            toggleColorPicker,
            iconColor,
            url,
            deleteElement,
            qrCodePosition,
            qrCodeStyle ,
            startDragQr,
            visible,
            hide,
            qrSettings,
            handleImageUpload,
            backgroundImageUrl,
            imgSetting,
            token
            



            

        }
        
    },
})
</script>


<style scoped>

.tools-container{
    padding: 15px;
    display: flex;
    background-color: #ffad691a;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    border-radius: 10px;
}


.tool-btn{
    padding: 10px;
    color: #fc8019;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    font-weight: 400;
    font-family: "Exo 2", sans-serif;
    cursor: pointer;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.tool-btn:hover{
    background-color:#ffad6959 ;
    transform: translateY(-3px);
}

.preview-container {
    margin-top: 20px;
    border: 1px solid #8b8b8b73;
    border-radius: 10px;
    height: 400px;
    position: relative;

   background-image: url('@/assets/img/bg.jpg');
   background-size: cover; 
   background-position: cover;
   filter: brightness(0.7);



}


.draggable {
    position: absolute;
    cursor: move;
    /* border: 1px dashed #ccc; */
}

.draggable.selected {
   border: 1px dashed #ccc;
}


.close-icon{
    color: #ccc;
}


.draggable:focus{
    border: none;
}



/*tools*/

.Txt-btn{
    font-weight: 600;
}
.bigLetter{
    font-size: 16px;
}

.tool-img-btn{
    width: 50px;
    height: auto;
}

.img-icon{
    font-size: 18px;
    padding:0px 2px;
}


.delete-elet-icon{

    font-size: 18px;
    padding:0px 2px;
    color: #fc1919;

}

.fs-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.font-size-btn{
    background-color: transparent;
    border:none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.font-size-btn:hover{
    
    background-color:#ffad6959 ;
}

.font-size-input{
    margin: 0px 5px;
    color: #484848;
    width: 30px;
    background-color: transparent;
    border: 1px solid #8b8b8b73;
    font-family: "Exo 2", sans-serif;
    border-radius: 5px;
    text-align: center;
}

.font-size-input:focus{
    border: none;
}

.fs-icon{
    color: #fc8019;
}

.fw-btn{
    padding: 5px 12px;
    color: #fc8019;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    font-weight: 400;
    font-family: "Exo 2", sans-serif;
    cursor: pointer;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);


}

.fw-btn:hover{
    background-color:#ffad6959 ;
    transform: translateY(-3px);
}

.underline{
    text-decoration: underline;
}




/* color picker */
.edit-color-btn {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  color: #fc8019;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  font-weight: 400;
  font-family: "Exo 2", sans-serif;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.edit-color-btn:hover {
  background-color: #ffad6959;
  transform: translateY(-3px);
}

.edit-icon {
  font-size: 17px;
}

.edit-color-btn .color-picker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border: none;
  outline: none;
}

.elet{
    padding: 10px;
}


</style>