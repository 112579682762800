<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>
  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">{{
        t("Pos.Home")
      }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.Settings") }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.PaymentSettings") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer(true)">{{
      t("Pos.Add")
    }}</a-button>
  </div>
  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title>
                {{ t("Pos.View") }}
              </template>

              <a-button
                class="view-btn"
                type="primary"
                @click="showDisplayDrawer(record)"
              >
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>
                {{ t("Pos.Edit") }}
              </template>

              <a-button type="primary" @click="() => showDrawer(record)">
                <template #icon>
                  <EditOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>
                {{ t("Pos.Delete") }}
              </template>

              <a-button type="primary" danger @click="showDeletePopup(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template v-if="column.key == 'key'">
          {{ formatName(record) }}
        </template>

        <template v-if="column.key == 'description'">
          {{ formatDescription(record) }}
        </template>

        <template v-if="column.key == 'config'">
          {{ getDescriptionFromConfig(record) }}
        </template>

        <template v-if="column.key == 'status'">
          {{ statusTranslation(record) }}
        </template>

        <template v-if="column.key === 'is_default'">
          <template v-if="record[column.dataIndex] === 1">
            <check-circle-two-tone two-tone-color="#52c41a" />
          </template>
          <template v-else>
            <check-circle-two-tone two-tone-color="#ff4d4f" />
          </template>
        </template>
      </template>

      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
            >{{ t("Pos.Reset") }}</a-button
          >
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        :total="reactiveData.totalPages"
        @change="handlePageChange"
        :default-page-size="perPage"
      />
    </div>

    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
      @close="resetFields"
    >
      <template #title>
        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.action == 'add'"
        >
          <span>{{ t("Pos.AddPaymentSettingsDrawer") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.action == 'add'"
        >
          <span>{{ t("Pos.AddPaymentSettingsDrawer") }}</span>
        </div>
        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.action == 'update'"
        >
          <span>{{ t("Pos.UpdatePaymentSettingsDrawer") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.action == 'update'"
        >
          <span>{{ t("Pos.UpdatePaymentSettingsDrawer") }}</span>
        </div>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="active">
              <template #label>
                <span class="label-form">{{ t("Pos.paymentType") }}</span>
              </template>
              <a-select
                v-model:value="form.active"
                :placeholder="t('Pos.paymentType')"
                class="input-form"
                :disabled="isDisabled"
                show-search
                :option-filter-prop="'label'"
                @change="handlePaymentType"
                ><a-select-option
                  v-for="paymentMethod in reactiveData2.data"
                  :key="paymentMethod.id"
                  :value="paymentMethod.payment_method_id"
                  :label="paymentMethod.name"
                  >{{ paymentMethod.name }}</a-select-option
                ></a-select
              >
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="status">
              <template #label>
                <span class="label-form">{{ t("Pos.Status") }}</span>
              </template>
              <a-select
                v-model:value="form.status"
                placeholder="Choose your payment methods"
                class="input-form"
              >
                <a-select-option value="active">{{
                  t("Pos.Active")
                }}</a-select-option>
                <a-select-option value="inactive">{{
                  t("Pos.InActive")
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="form.payementTypeName === 'Flousi'">
          <a-col :span="12">
            <a-form-item name="password">
              <template #label>
                <span class="label-form">{{ t("Pos.Password") }}</span>
              </template>
              <a-input-password
                v-model:value="form.flousiPassword"
                v-model:visible="visiblePassword"
                :placeholder="t('Pos.Password')"
                class="login-input"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="terminalId">
              <template #label>
                <span class="label-form">{{ t("Pos.terminalId") }}</span>
              </template>
              <a-input v-model:value="form.terminalId" class="input-form" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="serviceCode">
              <template #label>
                <span class="label-form">{{ t("Pos.serviceCode") }}</span>
              </template>
              <a-input v-model:value="form.serviceCode" class="input-form" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="serialNumber">
              <template #label>
                <span class="label-form">{{ t("Pos.serialNumber") }}</span>
              </template>
              <a-input v-model:value="form.serialNumber" class="input-form" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="relaisVersion">
              <template #label>
                <span class="label-form">{{ t("Pos.relaisVersion") }}</span>
              </template>
              <a-input v-model:value="form.relaisVersion" class="input-form" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="softPosVersion">
              <template #label>
                <span class="label-form">{{ t("Pos.softPosVersion") }}</span>
              </template>
              <a-input v-model:value="form.softPosVersion" class="input-form" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="providerChannel">
              <template #label>
                <span class="label-form">{{ t("Pos.providerChannel") }}</span>
              </template>
              <a-input
                v-model:value="form.providerChannel"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12" class="check-input">
            <a-form-item name="barCode">
              <a-checkbox v-model:checked="form.barCode">{{
                t("Pos.barCode")
              }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
        <div>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item name="cashInformation">
                <template #label>
                  <span class="label-form">{{ t("Pos.Description") }}</span>
                </template>
                <a-textarea
                  v-model:value="form.cashInformation"
                  :rows="4"
                  :placeholder="t('Pos.Description')"
                  class="input-form"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="default"
              ><a-checkbox v-model:checked="form.default"
                >{{ t("Pos.PaymentDefault") }}
              </a-checkbox></a-form-item
            >
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div :class="{ 'is-rtl': textDirection === 'rtl' }">
          <a-space v-if="reactiveData.action == 'update'">
            <a-button type="primary" @click="updatePaymentMethod(form.id)">{{
              t("Pos.Update")
            }}</a-button>
            <a-button type="primary" danger @click="closePopup">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
          <a-space v-else>
            <a-button type="primary" @click="addPaymentMethod">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="closePopup">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>

    <!-- Details-->
    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.PaymentDetails") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.PaymentDetails") }}</span>
        </div>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <template #header>
          <div class="custom-header">
            {{ t("Pos.GeneralInformations") }}
          </div>
        </template>
        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title" :placeholder="t('Pos.paymentType')">
                {{ t("Pos.paymentType") }} :</span
              >
              {{ form.name }}
            </p>
          </a-col>
          <a-col :span="12">
            <p>
              <span class="info-title" :placeholder="t('Pos.Description')">
                {{ t("Pos.Description") }} :</span
              >
              {{ form.cashInformation }}
            </p>
          </a-col>
          <a-col :span="12">
            <p>
              <span class="info-title" :placeholder="t('Pos.Status')">
                {{ t("Pos.Status") }} :</span
              >
              {{ form.status }}
            </p>
          </a-col>
        </a-row>

        <a-row v-if="form.payementTypeName === 'Flousi'">
          <a-col :span="24">
            <p class="config-title">{{ t("Pos.configFlousi") }}</p>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="form.payementTypeName === 'Flousi'">
          <a-col :span="12">
            <p>
              <span class="info-title"> {{ t("Pos.Password") }} :</span>
              {{ form.flousiPassword }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title"> {{ t("Pos.terminalId") }} :</span>
              {{ form.terminalId }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title"> {{ t("Pos.serviceCode") }} :</span>
              {{ form.serviceCode }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title"> {{ t("Pos.serialNumber") }} :</span>
              {{ form.serialNumber }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title"> {{ t("Pos.relaisVersion") }} :</span>
              {{ form.relaisVersion }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title"> {{ t("Pos.softPosVersion") }} :</span>
              {{ form.softPosVersion }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title"> {{ t("Pos.providerChannel") }} :</span>
              {{ form.providerChannel }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <check-circle-two-tone
                two-tone-color="#52c41a"
                v-if="form.barCode === 1 || form.barCode === true"
              />
              <close-circle-two-tone
                two-tone-color="#ff4d4f"
                v-if="form.barCode === 0 || form.barCode === false"
              />
              <span class="info-title title-check">{{ t("Pos.barCode") }}</span>
            </p>
          </a-col>
        </a-row>
      </a-form>

      <template #footer></template>
    </a-drawer>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRaw,
  computed,
} from "vue";

import type { Rule } from "ant-design-vue/es/form";

import Swal from "sweetalert2";

import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons-vue";

import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";

import { notification } from "ant-design-vue";

import { paymentMethodsService, paymentService } from "@/_services";

import dayjs from "dayjs";

import { useI18n } from "vue-i18n";

import { useRouter } from "vue-router";

export default defineComponent({
  name: "indexStore",
  components: {
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    LbrxLoaderSpinner,
    SearchOutlined,
    CheckCircleTwoTone,
  },
  setup() {
    const modalWidth = ref(720);

    const { locale } = useI18n();

    const searchInput = ref<HTMLInputElement | null>(null);
    const loading = ref(false);

    const isDisabled = ref(false);
    const { t } = useI18n();
    const columns = [
      {
        title: t("Pos.paymentType"),
        key: "key",
        dataIndex: "key",
        customFilterDropdown: false,
        onFilter: (value: any, record: any) => record.key,
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector(
                ".ant-table-filter-dropdown input"
              );

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },

      {
        title: t("Pos.Description"),
        key: "config",
        dataIndex: "config",
      },

      {
        title: t("Pos.Status"),
        key: "status",
        dataIndex: "status",
      },

      {
        title: t("Pos.IsDefault"),
        key: "is_default",
        dataIndex: "is_default",
      },

      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];

    const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
      // console.log('open', bool);
    };

    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };

    //form
    const formRef = ref();
    const visible = ref<boolean>(false);

    const reactiveData = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchText: "",
      searchedColumn: "",
    });

    const reactiveData2 = reactive({
      data: [] as any[],
      action: "",
    });
    const visiblePassword = ref<boolean>(false);

    const form = reactive({
      id: "",
      name: "",
      description: "",
      address: "",
      phone: "",
      email: "",
      username: "",
      password: "",
      payment_method_id: "",
      config: "",
      status: "active",
      accountNumber: "",
      active: "",
      isDisabled: false,
      default: 0,
      expiryDate: "",
      ccv: "",
      creditCardNumber: "",
      accountHolder: "",
      cashInformation: "",
      ticketDescription: "",
      ticketCode: "",
      payementTypeName: "",

      flousiPassword: "",
      terminalId: "",
      serviceCode: "",
      serialNumber: "",
      relaisVersion: "",
      softPosVersion: "",
      providerChannel: "",
      barCode: true,
    });

    const resetForm = () => {
      form.id = "";
      form.name = "";
      form.description = "";
      form.address = "";
      form.phone = "";
      form.email = "";
      form.username = "";
      form.payment_method_id = "";
      form.status = "active";
      form.password = "";
      (form.isDisabled = false),
        (form.expiryDate = ""),
        (form.ccv = ""),
        (form.creditCardNumber = ""),
        (form.accountHolder = "");
      form.cashInformation = "";
      (form.ticketDescription = ""), (form.ticketCode = "");
    };
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const rules: Record<string, Rule[]> = {
      active: [
        {
          required: true,
          message: t("Pos.PaymentVerification"),
        },
      ],
      cashInformation: [
        {
          required: true,
          message: t("Pos.DescriptionVerification"),
        },
      ],
      status: [
        {
          required: true,
          message: t("Pos.PaymentMethodVerification"),
        },
      ],
    };

    const hideForm = (bool: boolean) => {
      open.value = false;
      formRef.value.resetFields();
    };
    const resetFields = () => {
      formRef.value.resetFields();
    };

    const formatDate = (dateString: any) => {
      return dayjs(dateString);
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    const formatName = (dateString: any) => {
      // Check if 'method' property exists in dateString
      if (dateString && dateString.method) {
        // Check if 'name' property exists in dateString.method
        if (dateString.method.name) {
          return dateString.method.name;
        }
      }

      return "";
    };

    const formatDescription = (dateString: any) => {
      if (dateString && dateString.method) {
        if (dateString.method.description) {
          return dateString.method.description;
        }
      }

      return "";
    };

    const getDescriptionFromConfig = (dateString: any) => {
      if (dateString && dateString.config) {
        try {
          const configObj = dateString.config;

          return configObj.description || "";
        } catch (error) {
          console.error("Failed to parse JSON:", error);
          return "";
        }
      }
      return "";
    };

    const statusTranslation = (dateString: any) => {
      if (dateString.status == "active") {
        return t("Pos.Usage");
      } else {
        return t("Pos.UsageNot");
      }
    };

    //drawer

    const open = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
      updateModalWidth();
      resetForm();
      open.value = state;
      reactiveData.action = "add";
      isDisabled.value = false;
    };

    const afterOpenChange = (bool: boolean) => {
      //  console.log('open', bool);
    };

    const showDrawer = (record: any) => {
      updateModalWidth();
      open.value = true;
      getPaymentMethodById(record.id);
      reactiveData.action = "update";
      //  console.log(record.id)
      form.active = record.payment_method_id;
      form.id = record.id;
      isDisabled.value = true;
    };

    const openAdd = ref<boolean>(false);

    const afterOpenAddChange = (bool: boolean) => {
      //  console.log('open', bool);
    };

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();
      reactiveData.searchText = selectedKeys[0];
      reactiveData.searchedColumn = dataIndex;
      searchWithFullParams(selectedKeys[0]);
      console.log(reactiveData.searchText);
    };

    const searchWithFullParams = (selectedText: any) => {
      /*   loading.value = true;

              paymentService.searchWithFullParams("name", selectedText,currentPage.value,form.customPagination)
            .then((res) => {
              reactiveData.data = res.data;
              reactiveData.totalPages =res.meta.total

            })
            .catch((error) => {
              //console.log("error api : get warehouses", error);
            })
            .finally(() => {
              loading.value = false;
            });*/
      console.log("need to fix key and value in backend");
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({
        confirm: true,
      });
      reactiveData.searchText = "";
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;
      loading.value = true;

      // console.log('per page', perPage.value);
      // console.log('page', currentPage.value)

      paymentMethodsService
        .getAll(currentPage.value, perPage.value)
        .then((response) => {
          //   console.log('data', response.data)
          reactiveData.data = response.data;
        })
        .catch((error) => {
          //  console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getPaymentMethods = () => {
      loading.value = true;
      paymentMethodsService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
          // console.log(res.data);

          // After fetching payment methods, call getPaymentNames
          getPaymentNames();
        })
        .catch((error) => {
          //  console.log("error api : get payments settings", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      openDisplay.value = true;
      getPaymentMethodById(record.id);
      resetForm();
      reactiveData.action = "view";
    };

    const getPaymentNames = () => {
      paymentMethodsService
        .getNames()
        .then((res) => {
          const allPaymentMethods = reactiveData.data.map(
            (method) => method.id
          );
          const paymentNames: {
            payment_method_id: number;
            name: string;
          }[] = res.data;

          // Filter out payment names not present in allPaymentMethods
          const filteredPaymentNames = paymentNames.filter(
            (paymentName: { payment_method_id: number; name: string }) => {
              return !allPaymentMethods.includes(paymentName.payment_method_id);
            }
          );

          console.log("filtrespayments", paymentNames);

          reactiveData2.data = filteredPaymentNames;
          // console.log(filteredPaymentNames);
        })
        .catch((error) => {
          //  console.error('Error fetching Stores:', error);
        });
    };

    const deletePaymentMethod = (id: string) => {
      loading.value = true;
      paymentMethodsService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification(t("Pos.DeletePaymentSettings"));
        })
        .catch((err) => {
          //  console.log(err)
        })
        .finally(() => {
          // console.log('finish')
          loading.value = false;
        });
    };

    const getPaymentMethodById = (id: string) => {
      paymentMethodsService
        .show(id)
        .then((res) => {
          console.log("ttt", res.data.method.name);

          form.name = res.data.method.name;
          let stringDescription = res.data.config;
          form.cashInformation = stringDescription.description
            ? stringDescription.description
            : "";
          if (res.data.is_default == 1) {
            form.default = 1;
          } else {
            form.default = 0;
          }

          form.status = res.data.status;
          if (form.status === "active") {
            form.status = t("Pos.Usage");
          } else {
            form.status = t("Pos.UsageNot");
          }

          form.payementTypeName = res.data.method.name;
          console.log("aaa", res.data.method.name);

          const config = res.data.config;
          form.flousiPassword = config.password ? config.password : "";
          form.terminalId = config.idTerminal ? config.idTerminal : "";
          form.serviceCode = config.serviceCode ? config.serviceCode : "";
          form.serialNumber = config.serialNumber ? config.serialNumber : "";
          form.relaisVersion = config.relaisVersion ? config.relaisVersion : "";
          form.softPosVersion = config.softPosVersion
            ? config.softPosVersion
            : "";
          form.providerChannel = config.providerChannel
            ? config.providerChannel
            : "";
        })
        .catch((error) => {
          console.error("Error fetching payment method:", error);
        });
    };

    const closePopup = () => {
      open.value = false;
      openDisplay.value = false;
      resetFields();
    };

    const addPaymentMethod = () => {
      formRef.value
        .validate()
        .then(() => {
          submitPaymentMethod();
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    const handlePaymentType = (selectedValue: any) => {
      const selectedPaymentMethod = reactiveData2.data.find(
        (method) => method.id === selectedValue
      );

      if (selectedPaymentMethod) {
        form.payementTypeName = selectedPaymentMethod.name;
        //console.log('selected method', selectedPaymentMethod.name);
      }
    };

    const submitPaymentMethod = () => {
      let config;

      if (form.payementTypeName === "Flousi") {
        let flousiConfig = {
          barcode: form.barCode,
          password: form.flousiPassword,
          idTerminal: form.terminalId,
          serviceCode: form.serviceCode,
          serialNumber: form.serialNumber,
          relaisVersion: form.relaisVersion,
          softPosVersion: form.softPosVersion,
          providerChannel: form.providerChannel,
        };
        config = {
          ...flousiConfig,
          description: form.cashInformation,
        };
      } else {
        config = {
          description: form.cashInformation,
        };
      }

      let payload = {
        payment_method_id: form.active,
        status: form.status,
        config: JSON.stringify(config),
        is_default: convertBooleanToint(form.default),
      };

      console.log("payload", payload);

      loading.value = true;
      paymentMethodsService
        .create(payload)
        .then((res) => {
          reactiveData.data.push(res.data);
          showSuccessNotification(t("Pos.AddPaymentSettings"));
          open.value = false;
          location.reload();
        })
        .catch((err) => {
          //  console.log(err)
        })
        .finally(() => {
          // console.log('finish')

          loading.value = false;
        });
    };

    const updatePaymentMethod = (id: string) => {
      // console.log("id is "+id);
      formRef.value
        .validate()
        .then(() => {
          let flousiConfig = {
            barcode: "true",
            password: "P@ssw0rd2023",
            idTerminal: "0301329750",
            serviceCode: "024",
            serialNumber: "346-000-791",
            relaisVersion: "1.0.0",
            softPosVersion: "1.0.0",
            providerChannel: "02",
          };

          let config;

          if (form.payementTypeName === "Flousi") {
            config = {
              ...flousiConfig,
              description: form.cashInformation,
            };
          } else {
            config = {
              description: form.cashInformation,
            };
          }

          let payload = {
            payment_method_id: form.active,
            status: form.status,
            config: JSON.stringify(config),
            is_default: form.default,
          };
          console.log(payload);

          loading.value = true;

          paymentMethodsService
            .update(id, payload)
            .then((res) => {
              reactiveData.data = reactiveData.data.map((el) =>
                el.id !== id ? el : res.data
              );
              showSuccessNotification(t("Pos.UpdatePaymentSettings"));
            })
            .catch((err) => {
              //  console.log(err)
            })
            .finally(() => {
              // console.log('finish')
              loading.value = false;
              open.value = false;
              location.reload();
            });
        })
        .catch((error: any) => {
          // console.error('Validation error:', error);
        });
    };

    const convertBooleanToint = (bool: any) => {
      if (bool == true) {
        return 1;
      } else {
        return 0;
      }
    };

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t("Pos.TitleDeletePaymentSettings"),
        text: t("Pos.TextDeletePaymentSettings"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonText: t("Pos.Cancel"),
        cancelButtonColor: "#d33",
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deletePaymentMethod(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //  console.log("cancel delete ");
        }
      });
    };

    onMounted(() => {
      //getStore();
      getPaymentMethods();
      searchInput.value = document.querySelector(
        ".ant-table-filter-dropdown input"
      );
    });

    return {
      visiblePassword,
      handlePaymentType,
      updateModalWidth,
      modalWidth,
      searchWithFullParams,
      textDirection,
      locale,
      statusTranslation,
      afterOpenChange,
      handlePageChange,
      navigatePage,
      rules,
      t,
      columns,
      formRef,
      form,
      formatDisplayDate,
      formatName,
      reactiveData,
      reactiveData2,
      showAddDrawer,
      showDrawer,
      open,
      formatDate,
      showDeletePopup,
      visible,
      addPaymentMethod,
      updatePaymentMethod,
      formatDescription,
      isDisabled,
      closePopup,
      currentPage,
      perPage,
      afterOpenShowChange,
      openDisplay,
      showDisplayDrawer,
      loading,
      handleSearch,
      handleReset,
      searchInput,
      hideForm,
      getDescriptionFromConfig,
      resetFields,
    };
  },
});
</script>
      
<style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.test-btn {
  color: #bfffca8a;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.breadcrumb-icon {
  cursor: pointer;
}

.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 8px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-rtl {
  direction: rtl;
}

.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}

.check-input {
  display: flex;
  align-items: flex-end;
}

.config-title {
  font-weight: 600;
  font-size: 18px;
}

.title-check{
  margin-left: 4px;
}


@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}
</style>