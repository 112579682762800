<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.Customer") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer(true)">
      {{ t("Pos.Add") }}

      <template #icon>
        <UserOutlined />
      </template>
    </a-button>
  </div>
  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title> {{ t("Pos.View") }}</template>
              <a-button
                class="view-btn"
                type="primary"
                @click="showDisplayDrawer(record)"
              >
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title> {{ t("Pos.Edit") }}</template>

              <a-button type="primary" @click="() => showDrawer(record)">
                <template #icon>
                  <EditOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title> {{ t("Pos.Delete") }}</template>
              <a-button type="primary" danger @click="showDeletePopup(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template v-if="column.key == 'firstname'">
          {{ formatNameConcat(record) }}
        </template>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>
    </a-table>

    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        :total="form.customLastPage"
        @change="handlePageChange"
        :default-page-size="perPage"
        :showSizeChanger="false"
      />
      <a-select
        class="select-input"
        v-model:value="customPagination"
        @change="choosePerPage"
        v-if="form.customLastPage > 10"
      >
        <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
      </a-select>
    </div>
    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
      @close="resetUpdate"
    >
      <template #title>
        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.actions == 'update'"
        >
          <span>{{ t("Pos.UpdateWareCustomerDrawer") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.actions == 'update'"
        >
          <span>{{ t("Pos.UpdateWareCustomerDrawer") }}</span>
        </div>
      </template>
      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="first_name">
              <template #label>
                <span class="label-form">{{ t("Pos.FisrtName") }}</span>
              </template>

              <a-input
                v-model:value="form.first_name"
                :placeholder="t('Pos.FisrtName')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="last_name">
              <template #label>
                <span class="label-form">{{ t("Pos.LastName") }}</span>
              </template>

              <a-input
                v-model:value="form.last_name"
                :placeholder="t('Pos.LastName')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="email">
              <template #label>
                <span class="label-form">{{ t("Pos.Email") }}</span>
              </template>
              <a-input
                v-model:value="form.email"
                :placeholder="t('Pos.Email')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="phone">
              <template #label>
                <span class="label-form">{{ t("Pos.Phone") }}</span>
              </template>

              <a-input
                v-model:value="form.phone"
                :placeholder="t('Pos.Phone')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="address">
              <template #label>
                <span class="label-form">{{ t("Pos.Address") }}</span>
              </template>
              <a-input
                v-model:value="form.address"
                :placeholder="t('Pos.Address')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="company_name">
              <template #label>
                <span class="label-form">{{ t("Pos.CompanyName") }} </span>
              </template>

              <a-input
                v-model:value="form.company_name"
                :placeholder="t('Pos.CompanyName')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="vat_id">
              <template #label>
                <span class="label-form">{{ t("Pos.VATNumber") }} </span>
              </template>
              <a-input
                v-model:value="form.vat_id"
                :placeholder="t('Pos.VATNumber')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="contact_name">
              <template #label>
                <span class="label-form">{{ t("Pos.ContactName") }}</span>
              </template>

              <a-input
                v-model:value="form.contact_name"
                :placeholder="t('Pos.ContactName')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="billing_address">
              <template #label>
                <span class="label-form">{{ t("Pos.BillingAddress") }} </span>
              </template>
              <a-input
                v-model:value="form.billing_address"
                :placeholder="t('Pos.BillingAddress')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="shipping_address">
              <template #label>
                <span class="label-form">{{ t("Pos.ShippingAddress") }} </span>
              </template>
              <a-input
                v-model:value="form.shipping_address"
                :placeholder="t('Pos.ShippingAddress')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item name="default">
              <a-checkbox v-model:checked="form.default"
                >{{ t("Pos.CustomerFreeOrder") }}
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div :class="{ 'is-rtl': textDirection === 'rtl' }">
          <a-space>
            <a-button type="primary" @click="onSubmitUpdate(form.id)">{{
              t("Pos.Update")
            }}</a-button>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>

    <!-- Form Details -->

    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenShowChange"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.DetailsCustomerDrawer") }}</span>
        </div>

        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.DetailsCustomerDrawer") }}</span>
        </div>
      </template>
      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <template #header>
          <div class="custom-header">{{ t("Pos.GeneralInformations") }}</div>
        </template>

        <a-row :gutter="16">
          <a-col :span="24">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.FisrtName") }} :</span
              >
              {{ form.first_name }}
            </p>
          </a-col>

          <a-col :span="24">
            <p>
              <span class="info-title">{{ t("Pos.LastName") }} :</span>
              {{ form.last_name }}
            </p>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <p>
              <span class="info-title">{{ t("Pos.Email") }} :</span>
              {{ form.email }}
            </p>
          </a-col>

          <a-col :span="24">
            <p>
              <span class="info-title">{{ t("Pos.Phone") }} :</span>
              {{ form.phone }}
            </p>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.ContactName") }} :</span
              >
              {{ form.contact_name }}
            </p>
          </a-col>

          <a-col :span="24">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.VATNumber") }}:
              </span>
              {{ form.vat_id }}
            </p>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.Address") }} :</span
              >
              {{ form.address }}
            </p>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.BillingAddress") }} :</span
              >
              {{ form.billing_address }}
            </p>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.ShippingAddress") }} :</span
              >
              {{ form.shipping_address }}
            </p>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <p>
              <span class="info-title"
                >{{ t("Pos.CustomerFreeOrder") }} :
              </span>
              <template v-if="form.StringDefault === 'yes'">
                <check-circle-two-tone two-tone-color="#52c41a" />
              </template>
              <template v-if="form.StringDefault === 'no'">
                <close-circle-two-tone two-tone-color="#ff4d4f" />
              </template>
            </p>
          </a-col>
        </a-row>
      </a-form>

      <template #footer> </template>
    </a-drawer>

    <!--end Form details-->
    <a-drawer
      v-model:open="openAdd"
      @close="resetAdd"
      class="custom-class"
      root-class-name="root-class-name"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenAddChange"
    >
      <template #title>
        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.actions == 'add'"
        >
          <span>{{ t("Pos.AddCustomerDrawer") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.actions == 'add'"
        >
          <span>{{ t("Pos.AddCustomerDrawer") }}</span>
        </div>
      </template>

      <a-form
        ref="addFormRef"
        :model="addForm"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="first_name">
              <template #label>
                <span class="label-form">{{ t("Pos.FisrtName") }}</span>
              </template>

              <a-input
                v-model:value="addForm.first_name"
                :placeholder="t('Pos.FisrtName')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="last_name">
              <template #label>
                <span class="label-form">{{ t("Pos.LastName") }}</span>
              </template>

              <a-input
                v-model:value="addForm.last_name"
                :placeholder="t('Pos.LastName')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="email">
              <template #label>
                <span class="label-form">{{ t("Pos.Email") }}</span>
              </template>
              <a-input
                v-model:value="addForm.email"
                :placeholder="t('Pos.Email')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="phone">
              <template #label>
                <span class="label-form">{{ t("Pos.Phone") }}</span>
              </template>

              <a-input
                v-model:value="addForm.phone"
                :placeholder="t('Pos.Phone')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="address">
              <template #label>
                <span class="label-form">{{ t("Pos.Address") }}</span>
              </template>
              <a-input
                v-model:value="addForm.address"
                :placeholder="t('Pos.Address')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="company_name">
              <template #label>
                <span class="label-form">{{ t("Pos.CompanyName") }}</span>
              </template>

              <a-input
                v-model:value="addForm.company_name"
                :placeholder="t('Pos.CompanyName')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="vat_id">
              <template #label>
                <span class="label-form">{{ t("Pos.VATNumber") }}</span>
              </template>
              <a-input
                v-model:value="addForm.vat_id"
                :placeholder="t('Pos.VATNumber')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="contact_name">
              <template #label>
                <span class="label-form">{{ t("Pos.ContactName") }}</span>
              </template>

              <a-input
                v-model:value="addForm.contact_name"
                :placeholder="t('Pos.ContactName')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="billing_address">
              <template #label>
                <span class="label-form">{{ t("Pos.BillingAddress") }}</span>
              </template>
              <a-input
                v-model:value="addForm.billing_address"
                :placeholder="t('Pos.BillingAddress')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="shipping_address">
              <template #label>
                <span class="label-form">{{ t("Pos.ShippingAddress") }}</span>
              </template>
              <a-input
                v-model:value="addForm.shipping_address"
                :placeholder="t('Pos.ShippingAddress')"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item name="default">
              <a-checkbox v-model:checked="addForm.default"
                >{{ t("Pos.CustomerFreeOrder") }}
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div :class="{ 'is-rtl': textDirection === 'rtl' }">
          <a-space>
            <a-button type="primary" @click="onSubmitAdd">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="resetAdd">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>
  </div>
</template>
  
  <script lang="ts">
import { notification } from "ant-design-vue";
import { useRouter } from "vue-router";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import type { Rule } from "ant-design-vue/es/form";
import { useI18n } from "vue-i18n";
import { customerService } from "@/_services";
import Swal from "sweetalert2";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
  UserOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "indexCustomer",
  components: {
    UserOutlined,
    EyeOutlined,
    DeleteOutlined,
    SearchOutlined,
    EditOutlined,
    LbrxLoaderSpinner,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
  },

  setup() {
    const modalWidth = ref(720);
    const customPagination = ref("10");
    const { t } = useI18n();
    const { locale } = useI18n();
    const open = ref<boolean>(false);
    const openAdd = ref<boolean>(false);
    const addFormRef = ref();
    const formRef = ref();

    const loading = ref(false);
    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };

    const reactiveData = reactive({
      searchText: "",
      searchedColumn: "",
      actions: "",
      data: [] as any[],
      totalPages: 0,
    });
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const rules: Record<string, Rule[]> = {
      first_name: [{ required: true, message: t("Pos.firstNameVerification") }],
      last_name: [{ required: true, message: t("Pos.lastnameVerification") }],
    };
    const form = reactive({
      id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      company_name: "",
      vat_id: "",
      contact_name: "",
      billing_address: "",
      shipping_address: "",
      website: "",
      loyalty_points: "",
      sales: "",
      created_at: "",
      searchTextInput: "",
      default: "",
      StringDefault: "",
      customLastPage: 0,
    });

    const addForm = reactive({
      name: "",
      email: "",
      phone: "",
      address: "",
      company_name: "",
      vat_id: "",
      contact_name: "",
      billing_address: "",
      shipping_address: "",
      reactive: "",
      first_name: "",
      last_name: "",
      default: 0,
    });

    const resetForm = () => {
      form.id = "";
      form.first_name = "";
      form.last_name = "";
      form.email = "";
      form.phone = "";
      form.address = "";
      form.company_name = "";
      form.vat_id = "";
      form.contact_name = "";
      form.billing_address = "";
      form.shipping_address = "";
    };

    const searchInput = ref<HTMLInputElement | null>(null);

    const columns = [
      {
        title: t("Pos.FullName"),
        key: "firstname",
        dataIndex: "firstname",
        customFilterDropdown: true,
        onFilter: (value: string, record1: any) =>
          record1.first_name.toString() + record1.last_name.toString(),
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector(
                ".ant-table-filter-dropdown input"
              );

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },
      {
        title: t("Pos.Email"),
        key: "email",
        dataIndex: "email",
      },
      {
        title: t("Pos.Phone"),
        key: "phone",
        dataIndex: "phone",
      },
      {
        title: t("Pos.CompanyName"),
        key: "company_name",
        dataIndex: "company_name",
      },
      {
        title: t("Pos.Actions"),
        dataIndex: "actions",
        key: "actions",
      },
    ];

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    const onSubmitAdd = () => {
      addFormRef.value
        .validate()
        .then(() => {
          createCustomer();
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };

    const onSubmitUpdate = (id: string) => {
      formRef.value
        .validate()
        .then(() => {
          updateCustomer(id);
        })
        .catch((error: any) => {
          //console.log('error', error);
        });
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t("Pos.DeleteCustomer"),
        text: t("Pos.TextDeleteCustomer"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonColor: "#d33",
        cancelButtonText: t("Pos.Cancel"),
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteCustomer(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //console.log("cancel delete ");
        }
      });
    };

    const getAllCustomers = () => {
      loading.value = true;
      customerService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
          form.customLastPage = res.meta.last_page * 10;
          console.log("test", form.customLastPage);
        })
        .catch((error) => {
          //console.log("error api : get get customers", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getById = (id: string) => {
      customerService.show(id).then((res) => {
        form.first_name = res.data.first_name;
        form.last_name = res.data.last_name;
        form.email = res.data.email;
        form.phone = res.data.phone;
        form.address = res.data.address;
        form.company_name = res.data.company_name;
        form.vat_id = res.data.vat_id;
        form.contact_name = res.data.contact_name;
        form.billing_address = res.data.billing_address;
        form.shipping_address = res.data.shipping_address;
        form.loyalty_points = res.data.loyalty_points;
        form.created_at = res.data.created_at;
        form.sales = res.data.sales;
        form.id = res.data.id;
        form.default = res.data.can_get_free_orders;
        if (form.default == "1") {
          form.StringDefault = "yes";
        } else {
          form.StringDefault = "no";
        }
        console.log("form.default", form.default);
      });
    };

    const choosePerPage = () => {
      currentPage.value = 1;
      // currentPage.value = newPage;
      loading.value = true;
      //console.log('per page', perPage.value);
      //console.log('page', currentPage.value)
      customerService
        .searchWithFullParams(
          "name",
          reactiveData.searchText,
          currentPage.value,
          customPagination.value
        )
        .then((response) => {
          //console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;

          console.log("lastPage", form.customLastPage);
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;
      loading.value = true;
      //console.log('per page', perPage.value);
      console.log("page is ", customPagination.value);
      customerService
        .searchWithFullParams(
          "name",
          reactiveData.searchText,
          currentPage.value,
          customPagination.value
        )
        .then((response) => {
          //console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const getCurrentPage = (newPage: any) => {
      currentPage.value = newPage;
    };
    const handleSearch = (
      selectedKeys: any[],
      confirm: () => void,
      dataIndex: string
    ) => {
      currentPage.value = 1;
      confirm();
      console.log("hné", selectedKeys[0]);
      reactiveData.searchText = selectedKeys[0];
      searchWithFullParams(selectedKeys[0]);
      reactiveData.searchedColumn = dataIndex;
    };

    const searchWithFullParams = (selectedText: any) => {
      loading.value = true;

      customerService
        .searchWithFullParams(
          "name",
          selectedText,
          currentPage.value,
          customPagination.value
        )
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
          form.customLastPage = res.meta.last_page * 10;
        })
        .catch((error) => {
          //console.log("error api : get warehouses", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    const createCustomer = () => {
      let payload = {
        first_name: addForm.first_name,
        last_name: addForm.last_name,
        email: addForm.email,
        phone: addForm.phone,
        address: addForm.address,
        company_name: addForm.company_name,
        vat_id: addForm.vat_id,
        contact_name: addForm.contact_name,
        billing_address: addForm.billing_address,
        shipping_address: addForm.shipping_address,
        can_get_free_orders: addForm.default,
      };
      loading.value = true;
      customerService
        .store(payload)
        .then((res) => {
          reactiveData.data.push(res.data);
          showSuccessNotification(t("Pos.AddCustomer"));
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          // resetAdd();
          showAddDrawer(false);
          loading.value = false;
        });
    };

    const updateCustomer = (id: string) => {
      let payload = {
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        phone: form.phone,
        address: form.address,
        company_name: form.company_name,
        vat_id: form.vat_id,
        contact_name: form.contact_name,
        billing_address: form.billing_address,
        shipping_address: form.shipping_address,
        can_get_free_orders: form.default,
      };
      loading.value = true;

      customerService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
          console.log("value here ", form.default);
          showSuccessNotification(t("Pos.UpdateCustomer"));
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          // resetAdd();
          open.value = false;
          loading.value = false;
        });
    };

    const deleteCustomer = (id: string) => {
      loading.value = true;
      customerService
        .delete(id)
        .then(() => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification(t("Pos.DeleteCustomer"));
          location.reload();
          //getAllCustomers();
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          //console.log('finish')
          loading.value = false;
        });
    };

    const handleMoreActions = (record: any) => {
      //console.log('More actions', record);
    };

    //drawer
    const afterOpenChange = (bool: boolean) => {
      // console.log('open', bool);
    };

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      openDisplay.value = true;
      getById(record.id);
    };

    const formatNameConcat = (dateString: any) => {
      if (dateString && dateString.first_name && dateString.last_name) {
        return dateString.first_name + " " + dateString.last_name;
      } else {
        return "";
      }
    };

    const showDrawer = (record: any) => {
      //console.log('record:', record);

      /*   open.value = true;
        form.id = record.id;
        form.first_name = record.first_name;
        form.last_name = record.last_name;
        form.email = record.email;
        form.phone = record.phone;
        form.address = record.address;
        form.vat_id = record.vat_id;
        form.contact_name = record.contact_name;
        form.company_name = record.company_name;
        form.billing_address = record.billing_address;
        form.shipping_address = record.shipping_address;*/
      updateModalWidth();
      open.value = true;
      getById(record.id);
      resetForm();
      reactiveData.actions = "update";
    };

    const showAddDrawer = (state: boolean) => {
      updateModalWidth();
      openAdd.value = state;
      reactiveData.actions = "add";
    };

    const afterOpenAddChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    onMounted(() => {
      getAllCustomers();
      searchInput.value = document.querySelector(
        ".ant-table-filter-dropdown input"
      );
    });

    const handleReset = (clearFilter: (arg: any) => void) => {
      currentPage.value = 1;
      clearFilter({ confirm: true });
      reactiveData.searchText = "";
      form.searchTextInput = "";
      customPagination.value = "10";
      getAllCustomers();
      console.log(customPagination);
    };

    const resetAdd = () => {
      addFormRef.value.resetFields();
      openAdd.value = false;
    };

    const resetUpdate = () => {
      formRef.value.resetFields();
    };

    const hideForm = () => {
      openDisplay.value = false;
      open.value = false;
      openAdd.value = false;
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };

    return {
      updateModalWidth,
      modalWidth,
      resetForm,
      t,
      searchWithFullParams,
      loading,
      perPage,
      currentPage,
      handlePageChange,
      showDisplayDrawer,
      afterOpenShowChange,
      openDisplay,
      showSuccessNotification,
      columns,
      handleMoreActions,
      searchInput,
      open,
      afterOpenChange,
      afterOpenAddChange,
      showAddDrawer,
      openAdd,
      showDrawer,
      reactiveData,
      handleSearch,
      handleReset,
      form,
      rules,
      addForm,
      formRef,
      addFormRef,
      onSubmitAdd,
      resetAdd,
      onSubmitUpdate,
      showDeletePopup,
      hideForm,
      navigatePage,
      formatDisplayDate,
      resetUpdate,
      getCurrentPage,
      formatNameConcat,
      textDirection,
      locale,
      choosePerPage,
      customPagination,
    };
  },
  methods: {},
});
</script>
  
  <style scoped>
.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.eye-icon {
  font-size: 24px;
  padding: 0;
}

.view-btn {
  background: #1b6a2b;
}

.delete-btn {
  background: #ff4d4f;
}

/* drawer */
.btn-save {
  color: #1b6a2b;
  border: 2px solid #1b6a2b;
  box-shadow: inset 0 0 0 0 #1b6a2b;
}

.btn-save:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #1b6a2b;
}

.btn-save:active {
  transform: scale(0.9);
}

.btn-cancel {
  color: #ff4d4f;
  border: 2px solid #ff4d4f;
  box-shadow: inset 0 0 0 0 #ff4d4f;
}

.btn-cancel:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #ff4d4f;
}

.btn-cancel:active {
  transform: scale(0.9);
}

.drawer-btn {
  position: relative;
  display: inline-block;
  margin: 15px;
  padding: 7px 10px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 1px;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border-radius: 10px;
}

/* drawer */

.action-btn {
  font-family: inherit;
  font-size: 14px;
  color: white;
  padding: 0.5em 0.7em;
  padding-left: 0;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  margin-right: 5px;
}

.action-btn span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.action-btn .eye-icon {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
  transform: scale(0.7);
}

.action-btn:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.action-btn:hover .eye-icon {
  transform: translateX(1em) rotate(45deg) scale(0.8);
}

.action-btn:hover span {
  transform: translateX(3em);
}

/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn:active {
  transform: scale(0.95);
}

.drawer-footer {
  display: flex;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
 
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}
.input-form {
  color: black;
  background-color: white;
}

.breadcrumb-icon {
  cursor: pointer;
}

.is-rtl {
  direction: rtl;
}

.select-input {
  width: 100%;
}

.ingredient-des {
  margin-bottom: 15px;
}

.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.table-container {
  overflow-x: auto;
}

.paginationArrows {
  direction: ltr;
  margin-bottom: 16px;
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}


/*meduim*/
@media (min-width: 768px) {

  .pagination {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .select-input {
    width: 40%;
  }

  
}

/*large*/
@media (min-width: 1024px) {

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .select-input {
    width: 20%;
  }
    

}

</style>