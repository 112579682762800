<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{ t("Pos.Dashboard") }}
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{ t("Pos.Menu") }}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    
    <div class="btn-container">
        <a-button class="add-btn" @click="openAddMenu">
        {{ t("Pos.Add") }}
        </a-button>
    </div>

    <a-table 
        class="table-container" 
        :columns="columns" 
        :data-source="state.data"
        :pagination="false" 
    >
        <template #emptyText>
            <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
        </template>

        <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'actions'">
                <a-space>
                    <a-tooltip>
                        <template #title>{{ t("Pos.View") }}</template>
                        <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                        <template #icon>
                            <EyeOutlined />
                        </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip>
                        <template #title>{{ t("Pos.Edit") }}</template>

                        <a-button type="primary" @click="() => showDrawer(record)">
                        <template #icon>
                            <EditOutlined />
                        </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip>
                        <template #title>{{ t("Pos.Delete") }}</template>
                        <a-button type="primary" danger @click="showDeletePopup(record)">
                        <template #icon>
                            <DeleteOutlined />
                        </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip>
                        <template #title>{{ t("Pos.Menu") }}</template>
                        <a-button class="variant-btn" @click="goToMenu(record.id)">
                        <template #icon>
                            <BookOutlined />
                        </template>
                        </a-button>
                    </a-tooltip>
                </a-space>

                
            </template>

            <template v-if="column.key === 'location'">
                {{ record.location.name }}
            </template>
        </template>

        <!-- search -->
        <template
            #customFilterDropdown="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
            }"
        >
            <div style="padding: 8px">
                <template v-if="column.key === 'invoice_number'">
                    <a-input
                        ref="searchInput"
                        :placeholder="`${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="
                        (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                        "
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    />
                </template>

                <template v-if="column.key === 'warehouse_id'">
                    <div class="search-btn-radio">
                        <a-radio-group
                        v-model:value="value"
                        @change="selectedWarehouse"
                        class="search-radio-grp"
                        >
                        <a-radio
                            v-for="item in state.warehouses"
                            :label="item.name"
                            :value="item.id"
                            :key="item"
                            class="radio-item"
                            >{{ item.name }}</a-radio
                        >
                        </a-radio-group>
                    </div>
                </template>

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t("Pos.Search") }}
                </a-button>
                <a-button
                    size="small"
                    style="width: 90px"
                    @click="handleReset(clearFilters)"
                >
                {{ t("Pos.Reset") }}
                </a-button>
            </div>
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>
    <div class="pagination">
        <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        v-model:pageSize="perPage"
        :total="state.totalPages"
        :default-page-size="perPage"
        :showSizeChanger="false"
        @change="handlePageChange"
        />

        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages > 10"
            class="select-input"
        >
            <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
            <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
            <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
            <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
        </a-select>
    </div>

    

    

    <!-- add drawer -->
    <a-drawer 
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        @close="resetFormAdd"
    >

        <template #title>
            <p :class="{ 'is-rtl': textDirection === 'rtl' }">
                Add menu
            </p>
        </template>

        <a-form
            ref="addFormRef"
            :model="addForm"
            :rules="rules"
            layout="vertical"
            :class="{ 'is-rtl': textDirection === 'rtl' }"
        >

            <a-row>
                <a-col :span="24">
                    <a-form-item name="name">
                        <template #label>
                            <span class="label-form">{{t('Pos.Name')}}</span>
                        </template>
                        <a-input
                            v-model:value="addForm.name"
                            :placeholder="t('Pos.Name')"
                            class="input-form"
                        />
                    </a-form-item>
                </a-col>
            </a-row>


            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item name="description">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Description') }}</span>
                  </template>
                  <a-textarea v-model:value="addForm.description" :rows="4" :placeholder="t('Pos.Description')" />
                </a-form-item>
              </a-col>
            </a-row>


            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item name="location_id">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Location') }}</span>
                        </template>

                        <a-select
                            v-model:value="addForm.location_id"
                            show-search
                            :option-filter-prop="'label'"
                            mode="multiple"
                            :placeholder="t('Pos.FetchLocation')"
                        >

                            <template #notFoundContent>
                                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option
                                v-for="location in state.locations"
                                :label="location.name"
                                :key="location.id"
                                :value="location.id"
                                :selected="location.id === addForm.location_id"
                            >
                                {{ location.name }}
                            </a-select-option>

                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="24" >
                    <a-form-item name="categories">
                        <template #label>
                            <span class="label-form"> {{t('Pos.Categories')}} </span>
                        </template>

                        <a-select
                            v-model:value="state.categoryValue"
                            mode="multiple"
                            label-in-value
                            :placeholder="t('Pos.FetchCategory')"
                            style="width: 100%"
                            :filter-option="false"
                            :not-found-content="state.fetchingCat ? undefined : null"
                            :options="state.catOptions"
                            @search="fetchCategory"
                            @change="handleCatSelect"
                        >
                            <template v-if="state.fetchingCat" #notFoundContent>
                                <a-spin size="small" />
                            </template>
                        </a-select>
                           


                    </a-form-item>
                </a-col>
            </a-row>

            

        </a-form>

        <template #footer>
            <a-space>
                <a-button type="primary" @click="add">{{ t("Pos.Add") }}</a-button>
                <a-button type="primary" danger @click="resetFormAdd">{{
                t("Pos.Cancel")
                }}</a-button>
            </a-space>
        </template>



    </a-drawer>

    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
    >
        <template #title>
        <p :class="{ 'is-rtl': textDirection === 'rtl' }">
            {{ t("Pos.MenuInfo") }}
        </p>
        </template>

        <a-form
            ref="formRef"
            :model="form"
            :rules="rules"
            layout="vertical"
            :class="{ 'is-rtl': textDirection === 'rtl' }"
        >
        <a-row>
            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t("Pos.Name") }} :</span>
                    {{ form.name }}
                </p>
            </a-col>

            <a-col :span="12">
                <p>
                    <span class="info-title">{{ t("Pos.Location") }} :</span>
                    {{ form.location }}
                </p>
            </a-col>
        </a-row>

        <a-row>
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t("Pos.Description") }} :</span>
                    {{ form.description }}
                </p>
            </a-col>
        </a-row>

        <a-row>
            <a-col :span="24">

                <div class="cat-display">
                    <span class="info-title">{{ t("Pos.Categories") }} :</span>

                    <div class="cats">
                        <span v-for="(cat, index) in form.categories" :key="cat.id" class="choice">
                            {{ cat.name }}
                            <span v-if="index < form.categories.length - 1" class="column-span">, </span>

                        </span>
                    </div>
                   
                </div>

            </a-col>
        </a-row>


      

        

      
        </a-form>
    </a-drawer>

    <!-- update drawer -->
    <a-drawer
        v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :width="modalWidth"
        :placement="locale === 'ar' ? 'left' : 'right'"
    >
        <template #title>
            <p :class="{ 'is-rtl': textDirection === 'rtl' }">
                {{ t("Pos.UpdateMenu") }}
            </p>
        </template>

        <a-form
            ref="formRef"
            :model="form"
            :rules="rules"
            layout="vertical"
            :class="{ 'is-rtl': textDirection === 'rtl' }"
        >
            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item name="name">
                        <template #label>
                        <span class="label-form">{{ t("Pos.Name") }}</span>
                        </template>
                        <a-input
                        v-model:value="form.name"
                        :placeholder="t('Pos.Name')"
                        class="input-form"
                        
                        />
                    </a-form-item>
                </a-col>

                
            </a-row>

            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item name="description">
                        <template #label>
                            <span class="label-form">{{ t("Pos.Description") }}</span>
                        </template>
                        <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Pos.Description')" />
                    </a-form-item>
                </a-col>

            </a-row>

            <a-row>

                <a-col :span="24">
                    <a-form-item  name="location">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Location') }}</span>
                        </template>

                        <a-select
                            v-model:value="form.location"
                            mode="multiple"
                            label-in-value
                            :placeholder="t('Pos.Location')"
                            style="width: 100%"
                            :filter-option="false"
                            :not-found-content="state.fetchingUpdate ? undefined : null"
                            :options="filteredUsersUpdate"
                            @search="fetchLocationUpdate"
                            @change="handleLocationUpdateSelect"
                        >
                            <template v-if="state.fetchingUpdate" #notFoundContent>
                                <a-spin size="small" />
                            </template>
                        </a-select>
                        
                    </a-form-item>

                </a-col>

                <a-col :span="24">

                    <a-form-item  name="categories">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Categories') }}</span>
                        </template>

                        <a-select
                            v-model:value="form.categories"
                            mode="multiple"
                            label-in-value
                            :placeholder="t('Pos.FetchCategory')"
                            style="width: 100%"
                            :filter-option="false"
                            :not-found-content="state.fetchingUpdate ? undefined : null"
                            :options="filteredCatsUpdate"
                            @search="fecthCatsUpdate"
                            @change="handleCatUpdateSelect"
                        >
                            <template v-if="state.fetchingUpdate" #notFoundContent>
                                <a-spin size="small" />
                            </template>
                        </a-select>
                            
                    </a-form-item>


                </a-col>
            </a-row>
            
        </a-form>
        <template #footer>
            <a-space>
                <a-button type="primary" @click="update(form.id)">{{
                t("Pos.Update")
                }}</a-button>
                <a-button type="primary" danger @click="cancelUpdate">{{
                t("Pos.Cancel")
                }}</a-button>
            </a-space>
        </template>
    </a-drawer>

    
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { ref, defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import type { Rule } from "ant-design-vue/es/form";
import dayjs, { Dayjs } from "dayjs";
import { notification } from "ant-design-vue";
import Swal from "sweetalert2";
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  BookOutlined,
} from "@ant-design/icons-vue";
import { Empty } from "ant-design-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";

import { categoryService, menuService, tableLocationService } from "@/_services";
export default defineComponent({
    name: "menusIndex",
    components:{ 
        LbrxLoaderSpinner,
        SearchOutlined,
        DeleteOutlined,
        EyeOutlined,
        EditOutlined,
        BookOutlined

    },
    setup() {

        const router = useRouter();
        const navigatePage = (link: string) => {
        router.push(link); 
        };

        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const loading = ref(false);
        const { t } = useI18n();

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === "ar" ? "rtl" : "ltr";
        });

        const state = reactive({
            
            data: [] as any[],
            totalPages: 0,
            beginSearch: false,
          
            locations: [] as any[],

            categoryValue : [] as any[],
            fetchingCat:false,
            catOptions : [] as any[],

            valueUpdate: []as any[],
            fetchingUpdate:false,
            catsUpdate:[] as any[],


        });

        
        const columns = [
        {
            title: t("Pos.Name"),
            key: "name",
            dataIndex: "name",
        },
        {
            title: t("Pos.Location"),
            key: "location",
            dataIndex: "location",
        },
        
        {
            title: t("Pos.Actions"),
            key: "actions",
            dataIndex: "actions",
        }
        ];

        const showSuccessNotification = (message: string) => {
            notification.success({
                message: message,
                duration: 3,
                top: "80px",
                style: {
                backgroundColor: "#bfffca8a",
                color: "white",
                },
            });
        };

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref("10");

        const handleSelectPaginationChange = (selectedItem: any) => {
            perPage.value = Number.parseInt(selectedItem);
            currentPage.value = 1;

            if (state.beginSearch === true) {
                console.log('filter paginate');
            } else {
                loading.value = true;
                menuService
                .getAllWithPagination(currentPage.value, perPage.value)
                .then((response) => {
                    state.data = response.data;
                    state.totalPages = response.total;
                })
                .catch((error) => {
                    console.log("error api", error);
                })
                .finally(() => {
                    loading.value = false;
                });
            }
        };

        const handlePageChange = (newPage: any) => {
            currentPage.value = newPage;

            console.log("per page", perPage.value);
            console.log("page", currentPage.value);

            if (state.beginSearch === true) {
                state.data = [];
                
            } else {
                loading.value = true;
                menuService
                .getAllWithPagination(currentPage.value, perPage.value)
                .then((response) => {
                    state.data = response.data;
                    state.totalPages = response.total;
                })
                .catch((error) => {
                    console.log("error api", error);
                })
                .finally(() => {
                    loading.value = false;
                });
            }
        };


        //form
        const addFormRef = ref();

        const addForm= reactive({
            categories:[] as any[],
            location_id:[] as any[],
            name:'',
            description:'',
        });

        const formRef = ref();

        const form = reactive({
            id: "",
            name: "",
            description: "",
            categories: [],
            location: "",
            location_id:'',
            allLocations:[] as any[],
            allCategories:[] as any[],
            cat_id:"",
            cat_ids:"",


        });




        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t("Pos.RequiredField") }],
            location_id: [{ required: true, message: t("Pos.RequiredField") }],
            categories: [{ required: true, message: t("Pos.RequiredField") }],
        };  
        
        const resetFormAdd=()=>{
            addFormRef.value.resetFields();
            state.categoryValue=[];
            state.catOptions=[];
            state.fetchingCat=false;
        }

        const getLocations=()=>{
            tableLocationService.getAll().then((res)=>{
                state.locations=res.data;
            }).catch((error)=>{
                console.log('error geting table locations', error);
            })
        }


        //add drawer

        const openAdd = ref<boolean>(false);
        const openAddMenu =()=>{
            updateModalWidth();
            getLocations();
            openAdd.value=true;
        }

        //fetch categories
        
        let debounceTimer: ReturnType<typeof setTimeout> | null = null;

        const fetchCategory = (value: any) => {

            
            //disable fetching when the input is empty
            if(value===''){
                return;
            }


            state.catOptions = [];
           
            state.fetchingCat = true;


            //clear previous timer
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }


            //fetch categories 

            debounceTimer = setTimeout(() => {
                categoryService.filter("name", value)
                .then((res) => {
                    const data = res.data.map((cat: { name: any; id: any; }) => ({
                        label: `${cat.name}`,
                        value: cat.id,
                    }));

                    
                    state.catOptions = data;

                    
                    state.fetchingCat = false;

                    // if(data.length===0){
                    //     console.log('cat not found', data);
                    //     state.catOptions.push({
                    //         label: value,
                    //         value: value,
                    //     });
                    // }
                    

                    console.log('state.catOptions : ', state.catOptions);


                })
                .catch((error) => {
                    console.log('error fetching categories', error);
                });
            }, 700);





           
             

        };
     
        watch(state.categoryValue, () => {
            state.catOptions = [];
            state.fetchingCat = false;
        });
        

        const handleCatSelect=(value: any[])=>{
            console.log('selected category', value);
            addForm.categories=value;
        }

        const add=()=>{
            addFormRef.value.validateFields().then(()=>{
                console.log('add');

                const payload ={
                    name:addForm.name,
                    description:addForm.description,
                    table_location_id:addForm.location_id.map(id => id.toString()).join(',') ,
                    categories:state.categoryValue.map(cat => cat.value).join(',') 
                }
                console.log('payload', payload);

                
                loading.value=true;
                menuService.create(payload).then(()=>{

                    showSuccessNotification(t('Pos.MenuAddSuccess'));
                    getAll();


                }).catch((error)=>{
                    console.log('error adding menu', error);
                }).finally(()=>{
                    loading.value=false;
                    openAdd.value=false;
                    resetFormAdd();
                    state.catOptions=[];
                    state.categoryValue=[];
                    state.fetchingCat=false;
                
                })

                
                

                

                
            }).catch((error:any)=>{
                console.log('error validation fiels', error);
            });
        }




        //delete 
        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteMenu'),
                text: t('Pos.TextDeleteMenu'),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t("Pos.Delete"),
                cancelButtonText: t("Pos.Cancel"),
                cancelButtonColor: "#d33",
                customClass: {
                popup: "swal2-popup",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteMenu(record.id)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log("cancel delete ");
                }
            });
        };
        const deleteMenu = (id: string) => {
            menuService
            .delete(id)
            .then(() => {
            getAll();
                showSuccessNotification(t("Pos.MenuDeleteSuccess"));
            })
            .catch((err) => {
            console.log(err);
            })
            .finally(() => {
            console.log("finish");
            });
        };




        const getAll = () => {
            loading.value = true;
            menuService
            .getAllWithPagination(currentPage.value, perPage.value)
            .then((response) => {
                state.data = response.data;
                state.totalPages = response.total;

                console.log('menus', state.data);
            })
            .catch((error) => {
                console.log("error get invoice purchase", error);
            })
            .finally(() => {
                loading.value = false;
            });
        };

        // display drawer
        const openDisplay = ref<boolean>(false);

        const getById=(id:string)=>{
            menuService.show(id).then((res)=>{
                form.id=res.data.id;
                form.name=res.data.name;
                form.description=res.data.description;
                form.categories = res.data.categories.map((cat: any) => ({
                    label: cat.name,
                    value: cat.id,
                }));
                form.location=res.data.location.name;
                form.location_id=res.data.location.id;
            });
        }


        const showDisplayDrawer = (record: any) => {
            updateModalWidth();
            openDisplay.value = true;
            getById(record.id);
        };


        //update drawer
        const open = ref<boolean>(false);
        const showDrawer = (record: any) => {
            updateModalWidth();
            getById(record.id);
            open.value = true;
        };

        const update=(id:string)=>{
            formRef.value.validate().then(()=>{
                const payload={
                    name:form.name,
                    description:form.description,
                    table_location_id:form.location_id,
                    //table_location_id:form.location_id.map(id => id.toString()).join(',') ,
                    categories:form.cat_ids, 
                    

                }
                loading.value=true;
                menuService.update(id,payload).then((res)=>{
                    showSuccessNotification(t('Pos.MenuUpdateSuccess'));
                }).catch((error)=>{
                    console.log('error update menu', error);
                }).finally(()=>{
                    loading.value=false;

                    open.value=false;
                    state.fetchingUpdate=false;
                    
                    form.cat_ids='';
                    state.catsUpdate=[];
                    

                })

                console.log('payload for update', payload)
            }).catch(()=>{
                console.log('error validation fields');
            })
        }

        const cancelUpdate = () => {
            open.value = false;
        };

        const goToMenu=(id:string)=>{
            localStorage.setItem('MenuId', id);
            router.push('/menu');
        }

        onMounted(()=>{
            getAll();
        });


        //update categories
        const filteredCatsUpdate = computed(() => {
            const selectedCatIds = form.cat_id;
            return state.catsUpdate.filter((cat:any) => !selectedCatIds.includes(cat.value));
        });

        let debounceTimerUpdate: ReturnType<typeof setTimeout> | null = null;

        const fecthCatsUpdate = (value: any) => {
            console.log('fetching cat', value);
            state.catsUpdate = [];
            state.fetchingUpdate = true;


            //clear previous timer
            if (debounceTimerUpdate) {
                clearTimeout(debounceTimerUpdate);
            }


            //fetch cats 

            debounceTimerUpdate = setTimeout(() => {
                categoryService.filter("name", value)
                .then((res) => {
                    const data = res.data.map((cat: { name: any;id: any; }) => ({
                        label: `${cat.name}`,
                        value: cat.id,
                    }));

                    state.catsUpdate = data;
                    state.fetchingUpdate = false;
                })
                .catch((error) => {
                    console.log('error fetching categories', error);
                });
            }, 500);
             

        };

        watch(state.valueUpdate, () => {
            state.catsUpdate = [];
            state.fetchingUpdate = false;
        });

        const handleCatUpdateSelect=(value: any[])=>{
            
            console.log('selected cat', value);

            
            const selectedCatId = value
                .map(item => parseInt(item.value)) 
                .filter(item => !isNaN(item))
                .join(',');

            console.log('selectedCatId', selectedCatId);  

            form.cat_ids=selectedCatId

            
        }

        
      




        return{
            add,
            locale,
            modalWidth,
            simpleImage,
            loading,
            t,
            addFormRef,
            addForm,
            rules,
            state,
            fetchCategory,
            handleCatSelect,
            resetFormAdd,
            openAdd,
            openAddMenu,
            textDirection,
            showDeletePopup,
            columns,
            openDisplay,
            showDisplayDrawer,
            form,
            formRef,
            selectPagination,
            handleSelectPaginationChange,
            handlePageChange,
            navigatePage,
            perPage, 
            currentPage,
            showDrawer,
            open,
            cancelUpdate,
            update,
            goToMenu,
            filteredCatsUpdate,
            handleCatUpdateSelect,
            fecthCatsUpdate,
            






        }
        
    },
})
</script>

<style scoped>
.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}


.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}


.cat-display{
    display: flex;
}

.cats{
    display: flex;
    flex-wrap: wrap;
}



.column-span{
    padding-right: 5px;
}


.variant-btn{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
}

.variant-btn:hover{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
}

/* CSS for RTL */
.is-rtl {
  direction: rtl;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}

</style>