import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e25bf598"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_5 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_6 = {
  key: 2,
  class: "drawer-header-ar"
}
const _hoisted_7 = {
  key: 3,
  class: "drawer-header-all"
}
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = { class: "label-form" }
const _hoisted_17 = { class: "label-form" }
const _hoisted_18 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_19 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_20 = { class: "custom-header" }
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = ["placeholder"]
const _hoisted_23 = ["placeholder"]
const _hoisted_24 = { class: "config-title" }
const _hoisted_25 = { class: "info-title" }
const _hoisted_26 = { class: "info-title" }
const _hoisted_27 = { class: "info-title" }
const _hoisted_28 = { class: "info-title" }
const _hoisted_29 = { class: "info-title" }
const _hoisted_30 = { class: "info-title" }
const _hoisted_31 = { class: "info-title" }
const _hoisted_32 = { class: "info-title title-check" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_check_circle_two_tone = _resolveComponent("check-circle-two-tone")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_close_circle_two_tone = _resolveComponent("close-circle-two-tone")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, {
        style: {"margin":"16px 0"},
        class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Home")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Settings")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.PaymentSettings")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddDrawer(true)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.reactiveData.data,
        pagination: false
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Pos.View")), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        type: "primary",
                        onClick: ($event: any) => (_ctx.showDisplayDrawer(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Pos.Edit")), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Pos.Delete")), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key == 'key')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatName(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'description')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDescription(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'config')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(_toDisplayString(_ctx.getDescriptionFromConfig(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'status')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createTextVNode(_toDisplayString(_ctx.statusTranslation(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key === 'is_default')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                (record[column.dataIndex] === 1)
                  ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                      key: 0,
                      "two-tone-color": "#52c41a"
                    }))
                  : (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                      key: 1,
                      "two-tone-color": "#ff4d4f"
                    }))
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `Search ${column.dataIndex}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: 
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            ,
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_SearchOutlined)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.t("Pos.Search")), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Reset")), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
          total: _ctx.reactiveData.totalPages,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage
        }, null, 8, ["current", "total", "onChange", "default-page-size"])
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onAfterOpenChange: _ctx.afterOpenChange,
        onClose: _ctx.resetFields
      }, {
        title: _withCtx(() => [
          (_ctx.locale === 'ar' && _ctx.reactiveData.action == 'add')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.AddPaymentSettingsDrawer")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar' && _ctx.reactiveData.action == 'add')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.AddPaymentSettingsDrawer")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale === 'ar' && _ctx.reactiveData.action == 'update')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.UpdatePaymentSettingsDrawer")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar' && _ctx.reactiveData.action == 'update')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.UpdatePaymentSettingsDrawer")), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
          }, [
            (_ctx.reactiveData.action == 'update')
              ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.updatePaymentMethod(_ctx.form.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Pos.Update")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: _ctx.closePopup
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _ctx.addPaymentMethod
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: _ctx.closePopup
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "active" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t("Pos.paymentType")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.form.active,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.active) = $event)),
                            placeholder: _ctx.t('Pos.paymentType'),
                            class: "input-form",
                            disabled: _ctx.isDisabled,
                            "show-search": "",
                            "option-filter-prop": 'label',
                            onChange: _ctx.handlePaymentType
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reactiveData2.data, (paymentMethod) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: paymentMethod.id,
                                  value: paymentMethod.payment_method_id,
                                  label: paymentMethod.name
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(paymentMethod.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder", "disabled", "onChange"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "status" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t("Pos.Status")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.form.status,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.status) = $event)),
                            placeholder: "Choose your payment methods",
                            class: "input-form"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "active" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Pos.Active")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "inactive" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Pos.InActive")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.form.payementTypeName === 'Flousi')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 0,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "password" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t("Pos.Password")), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_password, {
                                value: _ctx.form.flousiPassword,
                                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.flousiPassword) = $event)),
                                visible: _ctx.visiblePassword,
                                "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.visiblePassword) = $event)),
                                placeholder: _ctx.t('Pos.Password'),
                                class: "login-input"
                              }, null, 8, ["value", "visible", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "terminalId" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t("Pos.terminalId")), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.terminalId,
                                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.terminalId) = $event)),
                                class: "input-form"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "serviceCode" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t("Pos.serviceCode")), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.serviceCode,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.serviceCode) = $event)),
                                class: "input-form"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "serialNumber" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t("Pos.serialNumber")), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.serialNumber,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.serialNumber) = $event)),
                                class: "input-form"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "relaisVersion" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t("Pos.relaisVersion")), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.relaisVersion,
                                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.relaisVersion) = $event)),
                                class: "input-form"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "softPosVersion" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t("Pos.softPosVersion")), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.softPosVersion,
                                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.softPosVersion) = $event)),
                                class: "input-form"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "providerChannel" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t("Pos.providerChannel")), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.providerChannel,
                                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.providerChannel) = $event)),
                                class: "input-form"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, {
                        span: 12,
                        class: "check-input"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "barCode" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.barCode,
                                "onUpdate:checked": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.barCode) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Pos.barCode")), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createVNode(_component_a_row, { gutter: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "cashInformation" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t("Pos.Description")), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              value: _ctx.form.cashInformation,
                              "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.cashInformation) = $event)),
                              rows: 4,
                              placeholder: _ctx.t('Pos.Description'),
                              class: "input-form"
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "default" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_checkbox, {
                            checked: _ctx.form.default,
                            "onUpdate:checked": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.default) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("Pos.PaymentDefault")), 1)
                            ]),
                            _: 1
                          }, 8, ["checked"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange", "onClose"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onAfterOpenChange: _ctx.afterOpenChange
      }, {
        title: _withCtx(() => [
          (_ctx.locale === 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.PaymentDetails")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.PaymentDetails")), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => []),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.t("Pos.GeneralInformations")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", {
                          class: "info-title",
                          placeholder: _ctx.t('Pos.paymentType')
                        }, _toDisplayString(_ctx.t("Pos.paymentType")) + " :", 9, _hoisted_21),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.name), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", {
                          class: "info-title",
                          placeholder: _ctx.t('Pos.Description')
                        }, _toDisplayString(_ctx.t("Pos.Description")) + " :", 9, _hoisted_22),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.cashInformation), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", {
                          class: "info-title",
                          placeholder: _ctx.t('Pos.Status')
                        }, _toDisplayString(_ctx.t("Pos.Status")) + " :", 9, _hoisted_23),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.status), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.form.payementTypeName === 'Flousi')
                ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.t("Pos.configFlousi")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.form.payementTypeName === 'Flousi')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 1,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t("Pos.Password")) + " :", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.form.flousiPassword), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t("Pos.terminalId")) + " :", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.form.terminalId), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t("Pos.serviceCode")) + " :", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.form.serviceCode), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t("Pos.serialNumber")) + " :", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.form.serialNumber), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.t("Pos.relaisVersion")) + " :", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.form.relaisVersion), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t("Pos.softPosVersion")) + " :", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.form.softPosVersion), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t("Pos.providerChannel")) + " :", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.form.providerChannel), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            (_ctx.form.barCode === 1 || _ctx.form.barCode === true)
                              ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                  key: 0,
                                  "two-tone-color": "#52c41a"
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.form.barCode === 0 || _ctx.form.barCode === false)
                              ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                  key: 1,
                                  "two-tone-color": "#ff4d4f"
                                }))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t("Pos.barCode")), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"])
    ])
  ], 64))
}