import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b4b04da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = {
  key: 1,
  class: "search-btn-radio"
}
const _hoisted_4 = { class: "pagination" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = { class: "label-form" }
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "info-title" }
const _hoisted_10 = { class: "info-title" }
const _hoisted_11 = { class: "info-title" }
const _hoisted_12 = { class: "cat-display" }
const _hoisted_13 = { class: "info-title" }
const _hoisted_14 = { class: "cats" }
const _hoisted_15 = {
  key: 0,
  class: "column-span"
}
const _hoisted_16 = { class: "label-form" }
const _hoisted_17 = { class: "label-form" }
const _hoisted_18 = { class: "label-form" }
const _hoisted_19 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_BookOutlined = _resolveComponent("BookOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Dashboard")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Menu")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.openAddMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_table, {
      class: "table-container",
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      emptyText: _withCtx(() => [
        _createVNode(_component_a_empty, {
          description: _ctx.t('Pos.NoData'),
          image: _ctx.simpleImage
        }, null, 8, ["description", "image"])
      ]),
      bodyCell: _withCtx(({ column, record }) => [
        (column.key == 'actions')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tooltip, null, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Pos.View")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      class: "view-btn",
                      onClick: () => _ctx.showDisplayDrawer(record)
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_EyeOutlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_tooltip, null, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Pos.Edit")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: () => _ctx.showDrawer(record)
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_EditOutlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_tooltip, null, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Pos.Delete")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: ($event: any) => (_ctx.showDeletePopup(record))
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_DeleteOutlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_tooltip, null, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Pos.Menu")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      class: "variant-btn",
                      onClick: ($event: any) => (_ctx.goToMenu(record.id))
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_BookOutlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.key === 'location')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(record.location.name), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      customFilterDropdown: _withCtx(({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
            }) => [
        _createElementVNode("div", _hoisted_2, [
          (column.key === 'invoice_number')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `${column.title}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: 
                        (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                        ,
                onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          (column.key === 'warehouse_id')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.value,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
                  onChange: _ctx.selectedWarehouse,
                  class: "search-radio-grp"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.warehouses, (item) => {
                      return (_openBlock(), _createBlock(_component_a_radio, {
                        label: item.name,
                        value: item.id,
                        key: item,
                        class: "radio-item"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "onChange"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Search")), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Reset")), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_a_pagination, {
        class: "paginationArrows",
        current: _ctx.currentPage,
        "onUpdate:current": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
        pageSize: _ctx.perPage,
        "onUpdate:pageSize": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.perPage) = $event)),
        total: _ctx.state.totalPages,
        "default-page-size": _ctx.perPage,
        showSizeChanger: false,
        onChange: _ctx.handlePageChange
      }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
      (_ctx.state.totalPages > 10)
        ? (_openBlock(), _createBlock(_component_a_select, {
            key: 0,
            value: _ctx.selectPagination,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectPagination) = $event)),
            onChange: _ctx.handleSelectPaginationChange,
            class: "select-input"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: "10" }, {
                default: _withCtx(() => [
                  _createTextVNode("10 / " + _toDisplayString(_ctx.t("Pos.page")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "20" }, {
                default: _withCtx(() => [
                  _createTextVNode("20 / " + _toDisplayString(_ctx.t("Pos.page")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "50" }, {
                default: _withCtx(() => [
                  _createTextVNode("50 / " + _toDisplayString(_ctx.t("Pos.page")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: "100" }, {
                default: _withCtx(() => [
                  _createTextVNode("100 / " + _toDisplayString(_ctx.t("Pos.page")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openAdd,
      "onUpdate:open": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.openAdd) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onClose: _ctx.resetFormAdd
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, " Add menu ", 2)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.resetFormAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "addFormRef",
          model: _ctx.addForm,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.Name')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.name,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addForm.name) = $event)),
                          placeholder: _ctx.t('Pos.Name'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "description" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.Description')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: _ctx.addForm.description,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addForm.description) = $event)),
                          rows: 4,
                          placeholder: _ctx.t('Pos.Description')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "location_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.Location')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.addForm.location_id,
                          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addForm.location_id) = $event)),
                          "show-search": "",
                          "option-filter-prop": 'label',
                          mode: "multiple",
                          placeholder: _ctx.t('Pos.FetchLocation')
                        }, {
                          notFoundContent: _withCtx(() => [
                            _createVNode(_component_a_empty, {
                              description: _ctx.t('Pos.NoData'),
                              image: _ctx.simpleImage
                            }, null, 8, ["description", "image"])
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.locations, (location) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                label: location.name,
                                key: location.id,
                                value: location.id,
                                selected: location.id === _ctx.addForm.location_id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(location.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["label", "value", "selected"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "categories" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Categories')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.state.categoryValue,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.categoryValue) = $event)),
                          mode: "multiple",
                          "label-in-value": "",
                          placeholder: _ctx.t('Pos.FetchCategory'),
                          style: {"width":"100%"},
                          "filter-option": false,
                          "not-found-content": _ctx.state.fetchingCat ? undefined : null,
                          options: _ctx.state.catOptions,
                          onSearch: _ctx.fetchCategory,
                          onChange: _ctx.handleCatSelect
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetchingCat)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onClose"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openDisplay,
      "onUpdate:open": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.openDisplay) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, _toDisplayString(_ctx.t("Pos.MenuInfo")), 3)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t("Pos.Name")) + " :", 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.form.name), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t("Pos.Location")) + " :", 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.form.location), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t("Pos.Description")) + " :", 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.form.description), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t("Pos.Categories")) + " :", 1),
                      _createElementVNode("div", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.categories, (cat, index) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: cat.id,
                            class: "choice"
                          }, [
                            _createTextVNode(_toDisplayString(cat.name) + " ", 1),
                            (index < _ctx.form.categories.length - 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_15, ", "))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.open,
      "onUpdate:open": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.open) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      width: _ctx.modalWidth,
      placement: _ctx.locale === 'ar' ? 'left' : 'right'
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, _toDisplayString(_ctx.t("Pos.UpdateMenu")), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.update(_ctx.form.id)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Update")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.cancelUpdate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "name" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t("Pos.Name")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.name,
                          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.name) = $event)),
                          placeholder: _ctx.t('Pos.Name'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "description" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t("Pos.Description")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: _ctx.form.description,
                          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.description) = $event)),
                          rows: 4,
                          placeholder: _ctx.t('Pos.Description')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "location" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.Location')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.location,
                          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.location) = $event)),
                          mode: "multiple",
                          "label-in-value": "",
                          placeholder: _ctx.t('Pos.Location'),
                          style: {"width":"100%"},
                          "filter-option": false,
                          "not-found-content": _ctx.state.fetchingUpdate ? undefined : null,
                          options: _ctx.filteredUsersUpdate,
                          onSearch: _ctx.fetchLocationUpdate,
                          onChange: _ctx.handleLocationUpdateSelect
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetchingUpdate)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "categories" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Pos.Categories')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.categories,
                          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.categories) = $event)),
                          mode: "multiple",
                          "label-in-value": "",
                          placeholder: _ctx.t('Pos.FetchCategory'),
                          style: {"width":"100%"},
                          "filter-option": false,
                          "not-found-content": _ctx.state.fetchingUpdate ? undefined : null,
                          options: _ctx.filteredCatsUpdate,
                          onSearch: _ctx.fecthCatsUpdate,
                          onChange: _ctx.handleCatUpdateSelect
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetchingUpdate)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "width", "placement"])
  ], 64))
}