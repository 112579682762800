import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createSlots as _createSlots, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aa7f457"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_5 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_6 = {
  key: 2,
  class: "drawer-header-ar"
}
const _hoisted_7 = {
  key: 3,
  class: "drawer-header-all"
}
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "ant-upload-drag-icon" }
const _hoisted_16 = { class: "ant-upload-text" }
const _hoisted_17 = { class: "ant-upload-hint" }
const _hoisted_18 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_19 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_20 = { class: "custom-header" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = ["disabled"]
const _hoisted_23 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_24 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_25 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_26 = { class: "info-title" }
const _hoisted_27 = { class: "label-form" }
const _hoisted_28 = { class: "label-form" }
const _hoisted_29 = { class: "label-form" }
const _hoisted_30 = { class: "label-form" }
const _hoisted_31 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_ShopOutlined = _resolveComponent("ShopOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_InboxOutlined = _resolveComponent("InboxOutlined")!
  const _component_a_upload_dragger = _resolveComponent("a-upload-dragger")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, {
        style: {"margin":"16px 0"},
        class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Home")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Store")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_row, { gutter: 16 }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 12 },
            lg: { span: 12 },
            xl: { span: 12 },
            xxl: { span: 12 },
            class: "action-btn-container"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                class: "add-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddDrawer(true)))
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_ShopOutlined)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")) + " ", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.reactiveData.data,
        pagination: false
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Pos.View")), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Pos.Edit")), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Pos.Delete")), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key === 'created_at' || column.key === 'updated_at')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDisplayDate(record[column.key])), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `Search ${column.dataIndex}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: 
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            ,
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_SearchOutlined)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.t("Pos.Search")), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Reset")), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
          total: _ctx.reactiveData.totalPages,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage
        }, null, 8, ["current", "total", "onChange", "default-page-size"])
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onAfterOpenChange: _ctx.afterOpenChange,
        onClose: _ctx.resetFields
      }, {
        title: _withCtx(() => [
          (_ctx.locale === 'ar' && _ctx.reactiveData.action == 'add')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.AddStoreDrawer")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar' && _ctx.reactiveData.action == 'add')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.AddStoreDrawer")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale === 'ar' && _ctx.reactiveData.action == 'update')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.UpdateStoreDrawer")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar' && _ctx.reactiveData.action == 'update')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.UpdateStoreDrawer")), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
          }, [
            (_ctx.reactiveData.action == 'update')
              ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateStore(_ctx.form.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Pos.Update")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: _ctx.close
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : (_ctx.reactiveData.action == 'add')
                ? (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _ctx.addStore
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: _ctx.close
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_a_space, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: _ctx.close
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t("Pos.Name")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.name,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.name) = $event)),
                            disabled: 
                  _ctx.reactiveData.action !== 'add' &&
                  _ctx.reactiveData.action !== 'update'
                ,
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "disabled", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "address" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t("Pos.Address")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.address,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.address) = $event)),
                            disabled: 
                  _ctx.reactiveData.action !== 'add' &&
                  _ctx.reactiveData.action !== 'update'
                ,
                            placeholder: _ctx.t('Pos.Address'),
                            class: "input-form"
                          }, null, 8, ["value", "disabled", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "email" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t("Pos.Email")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.email,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.email) = $event)),
                            placeholder: _ctx.t('Pos.Email'),
                            disabled: 
                  _ctx.reactiveData.action !== 'add' &&
                  _ctx.reactiveData.action !== 'update'
                ,
                            class: "input-form"
                          }, null, 8, ["value", "placeholder", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "description" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t("Pos.Description")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.description,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.description) = $event)),
                            disabled: 
                  _ctx.reactiveData.action !== 'add' &&
                  _ctx.reactiveData.action !== 'update'
                ,
                            placeholder: _ctx.t('Pos.Description'),
                            class: "input-form"
                          }, null, 8, ["value", "disabled", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "phone" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t("Pos.Phone")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.phone,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.phone) = $event)),
                            placeholder: _ctx.t('Pos.Phone'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "password" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t("Pos.Password")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_password, {
                            value: _ctx.form.password,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.password) = $event)),
                            visible: _ctx.visible,
                            "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.visible) = $event)),
                            placeholder: _ctx.t('Pos.Password'),
                            class: "input-form"
                          }, null, 8, ["value", "visible", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, null, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t("Pos.Photo")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_upload_dragger, {
                            fileList: _ctx.fileList,
                            "onUpdate:fileList": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.fileList) = $event)),
                            name: "file",
                            multiple: false,
                            "max-count": 1,
                            onChange: _ctx.handleChangeFile,
                            onDrop: _ctx.handleDrop,
                            "before-upload": _ctx.beforeUpload
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_15, [
                                _createVNode(_component_InboxOutlined)
                              ]),
                              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.t("Pos.dropTitle1")), 1),
                              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.t("Pos.dropTitle2")), 1)
                            ]),
                            _: 1
                          }, 8, ["fileList", "onChange", "onDrop", "before-upload"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange", "onClose"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenShowChange
      }, {
        title: _withCtx(() => [
          (_ctx.locale === 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.DetailsStoreDrawer")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t("Pos.DetailsStoreDrawer")), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "addFormRef",
            model: _ctx.form,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.t("Pos.GeneralInformations")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", {
                          class: "info-title",
                          disabled: 
                  _ctx.reactiveData.action !== 'add' &&
                  _ctx.reactiveData.action !== 'update'
                ,
                          placeholder: "name"
                        }, _toDisplayString(_ctx.t("Pos.FullName")) + " :", 9, _hoisted_21),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.name), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", {
                          class: "info-title",
                          disabled: 
                  _ctx.reactiveData.action !== 'add' &&
                  _ctx.reactiveData.action !== 'update'
                ,
                          placeholder: "name"
                        }, _toDisplayString(_ctx.t("Pos.Description")) + " :", 9, _hoisted_22),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.description), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t("Pos.Address")) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.address), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t("Pos.Email")) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.email), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t("Pos.Phone")) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.phone), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  (_ctx.form.photo !== null)
                    ? (_openBlock(), _createBlock(_component_a_row, {
                        key: 0,
                        span: 24
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 8 }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t("Pos.Photo")) + " :", 1)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 16 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "photo" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_image, {
                                    width: 200,
                                    src: _ctx.form.photo
                                  }, null, 8, ["src"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_modal, {
        open: _ctx.openTransferModal,
        "onUpdate:open": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.openTransferModal) = $event)),
        onCancel: _ctx.handleCancel
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: _ctx.transfer
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.handleCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
          }, _toDisplayString(_ctx.t("Pos.TransferItems")), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "transferFormRef",
            model: _ctx.transferForm,
            rules: _ctx.transferRules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "target_store" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t("Pos.From")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.state.sender_store,
                            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.sender_store) = $event)),
                            "show-search": "",
                            "default-active-first-option": false,
                            "filter-option": false,
                            onSearch: _ctx.fetchTarget,
                            options: _ctx.state.target_store,
                            onSelect: _ctx.selectedSenderStore
                          }, _createSlots({ _: 2 }, [
                            (_ctx.state.fetchingTargetStore)
                              ? {
                                  name: "notFoundContent",
                                  fn: _withCtx(() => [
                                    _createVNode(_component_a_spin, { size: "small" })
                                  ]),
                                  key: "0"
                                }
                              : undefined
                          ]), 1032, ["value", "onSearch", "options", "onSelect"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "destination_store" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t("Pos.To")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.state.receiver_store,
                            "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.receiver_store) = $event)),
                            "show-search": "",
                            "default-active-first-option": false,
                            "filter-option": false,
                            onSearch: _ctx.fetchDestination,
                            options: _ctx.state.destination_store,
                            disabled: _ctx.state.sender_store.length === 0,
                            class: "store-select"
                          }, _createSlots({ _: 2 }, [
                            (_ctx.state.fetchingDestinationStore)
                              ? {
                                  name: "notFoundContent",
                                  fn: _withCtx(() => [
                                    _createVNode(_component_a_spin, { size: "small" })
                                  ]),
                                  key: "0"
                                }
                              : undefined
                          ]), 1032, ["value", "onSearch", "options", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "item_type" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.t("Pos.Typeofmerchandise")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.transferForm.item_type,
                            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.transferForm.item_type) = $event)),
                            "show-search": "",
                            "option-filter-prop": 'label',
                            onChange: _ctx.handleTypeChange,
                            disabled: 
                  _ctx.state.receiver_store.length === 0 ||
                  _ctx.state.sender_store.length === 0
                
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, {
                                value: "product",
                                label: _ctx.t('Pos.Product')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Pos.Product")), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "ingredient",
                                label: _ctx.t('Pos.Ingredient')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Pos.Ingredient")), 1)
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }, 8, ["value", "onChange", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "items" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t("Pos.Items")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.state.itemValues,
                            "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.state.itemValues) = $event)),
                            mode: "multiple",
                            "label-in-value": "",
                            placeholder: _ctx.t('Pos.Item'),
                            style: {"width":"100%"},
                            "filter-option": false,
                            "not-found-content": _ctx.state.fetchingItems ? undefined : null,
                            options: _ctx.state.items,
                            onSearch: _ctx.fetchItems,
                            onChange: _ctx.handleMultiChange,
                            disabled: 
                  _ctx.state.receiver_store.length === 0 ||
                  _ctx.state.sender_store.length === 0 || !_ctx.transferForm.item_type
                
                          }, _createSlots({ _: 2 }, [
                            (_ctx.state.fetchingItems)
                              ? {
                                  name: "notFoundContent",
                                  fn: _withCtx(() => [
                                    _createVNode(_component_a_spin, { size: "small" })
                                  ]),
                                  key: "0"
                                }
                              : undefined
                          ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch", "onChange", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.qtArray, (item) => {
                return (_openBlock(), _createBlock(_component_a_row, {
                  key: item,
                  gutter: 16
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      xs: { span: 24},
                      sm: { span: 24},
                      md: {span: 9},
                      lg: { span: 9 },
                      xl: { span: 9 },
                      xxl: { span: 9 }
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_31, _toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_col, {
                      xs: { span: 24},
                      sm: { span: 24},
                      md: {span: 15},
                      lg: { span: 15 },
                      xl: { span: 15 },
                      xxl: { span: 15 }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          "has-feedback": "",
                          name: 'qt_' + item.item_id,
                          "validate-status": _ctx.getValidatingStatus(item.item_id, 'qt'),
                          help: _ctx.getValidatingHelp(item.item_id, 'qt')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              id: 'qt_' + item.item_id,
                              placeholder: _ctx.t('Pos.Quantity'),
                              value: item.quantity,
                              "onUpdate:value": ($event: any) => ((item.quantity) = $event),
                              class: "input-form"
                            }, null, 8, ["id", "placeholder", "value", "onUpdate:value"])
                          ]),
                          _: 2
                        }, 1032, ["name", "validate-status", "help"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "onCancel"])
    ])
  ], 64))
}