import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-242d67af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { class: "label-form" }
const _hoisted_3 = { class: "label-form" }
const _hoisted_4 = { class: "label-form" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = {
  key: 0,
  class: "occupied-text"
}
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "info-title" }
const _hoisted_13 = { class: "info-title" }
const _hoisted_14 = { class: "info-title" }
const _hoisted_15 = { class: "info-title" }
const _hoisted_16 = { class: "info-title" }
const _hoisted_17 = { class: "info-title" }
const _hoisted_18 = { class: "label-form" }
const _hoisted_19 = { class: "label-form" }
const _hoisted_20 = { class: "label-form" }
const _hoisted_21 = { class: "label-form" }
const _hoisted_22 = {
  key: 0,
  class: "occupied-text"
}
const _hoisted_23 = { class: "label-form" }
const _hoisted_24 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_ScheduleOutlined = _resolveComponent("ScheduleOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FullCalendar = _resolveComponent("FullCalendar")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_check_circle_outlined = _resolveComponent("check-circle-outlined")!
  const _component_close_circle_outlined = _resolveComponent("close-circle-outlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Tables')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.TableReservations')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_ScheduleOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')) + " ", 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          xs: { span: 24},
          sm: { span: 24},
          md: {span: 24},
          lg: { span: 24 },
          xl: { span: 24 },
          xxl: { span: 24 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FullCalendar, {
              ref: "calendarRef",
              options: _ctx.calendarOptions
            }, {
              eventContent: _withCtx((arg) => [
                _createElementVNode("b", null, _toDisplayString(arg.timeText), 1),
                _createElementVNode("i", null, _toDisplayString(arg.event.title), 1)
              ]),
              _: 1
            }, 8, ["options"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_drawer, {
      open: _ctx.openAdd,
      "onUpdate:open": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.openAdd) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onClose: _ctx.resetFormAdd
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Pos.AddTableReservation')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.resetFormAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "addFormRef",
          model: _ctx.addForm,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "reservation_time" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Pos.ReservationTime')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_date_picker, {
                          "show-time": "",
                          placeholder: _ctx.t('Pos.ReservationTime'),
                          value: _ctx.addForm.reservation_time,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addForm.reservation_time) = $event)),
                          class: "range-picker"
                        }, null, 8, ["placeholder", "value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "number_of_guests" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('Pos.NumberOfGuests')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.number_of_guests,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addForm.number_of_guests) = $event)),
                          placeholder: _ctx.t('Pos.NumberOfGuests'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "location_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('Pos.TableLocation')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          "show-search": "",
                          allowClear: "",
                          placeholder: "Please choose table location",
                          value: _ctx.addForm.location_id,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addForm.location_id) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tableLocations, (location) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: location.id,
                                value: location.id,
                                selected: location.id === _ctx.addForm.location_id 
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(location.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["value", "selected"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "table_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.Table')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          "show-search": "",
                          allowClear: "",
                          placeholder: "Please choose Table",
                          value: _ctx.addForm.table_id,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addForm.table_id) = $event)),
                          disabled: !_ctx.addForm.reservation_time || !_ctx.addForm.number_of_guests || !_ctx.addForm.location_id
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tables, (table) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: table.id,
                                value: table.id,
                                disabled: table.instantStatus === 'occupied',
                                class: _normalizeClass({ 'occupied-option': table.instantStatus === 'occupied' })
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(table.number) + " ", 1),
                                  (table.instantStatus === 'occupied')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, " - " + _toDisplayString(_ctx.t('Pos.Occupied')), 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["value", "disabled", "class"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "customer_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.Customer')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.state.userValue,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.userValue) = $event)),
                          "show-search": "",
                          "default-active-first-option": false,
                          "filter-option": false,
                          onSearch: _ctx.fetchUser,
                          options: _ctx.state.users,
                          placeholder: _ctx.t('Pos.Customer'),
                          style: {"width":"100%"},
                          onChange: _ctx.handleUsersSelect
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetchingUser)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "onSearch", "options", "placeholder", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.emptyData===true || _ctx.customers.length===0)
                  ? (_openBlock(), _createBlock(_component_a_col, {
                      key: 0,
                      span: 12,
                      class: "add-cutsomer-btn"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "customer" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              onClick: _ctx.addCustomer
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.AddCustomerBtn')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_ctx.addNewForm===true)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_a_row, { gutter: 16 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "first_name" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.FisrtName')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.first_name,
                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addForm.first_name) = $event)),
                                placeholder: _ctx.t('Pos.FisrtName'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "last_name" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.LastName')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.last_name,
                                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addForm.last_name) = $event)),
                                placeholder: _ctx.t('Pos.FisrtName'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { gutter: 16 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "email" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.Email')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.email,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.addForm.email) = $event)),
                                placeholder: _ctx.t('Pos.Email'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "phonr" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.Phone')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.phone,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.addForm.phone) = $event)),
                                placeholder: _ctx.t('Pos.Phone'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { gutter: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "free_orders" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.free_orders,
                                "onUpdate:checked": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addForm.free_orders) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.CustomerFreeOrder')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onClose"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openDisplay,
      "onUpdate:open": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.openDisplay) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onAfterOpenChange: _ctx.afterOpenChange
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Pos.TableReservationInfo')), 3)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.ReservationTime')) + " :", 1),
                  _createTextVNode(_toDisplayString(_ctx.form.reservation_time), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.Customer')) + " :", 1),
                  _createTextVNode(_toDisplayString(_ctx.form.customer_name), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Pos.Status')) + " :", 1),
                  (_ctx.form.status==='confirmed')
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 0,
                        bordered: false,
                        color: "orange"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t(`Pos.${_ctx.form.status}`)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.form.status==='arrived')
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 1,
                        bordered: false,
                        color: "success"
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_check_circle_outlined)
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.t(`Pos.${_ctx.form.status}`)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.form.status==='cancelled')
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 2,
                        bordered: false,
                        color: "error"
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_close_circle_outlined)
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.t(`Pos.${_ctx.form.status}`)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Pos.NumberOfGuests')) + " :", 1),
                  _createTextVNode(_toDisplayString(_ctx.form.number_of_guests), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, null, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Pos.Table')) + " :", 1),
                  _createTextVNode(_toDisplayString(_ctx.form.table_number), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_a_row, {
          gutter: 16,
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Pos.TableLocation')) + " :", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.form.location_name), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.open,
      "onUpdate:open": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.open) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onAfterOpenChange: _ctx.afterOpenChange
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, _toDisplayString(_ctx.t('Pos.UpdateTableReservation')), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.update(_ctx.form.id)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.cancelUpdate,
              danger: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "reservation_time" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.ReservationTime')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_date_picker, {
                          "show-time": "",
                          value: _ctx.form.reservation_datetime,
                          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.reservation_datetime) = $event)),
                          placeholder: _ctx.t('Pos.ReservationTime'),
                          class: "range-picker",
                          onChange: _ctx.setDirty
                        }, null, 8, ["value", "placeholder", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "number_of_guests" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Pos.NumberOfGuests')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.number_of_guests,
                          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.number_of_guests) = $event)),
                          placeholder: _ctx.t('Pos.NumberOfGuests'),
                          class: "input-form",
                          onInput: _ctx.setDirty
                        }, null, 8, ["value", "placeholder", "onInput"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "table_location_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Pos.TableLocation')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.location_name,
                          "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.location_name) = $event)),
                          onChange: _ctx.setDirty
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tableLocations, (location) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: location.id,
                                value: location.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(location.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "table_id" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Pos.Table')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          "show-search": "",
                          value: _ctx.form.table_id,
                          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.table_id) = $event)),
                          onChange: _ctx.handleTableChange
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.availabeTables, (table) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: table.id,
                                value: table.id,
                                disabled: table.instantStatus === 'occupied',
                                class: _normalizeClass({ 'occupied-option': table.instantStatus === 'occupied' }),
                                selected: table.id === _ctx.form.table_id 
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(table.number) + " ", 1),
                                  (table.instantStatus === 'occupied')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_22, " - " + _toDisplayString(_ctx.t('Pos.Occupied')), 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["value", "disabled", "class", "selected"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "status" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Pos.Status')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.status,
                          "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.status) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select_option, { value: "confirmed" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Confirmed')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "arrived" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Arrived')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "cancelled" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancelled')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "customers" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Pos.Customer')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.form.customer_name,
                          "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.customer_name) = $event)),
                          "show-search": "",
                          "default-active-first-option": false,
                          "filter-option": false,
                          onSearch: _ctx.fetchUser,
                          options: _ctx.state.users,
                          placeholder: _ctx.t('Pos.Customer'),
                          style: {"width":"100%"},
                          onChange: _ctx.handleUsersSelectUpdate
                        }, _createSlots({ _: 2 }, [
                          (_ctx.state.fetchingUser)
                            ? {
                                name: "notFoundContent",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_spin, { size: "small" })
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["value", "onSearch", "options", "placeholder", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onAfterOpenChange"])
  ], 64))
}