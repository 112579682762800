import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/nophoto.png'


const _withScopeId = n => (_pushScopeId("data-v-30bcf138"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = {
  key: 1,
  class: "search-btn-radio"
}
const _hoisted_4 = { class: "pagination" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = { class: "label-form" }
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "ant-upload-drag-icon" }
const _hoisted_12 = { class: "ant-upload-text" }
const _hoisted_13 = { class: "ant-upload-hint" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "photo-title" }
const _hoisted_16 = {
  key: 1,
  width: 100,
  src: _imports_0
}
const _hoisted_17 = { class: "info-title" }
const _hoisted_18 = { class: "info-title" }
const _hoisted_19 = {
  key: 0,
  value: "mg"
}
const _hoisted_20 = {
  key: 1,
  value: "pcs"
}
const _hoisted_21 = {
  key: 2,
  value: "ml"
}
const _hoisted_22 = {
  key: 3,
  value: "mm"
}
const _hoisted_23 = { class: "info-title" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "info-title" }
const _hoisted_27 = { class: "label-form" }
const _hoisted_28 = { class: "label-form" }
const _hoisted_29 = { class: "switch-container" }
const _hoisted_30 = { class: "label-form" }
const _hoisted_31 = { class: "label-form" }
const _hoisted_32 = { class: "label-form" }
const _hoisted_33 = { class: "ant-upload-drag-icon" }
const _hoisted_34 = { class: "ant-upload-text" }
const _hoisted_35 = { class: "ant-upload-hint" }
const _hoisted_36 = { class: "label-form" }
const _hoisted_37 = { class: "label-form" }
const _hoisted_38 = { class: "label-form" }
const _hoisted_39 = { class: "label-form" }
const _hoisted_40 = { class: "label-form" }
const _hoisted_41 = { class: "label-form" }
const _hoisted_42 = { class: "label-form" }
const _hoisted_43 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_HeartOutlined = _resolveComponent("HeartOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_check_circle_two_tone = _resolveComponent("check-circle-two-tone")!
  const _component_close_circle_two_tone = _resolveComponent("close-circle-two-tone")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_InboxOutlined = _resolveComponent("InboxOutlined")!
  const _component_a_upload_dragger = _resolveComponent("a-upload-dragger")!
  const _component_PaperClipOutlined = _resolveComponent("PaperClipOutlined")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_MinusCircleOutlined = _resolveComponent("MinusCircleOutlined")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Ingredients')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigatePage('/ingredients'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Ingredients')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        columns: _ctx.columns,
        "data-source": _ctx.state.data,
        pagination: false,
        class: "table-container"
      }, {
        emptyText: _withCtx(() => [
          _createVNode(_component_a_empty, {
            description: _ctx.t('Pos.NoData'),
            image: _ctx.simpleImage
          }, null, 8, ["description", "image"])
        ]),
        bodyCell: _withCtx(({ column, record }) => [
          (column.key=='actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Preferences')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "pref-btn",
                        onClick: ($event: any) => (_ctx.showPrefModal(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_HeartOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key=='unit')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (record.unit==='mg')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (record.unit==='pcs')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (record.unit==='ml')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (record.unit==='mm')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.key==='has_price')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (record.has_price===1 || record.has_price===true || record.has_price==='1')
                  ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                      key: 0,
                      "two-tone-color": "#52c41a"
                    }))
                  : _createCommentVNode("", true),
                (record.has_price===0 || record.has_price===false || record.has_price==='0  ' )
                  ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                      key: 1,
                      "two-tone-color": "#ff4d4f"
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            (column.key === 'name')
              ? (_openBlock(), _createBlock(_component_a_input, {
                  key: 0,
                  ref: "searchInput",
                  placeholder: `${column.title}`,
                  value: selectedKeys[0],
                  style: {"width":"188px","margin-bottom":"8px","display":"block"},
                  onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                  onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
                }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
              : _createCommentVNode("", true),
            (column.key === 'unit')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_a_radio_group, {
                    value: _ctx.value,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
                    onChange: _ctx.selectedType,
                    class: "search-radio-grp"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_radio, {
                        value: "mg",
                        class: "radio-item"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_radio, {
                        value: "pcs",
                        class: "radio-item"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_radio, {
                        value: "ml",
                        class: "radio-item"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_radio, {
                        value: "mm",
                        class: "radio-item"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "onChange"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentPage) = $event)),
          pageSize: _ctx.perPage,
          "onUpdate:pageSize": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.perPage) = $event)),
          total: _ctx.state.totalPages,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage,
          showSizeChanger: false
        }, null, 8, ["current", "pageSize", "total", "onChange", "default-page-size"]),
        (_ctx.state.totalPages>10)
          ? (_openBlock(), _createBlock(_component_a_select, {
              key: 0,
              class: "select-input",
              value: _ctx.selectPagination,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectPagination) = $event)),
              onChange: _ctx.handleSelectPaginationChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "10" }, {
                  default: _withCtx(() => [
                    _createTextVNode("10 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "20" }, {
                  default: _withCtx(() => [
                    _createTextVNode("20 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "50" }, {
                  default: _withCtx(() => [
                    _createTextVNode("50 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "100" }, {
                  default: _withCtx(() => [
                    _createTextVNode("100 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.cancelUpdate
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.UpdateIngredient')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.updateIngredient(_ctx.form.id)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.cancelUpdate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.name,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "unit" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.Unit')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.form.unit,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.unit) = $event)),
                            "show-search": ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, {
                                value: "mg",
                                label: _ctx.t('Pos.Milligrams')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "pcs",
                                label: _ctx.t('Pos.Pieces')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "ml",
                                label: _ctx.t('Pos.Milliliter')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "mm",
                                label: _ctx.t('Pos.Millimeter')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "has_price" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.HasPrice')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_switch, {
                            checked: _ctx.form.has_price,
                            "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.has_price) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.form.has_price === 1 || _ctx.form.has_price===true)
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 0,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "default_price" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Price')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.default_price,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.default_price) = $event)),
                                placeholder: _ctx.t('Pos.Price'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "ingredient_category_id" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.Category')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.form.ingredient_category_id,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.ingredient_category_id) = $event)),
                            "show-search": "",
                            "option-filter-prop": 'label'
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.categories, (category) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: category.id,
                                  value: category.id,
                                  label: category.name,
                                  selected: category.id === _ctx.form.ingredient_category_id 
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(category.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value", "label", "selected"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "photo" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.Photo')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_upload_dragger, {
                            fileList: _ctx.fileList,
                            "onUpdate:fileList": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.fileList) = $event)),
                            name: "file",
                            multiple: false,
                            "max-count": 1,
                            onChange: _ctx.handleChangeFile,
                            onDrop: _ctx.handleDrop,
                            "before-upload": _ctx.beforeUpload
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_11, [
                                _createVNode(_component_InboxOutlined)
                              ]),
                              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('Pos.dropTitle1')), 1),
                              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t('Pos.dropTitle2')), 1)
                            ]),
                            _: 1
                          }, 8, ["fileList", "onChange", "onDrop", "before-upload"]),
                          (_ctx.form.photo!==null && !_ctx.state.fileChanged)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createElementVNode("p", {
                                  class: "photo-info",
                                  onClick: _cache[12] || (_cache[12] = () => _ctx.setVisible(true))
                                }, [
                                  _createVNode(_component_PaperClipOutlined),
                                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.extractFilename(_ctx.form.photo)), 1),
                                  _createTextVNode(),
                                  _createVNode(_component_DeleteOutlined, { onClick: _ctx.deletePhoto }, null, 8, ["onClick"])
                                ]),
                                _createVNode(_component_a_image, {
                                  width: 200,
                                  style: { display: 'none' },
                                  preview: {
                      visible: _ctx.visible,
                      onVisibleChange: _ctx.setVisible,
                    },
                                  src: _ctx.form.photo
                                }, null, 8, ["preview", "src"])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenShowChange
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.IngredientInfo')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_row, {
            gutter: 16,
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { flex: "150px" }, {
                default: _withCtx(() => [
                  (_ctx.form.photo!==null)
                    ? (_openBlock(), _createBlock(_component_a_image, {
                        key: 0,
                        width: 100,
                        height: 100,
                        src: _ctx.form.photo
                      }, null, 8, ["src"]))
                    : _createCommentVNode("", true),
                  (_ctx.form.photo===null)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_16))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { flex: "auto" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.transformUpperCase(_ctx.form.name)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_a_row, {
            gutter: 16,
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Pos.Category')) + " :", 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.form.ingredient_category_id), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.Unit')) + " :", 1),
                    (_ctx.form.unit==='mg')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.t('Pos.Milligrams')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.form.unit==='pcs')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.t('Pos.Pieces')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.form.unit==='ml')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.t('Pos.Milliliter')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.form.unit==='mm')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.t('Pos.Millimeter')), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_a_row, {
            gutter: 16,
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Pos.HasPrice')) + " :", 1),
                    (_ctx.form.has_price===1 || _ctx.form.has_price===true)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, [
                          _createVNode(_component_check_circle_two_tone, { "two-tone-color": "#52c41a" })
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.form.has_price===0 || _ctx.form.has_price===false)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_25, [
                          _createVNode(_component_close_circle_two_tone, { "two-tone-color": "#ff4d4f" })
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }),
              (_ctx.form.has_price===1 || _ctx.form.has_price===true)
                ? (_openBlock(), _createBlock(_component_a_col, {
                    key: 0,
                    span: 12
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t('Pos.Price')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.default_price), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openAdd,
        "onUpdate:open": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.openAdd) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.resetFormAdd
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AddIngredient')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _ctx.addIngredient
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.resetFormAdd
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "addFormRef",
            model: _ctx.addForm,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.name,
                            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.addForm.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "unit" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t('Pos.Unit')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addForm.unit,
                            "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.addForm.unit) = $event)),
                            "show-search": ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "mg" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "pcs" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "ml" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "mm" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "has_price" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.HasPrice')) + " :", 1),
                            _createVNode(_component_a_switch, {
                              checked: _ctx.addForm.has_price,
                              "onUpdate:checked": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.addForm.has_price) = $event))
                            }, null, 8, ["checked"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.addForm.has_price===1 || _ctx.addForm.has_price===true )
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 0,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "default_price" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('Pos.Price')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.default_price,
                                "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.addForm.default_price) = $event)),
                                placeholder: _ctx.t('Pos.Price'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "ingredient_category_id" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('Pos.Category')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addForm.ingredient_category_id,
                            "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.addForm.ingredient_category_id) = $event)),
                            "show-search": "",
                            "option-filter-prop": 'label'
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.categories, (category) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: category.id,
                                  value: category.id,
                                  label: category.name
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(category.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "photo" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t('Pos.Photo')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_upload_dragger, {
                            fileList: _ctx.fileList,
                            "onUpdate:fileList": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.fileList) = $event)),
                            name: "file",
                            multiple: false,
                            "max-count": 1,
                            onChange: _ctx.handleChange,
                            onDrop: _ctx.handleDrop,
                            "before-upload": _ctx.beforeUpload
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_33, [
                                _createVNode(_component_InboxOutlined)
                              ]),
                              _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.t('Pos.dropTitle1')), 1),
                              _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.t('Pos.dropTitle2')), 1)
                            ]),
                            _: 1
                          }, 8, ["fileList", "onChange", "onDrop", "before-upload"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.state.customFields !== null)
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 0,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.customFields, (item) => {
                        return (_openBlock(), _createBlock(_component_a_col, {
                          key: item,
                          span: 12
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_row, { span: 12 }, {
                              default: _withCtx(() => [
                                (item.type==='text')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: 'customData_' + item.id,
                                      rules: [item.is_mandatory === 1 ? { required: true, message:_ctx.t('Pos.RequiredField')  } : {}],
                                      class: "dynamic-input"
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input, {
                                          class: "custom-input",
                                          id: 'txt_' + item.id
                                        }, null, 8, ["id"])
                                      ]),
                                      _: 2
                                    }, 1032, ["name", "rules"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, { span: 12 }, {
                              default: _withCtx(() => [
                                (item.type==='textarea')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}],
                                      class: "dynamic-input"
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_textarea, {
                                          class: "custom-input",
                                          id: 'area_' + item.id,
                                          rows: 4
                                        }, null, 8, ["id"])
                                      ]),
                                      _: 2
                                    }, 1032, ["rules"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, { span: 12 }, {
                              default: _withCtx(() => [
                                (item.type==='number')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}],
                                      class: "dynamic-input"
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input_number, {
                                          class: "custom-input",
                                          id: 'number_' + item.id
                                        }, null, 8, ["id"])
                                      ]),
                                      _: 2
                                    }, 1032, ["rules"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, { span: 12 }, {
                              default: _withCtx(() => [
                                (item.type==='date')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}],
                                      class: "dynamic-input"
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_date_picker, {
                                          "show-time": "",
                                          class: "custom-input",
                                          id: 'date_' + item.id
                                        }, null, 8, ["id"])
                                      ]),
                                      _: 2
                                    }, 1032, ["rules"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, { span: 12 }, {
                              default: _withCtx(() => [
                                (item.type==='select')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}],
                                      class: "dynamic-input"
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select, {
                                          id: 'select_' + item.id,
                                          onChange: _ctx.handleSelectChange
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                key: option,
                                                value: option
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(option), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["id", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1032, ["rules"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, { span: 12 }, {
                              default: _withCtx(() => [
                                (item.type==='checkbox')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}]
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_checkbox_group, {
                                          id: 'check_' + item.id
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                              return (_openBlock(), _createBlock(_component_a_checkbox, {
                                                key: option,
                                                value: option,
                                                style: {"line-height":"32px"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(option), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["id"])
                                      ]),
                                      _: 2
                                    }, 1032, ["rules"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, { span: 12 }, {
                              default: _withCtx(() => [
                                (item.type==='radio')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}]
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_radio_group, {
                                          id: 'radio_' + item.id
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                              return (_openBlock(), _createBlock(_component_a_radio, {
                                                key: option,
                                                value: option,
                                                style: {"line-height":"32px"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(option), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["id"])
                                      ]),
                                      _: 2
                                    }, 1032, ["rules"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"]),
      _createVNode(_component_a_modal, {
        open: _ctx.openPref,
        "onUpdate:open": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.openPref) = $event)),
        onCancel: _ctx.resetPref
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.Preferences')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.resetPref,
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "class"])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.preferences, (item) => {
            return (_openBlock(), _createBlock(_component_a_space, {
              key: item,
              style: {"display":"flex","margin-bottom":"8px"},
              align: "baseline",
              class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  "has-feedback": "",
                  name: 'name' + item.id,
                  "validate-status": _ctx.getValidatingStatus(item.id,'name'),
                  help: _ctx.getValidatingHelp(item.id,'name')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: item.preference,
                      "onUpdate:value": ($event: any) => ((item.preference) = $event),
                      id: 'name' + item.id,
                      placeholder: _ctx.t('Pos.Preference'),
                      class: "input-form"
                    }, null, 8, ["value", "onUpdate:value", "id", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["name", "validate-status", "help"]),
                _createVNode(_component_a_form_item, {
                  "has-feedback": "",
                  name: 'desc' + item.id,
                  "validate-status": _ctx.getValidatingStatus(item.id,'desc'),
                  help: _ctx.getValidatingHelp(item.id,'desc')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: item.description,
                      "onUpdate:value": ($event: any) => ((item.description) = $event),
                      id: 'desc' + item.id,
                      placeholder: _ctx.t('Pos.Description'),
                      class: "input-form"
                    }, null, 8, ["value", "onUpdate:value", "id", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["name", "validate-status", "help"]),
                _createVNode(_component_MinusCircleOutlined, {
                  class: "cancel-pref-icon",
                  onClick: ($event: any) => (_ctx.showDeletePref(item.id))
                }, null, 8, ["onClick"]),
                _createVNode(_component_EditOutlined, {
                  class: "edit-pref-icon",
                  onClick: ($event: any) => (_ctx.updatePreference(item))
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["class"]))
          }), 128)),
          _createVNode(_component_a_form, {
            ref: "formRefPref",
            name: "dynamic_form_nest_item",
            model: _ctx.dynamicValidateForm,
            onFinish: _ctx.onFinish
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicValidateForm.prefs, (pref, index) => {
                return (_openBlock(), _createBlock(_component_a_space, {
                  key: pref.id,
                  style: {"display":"flex","margin-bottom":"8px"},
                  align: "baseline"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      name: ['prefs', index, 'preference'],
                      rules: {
                required: true,
                message: _ctx.t('Pos.RequiredField'),
              }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: pref.preference,
                          "onUpdate:value": ($event: any) => ((pref.preference) = $event),
                          placeholder: _ctx.t('Pos.Preference')
                        }, null, 8, ["value", "onUpdate:value", "placeholder"])
                      ]),
                      _: 2
                    }, 1032, ["name", "rules"]),
                    _createVNode(_component_a_form_item, {
                      name: ['prefs', index, 'description'],
                      rules: {
                required: true,
                message: _ctx.t('Pos.RequiredField'),
              }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: pref.description,
                          "onUpdate:value": ($event: any) => ((pref.description) = $event),
                          placeholder: _ctx.t('Pos.Description')
                        }, null, 8, ["value", "onUpdate:value", "placeholder"])
                      ]),
                      _: 2
                    }, 1032, ["name", "rules"]),
                    _createVNode(_component_MinusCircleOutlined, {
                      class: "cancel-pref-icon",
                      onClick: ($event: any) => (_ctx.removePref(pref))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "dashed",
                    block: "",
                    onClick: _ctx.addPrefs
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PlusOutlined),
                      _createTextVNode(" " + _toDisplayString(_ctx.t('Pos.AddPreference')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              (_ctx.dynamicValidateForm.prefs.length!==0)
                ? (_openBlock(), _createBlock(_component_a_form_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        "html-type": "submit"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "onFinish"])
        ]),
        _: 1
      }, 8, ["open", "onCancel"]),
      _createVNode(_component_a_modal, {
        open: _ctx.displayPref,
        "onUpdate:open": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.displayPref) = $event)),
        onCancel: _ctx.closeShowPref
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.ViewPreference')), 1)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: _ctx.updatePref
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Confirm')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.closeShowPref
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_row, { class: "pref-container" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.t('Pos.Preference')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.preferences, (item) => {
            return (_openBlock(), _createBlock(_component_a_row, {
              key: item,
              gutter: 16
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 11 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      "has-feedback": "",
                      name: 'name' + item.id,
                      "validate-status": _ctx.getValidatingStatus(item.id,'name'),
                      help: _ctx.getValidatingHelp(item.id,'name')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: item.preference,
                          "onUpdate:value": ($event: any) => ((item.preference) = $event),
                          id: 'name' + item.id,
                          placeholder: _ctx.t('Pos.Preference'),
                          class: "input-form"
                        }, null, 8, ["value", "onUpdate:value", "id", "placeholder"])
                      ]),
                      _: 2
                    }, 1032, ["name", "validate-status", "help"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_col, { span: 11 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      "has-feedback": "",
                      name: 'desc' + item.id,
                      "validate-status": _ctx.getValidatingStatus(item.id,'desc'),
                      help: _ctx.getValidatingHelp(item.id,'desc')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: item.description,
                          "onUpdate:value": ($event: any) => ((item.description) = $event),
                          id: 'desc' + item.id,
                          placeholder: _ctx.t('Pos.Description'),
                          class: "input-form"
                        }, null, 8, ["value", "onUpdate:value", "id", "placeholder"])
                      ]),
                      _: 2
                    }, 1032, ["name", "validate-status", "help"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_col, { span: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MinusCircleOutlined, {
                      class: "cancel-pref-icon",
                      onClick: ($event: any) => (_ctx.showDeletePref(item.id))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["open", "onCancel"])
    ])
  ], 64))
}