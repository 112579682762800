<template>

  <div >
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>


  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{t('Pos.Dashboard')}}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Ingredients')}}</a-breadcrumb-item>
      <a-breadcrumb-item @click="navigatePage('/ingredients')"  class="breadcrumb-icon">{{t('Pos.Ingredients')}}</a-breadcrumb-item>

    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer" >
      {{ t('Pos.Add') }}
    </a-button>
  </div>

  <div>
    
    <a-table
      :columns="columns"
      :data-source="state.data"
      :pagination='false'
      class="table-container"
    >

      <template #emptyText>
        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key=='actions'">
          <a-space>
            <a-tooltip>
              <template #title>{{ t('Pos.View') }}</template>
              <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
            
              <template #icon>
                <EyeOutlined/>
              </template>
              </a-button>
            </a-tooltip>
              

            <a-tooltip>
              <template #title>{{ t('Pos.Update') }}</template>

              <a-button type="primary" @click="() => showDrawer(record)">
                <template #icon>
                  <EditOutlined />
                </template>
              </a-button>

            </a-tooltip>

            

            <a-tooltip>
              <template #title>{{ t('Pos.Delete') }}</template>
                <a-button type="primary" danger @click="showDeletePopup(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t('Pos.Preferences') }}</template>
                <a-button class="pref-btn"  @click="showPrefModal(record)">
                <template #icon>
                  <HeartOutlined />
                </template>
              </a-button>
            </a-tooltip>

          </a-space>
        </template>

        <template v-if="column.key=='unit'">
          <template v-if="record.unit==='mg'">{{t('Pos.Milligrams')}}</template>
          <template v-if="record.unit==='pcs'">{{t('Pos.Pieces')}}</template>
          <template v-if="record.unit==='ml'">{{t('Pos.Milliliter')}}</template>
          <template v-if="record.unit==='mm'">{{t('Pos.Millimeter')}}</template>
        </template>

        <template v-if="column.key==='has_price'">
           <template v-if="record.has_price===1 || record.has_price===true || record.has_price==='1'">
              <check-circle-two-tone two-tone-color="#52c41a" />
            </template>
            <template  v-if="record.has_price===0 || record.has_price===false || record.has_price==='0  ' ">
              <close-circle-two-tone two-tone-color="#ff4d4f"/>
            </template>
        </template>
      </template>

      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
            <template  v-if="column.key === 'name'">
              <a-input
                ref="searchInput"
                :placeholder="`${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
              /> 
            </template>

            <template  v-if="column.key === 'unit'">
              <div class="search-btn-radio">
                <a-radio-group  v-model:value="value" @change="selectedType" class="search-radio-grp">
                  <a-radio value="mg" class="radio-item"> {{t('Pos.Milligrams')}}</a-radio>
                  <a-radio value="pcs" class="radio-item"> {{t('Pos.Pieces')}}</a-radio>
                  <a-radio value="ml" class="radio-item">{{t('Pos.Milliliter')}}</a-radio>
                  <a-radio value="mm" class="radio-item">{{t('Pos.Millimeter')}}</a-radio>

                </a-radio-group>  
              </div>   
            </template>


            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              {{ t('Pos.Search') }}
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
              {{ t('Pos.Reset') }}
            </a-button>
          </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <div class="pagination">
     
      <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />
      <a-select
        class="select-input"
        v-model:value="selectPagination"
        @change="handleSelectPaginationChange"
        v-if="state.totalPages>10"
      >
        <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
      </a-select>
    </div>

    <!-- <div class="pagination" v-if="state.totalPagesSearch ===0 && !state.beginSearch">
      <a-pagination v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />
    </div>

    <div class="pagination" v-if="state.totalPagesSearch !==0 && state.beginSearch">
      
      <a-pagination v-model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch" :showSizeChanger=false />
    </div> -->


    <!-- update drawer -->
    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      @close="cancelUpdate"
    >

      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateIngredient')}}</p>
      </template>

      <a-form  ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-row :gutter="16">   
          <a-col :span="12">
            <a-form-item  name="name">
              <template #label>
                  <span class="label-form">{{ t('Pos.Name') }}</span>
              </template>
              <a-input  v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form"/>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item  name="unit">
              <template #label>
                <span class="label-form">{{ t('Pos.Unit') }}</span>
              </template>
              <a-select v-model:value="form.unit" show-search >
                <a-select-option value="mg" :label="t('Pos.Milligrams')">{{ t('Pos.Milligrams') }}</a-select-option>
                  <a-select-option value="pcs" :label="t('Pos.Pieces')">{{ t('Pos.Pieces') }}</a-select-option>
                  <a-select-option value="ml" :label="t('Pos.Milliliter')">{{ t('Pos.Milliliter') }}</a-select-option>
                  <a-select-option value="mm" :label="t('Pos.Millimeter')">{{ t('Pos.Millimeter') }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

        </a-row>

        <a-row :gutter="16">   
          <a-col :span="12">
            <a-form-item  name="has_price">
            <template #label>
              
              <span class="label-form">{{ t('Pos.HasPrice') }}</span>
            </template>
              <a-switch v-model:checked="form.has_price" />
            </a-form-item>
          </a-col>

            <a-col :span="12" v-if="form.has_price === 1 || form.has_price===true">
              <a-form-item  name="default_price">
                <template #label>
                    <span class="label-form">{{ t('Pos.Price') }}</span>
                </template>
              <a-input  v-model:value="form.default_price" :placeholder="t('Pos.Price')" class="input-form"/>
              </a-form-item>
            </a-col>
        </a-row>

        <a-row >   
          <a-col :span="24">
              <a-form-item  name="ingredient_category_id">
                <template #label>
                  <span class="label-form">{{ t('Pos.Category') }}</span>
                </template>
                <a-select  v-model:value="form.ingredient_category_id" show-search :option-filter-prop="'label'">
                  <a-select-option v-for="category in state.categories" :key="category.id" :value="category.id" :label="category.name" :selected="category.id === form.ingredient_category_id ">{{ category.name }}</a-select-option>
                </a-select>                
              </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">   
          <a-col :span="24">
            <a-form-item  name="photo">
              <template #label>
                <span class="label-form">{{ t('Pos.Photo') }}</span>
              </template>

              <a-upload-dragger
                  v-model:fileList="fileList"
                  name="file"
                  :multiple="false"
                  :max-count="1"
                  @change="handleChangeFile"
                  @drop="handleDrop"
                  :before-upload="beforeUpload"
                >

                  <p class="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
                  <p class="ant-upload-hint">
                    {{ t('Pos.dropTitle2') }}
                  </p>

              </a-upload-dragger>   
              <template v-if="form.photo!==null && !state.fileChanged">
                <div>
                  <p class="photo-info" @click="() => setVisible(true)"><PaperClipOutlined /><span class="photo-title"> {{extractFilename(form.photo)}}</span> <DeleteOutlined @click="deletePhoto"/></p>

                  <a-image
                    :width="200"
                    :style="{ display: 'none' }"
                    :preview="{
                      visible,
                      onVisibleChange: setVisible,
                    }"
                    :src="form.photo"
                  />
                </div>
              </template>    


            </a-form-item>
          </a-col>
        </a-row>

    
      </a-form>
      <template #footer>
          <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-button  type="primary" @click="updateIngredient(form.id)">{{ t('Pos.Update') }}</a-button>
            <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
          </a-space>
      </template>

    </a-drawer>


    <!-- begin display-->
    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      @after-open-change="afterOpenShowChange"
    >

      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.IngredientInfo')}}</p>

      </template>

      <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}"> 

        <a-col flex="150px">
          <a-image
            :width="100"
            :height="100"
            :src="form.photo"
            v-if="form.photo!==null"
          />  

          <img 
            :width="100"
            src="@/assets/img/nophoto.png"
            v-if="form.photo===null"
          />
        </a-col>
        <a-col flex="auto" >
          <p>{{transformUpperCase(form.name)}}</p>
        </a-col>
      </a-row>

      <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}"> 

        <a-col :span="12">
          <p><span class="info-title">{{ t('Pos.Category') }} :</span> {{form.ingredient_category_id}}</p>
        </a-col>
        <a-col :span="12" >
          <p>
            <span class="info-title">{{ t('Pos.Unit') }} :</span>
            <span value="mg" v-if="form.unit==='mg'">{{t('Pos.Milligrams')}}</span>
            <span value="pcs" v-if="form.unit==='pcs'">{{t('Pos.Pieces')}}</span>
            <span value="ml" v-if="form.unit==='ml'">{{t('Pos.Milliliter')}}</span>
            <span value="mm" v-if="form.unit==='mm'">{{t('Pos.Millimeter')}}</span>
          </p>
        </a-col>
      </a-row>


      <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}"> 

        <a-col :span="12">
          <p>
            <span class="info-title">{{ t('Pos.HasPrice') }} :</span>
            <span v-if="form.has_price===1 || form.has_price===true">
              <check-circle-two-tone two-tone-color="#52c41a" />
            </span>
            <span  v-if="form.has_price===0 || form.has_price===false">
              <close-circle-two-tone two-tone-color="#ff4d4f"/>
            </span>
          </p>
        </a-col>
        <a-col :span="12" v-if="form.has_price===1 || form.has_price===true">
          <p>
            <span class="info-title">{{ t('Pos.Price') }} :</span>
            {{form.default_price}}
          </p>
        </a-col>
      </a-row>
      
    </a-drawer>
    <!-- end display-->

    <!-- begin add drawer -->
    <a-drawer
      v-model:open="openAdd"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      @close="resetFormAdd"
    >

    <template #title>
      <p  :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddIngredient')}}</p>
    </template>

      <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical"  :class="{ 'is-rtl': textDirection==='rtl'}">
      <a-row :gutter="16">   
        <a-col :span="12">
          <a-form-item  name="name">
            <template #label>
                <span class="label-form">{{ t('Pos.Name') }}</span>
            </template>
            <a-input  v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form"/>
          </a-form-item>
        </a-col>     
        <a-col :span="12">
          <a-form-item  name="unit">
            <template #label>
                <span class="label-form">{{ t('Pos.Unit') }}</span>
            </template>

            <a-select v-model:value="addForm.unit" show-search>
              <a-select-option value="mg">{{t('Pos.Milligrams')}}</a-select-option>
              <a-select-option value="pcs">{{t('Pos.Pieces')}}</a-select-option>
              <a-select-option value="ml">{{t('Pos.Milliliter')}}</a-select-option>
              <a-select-option value="mm">{{t('Pos.Millimeter')}}</a-select-option>
            </a-select>

          </a-form-item>
        </a-col>       
      </a-row>

      <a-row :gutter="16">   
        <a-col :span="12">
          <a-form-item  name="has_price">
            <div class="switch-container">
              <p>{{ t('Pos.HasPrice') }} :</p>
              <a-switch  v-model:checked="addForm.has_price" />
            </div>
          </a-form-item>
        </a-col>

        <a-col :span="12" v-if="addForm.has_price===1 || addForm.has_price===true ">
          <a-form-item  name="default_price">
            <template #label>
                <span class="label-form">{{ t('Pos.Price') }}</span>
            </template>
            <a-input  v-model:value="addForm.default_price" :placeholder="t('Pos.Price')" class="input-form"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">   
        <a-col :span="24">
          <a-form-item  name="ingredient_category_id">
            <template #label>
              <span class="label-form">{{ t('Pos.Category') }}</span>
            </template>
            <a-select  v-model:value="addForm.ingredient_category_id" show-search :option-filter-prop="'label'">
              <a-select-option v-for="category in state.categories" :key="category.id" :value="category.id" :label="category.name">{{ category.name }}</a-select-option>
            </a-select>            
          </a-form-item>
        </a-col>

        
      </a-row>

      <a-row :gutter="16">   
        <a-col :span="24">
          <a-form-item  name="photo">
            <template #label>
              <span class="label-form">{{ t('Pos.Photo') }}</span>
            </template>

            <a-upload-dragger
              v-model:fileList="fileList"
              name="file"
              :multiple="false"
              :max-count="1"
              @change="handleChange"
              @drop="handleDrop"
              :before-upload="beforeUpload"
            >

              <p class="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
              <p class="ant-upload-hint">
                {{ t('Pos.dropTitle2') }}
              </p>

            </a-upload-dragger>

          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="state.customFields !== null" :gutter="16">
          <a-col v-for="item in state.customFields" :key="item" :span="12">
           
            <a-row :span="12">
              <a-form-item
                :name="'customData_' + item.id"
                v-if="item.type==='text'"
                :rules="[item.is_mandatory === 1 ? { required: true, message:t('Pos.RequiredField')  } : {}]"
                class="dynamic-input"
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>
                <a-input class="custom-input" :id="'txt_' + item.id" />

              
              
              </a-form-item>
            </a-row> 
            
            <a-row :span="12">
              <a-form-item
                name="customData"
                :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                v-if="item.type==='textarea'"
                class="dynamic-input"
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>
                <a-textarea class="custom-input" :id="'area_' + item.id"   :rows="4" />
              </a-form-item>
            </a-row>
    
           
            <a-row :span="12">
              <a-form-item
                name="customData"
                :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                v-if="item.type==='number'"
                class="dynamic-input"
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>
                <a-input-number class="custom-input" :id="'number_' + item.id" />
              </a-form-item>
            </a-row>
           
           
            <a-row :span="12">
              <a-form-item
                name="customData"
                :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                v-if="item.type==='date'"
                class="dynamic-input"
              >
                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>


                <a-date-picker show-time class="custom-input" :id="'date_' + item.id" />
              </a-form-item>
            </a-row>
          
            
            <a-row :span="12">
              <a-form-item
                name="customData"
                :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                v-if="item.type==='select'"
                class="dynamic-input"
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>

                <a-select
                  
                  :id="'select_' + item.id" @change="handleSelectChange"
                >
                  <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                    <a-select-option  :value="option">{{ option }}</a-select-option>
                  </template>
                </a-select>

              </a-form-item>
            </a-row>
            
            <a-row :span="12">
              <a-form-item 
                name="customData"
                :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                v-if="item.type==='checkbox'"
                
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>

                <a-checkbox-group  :id="'check_' + item.id">
                  <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                    <a-checkbox :value="option" style="line-height: 32px">{{ option }}</a-checkbox>
                  </template>
                </a-checkbox-group>
              </a-form-item>
            </a-row>
            
            
            <a-row :span="12">
              <a-form-item 
                name="customData"
                :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                v-if="item.type==='radio'"
               
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>

                <a-radio-group :id="'radio_' + item.id">
                  <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                    <a-radio :value="option" style="line-height: 32px">{{ option }}</a-radio>
                  </template>
                </a-radio-group>
              </a-form-item>
            </a-row>
            

          </a-col>
      </a-row>
      
          
      </a-form>
      <template #footer>
          <a-space  :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-button  type="primary" @click="addIngredient">{{ t('Pos.Add') }}</a-button>
            <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
          </a-space>
      </template>
    </a-drawer>
    <!-- add drawer --> 


    <!-- preference modal -->
      <a-modal v-model:open="openPref" @cancel="resetPref" >

        <template #title>
          <p  :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.Preferences') }}</p>
        </template> 

        <template #footer>
          <a-button key="back" @click="resetPref"  :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.Cancel') }}</a-button>
        </template>

        

        <template v-for="item in state.preferences" :key="item">

          <a-space
            style="display: flex; margin-bottom: 8px"
            align="baseline"
            :class="{ 'is-rtl': textDirection==='rtl'}"
          >

            <a-form-item has-feedback  :name="'name' + item.id" :validate-status="getValidatingStatus(item.id,'name')" :help="getValidatingHelp(item.id,'name')">
              <a-input v-model:value="item.preference" :id="'name' + item.id" :placeholder="t('Pos.Preference')" class="input-form"/>
            </a-form-item>

            <a-form-item has-feedback  :name="'desc' + item.id" :validate-status="getValidatingStatus(item.id,'desc')" :help="getValidatingHelp(item.id,'desc')">
              <a-input v-model:value="item.description" :id="'desc' + item.id" :placeholder="t('Pos.Description')" class="input-form"/>
            </a-form-item>
            

            <MinusCircleOutlined class="cancel-pref-icon" @click="showDeletePref(item.id)" />
            <EditOutlined class="edit-pref-icon" @click="updatePreference(item)"/>

          </a-space>
          <!-- <a-row :gutter="16">
            <a-col :span="11">
              <a-form-item has-feedback  :name="'name' + item.id" :validate-status="getValidatingStatus(item.id,'name')" :help="getValidatingHelp(item.id,'name')">
                <a-input v-model:value="item.preference" :id="'name' + item.id" :placeholder="t('Pos.Preference')" class="input-form"/>
              </a-form-item>
              
            </a-col>
            <a-col :span="11">
              <a-form-item has-feedback  :name="'desc' + item.id" :validate-status="getValidatingStatus(item.id,'desc')" :help="getValidatingHelp(item.id,'desc')">
                <a-input v-model:value="item.description" :id="'desc' + item.id" :placeholder="t('Pos.Description')" class="input-form"/>
              </a-form-item>
            </a-col>

            <a-col :span="2" >
              <MinusCircleOutlined class="cancel-pref-icon" @click="showDeletePref(item.id)" />
            </a-col>
          </a-row> -->
        </template>

       

        <a-form
          ref="formRefPref"
          name="dynamic_form_nest_item"
          :model="dynamicValidateForm"
          @finish="onFinish"
          
        >
          <a-space
            v-for="(pref, index) in dynamicValidateForm.prefs"
            :key="pref.id"
            style="display: flex; margin-bottom: 8px"
            align="baseline"
          >
            <a-form-item
              :name="['prefs', index, 'preference']"
              :rules="{
                required: true,
                message: t('Pos.RequiredField'),
              }"
            >
              <a-input v-model:value="pref.preference" :placeholder="t('Pos.Preference')" />
            </a-form-item>
            <a-form-item
              :name="['prefs', index, 'description']"
              :rules="{
                required: true,
                message: t('Pos.RequiredField'),
              }"
            >
              <a-input v-model:value="pref.description" :placeholder="t('Pos.Description')" />
            </a-form-item>
            <MinusCircleOutlined class="cancel-pref-icon" @click="removePref(pref)" />
          </a-space>
          <a-form-item>
            <a-button type="dashed" block @click="addPrefs">
              <PlusOutlined />
              {{t('Pos.AddPreference')}}
            </a-button>
          </a-form-item>
          <a-form-item v-if="dynamicValidateForm.prefs.length!==0">
            <a-button type="primary" html-type="submit">{{t('Pos.Add')}}</a-button>
          </a-form-item>
        </a-form>



        <!-- <template #footer>
          <a-button key="submit" type="primary"  @click="addPref">{{ t('Pos.Confirm') }}</a-button>
          <a-button key="back" @click="resetPref">{{ t('Pos.Cancel') }}</a-button>
        </template>

        <a-form  ref="formPrefRef" :model="formPref" :rules="prefRules" layout="vertical">
        
          <a-row>
            <a-col :span="24">
              <a-form-item name="preference">
                <template #label>
                  <span class="label-form">{{ t('Pos.Preference') }}</span>
                </template>
                <a-input v-model:value="formPref.preference" :rows="4" :placeholder="t('Pos.Preference')" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="24">
              <a-form-item name="description">
                <template #label>
                  <span class="label-form">{{ t('Pos.Description') }}</span>
                </template>
                <a-textarea v-model:value="formPref.description" :rows="4" :placeholder="t('Pos.Description')" />
              </a-form-item>
            </a-col>
          </a-row>
                

        </a-form> -->

      </a-modal>
    <!-- preference modal -->

    <!-- display preferences modal -->
    <a-modal v-model:open="displayPref" @cancel="closeShowPref" >

      <template #title>
        <p>{{ t('Pos.ViewPreference') }}</p>
      </template> 

      <template #footer>
        <a-button key="submit" type="primary"  @click="updatePref">{{ t('Pos.Confirm') }}</a-button>
        <a-button key="back" @click="closeShowPref">{{ t('Pos.Cancel') }}</a-button>
      </template>

      <a-row  class="pref-container">
        <a-col :span="24">
          <span class="label-form">{{ t('Pos.Preference') }}</span>
        </a-col>
      </a-row>

      <template v-for="item in state.preferences" :key="item">

        <a-row :gutter="16">
          <a-col :span="11">
            <a-form-item has-feedback  :name="'name' + item.id" :validate-status="getValidatingStatus(item.id,'name')" :help="getValidatingHelp(item.id,'name')">
              <a-input v-model:value="item.preference" :id="'name' + item.id" :placeholder="t('Pos.Preference')" class="input-form"/>
            </a-form-item>
            
          </a-col>
          <a-col :span="11">
            <a-form-item has-feedback  :name="'desc' + item.id" :validate-status="getValidatingStatus(item.id,'desc')" :help="getValidatingHelp(item.id,'desc')">
              <a-input v-model:value="item.description" :id="'desc' + item.id" :placeholder="t('Pos.Description')" class="input-form"/>
            </a-form-item>
          </a-col>

          <a-col :span="2" >
            <MinusCircleOutlined class="cancel-pref-icon" @click="showDeletePref(item.id)" />
          </a-col>
        </a-row>
      </template>

     

    </a-modal>

  </div>


</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InboxOutlined,
  SearchOutlined,
  PaperClipOutlined,
  HeartOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import {ingredientsService, ingredientCategoriesService, customFieldsService, preferenceService } from'@/_services'
import type { UploadChangeParam, UploadFile } from 'ant-design-vue';
import { useRouter , useRoute} from 'vue-router';
import { useI18n } from 'vue-i18n';
import LbrxLoaderSpinner from '../../../components/LbrxLoaderSpinner.vue';
import type { FormInstance } from 'ant-design-vue';
import { Empty } from 'ant-design-vue';

export default defineComponent({
  name: 'indexIngredienrs',
  components: {
    EyeOutlined,
    DeleteOutlined,
    InboxOutlined,
    EditOutlined,
    LbrxLoaderSpinner,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    SearchOutlined,
    PaperClipOutlined,
    HeartOutlined,
    MinusCircleOutlined,
    PlusOutlined

  },
  setup() {
    const modalWidth = ref(720);
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    const { t } = useI18n();
    const loading = ref(false);

    
    

    const router = useRouter();
    const route=useRoute();

    const path = route.path;



    const navigatePage = (link: string) => {
      router.push(link); 
    };

    

    const state = reactive({
      searchText: '',
      searchedColumn: '',
      data: [] as any[],
      categories:[] as any[],
      hasPrice: 0,
      action:'',
      totalPages:0,
      customFields:[] as any[],
      totalPagesSearch:0,
      selectedType:'',
      beginSearch:false,
      fileChanged:false,

      preferences: [] as any[],

      searchName:'',

    });
    const searchInput = ref<HTMLInputElement | null>(null);
    const searchContact = ref<HTMLInputElement | null>(null);

    const value = ref<string>('1');
    const selectedType =(value:any)=>{
      

      console.log('selected radio option ', value.target.value);
      
      state.selectedType = value.target.value;
      
    }


    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      openDisplay.value = true;
      getById(record.id);
    };

    let debounceTimer: ReturnType<typeof setTimeout> | null = null;

    const columns = [
      {
        title: t('Pos.Name'),
        key:'name',
        dataIndex: 'name',
        customFilterDropdown: true,
        onFilterDropdownOpenChange:  (visible: boolean) => {
          if (visible  && !searchInput.value) {
            setTimeout(() => {
            const input = document.querySelector('.ant-table-filter-dropdown input');

            if (input instanceof HTMLInputElement) {
              searchInput.value = input;
              searchInput.value.focus();
            }
            }, 100);
          }
        },
      },
      {
        title: t('Pos.Unit'),
        key:'unit',
        dataIndex: 'unit',
        customFilterDropdown: true,
      },
      {
        title:t('Pos.HasPrice'),
        key:'has_price',
        dataIndex: 'has_price',
      },
      
      {
        title: t('Pos.Actions'),
        key:'actions',
        dataIndex: 'actions',
      }
    ];

    //form  
    const formRef = ref();
    const formPrefRef = ref();
    const addFormRef = ref();

    const form = reactive({
      id:'',
      name: '',
      photo: '',
      has_price: false,
      default_price: '',
      unit:'',
      ingredient_category_id: '',
      preferences:'',
    });

    const addForm = reactive({
      id:'',
      name: '',
      photo: '',
      description:'',
      has_price: 0,
      default_price: '',
      unit:'',
      ingredient_category_id: '',
    });

    const formPref = reactive({
      id:'',
      target_type: '',
      target_id: '',
      preference: '',
      description:'',
    });

    //pagination 

    const selectPagination = ref('10');
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

    const perPageSearch = ref<number>(10);
    const currentPageSearch = ref<number>(1);


    const rules: Record<string, Rule[]> = {
      name: [{ required: true, message: t('Pos.RequiredField') }],
      unit: [{ required: true, message: t('Pos.RequiredField') }],
      default_price: [
        { required: true, message: t('Pos.RequiredField') },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t('Pos.numericField'));
          }
        }
      ],
      ingredient_category_id: [{ required: true, message: t('Pos.RequiredField') }],
    };

    const prefRules: Record<string, Rule[]> = {
      preference: [{ required: true, message: t('Pos.RequiredField') }],
    };

    //notification
    const showSuccessNotification  = (message:string)=>{
      notification.success({
        message: message,
        duration: 3,
        top: '80px',
        style:{
        backgroundColor: '#bfffca8a',
        color: 'white'
        }
      });
    };

    //drawer
    const open = ref<boolean>(false);

    const afterOpenChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const showDrawer = (record:any) => {
      updateModalWidth();
      open.value = true;
      getById(record.id);
            
            
    };

    const openAdd = ref<boolean>(false);

    const afterOpenAddChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const showAddDrawer = () => {
      updateModalWidth();
      openAdd.value = true;
      getCustomFields();
    };

    const resetFormAdd = () => {
      console.log("begin rest");
      addFormRef.value.resetFields();

      fileList.value=[];

    };

    const getIngredients=()=>{
      loading.value = true;
      ingredientsService.getIngredientsPaginate(perPage.value, currentPage.value).then((response)=>{
        console.log("getting ingredients", response.data);
        state.data=response.data;
        state.totalPages = response.meta.total;
      }).catch((error)=>{
        console.log("error get ingredients", error);
      }).finally(()=>{
        loading.value = false;
      });
    }

    const getById = (id: string) => {
      loading.value=true;
      ingredientsService.show(id)
        .then(res => {
          form.name = res.data.name;
          form.photo = res.data.photo;
          form.has_price = res.data.has_price === 1;
          form.default_price = res.data.default_price;
          form.ingredient_category_id = res.data.ingredient_category_id;
          form.unit=res.data.unit;
          form.id = res.data.id;

          form.preferences=res.data.preferences;
          state.preferences=res.data.preferences;
          loading.value=false;


        })
        .catch(error => {
          console.error('Error fetching ingredient by id:', error);
        });
    }

    const cancelUpdate=()=>{

      console.log('close update filed')
      open.value=false;
    }

    const selectedOption = ref('');
    const  handleSelectChange=(value:any) =>{
      console.log('Selected value:', value);
      selectedOption.value=value;
    }

    
    const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

   


    //file
    const fileList = ref([]);

    const fileListUpdate = ref([]);

    const beforeUpload = () => {
      return false;
    };


    const handlePageChange = (newPage:any) => {
      currentPage.value = newPage;
      

      console.log('per page', perPage.value);
      console.log('page', currentPage.value);


      if(state.beginSearch===true){
        state.data=[];
        if(state.selectedType!==''){
          loading.value = true;
          ingredientsService.filterWithPagination("unit",state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
            state.data=res.data;
            state.totalPages=res.meta.total;
          }).catch((err:any) => {
            console.log(err)
          }).finally(()=>{
            loading.value = false;
          });

        }

        if(state.searchName!=''){
          loading.value = true;
          ingredientsService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
            state.data=res.data;
            state.totalPages=res.meta.total;
          }).catch((err:any) => {
            console.log(err)
          }).finally(()=>{
            loading.value = false;
          });
        }

      }else{
        loading.value = true;
        ingredientsService.getIngredientsPaginate(perPage.value,currentPage.value).then((response)=>{
        
          state.data=response.data;
          state.totalPages=response.meta.total;
        }).catch((error)=>{
          console.log("error api : get products", error);
        })
        .finally(()=>{
          loading.value = false;
        });

      }

      
    }

    const handlePageChangeSearch = (newPage:any) => {
      currentPageSearch.value = newPage;
      loading.value = true;

      console.log('per page', perPageSearch.value);
      console.log('page', currentPageSearch.value);

      state.data=[];
      if(state.selectedType!==''){
        ingredientsService.filterWithPagination("unit",state.selectedType,currentPageSearch.value.toString(), perPage.value.toString()).then((res)=>{
        console.log('res', res.data);
        state.data=res.data;
        state.totalPagesSearch = res.meta.total;
        }).catch((err:any) => {
          console.log(err)
        }).finally(()=>{
          loading.value = false;
        });
      }

      if(state.searchText!==''){
        ingredientsService.filterWithPagination("name",state.searchText,currentPageSearch.value.toString(), perPage.value.toString()).then((res)=>{
        console.log('res', res.data);
        state.data=res.data;
        state.totalPagesSearch = res.meta.total;
        }).catch((err:any) => {
          console.log(err)
        }).finally(()=>{
          loading.value = false;
        });
      }
      


    }

    
    let  photoInfo ="";
    const fileInfo = ref<UploadFile | null>(null);

    const fileUpdateInfo = ref<UploadFile | null>(null);

    const handleChange = (info: UploadChangeParam) => {
     fileInfo.value=info.file;
    };

    const handleChangeFile = (info: UploadChangeParam) => {
      state.fileChanged=true;
      fileUpdateInfo.value=info.file;
    };

    function extractFilename(url:any) {
      const parts = url.split('/');
      const filename = parts[parts.length - 1];
      return filename;
    }

    function handleDrop(e: DragEvent) {
      console.log(e);
    }

    const addIngredients =()=>{
      
      
      addFormRef.value
      .validate()
      .then(()=>{
        let payload = {
          name: addForm.name,
          has_price : addForm.has_price,
          default_price :addForm.default_price,
          unit:addForm.unit,
          ingredient_category_id:addForm.ingredient_category_id,
        }
        loading.value = true;
        ingredientsService.create(payload)
        .then((response)=>{
          
          state.data.push(response.data);
          showSuccessNotification(t('Pos.AddIngredientSuccess'));
        }).catch((err) => {
          console.log(err);
          resetFormAdd();

        }).finally(() => {
          
          console.log('finish');
          loading.value = false;
          openAdd.value=false;
          resetFormAdd();
          
        }); 
      }).catch((error:any) => {
        console.error('Validation error:', error);
      });
      

    }

    const addIngredient =()=>{
           
      addFormRef.value
      .validate()
      .then(()=>{
        let customData: { [key: string]: string } = {};
         state.customFields.forEach(element => {
              
          const txtInput = document.getElementById(`txt_${element.id}`) as HTMLInputElement;
          const areaInput = document.getElementById(`area_${element.id}`) as HTMLInputElement;
          const numberInput = document.getElementById(`number_${element.id}`) as HTMLInputElement;
          const dateInput = document.getElementById(`date_${element.id}`) as HTMLInputElement;
          const selectInput = document.getElementById(`select_${element.id}`) as HTMLInputElement;
          const checkInput = document.getElementById(`check_${element.id}`) as HTMLInputElement;
          const radioInput = document.getElementById(`radio_${element.id}`) as HTMLInputElement;
            
          if(txtInput){
            console.log(`input ${element.name}:`, txtInput.value);
            customData[element.id] = txtInput.value;
          }
          if(areaInput){
            console.log(`input ${element.name}:`, areaInput.value);
            customData[element.id] = areaInput.value;

          }
          if(numberInput){
            console.log(`input ${element.name}:`, numberInput.value);
            customData[element.id] = numberInput.value;

          }
          if(dateInput){
            console.log(`input ${element.name}:`, dateInput.value);
            customData[element.id] = dateInput.value;

          }

          if(selectInput){
            console.log('selected input');
            console.log(`input ${element.name}:`, selectInput.value);
            

            if(selectedOption.value!==null){
              customData[element.id] =selectedOption.value;
            }

          }

          if(checkInput){
            const selectedCheckboxes = Array.from(checkInput.querySelectorAll('input[type="checkbox"]:checked')) as HTMLInputElement[];
            const selectedValues = selectedCheckboxes.map((checkbox) => checkbox.value);
            console.log(`Selected values for ${element.name}:`, selectedValues);
            customData[element.id] = selectedValues.join(','); 

          }

          if(radioInput){
            console.log(`input ${element.name}:`, radioInput.value);
            customData[element.id] = radioInput.value;
          }

              
        });
        console.log('customData:', customData);
        const file = fileList.value[0];
        const hasPrice = addForm.has_price ? 1 : 0;
        loading.value = true;
        ingredientsService.addIngredient(addForm.name,hasPrice,addForm.default_price,
          addForm.ingredient_category_id,JSON.stringify(customData),addForm.unit, fileInfo.value  )
        .then((response)=>{
          
          //state.data.push(response.data);
          getIngredients();
          showSuccessNotification(t('Pos.AddIngredientSuccess'));

        }).catch((err) => {
        console.log(err)
        }).finally(() => {
          loading.value = false;
            openAdd.value=false;
           resetFormAdd();
        }); 
      }).catch((error:any) => {
        console.error('Validation error:', error);
      });
    }

  
    

    const deleteIngredient = (id: string) => {
      loading.value = true;
      ingredientsService.delete(id).then((res) => {
        state.data = state.data.filter(e => e.id !== id);
        showSuccessNotification(t('Pos.DeleteIngredientSuccess'));
      }).catch((err) => {
          console.log(err)
      }).finally(() => {
        console.log('finish');
        loading.value = false;
      })
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t('Pos.TitleDeleteingredient'),
        text: t('Pos.TextDeleteingredient'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t('Pos.Delete'),
        cancelButtonColor: "#d33",
        cancelButtonText: t('Pos.Cancel'),
        customClass: {
            popup: 'swal2-popup'
        }

      }).then((result) => {
        if (result.isConfirmed) {
          deleteIngredient(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      })
    };


    const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
    console.log('open', bool);
};
    const getCategories=()=>{
      ingredientCategoriesService.getAll().then((response)=>{
        state.categories=response.data;
      }).catch((error)=>{
        console.log('error get categories api', error);
      });
    }

    function removeLeadingSlash(str:string) {
      if (str.startsWith('/')) {
        return str.substring(1);
      } else {
        return str;
      }
    }

    const getCustomFields=()=>{
      customFieldsService.filter("ingredient").then((response)=>{
        state.customFields= response.data;
        console.log('custom fields', state.customFields);
      })
    }

    onMounted(() => {
      getIngredients();
      getCategories();
      console.log('path : ', path);

      searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
      searchContact.value = document.querySelector('.ant-table-filter-dropdown input');

          
    });

    const transformUpperCase=(str:string)=>{
      if (!str || str.length === 0) {
        return str;
      }

      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const filter=(key:string, value:string)=>{
      currentPage.value=1;
      loading.value=true;
      ingredientsService.filterWithPagination(key,value, currentPage.value.toString(),perPage.value).then((res)=>{
        state.data=res.data;
        state.totalPages=res.meta.total;
        state.beginSearch=true;
        loading.value=false;
      }).catch((error)=>{
        console.log('error fetching', error);
      });
    }

    //search

    const handleSelectPaginationChange=(selectedItem:any)=>{
      console.log('selected Per page:', selectedItem);
      perPage.value=Number.parseInt(selectedItem);
      currentPage.value=1;

      if(state.beginSearch===true){

        if(state.selectedType!==''){
          loading.value = true;
          ingredientsService.filterWithPagination("unit",state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
            state.data=res.data;
            state.totalPages=res.meta.total;
          }).catch((err:any) => {
            console.log(err)
          }).finally(()=>{
            loading.value = false;
          });
        }

        

        if(state.searchName!=''){
          loading.value = true;
          ingredientsService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
            state.data=res.data;
            state.totalPages = res.meta.total;
          }).catch((err:any) => {
            console.log(err)
          }).finally(()=>{
            loading.value = false;
          });

        }

      }else{

        loading.value=true;
        ingredientsService.getIngredientsPaginate(perPage.value,currentPage.value).then((response)=>{
          state.data=response.data;
          state.totalPages=response.meta.total;
        }).catch((error)=>{
          console.log("error api : get products", error);
        })
        .finally(()=>{
          loading.value = false;
        });

      }

            
    }

    const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
      console.log('selected keys', selectedKeys);
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;

      if (dataIndex === 'name' && state.searchText!=='') {
        state.totalPagesSearch=0;
        state.searchName=state.searchText;
        filter("name",state.searchText );
      }

      if (dataIndex === 'unit' && state.selectedType!=='') {
        state.totalPagesSearch=0;
        console.log('begin filter unit', state.selectedType);
        filter("unit",state.selectedType );
      }
    };

    const handleReset =  (clearFilter: (arg: any) => void)=> {
      console.log('reset');

      clearFilter({ confirm: true });
      state.searchText = '';
      state.selectedType='';
      state.totalPagesSearch=0;
      state.beginSearch=false;
      value.value='';
      state.searchName='';

      currentPage.value=1;
      perPage.value=10;
      selectPagination.value='10';
      getIngredients();
    };

    const filterName=(value:string)=>{
      console.log('value serch', value);
    }

    //img visible 
    const visible = ref<boolean>(false);
    const setVisible = (value:any): void => {
      visible.value = value;
    };

    const deletePhoto=()=>{
      form.photo="";
      state.fileChanged=true;
    }

    //preference modal  
    const openPref = ref<boolean>(false);
    const displayPref = ref<boolean>(false);

    const showPrefrences=()=>{
      displayPref.value = true;
    }
    const closeShowPref=()=>{
      displayPref.value = false;
      validationHelp.value={};
      validationStatus.value={};
    }

    const showPrefModal = (record:any) => {
      formPref.target_id=record.id;
     
      getById(record.id);

      openPref.value = true;

      console.log('prefrences dynamic',dynamicValidateForm.prefs )
    }


    const resetPref=()=>{
      //formPrefRef.value.resetFields();
      openPref.value = false;
    }

    //add pref dynamic fields 
    interface Preference {
      preference: string;
      description: string;
      target_type: string;
      target_id:string;
    }
    const formRefPref = ref<FormInstance>();
    const dynamicValidateForm = reactive<{ prefs: Preference[] }>({
      prefs: [],
    });

    const removePref = (item: Preference) => {
      const index = dynamicValidateForm.prefs.indexOf(item);
      console.log('remove', item);

      if (index !== -1) {
        dynamicValidateForm.prefs.splice(index, 1);
      }
    };

    const addPrefs = () => {
      dynamicValidateForm.prefs.push({
        preference: '',
        description: '',
        target_type:'ingredient',
        target_id:formPref.target_id
      });
    };

    const onFinish = (values:any) => {
      console.log('Received values of form:', values);
      console.log('dynamicValidateForm.prefs:', dynamicValidateForm.prefs);


      dynamicValidateForm.prefs.forEach((item)=>{
        console.log('item of dynamic ', item);
        loading.value = true;
        preferenceService.store(item).then((res)=>{
          console.log('res', res);
          showSuccessNotification(t('Pos.PreferenceAddSuccess'));
          //getById(formPref.target_id);
          state.preferences.push(res.data);
        }).catch((error)=>{
          console.log(error);
        }).finally(()=>{
          loading.value = false;
        });
      });
      dynamicValidateForm.prefs = [];
    };


    

    const addPref=()=>{
      formPrefRef.value.validate().then(()=>{
        let payload={
          target_type:"ingredient",
          target_id:formPref.target_id,
          preference:formPref.preference,
          description:formPref.description,
        }
        console.log('payload', payload);
        loading.value = true;
        preferenceService.store(payload).then((res)=>{
          
          showSuccessNotification(t('Pos.PreferenceAddSuccess'));
          getById(formPref.target_id);
        }).catch((error)=>{
          console.log(error);
        }).finally(()=>{
          loading.value = false;
          openPref.value = false;
          resetPref();
        })
      }).catch((error:any) => {
        console.error('Validation error:', error);
      });
    }

    //validation 
    interface ValidationStatus {
      [key: string]: string;
    }

    interface ValidationHelp {
      [key: string]: string;
    }

    const validationStatus = ref<ValidationStatus>({});

    const validationHelp=ref<ValidationHelp>({});

    const getValidatingStatus=(id:any, fieldType: string)=>{
      return validationStatus.value[`${fieldType}${id}`] || '';
    }

    const getValidatingHelp=(id:any, fieldType: string)=>{
      return validationHelp.value[`${fieldType}${id}`] || '';
    }

    const updatePreference=(item:any)=>{
      let hasErrors = false;
      const txtInput = document.getElementById(`name${item.id}`) as HTMLInputElement;
      const descInput = document.getElementById(`desc${item.id}`) as HTMLInputElement;

      if(txtInput.value ===""){
        validationStatus.value[`name${item.id}`] = 'error';
        validationHelp.value[`name${item.id}`] = t('Pos.RequiredField');
        hasErrors = true;
      }else{
        validationStatus.value[`name${item.id}`] = 'success';
        validationHelp.value[`name${item.id}`] = '';  
      }

      if (!hasErrors){

        let payload={
          id:item.id,
          target_type:"ingredient",
          target_id:item.target_id,
          preference:txtInput.value,
          description:descInput.value,
        }

        console.log('payload',payload);
        loading.value=true;
        preferenceService.update(item.id,payload).then((res)=>{
          console.log('pref updated success', res);
          showSuccessNotification(t('Pos.PreferenceUpdateSuccess'));
          loading.value=false;
        }).catch((error)=>{
          console.log('error updating prefrene', error);
          loading.value=false;
        }).finally(()=>{
          
          displayPref.value=false;

          validationHelp.value={};
          validationStatus.value={};
        });

      }else{
        console.log('error in validation of dynmaic fields');
      }
    }

    const updatePref=()=>{
      let hasErrors = false;

      let payloads: { id: string, payload: any }[] = [];
     
      state.preferences.forEach((item)=>{
        const txtInput = document.getElementById(`name${item.id}`) as HTMLInputElement;
        const descInput = document.getElementById(`desc${item.id}`) as HTMLInputElement;

        if(txtInput.value ===""){
          validationStatus.value[`name${item.id}`] = 'error';
          validationHelp.value[`name${item.id}`] = t('Pos.RequiredField');
          hasErrors = true;
        }else{
          validationStatus.value[`name${item.id}`] = 'success';
          validationHelp.value[`name${item.id}`] = '';     
          console.log('item', item);

          let payload={
            id:item.id,
            target_type:"ingredient",
            target_id:item.target_id,
            preference:txtInput.value,
            description:descInput.value,
          }

          payloads.push({ id: item.id, payload: payload });
         
        }
      });

      if (!hasErrors){
        payloads.forEach((item)=>{
          loading.value=true;
          preferenceService.update(item.id, item.payload).then((res)=>{
            console.log('pref updated success', res);
            showSuccessNotification(t('Pos.PreferenceUpdateSuccess'));
            loading.value=false;
          }).catch((error)=>{
            console.log('error updating prefrene', error);
            loading.value=false;
          }).finally(()=>{
            
            displayPref.value=false;

            validationHelp.value={};
            validationStatus.value={};
          })
        });
         

      }else{
        console.log('error in validation of dynmaic fields');
      }
    }

    const showDeletePref = (id: any) => {
      Swal.fire({
        title: t('Pos.TitleDeletePreference'),
        text: t('Pos.TextDeletePreference'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t('Pos.Delete'),
        cancelButtonColor: "#d33",
        cancelButtonText: t('Pos.Cancel'),
        customClass: {
            popup: 'swal2-popup'
        }

      }).then((result) => {
        if (result.isConfirmed) {
          deletePref(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      })
    };

    const deletePref=(id:any)=>{
      loading.value = true;
      preferenceService.delete(id).then((res)=>{
        console.log('delete success', res);
        showSuccessNotification(t('Pos.PreferenceDeleteSuccess'));
        state.preferences = state.preferences.filter(e => e.id !== id);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        loading.value = false;
        //displayPref.value=false;
      })
    }

    const updateIngredient =(id:string)=>{
    
      formRef.value
      .validate()
      .then(()=>{
        const hasPrice=form.has_price ? 1 : 0
        loading.value = true;
        ingredientsService.updateIngredient(id, form.name,fileUpdateInfo.value ,hasPrice,
          form.default_price,form.ingredient_category_id, form.unit ).then((response)=>{
            showSuccessNotification(t('Pos.UpdateIngredientSuccess'));
            state.data = state.data.map(el => el.id !== id ? el : response.data);
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          
          loading.value = false;
          open.value=false;
          state.fileChanged=false;
          fileList.value=[];
        }); 


        // ingredientsService.update(id, payload).then((response)=>{
        //     open.value=false;
        //     state.data = state.data.map(el => el.id !== id ? el : response.data);
        // }).catch((err) => {
        // console.log(err)
        // }).finally(() => {
        //   showSuccessNotification(t('Pos.UpdateIngredientSuccess'));
        //   console.log('finish')
        // }); 
      });
      

    }

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );




    return {
      updateModalWidth,
      modalWidth,
      t,
      textDirection,
      deletePref,
      fileListUpdate,
      deletePhoto,
      visible,
      setVisible,
      currentPage,
      perPage,
      handleSearch,
      handleReset,
      afterOpenShowChange,
      transformUpperCase,
      openDisplay,
      showDisplayDrawer,
      state, 
      columns,
      formRef,
      addFormRef,
      form,
      addForm,
      rules,
      open,
      afterOpenChange,
      showDrawer,
      openAdd,
      afterOpenAddChange,
      showAddDrawer,
      resetFormAdd,
      showDeletePopup,
      updateIngredient,
      addIngredient,
      fileList,
      handleChange,
      handleDrop,
      beforeUpload,
      navigatePage,
      addIngredients,
      cancelUpdate,
      handlePageChange,
      loading,
      handleSelectChange,
      perPageSearch,
      currentPageSearch,
      value,
      selectedType,
      handlePageChangeSearch,
      handleChangeFile,
      extractFilename,
      openPref,
      resetPref,
      showPrefModal,
      formPref,
      formPrefRef,
      prefRules,
      addPref,
      validationStatus,
      validationHelp,
      getValidatingStatus,
      getValidatingHelp,
      updatePref,
      displayPref,
      showPrefrences,
      closeShowPref,
      showDeletePref,
      dynamicValidateForm,
      formRefPref,
      removePref,
      addPrefs,
      onFinish,
      updatePreference,
      selectPagination,
      handleSelectPaginationChange,
      simpleImage,
      locale,











    };
    },
})
</script>

<style scoped>

.btn-container{
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.add-btn{
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}
.add-btn:hover{
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.label-form{
  font-family: "Exo 2", sans-serif; 
  font-size: 14px;
  font-weight: 600;
}

.date-picker{
  width:100%
}

.switch-container {
  display: flex;
  align-items: center;
}
.switch-container p {
  margin-right: 16px;
  font-size: 14px;
  font-weight: 600;
}

.ant-upload-wrapper{
  color: #fc8019  ;
}
/*
.ant-upload-text-icon{
  color: #fc8019;

}

.ant-upload-list-item-name{
  color: #fc8019;
}

.ant-upload-list-item-actions{
  color: #fc8019;
}*/

.breadcrumb-icon{
  cursor: pointer;
}


.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

  .select-input {
    width: 100%;
  }

.view-btn{
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-title{
  font-size: 15px !important;
  font-weight: bold;
  margin-right: 4px;
}

.dynamic-input{width: 100%;}

.add-btn:hover{
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.search-radio-grp{
  display: flex;
  flex-direction: column !important;
}

.radio-item{
  margin-bottom: 10px;
}

.photo-info{
  padding:0px 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.photo-info:hover{
 background: #efefefec;
}

.variant-btn{
  color: #3b8dffdf;
  border-color: #3b8dffdf;
  background: #fafafa;
}

.pref-btn{
  color: #ff00268a;
  border-color: #ff00268a;
  background: #fafafa;
}

.ingredient-des{
  margin-bottom: 15px;
}

.pref-container{
  margin-bottom: 15px;
}

.cancel-pref-icon{
  cursor: pointer;
  color:#ea5455 ;
  font-size: 20px;
}

.edit-pref-icon{
  cursor: pointer;
  color:#fc8019 ;
  font-size: 20px;
}

.pref-container{
  margin-bottom: 10px;
}

.add-pref-btn-conatiner{
  display:flex;
  align-items: center;
  justify-content: center;
}

/* CSS for RTL */
.is-rtl {
  direction:rtl;
}

.paginationArrows{
  direction:ltr;
  margin-bottom: 16px;


}

.table-container {
  overflow-x: auto;
}


/*meduim*/
@media (min-width: 768px) {

  .pagination {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .select-input {
    width: 40%;
  }


}

  /*large*/
  @media (min-width: 1024px) {

    .pagination {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
    }

    .select-input {
      width: 20%;
    }
          

  }

 
</style>
