<template>

    <div >
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{t('Pos.Dashboard')}}
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{t('Pos.Inventory')}}</a-breadcrumb-item>
        <a-breadcrumb-item>{{t('Pos.Transactions')}}</a-breadcrumb-item>

        </a-breadcrumb>
    </div>

    
    <!-- <div class="btn-container">
        <a-button class="add-btn"  @click="showAddDrawer">Add</a-button>
    </div> -->

    <div>
        <a-table
            class="table-container"
            :columns="columns"
            :data-source="state.data"
            :pagination="false"
            
        >

        
            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>

            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{t('Pos.View')}}</template>
                            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <!-- <a-tooltip>
                            <template #title>Update</template>
                            <a-button type="primary"  @click="() => showDrawer(record)">
                            <template #icon>
                                <EditOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                        
                        <a-tooltip>
                            <template #title>Delete</template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip> -->

                    </a-space>
                </template>


                <template  v-if="column.key=='item_type'">
                   
                    <template v-if="record.product!==null">
                      {{record.product.name}}
                    </template>
                    <template v-if="record.ingredient!==null">
                        {{record.ingredient.name}}   
                    </template >

                </template>

                <template  v-if="column.key=='type'">
                   
                   <a-tag  >{{t(`Pos.${record.type}`)}}</a-tag>

                </template>

                <template  v-if="column.key=='item'">
                   
                    <template v-if="record.product!==null">
                       {{t('Pos.Product')}}
                    </template>
                    <template v-if="record.ingredient!==null">
                        {{t('Pos.Ingredient')}}    
                    </template >

                </template>

                
    
                <template v-if="column.key === 'transaction_date'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>

            </template>

            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                    <template  v-if="column.key === 'name'">
                        <a-input
                            ref="searchInput"
                            :placeholder="`${column.title}`"
                            :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        /> 
                    </template>

                    <template  v-if="column.key === 'transaction_date'">
                        <div >
                            <a-date-picker v-model:value="transaction_date" class="date-input" />
                        </div>
                    </template>

                    

                    <template  v-if="column.key === 'item'">
                        <div class="search-btn-radio">
                            <a-radio-group  v-model:value="value" @change="selectedType" class="search-radio-grp">
                                <a-radio value="product" class="radio-item"> {{t('Pos.Product')}}</a-radio>
                                <a-radio value="ingredient" class="radio-item"> {{t('Pos.Ingredient')}}</a-radio>
                            </a-radio-group>  
                        </div>   
                    </template>

                    <template  v-if="column.key === 'type'">
                        <div class="search-btn-radio">
                            <a-radio-group  v-model:value="value" @change="selectedInventoryType" class="search-radio-grp">
                                <a-radio value="sale" class="radio-item"> {{t('Pos.sale')}}</a-radio>
                                <a-radio value="transfered" class="radio-item"> {{t('Pos.transfered')}}</a-radio>
                                <a-radio value="adjustment" class="radio-item"> {{t('Pos.adjustment')}}</a-radio>
                                <a-radio value="received" class="radio-item"> {{t('Pos.received')}}</a-radio>
                            </a-radio-group>  
                        </div>   
                    </template>


                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 5px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    {{ t('Pos.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                {{ t('Pos.Reset') }}
                </a-button>
            </div>
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>

           
        </a-table>

        <div class="pagination">
            <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange"/>

            <a-select
                class="select-input"
                v-model:value="selectPagination"
                @change="handleSelectPaginationChange"
                v-if="state.totalPages>10"
            >
                <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
            </a-select>
        </div>


        <!-- <div class="pagination" v-if="state.totalPagesSearch ===0 && !state.beginSearch">
            <a-pagination v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage"/>
        </div> -->

        <!-- <div class="pagination" v-if="state.totalPagesSearch !==0 && state.beginSearch">
            <a-pagination v-model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch"/>
        </div> -->

        <!-- display drawer -->
            <a-drawer
                v-model:open="openDisplay"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenChange"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.InventoryTransactionInfo')}}</p>
                </template>

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                    <a-row :gutter="16">   
                        <a-col :span="12">
                            <p><span class="info-title">{{t('Pos.Item')}} :</span> 
                                <template v-if="form.product!==null">
                                    {{form.product.name}}
                                </template>

                                <template v-if="form.ingredient!==null">
                                    {{form.ingredient.name}}  
                                </template>
                            </p>
                        </a-col>  

                        <a-col :span="12">

                            <p><span class="info-title">{{t('Pos.Variant(s)')}} :</span> 
                                {{form.variant_id}} 
                                <a-tag v-if="form.variant_id===null">{{ t('Pos.Emptyfield') }}</a-tag>
                            </p>
                            
                        </a-col>   
                    </a-row>

                    <a-row :gutter="16">   
                        <a-col :span="12">
                            
                            <p><span class="info-title">{{t('Pos.Warehouse')}} :</span> 
                                {{form.warehouse_id.name}} 
                                <a-tag v-if="form.warehouse_id===null">{{ t('Pos.Emptyfield') }}</a-tag>
                            </p>
                        </a-col>   

                        <a-col :span="12">

                            <p><span class="info-title">{{t('Pos.Type')}} :</span> 
                               
                                <a-tag >{{t(`Pos.${form.type}`)}}</a-tag>
                                <a-tag v-if="form.type===null">{{ t('Pos.Emptyfield') }}</a-tag>
                            </p>
                           
                        </a-col>   
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12">

                            <p><span class="info-title">{{t('Pos.Quantity')}} :</span> 
                                {{form.quantity}} 
                                <a-tag v-if="form.quantity===null">{{ t('Pos.Emptyfield') }}</a-tag>
                            </p>
                           
                        </a-col>

                        <a-col :span="12">

                            <p><span class="info-title">{{t('Pos.TransactionDate')}} :</span> 
                                {{formatStringDate(form.transaction_date)}} 
                                <a-tag v-if="form.transaction_date===null">{{ t('Pos.Emptyfield') }}</a-tag>
                            </p>
                           
                        </a-col>

                    </a-row>

                    <a-row>
                        <a-col :span="24">

                            <p><span class="info-title">{{t('Pos.Notes')}} :</span> 
                                {{form.notes}} 
                                <a-tag v-if="form.notes===null">{{ t('Pos.Emptyfield') }}</a-tag>
                            </p>
                           
                        </a-col>
                    </a-row>


                </a-form>
        

            </a-drawer>
        <!-- display drawer -->

        <!-- update drawer -->
            <a-drawer
                v-model:open="open"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width="720"
                @after-open-change="afterOpenChange"
            >

                
                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">

                    <a-row :gutter="16">   
                        <a-col :span="12">
                            <a-form-item name="product_id">
                                <template #label>
                                    <span class="label-form">Product</span>
                                </template>
                                <a-select  placeholder="Please choose the product" v-model:value="form.product_id">
                                    <a-select-option v-for="item in state.products" :key="item.id" :value="item.id" :selected="item.id === form.product_id ">{{ item.name }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>  

                        <a-col :span="12">
                            <a-form-item  name="variant_id">
                                <template #label>
                                <span class="label-form">Variant</span>
                                </template>
                                <a-select  placeholder="Please choose variant" v-model:value="form.variant_id">
                                    <a-select-option v-for="item in state.variants" :key="item.id" :value="item.id" :selected="item.id === form.variant_id ">{{ item.name }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>   
                    </a-row>

                    <a-row :gutter="16">   
                        <a-col :span="12">
                            <a-form-item  name="warehouse_id">
                                <template #label>
                                <span class="label-form">Warehouse</span>
                                </template>
                                <a-select  placeholder="Please choose variant" v-model:value="form.warehouse_id">
                                    <a-select-option v-for="item in state.warehouses" :key="item.id" :value="item.id" :selected="item.id === form.warehouse_id ">{{ item.name }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>   

                        <a-col :span="12">
                            <a-form-item name="type">
                                <template #label>
                                <span class="label-form">Type</span>
                                </template>
                                <a-select v-model:value="form.type" show-search>
                                    <a-select-option value="transfer">Transfer</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>   
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item name="quantity">

                                <template #label>
                                    <span class="label-form">Quantity</span>
                                </template>

                                <a-input v-model:value="form.quantity" placeholder="Quantity"/>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item name="transaction_date">

                                <template #label>
                                    <span class="label-form">Transaction date</span>
                                </template>
                                <a-date-picker v-model:value="form.transaction_date" class="date-picker" />

                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row>
                        <a-col :span="24">
                            <a-form-item name="notes">

                                <template #label>
                                    <span class="label-form">Notes</span>
                                </template>
                                <a-textarea v-model:value="form.notes" :rows="4" placeholder="Notes" />
                            </a-form-item>
                        </a-col>
                    </a-row>


                </a-form>
                <template #footer>
                    <a-space>
                        <a-button  type="primary" @click="updateUser(form.id)">Update</a-button>
                        <a-button type="primary" danger >Cancel</a-button>
                    </a-space>
                </template>

            </a-drawer>
        <!-- update drawer -->

        <!-- add drawer -->
        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            title="Add inventory transaction"
            placement="right"
            :width="720"
            @after-open-change="afterOpenAddChange"
        >

            <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical">

                <a-row :gutter="16">   
        
                    <a-col :span="12">
                        <a-form-item name="product_id">
                            <template #label>
                            <span class="label-form">Product</span>
                            </template>
                            <a-select  placeholder="Please choose the product" v-model:value="addForm.product_id">
                                <a-select-option v-for="item in state.products" :key="item.id" :value="item.id" :selected="item.id === addForm.product_id ">{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>  

                    <a-col :span="12">
                        <a-form-item  name="variant_id">
                            <template #label>
                            <span class="label-form">Variant</span>
                            </template>
                            <a-select  placeholder="Please choose variant" v-model:value="addForm.variant_id">
                                <a-select-option v-for="item in state.variants" :key="item.id" :value="item.id" :selected="item.id === addForm.variant_id ">{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>   
                </a-row>

                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item  name="warehouse_id">
                            <template #label>
                            <span class="label-form">Warehouse</span>
                            </template>
                            <a-select  placeholder="Please choose variant" v-model:value="addForm.warehouse_id">
                                <a-select-option v-for="item in state.warehouses" :key="item.id" :value="item.id" :selected="item.id === addForm.warehouse_id ">{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>   

                    <a-col :span="12">
                        <a-form-item name="type">
                            <template #label>
                            <span class="label-form">Type</span>
                            </template>
                            <a-select v-model:value="addForm.type" show-search>
                                <a-select-option value="transfer">Transfer</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>   
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item name="quantity">

                            <template #label>
                                <span class="label-form">Quantity</span>
                            </template>

                            <a-input v-model:value="addForm.quantity" placeholder="Quantity" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item name="transaction_date">

                            <template #label>
                                <span class="label-form">Transaction date</span>
                            </template>
                            <a-date-picker v-model:value="addForm.transaction_date" class="date-picker" />

                        </a-form-item>
                    </a-col>

                </a-row>

                <a-row>
                    <a-col :span="24">
                        <a-form-item name="notes">

                            <template #label>
                                <span class="label-form">Notes</span>
                            </template>
                            <a-textarea v-model:value="addForm.notes" :rows="4" placeholder="Notes" />
                        </a-form-item>
                    </a-col>
                </a-row>

            </a-form>

            <template #footer>
            <a-space>
                <a-button  type="primary" @click="add">Add</a-button>
                <a-button type="primary" danger  @click="resetFormAdd">Cancel</a-button>
            </a-space>
            </template>


        </a-drawer>
        <!-- add drawer -->
       

    </div>




</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed} from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
EyeOutlined,
SearchOutlined,


} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { inventoryService, userService} from '@/_services'
import dayjs , { Dayjs }from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime';

import { useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import type { FormInstance } from 'ant-design-vue';

import { useI18n } from 'vue-i18n';
import 'dayjs/locale/fr'; 
import 'dayjs/locale/ar'; 
import { Empty } from 'ant-design-vue';

export default defineComponent({
    name: 'indexInventory',
    components: {
        EyeOutlined,
        SearchOutlined,
        LbrxLoaderSpinner
        // DeleteOutlined,
        // EditOutlined,
        
        
    },
    setup() {
        const modalWidth = ref(720);
        const selectPagination = ref('10');
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const { t } = useI18n();
        dayjs.extend(relativeTime);


        const loading = ref(false);
        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const searchInput = ref<HTMLInputElement | null>(null);


        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            stores: [] as any[],
            products: [] as any[],
            variants: [] as any[],
            warehouses: [] as any[],
            totalPages:0,

            totalPagesSearch:0,
            selectedType:'',
            selectedInvtoryType:'',
            beginSearch:false,

            transaction_date:'',
        });

        const columns=[
           {
                title: t('Pos.Item'),
                key:'item_type',
                dataIndex: 'item_type',
            },
            {
                title: t('Pos.Type'),
                key:'type',
                dataIndex: 'type',
                customFilterDropdown: true,
                
            },
            {
                title:t('Pos.Quantity'),
                key:'quantity',
                dataIndex: 'quantity',
            },
            {
                title: t('Pos.ItemType'),
                key:'item',
                dataIndex: 'item',
                customFilterDropdown: true,

            },  
            {
                title: t('Pos.Date'),
                key:'transaction_date',
                dataIndex: 'transaction_date',
                customFilterDropdown: true,

            },  
            {
                title:t('Pos.Actions'),
                key:'actions'
            }
        ];

        //form  
        const formRef =  ref<FormInstance>();
        const addFormRef = ref();

        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);

        const perPageSearch = ref<number>(10);
        const currentPageSearch = ref<number>(1);

        
        const form = reactive({
            id: '',
            product: '',
            ingredient: '',
            variant_id: '',
            warehouse_id: '',
            type: '',
            quantity:'',
            user_id:'',
            notes: '',
            transaction_date: null as Dayjs | null,
            created_at: null as Dayjs | null,
            updated_at: null as Dayjs | null,
        });

        const addForm = reactive({
            id: '',
            product_id: '',
            variant_id: '',
            warehouse_id: '',
            type: '',
            quantity:'',
            user_id:'',
            notes: '',
            transaction_date: null as Dayjs | null,
            created_at: null as Dayjs | null,
            updated_at: null as Dayjs | null,
        })

        const rules: Record<string, Rule[]> = {
            product_id: [{ required: true, message: 'Please select product' }],
            variant_id: [{ required: true, message: 'Please select variant' }],
            warehouse_id: [{ required: true, message: 'Please select warehouse' }],
            type: [{ required: true, message: 'Please select type' }],
            quantity: [
                { required: true, message: 'Please enter quantity' },
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject('Quantity must be a numeric value');
                    }
                }

            ],
            transaction_date: [{ required: true, message: 'Please enter transaction date' }],
            notes: [{ required: true, message: 'Please enter notes' }],

        };

        const lang=localStorage.getItem('Language')|| 'en';


        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).locale(lang).fromNow();
        }
        const formatStringDate=(str:string)=>{
           return dayjs(str).format("DD-MM-YYYY HH:mm:ss");
        }
       

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        //drawer
        const open = ref<boolean>(false);
        const openDisplay = ref<boolean>(false);
        const openAdd = ref<boolean>(false);

        const showAddDrawer = (state: boolean) => {
            openAdd.value = true;
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDisplayDrawer =(record:any)=>{
            updateModalWidth();
            openDisplay.value = true;
            getById(record.id);
        }

        const showDrawer = (record: any) => {
            open.value = true;
            getById(record.id);
        };

        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
        };

        const getAll=()=>{
            loading.value = true;
            inventoryService.getInventoriesWithPaginationPerPage(currentPage.value,perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages=response.meta.total;
                console.log('inventories', response.data);
            }).catch((error)=>{
                console.log("error get discounts", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const getById=(id:string)=>{
            
            inventoryService.show(id)
            .then(res => {
                console.log('inventory', res.data);
                
                form.id = res.data.id;
                form.product=res.data.product ? res.data.product :"";
                form.ingredient=res.data.ingredient ? res.data.ingredient :"";
                form.variant_id=res.data.variant_id ? res.data.variant_id :"";
                form.warehouse_id = res.data.warehouse ? res.data.warehouse : "";

                form.type=res.data.type ? res.data.type :"";
                form.quantity=res.data.quantity ? res.data.quantity :"";
                form.user_id=res.data.user_id ? res.data.user_id :"";
                form.notes=res.data.notes ?res.data.notes:"" ;
                form.transaction_date=formatDate(res.data.transaction_date);
                form.created_at=res.data.created_at;
                form.updated_at=res.data.updated_at;
            })
            .catch(error => {
                console.error('Error fetching inventory by id:', error);
            });  
        }

        const update =(id:string)=>{
        
            formRef!.value!
            .validate()
            .then(()=>{
                let payload = {
                    product:form.product,
                    variant_id:form.variant_id,
                    warehouse_id:form.warehouse_id,
                    type:form.type,
                    quantity:form.quantity,
                    user_id:form.user_id,
                    notes:form.notes,
                    transaction_date:form.transaction_date,
                }
                console.log('payload', payload);
                inventoryService.update(id, payload).then((response)=>{
                    open.value=false;
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    showSuccessNotification("Inventory transaction updated successfully");
                    console.log('finish')
                }); 
            })
        }

        const _delete = (id: string) => {
            console.log(id);
            
            userService.delete(id).then((res) => {
                state.data = state.data.filter(e => e.id !== id);
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                showSuccessNotification("Inventory transaction deleted successfully");
                console.log('finish')
            })
        };

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: 'Delete this inventory transaction',
                text: 'Are you sure you want to delte this inventory transaction',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: 'Delete',
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    _delete(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const add=()=>{

            addFormRef.value
                .validate()
                .then(()=>{
                
                    let user_id = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';

                    let payload = {
                        product_id:addForm.product_id,
                        variant_id:addForm.variant_id,
                        warehouse_id:addForm.warehouse_id,
                        type:addForm.type,
                        quantity:addForm.quantity,
                        transaction_date:addForm.transaction_date,
                        notes: addForm.notes,
                        user_id:user_id
                    
                    }

                    console.log('payload', payload);
                   
                    inventoryService.create(payload).then((rep)=>{
                        console.log('Inventory added successfully:', rep);
                        openAdd.value=false;
                        state.data.push(rep.data);
                        resetFormAdd();
                        showSuccessNotification('Inventory transaction added successfully');
                    }).catch((error) => {
                        console.error('Error adding Inventory transaction:', error); 
                    });
                    

                }).catch((error:any) => {
                    console.error('Validation error:', error);
                });
                

        }

        const onShowSizeChange = (current: number, pageSize: number) => {
            console.log(current, pageSize);
            perPage.value=pageSize;
            console.log('222');
            loading.value=true;
            inventoryService.getInventoriesWithPaginationPerPage(currentPage.value, perPage.value).then((res)=>{
                state.data=res.data;
            }).catch((err:any) => {
                console.log(err)
            }).finally(()=>{
                loading.value = false;
            });
    
        };

        const handlePageChange = (newPage:number, pageSize:number) => {
            console.log('handlePage change','new page : ',newPage, ' per page', pageSize);

            perPage.value=pageSize;
            currentPage.value = newPage;

           

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){

                state.data=[];
                if(state.selectedType!==''){
                    loading.value = true;
                    inventoryService.filterWithPaginationPerpage("transaction_type",state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;

                        //new 
                        state.totalPages=res.meta.total;

                        //new

                        //state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

                if(state.selectedInvtoryType!==''){
                    loading.value = true;
                    inventoryService.filterWithPaginationPerpage("type",state.selectedInvtoryType,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

                if(state.transaction_date!==''){
                    loading.value = true;
                    inventoryService.filterWithPaginationPerpage("transaction_date",state.transaction_date,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }
                
                

            }else{

                loading.value = true;     
                inventoryService.getInventoriesWithPaginationPerPage(currentPage.value,pageSize).then((response)=>{
                    state.data=response.data;
                }).catch((error)=>{
                    console.log("error api : get inventories", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

           
        }

        const handlePageChangeSearch = (newPage:any) => {
            currentPageSearch.value = newPage;
            

            console.log('per page', perPageSearch.value);
            console.log('page', currentPageSearch.value);

            state.data=[];
            if(state.selectedType!==''){
                loading.value = true;
                inventoryService.filterWithPagination("transaction_type",state.selectedType,currentPageSearch.value.toString()).then((res)=>{
                    console.log('res', res.data);
                    state.data=res.data;
                    state.totalPagesSearch = res.meta.total;
                }).catch((err:any) => {
                    console.log(err)
                }).finally(()=>{
                    loading.value = false;
                });
            }

            if(state.selectedInvtoryType!==''){
                loading.value = true;
                inventoryService.filterWithPagination("type",state.selectedInvtoryType,currentPageSearch.value.toString()).then((res)=>{
                    console.log('res', res.data);
                    state.data=res.data;
                    state.totalPagesSearch = res.meta.total;
                }).catch((err:any) => {
                    console.log(err)
                }).finally(()=>{
                    loading.value = false;
                });
            }

                

        }

    
       

        const formatDate=(dateString:string)=>{
            return dayjs(dateString);
        }

        //search select
        const value = ref<string>('1');
        const selectedType =(value:any)=>{
            console.log('selected radio option ', value.target.value);
            state.selectedType = value.target.value;
        }

        const selectedInventoryType =(value:any)=>{
            console.log('selected radio option ', value.target.value);
            state.selectedInvtoryType = value.target.value;
        }

        const filter=(key:string, value:string)=>{
            currentPage.value=1;
            loading.value=true;
            inventoryService.filterWithPaginationPerpage(key,value, currentPage.value.toString(), perPage.value).then((res)=>{
                state.data=res.data;

                //new
                state.totalPages=res.meta.total;
                //new
                
                //state.totalPagesSearch= res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((error)=>{
                console.log('error fetching', error);
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'item' && state.selectedType!=='') {
                state.totalPagesSearch=0;
                console.log('begin filter unit', state.selectedType);
                filter("transaction_type",state.selectedType );
            }

            if (dataIndex === 'type' && state.selectedInvtoryType!=='') {
                state.totalPagesSearch=0;
                console.log('begin filter unit', state.selectedInvtoryType);
                filter("type",state.selectedInvtoryType );
            }

            if (dataIndex === 'transaction_date') {
                state.totalPagesSearch=0;

               
                let formattedDate="";
                if (transaction_date.value) {
                    //formattedDate = dayjs(transaction_date.value).format('YYYY-MM-DD HH:mm:ss').toString();
                    formattedDate = dayjs(transaction_date.value).format('YYYY-MM-DD').toString();
                } else {
                    formattedDate = "";
                }

                state.transaction_date=formattedDate;

                filter('transaction_date',formattedDate)
               

                console.log('date', formattedDate);
            }
        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.selectedType='';
            state.totalPagesSearch=0;
            state.beginSearch=false;
            value.value='';
            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';
            getAll();
        };

        const transaction_date = ref<Dayjs>();

        //pagination perPage
        const handleSelectPaginationChange=(selectedItem:any)=>{
            console.log('selected Per page:', selectedItem);
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.selectedType!==''){
                    loading.value = true;
                    inventoryService.filterWithPaginationPerpage("transaction_type",state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

                if(state.selectedInvtoryType!==''){
                    loading.value = true;
                    inventoryService.filterWithPaginationPerpage("type",state.selectedInvtoryType,currentPage.value.toString(),perPage.value).then((res)=>{
                        console.log('res', res.data);
                        state.data=res.data;
                        state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

                if(state.transaction_date!==''){
                    loading.value = true;
                    inventoryService.filterWithPaginationPerpage("transaction_date",state.transaction_date,currentPage.value.toString(),perPage.value).then((res)=>{
                        console.log('res', res.data);
                        state.data=res.data;
                        state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value=true;
                inventoryService.getInventoriesWithPaginationPerPage(currentPage.value, perPage.value).then((res)=>{
                    state.data=res.data;
                    state.totalPages=res.meta.total;
                }).catch((err:any) => {
                    console.log(err)
                }).finally(()=>{
                    loading.value = false;
                });

            }

            
        }

            

        onMounted(() => {
            getAll();
            
        
        });

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );


        const updateModalWidth = () => {
                modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
            };


        return{
            updateModalWidth,
            modalWidth,
            t,
            textDirection,
            locale,
            loading,
            navigatePage,
            state,
            columns,
            formRef,
            addFormRef,
            perPage,
            currentPage,
            form,
            addForm,
            rules,
            formatDisplayDate,
            open,
            openDisplay,
            openAdd,
            showAddDrawer,
            afterOpenChange,
            showDisplayDrawer,
            showDrawer,
            afterOpenAddChange,
            resetFormAdd,
            showDeletePopup,
            update,
            handlePageChange,
            add,
            formatDate,
            perPageSearch,
            currentPageSearch,
            value,
            selectedType,
            handleReset,
            handleSearch,
            handlePageChangeSearch,
            formatStringDate,
            selectedInventoryType,
            simpleImage,
            onShowSizeChange,
            transaction_date,
            selectPagination,
            handleSelectPaginationChange












            










        }
        
    },
})
</script>

<style scoped>

    .add-btn {
        color: #fc8019;
        border: 2px solid #fc8019;
        box-shadow: inset 0 0 0 0 #fc8019;
    }

    .table-container {
    overflow-x: auto;
    }
    
    .add-btn:hover {
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .btn-container {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .label-form {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .date-picker {
        width: 100%
    }
    .range-picker{
        width: 100% 
    }

    .switch-container {
        display: flex;
        align-items: center;
    }
    .switch-container p {
        margin-right: 15px ;
    }

    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }
   
    .breadcrumb-icon{
        cursor: pointer;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    .select-input {
        width: 100%;
    }

    .info-title{
        font-size: 15px !important;
        font-weight: bold;
        margin-right: 4px;
    }

    .search-radio-grp{
        display: flex;
        flex-direction: column !important;
    }

    .radio-item{
        margin-bottom: 10px;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .date-input{
        margin-bottom: 10px;
        width: 100%;
    }

    /* CSS for RTL */
    .is-rtl {
        direction:rtl;
    }

    .paginationArrows{
        direction: ltr;
        margin-bottom: 16px;

    }

    
        
    /*meduim*/
    @media (min-width: 768px) {

        .pagination {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .select-input {
            width: 40%;
        }

    
    }

    /*large*/
    @media (min-width: 1024px) {

        .pagination {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .select-input {
            width: 20%;
        }
            

    }
</style>

