<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.Store") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-row :gutter="16">
      <a-col
        :xs="{ span: 24 }"
        :sm="{ span: 12 }"
        :md="{ span: 12 }"
        :lg="{ span: 12 }"
        :xl="{ span: 12 }"
        :xxl="{ span: 12 }"
        class="action-btn-container"
      >
        <a-button class="add-btn" @click="showAddDrawer(true)">
          {{ t("Pos.Add") }}
          <template #icon>
            <ShopOutlined />
          </template>
        </a-button>
      </a-col>

      <!-- taraji -->
      <!-- <a-col
        :xs="{ span: 24 }"
        :sm="{ span: 12 }"
        :md="{ span: 12 }"
        :lg="{ span: 12 }"
        :xl="{ span: 12 }"
        :xxl="{ span: 12 }"
        class="action-btn-container"
      >
        <a-button class="add-btn" @click="showTransferModal()">
          {{ t("Pos.Transfer") }}
          <template #icon>
            <SwapOutlined />
          </template>
        </a-button>
      </a-col> -->
    </a-row>
  </div>
  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title>{{ t("Pos.View") }}</template>
              <a-button
                class="view-btn"
                @click="() => showDisplayDrawer(record)"
              >
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Edit") }}</template>

              <a-button type="primary" @click="() => showDrawer(record)">
                <template #icon>
                  <EditOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Delete") }}</template>
              <a-button type="primary" danger @click="showDeletePopup(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template
          v-if="column.key === 'created_at' || column.key === 'updated_at'"
        >
          {{ formatDisplayDate(record[column.key]) }}
        </template>
      </template>

      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        :total="reactiveData.totalPages"
        @change="handlePageChange"
        :default-page-size="perPage"
      />
    </div>

    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
      @close="resetFields"
    >
      <template #title>
        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.action == 'add'"
        >
          <span>{{ t("Pos.AddStoreDrawer") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.action == 'add'"
        >
          <span>{{ t("Pos.AddStoreDrawer") }}</span>
        </div>

        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.action == 'update'"
        >
          <span>{{ t("Pos.UpdateStoreDrawer") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.action == 'update'"
        >
          <span>{{ t("Pos.UpdateStoreDrawer") }}</span>
        </div>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="name">
              <template #label>
                <span class="label-form">{{ t("Pos.Name") }}</span>
              </template>
              <a-input
                v-model:value="form.name"
                :disabled="
                  reactiveData.action !== 'add' &&
                  reactiveData.action !== 'update'
                "
                :placeholder="t('Pos.Name')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="address">
              <template #label>
                <span class="label-form">{{ t("Pos.Address") }}</span>
              </template>
              <a-input
                v-model:value="form.address"
                :disabled="
                  reactiveData.action !== 'add' &&
                  reactiveData.action !== 'update'
                "
                :placeholder="t('Pos.Address')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="email">
              <template #label>
                <span class="label-form">{{ t("Pos.Email") }}</span>
              </template>
              <a-input
                v-model:value="form.email"
                :placeholder="t('Pos.Email')"
                :disabled="
                  reactiveData.action !== 'add' &&
                  reactiveData.action !== 'update'
                "
                class="input-form"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="description">
              <template #label>
                <span class="label-form">{{ t("Pos.Description") }}</span>
              </template>
              <a-input
                v-model:value="form.description"
                :disabled="
                  reactiveData.action !== 'add' &&
                  reactiveData.action !== 'update'
                "
                :placeholder="t('Pos.Description')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="phone">
              <template #label>
                <span class="label-form">{{ t("Pos.Phone") }}</span>
              </template>
              <a-input
                v-model:value="form.phone"
                :placeholder="t('Pos.Phone')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="password">
              <template #label>
                <span class="label-form">{{ t("Pos.Password") }}</span>
              </template>
              <a-input-password
                v-model:value="form.password"
                v-model:visible="visible"
                :placeholder="t('Pos.Password')"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <!-- new -->
        <!-- <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="maxQt">
              <template #label>
                <a-tooltip>
                  <template #title>{{t('Pos.maxQuantityTxt')}}</template>
                  <QuestionCircleOutlined class="tooltip-icon"/>
                </a-tooltip>
                <span class="label-form">{{ t("Pos.maxQuantity") }}</span>
              </template>
              <a-input
                v-model:value="form.maxQt"
                :placeholder="t('Pos.maxQuantity')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="minQt">
              <template #label>
                <a-tooltip>
                  <template #title>{{t('Pos.minQuantityTxt')}}</template>
                  <QuestionCircleOutlined class="tooltip-icon"/>
                </a-tooltip>
                <span class="label-form">{{ t("Pos.minQuantity") }}</span>
              </template>
              <a-input
                v-model:value="form.minQt"
                :placeholder="t('Pos.minQuantity')"
                class="input-form"
              />
            </a-form-item>
          </a-col>

        </a-row> -->

        <a-row>
          <a-col :span="24">
            <a-form-item>
              <template #label>
                <span class="label-form">{{ t("Pos.Photo") }}</span>
              </template>

              <a-upload-dragger
                v-model:fileList="fileList"
                name="file"
                :multiple="false"
                :max-count="1"
                @change="handleChangeFile"
                @drop="handleDrop"
                :before-upload="beforeUpload"
              >
                <p class="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p class="ant-upload-text">{{ t("Pos.dropTitle1") }}</p>
                <p class="ant-upload-hint">
                  {{ t("Pos.dropTitle2") }}
                </p>
              </a-upload-dragger>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div :class="{ 'is-rtl': textDirection === 'rtl' }">
          <a-space v-if="reactiveData.action == 'update'">
            <a-button type="primary" @click="updateStore(form.id)">{{
              t("Pos.Update")
            }}</a-button>
            <a-button type="primary" danger @click="close">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
          <a-space v-else-if="reactiveData.action == 'add'">
            <a-button type="primary" @click="addStore">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="close">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>

          <a-space v-else>
            <a-button type="primary" danger @click="close">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>
    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      @after-open-change="afterOpenShowChange"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.DetailsStoreDrawer") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.DetailsStoreDrawer") }}</span>
        </div>
      </template>
      <a-form
        ref="addFormRef"
        :model="form"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <template #header>
          <div class="custom-header">{{ t("Pos.GeneralInformations") }}</div>
        </template>

        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span
                class="info-title"
                :disabled="
                  reactiveData.action !== 'add' &&
                  reactiveData.action !== 'update'
                "
                placeholder="name"
                >{{ t("Pos.FullName") }} :</span
              >
              {{ form.name }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span
                class="info-title"
                :disabled="
                  reactiveData.action !== 'add' &&
                  reactiveData.action !== 'update'
                "
                placeholder="name"
                >{{ t("Pos.Description") }} :</span
              >
              {{ form.description }}
            </p>
          </a-col>
          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.Address") }} :</span
              >
              {{ form.address }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.Email") }} :</span
              >
              {{ form.email }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="name"
                >{{ t("Pos.Phone") }} :</span
              >
              {{ form.phone }}
            </p>
          </a-col>

          <a-row :span="24" v-if="form.photo !== null">
            <a-col :span="8">
              <p>
                <span class="info-title">{{ t("Pos.Photo") }} :</span>
              </p>
            </a-col>
            <a-col :span="16">
              <a-form-item name="photo">
                <a-image :width="200" :src="form.photo" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-row>
      </a-form>

      <template #footer>
        <a-space> </a-space>
      </template>
    </a-drawer>

    <!-- transfer modal -->
    <a-modal v-model:open="openTransferModal" @cancel="handleCancel">
      <template #footer>
        <a-button key="submit" type="primary" @click="transfer">{{
          t("Pos.Confirm")
        }}</a-button>
        <a-button key="back" @click="handleCancel">{{
          t("Pos.Cancel")
        }}</a-button>
      </template>

      <template #title>
        <p :class="{ 'is-rtl': textDirection === 'rtl' }">
          {{ t("Pos.TransferItems") }}
        </p>
      </template>

      <a-form
        ref="transferFormRef"
        :model="transferForm"
        :rules="transferRules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="target_store">
              <template #label>
                <span class="label-form">{{ t("Pos.From") }}</span>
              </template>

              <a-select
                v-model:value="state.sender_store"
                show-search
                :default-active-first-option="false"
                :filter-option="false"
                @search="fetchTarget"
                :options="state.target_store"
                @select="selectedSenderStore"
              >
                <template v-if="state.fetchingTargetStore" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="destination_store">
              <template #label>
                <span class="label-form">{{ t("Pos.To") }}</span>
              </template>
              <a-select
                v-model:value="state.receiver_store"
                show-search
                :default-active-first-option="false"
                :filter-option="false"
                @search="fetchDestination"
                :options="state.destination_store"
                :disabled="state.sender_store.length === 0"
                class="store-select"
              >
                <template
                  v-if="state.fetchingDestinationStore"
                  #notFoundContent
                >
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="item_type">
              <template #label>
                <span class="label-form">{{ t("Pos.Typeofmerchandise") }}</span>
              </template>
              <a-select
                v-model:value="transferForm.item_type"
                show-search
                :option-filter-prop="'label'"
                @change="handleTypeChange"
                :disabled="
                  state.receiver_store.length === 0 ||
                  state.sender_store.length === 0
                "
              >
                <template #notFoundContent>
                  <a-empty
                    :description="t('Pos.NoData')"
                    :image="simpleImage"
                  />
                </template>
                <a-select-option value="product" :label="t('Pos.Product')">{{
                  t("Pos.Product")
                }}</a-select-option>
                <a-select-option
                  value="ingredient"
                  :label="t('Pos.Ingredient')"
                  >{{ t("Pos.Ingredient") }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="items">
              <template #label>
                <span class="label-form">{{ t("Pos.Items") }}</span>
              </template>

              <a-select
                v-model:value="state.itemValues"
                mode="multiple"
                label-in-value
                :placeholder="t('Pos.Item')"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="state.fetchingItems ? undefined : null"
                :options="state.items"
                @search="fetchItems"
                @change="handleMultiChange"
                :disabled="
                  state.receiver_store.length === 0 ||
                  state.sender_store.length === 0 || !transferForm.item_type
                "
              >
                <template v-if="state.fetchingItems" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
          </a-col>

        
        </a-row>

        <a-row v-for="item in state.qtArray" :key="item" :gutter="16">
            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 9}" :lg="{ span: 9 }" :xl="{ span: 9 }" :xxl="{ span: 9 }">
                <span class="label-form">{{item.name}}</span>
            </a-col>

            <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 15}" :lg="{ span: 15 }" :xl="{ span: 15 }" :xxl="{ span: 15 }">
                <a-form-item
                    has-feedback
                    :name="'qt_' + item.item_id"
                    :validate-status="getValidatingStatus(item.item_id, 'qt')"
                    :help="getValidatingHelp(item.item_id, 'qt')"
                >
                <a-input
                    :id="'qt_' + item.item_id"
                    :placeholder="t('Pos.Quantity')"
                    v-model:value="item.quantity"
                    class="input-form"
                />
                </a-form-item>
            </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRaw,
  computed,
  watch,
} from "vue";
import type { Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";
import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  InboxOutlined,
  ShopOutlined,
  SwapOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { notification } from "ant-design-vue";
import { ingredientsService, productService, storeService } from "@/_services";
import type { UploadChangeParam, UploadFile } from "ant-design-vue";
import dayjs from "dayjs";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { and } from "@vuelidate/validators";
import { Empty } from "ant-design-vue";

export default defineComponent({
  name: "indexStore",
  components: {
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    InboxOutlined,
    SearchOutlined,
    LbrxLoaderSpinner,
    ShopOutlined,
    //SwapOutlined,
    // QuestionCircleOutlined
  },
  setup() {
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const modalWidth = ref(720);

    const { locale } = useI18n();
    const handleChangeFile = (info: UploadChangeParam) => {
      fileInfo.value = info.file;
    };

    const cancelUpdate = () => {
      form.name = "";
      form.photo = "";
      form.description = "";
    };

    const fileList = ref([]);
    const fileInfo = ref<UploadFile | null>(null);
    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;
      loading.value = true;

      //console.log('per page', perPage.value);
      //console.log('page', currentPage.value)

      storeService
        .searchWithFullParams(
          "name",
          reactiveData.searchText,
          currentPage.value
        )
        .then((response) => {
          //console.log('data', response.data)
          reactiveData.data = response.data;
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleChange = (info: UploadChangeParam) => {
      const file = info.file.originFileObj; // Access the actual file object
      //console.log('info', info.file);
      fileInfo.value = info.file;
    };

    function handleDrop(e: DragEvent) {
      //console.log(e);
    }
    const beforeUpload = () => {
      return false;
    };

    const files = ref<File | null>(null);

    const addStore = () => {
      formRef.value
        .validate()
        .then(() => {
          let payload = {
            name: form.name,
            description: form.description,
            address: form.address,
            phone: form.phone,
            email: form.email,
          };
          const UserString = localStorage.getItem("user");
          if (UserString === null) {
            return;
          }
          const user = JSON.parse(UserString);
          form.active = user.account_id;

          loading.value = true;
          const formData = new FormData();
          formData.append("name", form.name);
          formData.append("description", form.description);
          formData.append("address", form.address);
          formData.append("email", form.email);
          formData.append("phone", form.phone);
          formData.append("password", form.password);
          formData.append("account", form.active);

          storeService
            .createStore(
              form.name,
              form.description,
              form.address,
              form.email,
              form.phone,
              form.password,
              form.active,
              fileInfo.value
            )
            .then((res) => {
              reactiveData.data.push(res.store);
              showSuccessNotification(t("Pos.AddStore"));
              open.value = false;
            })
            .catch((err) => {
              //console.log(err)
            })
            .finally(() => {
              loading.value = false;
            });
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    const loading = ref(false);
    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };
    const searchInput = ref<HTMLInputElement | null>(null);
    const { t } = useI18n();
    const columns = [
      {
        title: t("Pos.Name"),
        key: "name",
        dataIndex: "name",
        customFilterDropdown: false,
        onFilter: (value: string, record: any) =>
          record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector(
                ".ant-table-filter-dropdown input"
              );

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },
      {
        title: t("Pos.Description"),
        key: "description",
        dataIndex: "description",
      },
      {
        title: t("Pos.Phone"),
        key: "phone",
        dataIndex: "phone",
      },
      {
        title: t("Pos.Address"),
        key: "address",
        dataIndex: "address",
      },

      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];

    //form
    const formRef = ref();
    const addFormRef = ref();
    const visible = ref<boolean>(false);

    const reactiveData = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchText: "",
      searchedColumn: "",
    });

    const reactiveDataForAccounts = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchText: "",
      searchedColumn: "",
    });

    const resetFields = () => {
      formRef.value.resetFields();
    };
    const form = reactive({
      id: "",
      name: "",
      description: "",
      address: "",
      phone: "",
      email: "",
      account: "",
      username: "",
      password: "",
      searchTextInput: "",
      photo: "",
      active: "",
      maxQt:'',
      minQt:''
    });

    const resetForm = () => {
      form.id = "";
      form.name = "";
      form.description = "";
      form.address = "";
      form.phone = "";
      form.email = "";
      form.username = "";
      form.password = "";
      form.active = "";
      form.account = "";
    };
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const rules: Record<string, Rule[]> = {
      name: [{ required: true, message: t("Pos.nameVerification") }],
      email: [
        {
          required: true,
          message: t("Pos.emailVerification"),
          trigger: "change",
        },
        {
          validator: (rule, value) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
              return Promise.reject(t("Pos.InvalidEmail"));
            }
            return Promise.resolve();
          },
          trigger: "change",
        },
      ],

      password: [{ required: true, message: t("Pos.passwordVerification") }],

      maxQt: [
        { required: true, message: t("Pos.RequiredField") },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
            return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ],

      minQt: [
        { required: true, message: t("Pos.RequiredField") },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
            return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ],


    };

    const formatDate = (dateString: string) => {
      return dayjs(dateString);
    };

    const close = () => {
      resetFields();
      open.value = false;
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    //drawer

    const open = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
      updateModalWidth();
      open.value = state;
      reactiveData.action = "add";
      resetForm();
    };

    const afterOpenChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    const showDrawer = (record: any) => {
      updateModalWidth();
      open.value = true;
      getStoreById(record.id);
      resetForm();
      reactiveData.action = "update";
    };

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      openDisplay.value = true;
      getStoreById(record.id);
      resetForm();
      reactiveData.action = "view";
    };

    const openAdd = ref<boolean>(false);

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    const getStore = () => {
      loading.value = true;
      storeService
        .getMyStores()
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
        })
        .catch((error) => {
          // console.log("error api : get store", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getStoresForFilter = (searchText: string) => {
      loading.value = true;
      // Adjust this part according to your API call
      storeService
        .searchWithFullParams("name", searchText, currentPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
        })
        .catch((error) => {
          // console.log("error api : get warehouses", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getStoreById = (id: string) => {
      storeService
        .show(id)
        .then((res) => {
          form.name = res.data.name;
          form.description = res.data.description;
          form.address = res.data.address;
          form.phone = res.data.phone;
          form.email = res.data.email;
          form.id = res.data.id;
          form.photo = res.data.logo;
          form.active = res.data.account_id;
        })
        .catch((error) => {
          // console.error('Error fetching categories:', error);
        });
    };

    const handleSearch = (
      selectedKeys: any[],
      confirm: () => void,
      dataIndex: string
    ) => {
      confirm();
      getStoresForFilter(selectedKeys[0]);
      reactiveData.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      reactiveData.searchText = "";
      getStore();
    };

    const updateStore = (id: string) => {
      formRef.value
        .validate()
        .then(() => {
          const UserString = localStorage.getItem("user");
          if (UserString === null) {
            return;
          }
          const user = JSON.parse(UserString);
          form.active = user.account_id;

          loading.value = true;
          storeService
            .updateStore(
              id.toString(),
              form.name,
              form.description,
              form.address,
              form.email,
              form.phone,
              form.password,
              form.active,
              fileInfo.value
            )
            .then((res) => {
              console.log('res data', res.data)

              localStorage.setItem("store", JSON.stringify(res.data))
              reactiveData.data = reactiveData.data.map((el) =>
                el.id !== id ? el : res.data
              );
              showSuccessNotification(t("Pos.UpdateStore"));
            })
            .catch((err) => {
              // console.log(err)
            })
            .finally(() => {
              open.value = false;
              loading.value = false;
            });
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    const deleteStore = (id: string) => {
      loading.value = true;
      storeService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification(t("Pos.DeleteStore"));
        })
        .catch((err) => {
          //  console.log(err)
        })
        .finally(() => {
          // console.log('finish')
          loading.value = false;
        });
    };

    const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
      // console.log('open', bool);
    };

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });
    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t("Pos.TitleDeleteStore"),
        text: t("Pos.TextDeleteStore"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonText: t("Pos.Cancel"),
        cancelButtonColor: "#d33",
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteStore(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //  console.log("cancel delete ");
        }
      });
    };

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };

    //transfer modal

    const state = reactive({
      sender_store: [] as any[],
      target_store: [] as any[],
      fetchingTargetStore: false,

      receiver_store: [] as any[],
      destination_store: [] as any[],
      fetchingDestinationStore: false,

      target_store_id: "",
      itemType: "",

      itemValues: [] as any[],
      items: [] as any[],
      fetchingItems: false,

      qtArray: [] as any[],
    });
    const openTransferModal = ref<boolean>(false);

    const showTransferModal = () => {
      openTransferModal.value = true;
    };

    const handleCancel = () => {
        transferFormRef.value.resetFields();
       openTransferModal.value = false;
       state.qtArray=[];
       state.sender_store=[];
       state.destination_store=[];
       state.target_store=[];
       state.destination_store=[];
       state.itemValues=[];
       state.items=[];
       state.fetchingDestinationStore=false;
       state.fetchingTargetStore=false;
       state.fetchingItems=false;

    };

    const transferFormRef = ref();

    const transferForm = reactive({
      id: "",
      targetStoreId: "",
      destinationStoreId: "",
      item_type: "",
      items: [] as any[],
      quantities: [] as any[],

      target_store:state.sender_store,
      destination_store:state.receiver_store,

    });

    const transferRules: Record<string, Rule[]> = {
      

    };

    //target store fetch
    let debonceTimerTraget: ReturnType<typeof setTimeout> | null = null;
    const fetchTarget = (value: any) => {
      //disable fetching when the input is empty
      if (value === "") {
        return;
      }

      state.target_store = [];

      state.fetchingTargetStore = true;

      //clear previous timer
      if (debonceTimerTraget) {
        clearTimeout(debonceTimerTraget);
      }

      //fetch stores

      debonceTimerTraget = setTimeout(() => {
        storeService
          .searchMyStoresWithoutPagination("name", value)
          .then((res) => {
            const data = res.data.map((store: { name: any; id: any }) => ({
              label: `${store.name}`,
              value: store.id,
            }));

            state.target_store = data;

            state.fetchingTargetStore = false;

            console.log('state.target_store', state.target_store);
          })
          .catch((error) => {
            console.log("error fetching stores", error);
          });
      }, 700);
    };

    watch(state.sender_store, () => {
      state.target_store = [];
      state.fetchingTargetStore = false;
    });

    const selectedSenderStore = (value: any, option: any) => {
      state.target_store_id = value;

      state.destination_store = state.target_store.filter(
        (store) => store.value !== value
      );
      console.log('neww', state.destination_store);
      if (state.destination_store.length === 0) {
        transferForm.destinationStoreId = "";
      }
    };

    //destination store fetch
    let debonceTimerDestination: ReturnType<typeof setTimeout> | null = null;
    const fetchDestination = (value: any) => {
      //disable fetching when the input is empty
      if (value === "") {
        return;
      }

      state.destination_store = [];

      state.fetchingDestinationStore = true;

      //clear previous timer
      if (debonceTimerDestination) {
        clearTimeout(debonceTimerDestination);
      }

      //fetch stores

      debonceTimerTraget = setTimeout(() => {
        storeService
          .searchMyStoresWithoutPagination("name", value)
          .then((res) => {
            const data = res.data.map((store: { name: any; id: any }) => ({
              label: `${store.name}`,
              value: store.id,
            }));

            state.destination_store = data;

            state.fetchingDestinationStore = false;
          })
          .catch((error) => {
            console.log("error fetching stores", error);
          });
      }, 700);
    };

    watch(state.receiver_store, () => {
      state.destination_store = [];
      state.fetchingDestinationStore = false;
    });

    const handleTypeChange = (selectedItem: any) => {
      state.itemType = "";
      state.itemValues=[];
      state.items=[];
      state.fetchingItems=false;
      if (selectedItem === "product") {
        state.itemType = "product";
      } else if (selectedItem === "ingredient") {
        state.itemType = "ingredient";
      }
    };

    let debounceTimerItems: ReturnType<typeof setTimeout> | null = null;

    const fetchItems = (value: any) => {
      //disable fetching when the input is empty
      if (value === "") {
        return;
      }

      state.items = [];

      state.fetchingItems = true;

      //clear previous timer
      if (debounceTimerItems) {
        clearTimeout(debounceTimerItems);
      }

      //fetch products
      if(state.itemType==='product'){
        debounceTimerItems = setTimeout(() => {
        productService
          .getProductsByName(value)
          .then((res) => {
            const data = res.data.map(
              (product: { name: any; id: any }) => ({
                label: `${product.name} `,
                value: product.id,
              })
            );

            state.items = data;

            console.log("products", state.items);

            state.fetchingItems = false;

          
            console.log("state.itemValues : ", state.itemValues);
          })
          .catch((error) => {
            console.log("error fetching users", error);
          });
         }, 700);
        
      }else if( state.itemType==='ingredient'){

        debounceTimerItems = setTimeout(() => {
        ingredientsService
          .filterWithoutPagination("name",value)
          .then((res) => {
            const data = res.data.map(
              (product: { name: any; id: any }) => ({
                label: `${product.name} `,
                value: product.id,
              })
            );

            state.items = data;

            console.log("ingredients", state.items);

            state.fetchingItems = false;

          
            console.log("state.itemValues : ", state.itemValues);
          })
          .catch((error) => {
            console.log("error fetching users", error);
          });
         }, 700);
        

      }

      
    };

    watch(state.itemValues, () => {
      state.items = [];
      state.fetchingItems = false;
    });

    const handleMultiChange = (value: any[]) => {

        console.log('value', value);
        state.qtArray = state.qtArray.filter((item) => value.includes(item.key));
        if(value){
            value.forEach((itemId)=>{

                console.log('itemId', itemId);
                const selectedItem = state.items.find((option: any) => option.value === itemId.value);
                if (selectedItem && !state.qtArray.some((item) => item.item_id === itemId.value) ) {
                    state.qtArray.push({
                        
                        item_id: itemId.key,
                        name: selectedItem.label,
                        quantity:"",
                    });
                }
            })
        }
        console.log('qt array', state.qtArray);

    }

    
    interface ValidationStatus {
      [key: string]: string;
    }

    interface ValidationHelp {
      [key: string]: string;
    }

    const validationStatus = ref<ValidationStatus>({});

    const validationHelp = ref<ValidationHelp>({});

    const getValidatingStatus = (id: any, fieldType: string) => {
      return validationStatus.value[`${fieldType}_${id}`] || "";
    };

    const getValidatingHelp = (id: any, fieldType: string) => {
      return validationHelp.value[`${fieldType}_${id}`] || "";
    };

 

    function isNum(value: any) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }


    
    const transfer = () => {
      transferFormRef.value.validate().then(()=>{
        let items: any[] = [];
        let quantities: string[] = [];
        let hasErrors = false;
        state.qtArray.forEach((item) => {
            const qtInput = document.getElementById(
            `qt_${item.item_id}`
            ) as HTMLInputElement;


            if (qtInput.value === "") {
                console.log("here : ",item.item_id, qtInput);
                validationStatus.value[`qt_${item.item_id}`] = "error";
                validationHelp.value[`qt_${item.item_id}`] =t("Pos.RequiredField");
                hasErrors = true;
            } else if (!isNum(qtInput.value)) {
                console.log('is number', item.item_id, qtInput );
                validationStatus.value[`qt_${item.item_id}`] = "error";
                validationHelp.value[`qt_${item.item_id}`] = t("Pos.numericField");
                hasErrors = true;
            } else {
                console.log('success');
                validationStatus.value[`qt_${item.item_id}`] = "";
                validationHelp.value[`qt_${item.item_id}`] = "";

                quantities.push(qtInput.value);
                items.push(item.item_id);
            }

            console.log('items', items);
            console.log('quantities', quantities);

        });
      }).catch((error:any)=>{
        console.log('error validation');
      })
    };




    onMounted(() => {
      updateModalWidth;
      window.addEventListener("resize", updateModalWidth);
      getStore();
    });

    return {
      simpleImage,
      textDirection,
      locale,
      resetFields,
      reactiveDataForAccounts,
      beforeUpload,
      handleDrop,
      fileList,
      handleChangeFile,
      handleChange,
      getStoresForFilter,
      addFormRef,
      handleReset,
      handleSearch,
      afterOpenShowChange,
      openDisplay,
      perPage,
      isFieldDisabled: true,
      close,
      t,
      columns,
      navigatePage,
      formRef,
      form,
      formatDisplayDate,
      reactiveData,
      showAddDrawer,
      showDrawer,
      showDisplayDrawer,
      open,
      formatDate,
      updateStore,
      addStore,
      showDeletePopup,
      visible,
      currentPage,
      loading,
      rules,
      afterOpenChange,
      cancelUpdate,
      handlePageChange,
      modalWidth,
      updateModalWidth,

      openTransferModal,
      showTransferModal,
      handleCancel,
      transferFormRef,
      transferForm,
      transfer,
      transferRules,
      state,
      fetchTarget,
      fetchDestination,
      selectedSenderStore,
      handleTypeChange,
      fetchItems,
      handleMultiChange,

      getValidatingStatus,
      getValidatingHelp,




      
    };
  },
});
</script>
   
   <style scoped>
.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.table-container {
  overflow-x: auto;
}

.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.test-btn {
  color: #bfffca8a;
}

.breadcrumb-icon {
  cursor: pointer;
}
.input-form[disabled] {
  background-color: white;
  color: #000000;
}
.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.select-input {
  width: 100%;
}


.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}
/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-rtl {
  direction: rtl;
}

.paginationArrows {
  direction: ltr;
}

.action-btn-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.tooltip-icon{
  margin-right: 5px;
}


/*meduim*/
@media (min-width: 768px) {

  .pagination {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .select-input {
    width: 40%;
  }

  
}

/*large*/
@media (min-width: 1024px) {

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .select-input {
    width: 20%;
  }
    

}
</style>